import React, {Component} from "react";
import {Link} from "react-router-dom";
import Modal from "../common/Modal";
import Form from "../common/form/Form";
import ok from "../../../img/notification_ready.svg";
import {isSupportUser} from "../../tools/tools";
import PropTypes from "prop-types";

export class OrderStatus extends Component{

    render(){
        let {
            className,
            status,
            paused
        } = this.props;

        if (!status) return null;

        if (Array.isArray(status)) {
            var statusInfo = [];

            status.map((statusStep, key) => {

                statusInfo.push(
                    <div key={key} className={"status-step u-tooltip_theme_default"+(statusStep.IsAccomplished ? " status-step_complete" : (statusStep.IsCurrent ? " status-step_process" : ""))}>
                    {statusStep.IsCurrent ? <span>{statusStep.StatusName}</span> : ""}
                    {statusStep.ToolTip ? <div className="u-tooltip__container">{statusStep.ToolTip}</div> : null}
                    {statusStep.IsCurrent === 0 && statusStep.IsAccomplished === 0 && !statusStep.ToolTip ?
                        <div className="u-tooltip__container u-tooltip__nextstatus">{statusStep.StatusName}</div>
                    : null}
                </div>);
            })
        } else {
            var iconClass = "";
            switch (status.IconName) {
                case 'done':
                    iconClass = "status-info_complete";
                    break;
                case 'decline':
                    iconClass = "status-info_warning";
                    break;
                case 'abandon':
                    iconClass = "status-info_complete_almost";
                    break;
                case 'recall':
                    iconClass = "status-info_decline";
                    break;
            }
            var statusInfo = <div className={"status-step u-tooltip_theme_default "+iconClass}>
                <span>{status.StatusName}</span>
                {status.ToolTip ? <div className="u-tooltip__container">{status.ToolTip}</div> : null}
            </div>
        }
        return <div className={className ? className : "applications__item-status"}>
            <div>{statusInfo}</div>
            {paused ? <div className="status-info status-info_pause">
                <span><i>Работа приостановлена до </i>{paused}</span>
            </div> : null}
        </div>
    }
}

export class OrderActions extends Component{

    constructor(props){
        super(props);
        this.state = {
            modal: null,
            isFromAdmin: false,
        }
    }
    componentDidMount() {
        this.setState({isFromAdmin: window.location.href.indexOf('frontend') !== -1})
    }


    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps){
        if ((this.props.isActionOk != nextProps.isActionOk) && nextProps.isActionOk && this.state.sendRequest) {
            this.setState({modal: 'success', sendRequest: false});
            setTimeout(this.closeModal, 2000);
        }
        if ((this.props.actionMessage != nextProps.actionMessage) && nextProps.actionMessage) {
            this.setState({sendRequest: false});
        }
    }

    onClick = (action) => {
        var id = this.props.id;
        switch (action) {
            case 'send':
                return (e) => {
                    e.preventDefault();
                    this.props.sendApplication(id);
                };
            case 'recall':
            case 'request':
            case 'resume':
                return (e) => {
                    e.preventDefault();
                    this.setState({modal: action});
                };
            case 'documents':
                return (e) => {
                    e.preventDefault();
                    this.props.uploadDocuments();
                };
            case 'print':
                return (e) => {
                    e.preventDefault();
                    this.props.pdfApplicationFormAction(id);
                };
            case 'sign-edo':
                return (e) => {
                    e.preventDefault();
                    if(this.state.isFromAdmin) {
                        this.props.openModalDiadocFromAdminError('diadoc_enter_from_admin_user')
                    } else {
                        this.props.writeOrderFromList()
                        this.props.startApplicationDiadocAction('', id);
                    }
                };
            default:
                return (e) => {
                    e.preventDefault();
                };
        }

    };

    closeModal = () => {
        this.setState({modal: null})
    };

    onSubmit = (callback) => {
        return (id, desc) => {
            if (this.state.sendRequest) {
                return false;
            } else {
                this.setState({sendRequest: true}, () => {
                    callback(id, desc);
                });
            }
        }
    };

    renderModal = () => {
        if (!this.state.modal) return null;

        let {
            id,
            recallApplication,
            requestApplication,
            resumeApplication,
            actionMessage,
            modalBtnIsDisabled
        } = this.props;

        switch (this.state.modal){
            case "recall":
                return <Modal modalSize="l" classNameContent="modal modal_visible" onClickBackground={this.closeModal}>
                    <RecallForm
                      id={id}
                      message={actionMessage}
                      onSubmit={this.onSubmit(recallApplication)}
                      modalBtnIsDisabled={modalBtnIsDisabled}
                    />
                </Modal>;
            case "request":
                return <Modal modalSize="l" classNameContent="modal modal_visible" onClickBackground={this.closeModal}>
                    <RequestForm
                      id={id}
                      message={actionMessage}
                      onSubmit={this.onSubmit(requestApplication)}
                      modalBtnIsDisabled={modalBtnIsDisabled}
                    />
                </Modal>;
            case "resume":
                return <Modal modalSize="l" classNameContent="modal modal_visible" onClickBackground={this.closeModal}>
                    <ResumeForm id={id} message={actionMessage} onSubmit={this.onSubmit(resumeApplication)}/>
                </Modal>;
            case "success":
                return <Modal modalSize="s" classNameContent="modal modal_visible modal-wrapper modal__xs text-center" onClickBackground={this.closeModal}>
                        <p><img src={ok} /></p>
                        <h2 className="form__title">Ваш запрос успешно отправлен</h2>
                </Modal>;
            default:
                return null;
        }

    };

    render() {
        let {
            actions,
            id,
            projectId,
            firstAsBtn = false
        } = this.props;

        var actionsLinks = [];

        actions.map((name, key) => {
            switch (name) {
                case 'send':
                    actionsLinks.push(<div key={name}>
                        <a className={(key === 0 && firstAsBtn ? "btn" : "applications__action applications__action_send")} href="#" onClick={this.onClick('send')}>{this.context.translate('Отправить')}</a>
                    </div>);
                    break;
                case 'edit':
                    actionsLinks.push(<div key={name}>
                        <Link className={(key === 0 && firstAsBtn ? "btn" : "applications__action applications__action applications__action_edit")} to={"/applications/update/"+id}>{this.context.translate('Редактировать')}</Link>
                    </div>);
                    break;
                case 'resend':
                    actionsLinks.push(<div key={name}>
                        <Link className={(key === 0 && firstAsBtn ? "btn" : "applications__action applications__action_send")} to={{
                            pathname: "/applications/create",
                            state: {from: 'recreate', id}
                        }}>{this.context.translate('Отредактировать и отправить заново')}</Link>
                    </div>);
                    break;
                case 'project':
                    actionsLinks.push(<div key={name}>
                        <Link className={(key === 0 && firstAsBtn ? "btn" : "applications__action applications__action_project")} to={"/projects/"+projectId}>{this.context.translate('Перейти в проект')}</Link>
                    </div>);
                    break;
                case 'request':
                    actionsLinks.push(<div key={name}>
                        <a className={(key === 0 && firstAsBtn ? "btn" : "applications__action applications__action_request")} href="#" onClick={this.onClick('request')}>{this.context.translate('Запросить изменение условий')}</a>
                    </div>);
                    break;
                case 'add-docs':
                    actionsLinks.push(<div key={name}>
                        <a className={(key === 0 && firstAsBtn ? "btn" : "applications__action applications__action_add")} href="#" onClick={this.onClick('documents')}>{this.context.translate('Загрузить документы')}</a>
                    </div>);
                    break;
                case 'recall':
                    actionsLinks.push(<div key={name}>
                        <a className={(key === 0 && firstAsBtn ? "btn" : "applications__action applications__action_recall")} href="#" onClick={this.onClick('recall')}>{this.context.translate('Отозвать заявку')}</a>
                    </div>);
                    break;
                case 'resume':
                    actionsLinks.push(<div key={name}>
                        <a className={(key === 0 && firstAsBtn ? "btn" : "applications__action applications__action_recall")} href="#" onClick={this.onClick('resume')}>{this.context.translate('Возобновить работу')}</a>
                    </div>);
                    break;
                // case 'print':
                //     actionsLinks.push(<div key={name}>
                //         <Link className={(key === 0 && firstAsBtn ? "btn" : "applications__action applications__action_print")} target={isSupportUser ? "" : "_blank"} to={"/applications/pdf/"+id}>{this.context.translate('Распечатать бланк заявки3')}</Link>
                //     </div>);
                //     break;

                case 'print':
                    actionsLinks.push(<div key={name}>
                        <a 
                            className={(key === 0 && firstAsBtn ? "btn" : "applications__action applications__action_print")} 
                            href="#" 
                            onClick={this.onClick('print')} 
                        >
                            {this.context.translate('Распечатать бланк заявки')}
                        </a>
                    </div>);
                    break;
                case 'sign-edo':
                    actionsLinks.push(<div key={name}>
                        <a 
                            className={(key === 0 && firstAsBtn ? "btn" : "applications__action applications__action_print")} 
                            href="#" 
                            onClick={this.onClick('sign-edo')} 
                        >
                            {this.context.translate('Подписать по ЭДО')}
                        </a>
                    </div>);
                    break;
            }
        });

        return <div className="applications__item-actions">
            {actionsLinks}
            {this.renderModal()}
        </div>
    }
}

class RecallForm extends Component{

    constructor(props){
        super(props);
        this.state = {
            RecallReason: ""
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    onChange = (e) => {
        this.setState({RecallReason: e.target.value});
    };

    onSubmit = () => {
        if(!this.props.modalBtnIsDisabled) this.props.onSubmit(this.props.id, this.state.RecallReason);
    };

    render(){
        return <Form className="form_modal"
                     validate={false}
                     onSubmit={this.onSubmit}
        >
            <h2 className="form__title">{this.context.translate('Отозвать заявку')}</h2>
            <div className="form__row">
                <div className="form__field">
                    <div className="form__textarea">
                        <textarea onChange={this.onChange} placeholder={this.context.translate('Введите причину отзыва заявки')}/>
                    </div>
                    {this.props.message ? <div className="form__text">
                        <span className="error">{this.props.message}</span>
                    </div> : null}
                </div>
            </div>

            <div className="form__buttons">
                <button className={"btn"+((!this.state.RecallReason || this.props.modalBtnIsDisabled) ? " disabled" : "")} type="submit">{this.context.translate('Отозвать')}</button>
            </div>
        </Form>
    }
}

class RequestForm extends Component{

    constructor(props){
        super(props);
        this.state = {
            Description: "",
            preventSubmit: false
        }
    }
    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    onChange = (e) => {
        this.setState({Description: e.target.value});
    };

    onSubmit = () => {
        if(!this.props.modalBtnIsDisabled) this.props.onSubmit(this.props.id, this.state.Description);
    };

    render(){
        return <Form className="form_modal"
                     validate={false}
                     onSubmit={this.onSubmit}
        >
            <h2 className="form__title">{this.context.translate('Запросить изменение условий')}</h2>
            <div className="form__row">
                <div className="form__field">
                    <div className="form__textarea">
                        <textarea onChange={this.onChange} placeholder={this.context.translate('Описание запрашиваемых изменений')}/>
                    </div>
                    {this.props.message ? <div className="form__text">
                        <span className="error">{this.props.message}</span>
                    </div> : null}
                </div>
            </div>

            <div className="form__buttons">
                <button className={"btn"+((!this.state.Description || this.props.modalBtnIsDisabled) ? " disabled" : "")} type="submit">{this.context.translate('Запросить')}</button>
            </div>
        </Form>
    }
}

class ResumeForm extends Component{

    constructor(props){
        super(props);
        this.state = {
            Description: ""
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    onChange = (e) => {
        this.setState({Description: e.target.value});
    };

    onSubmit = () => {
        this.props.onSubmit(this.props.id, this.state.Description);
    };

    render(){
        return <Form className="form_modal"
                     validate={false}
                     onSubmit={this.onSubmit}
        >
            <h2 className="form__title">{this.context.translate('Возобновить работу по заявке')}</h2>
            <div className="form__row">
                <div className="form__field">
                    <div className="form__textarea">
                        <textarea onChange={this.onChange} placeholder={this.context.translate('Комментарий')}/>
                    </div>
                    {this.props.message ? <div className="form__text">
                        <span className="error">{this.props.message}</span>
                    </div> : null}
                </div>
            </div>

            <div className="form__buttons">
                <button className={"btn"+(!!this.state.Description ? "" : " disabled")} type="submit">{this.context.translate('Возобновить')}</button>
            </div>
        </Form>
    }
}