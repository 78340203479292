import React, { Component } from 'react';
import {Link} from "react-router-dom";
import OrdersListItem from "../orders/OrdersListItem";
import {getWord} from "../../tools/tools";
import Modal from "../common/Modal";
import PropTypes from "prop-types";

export default class ApplicationsWidget extends Component{

    constructor(props){
        super(props);
        this.state = {
            modal: false
        }
    }
    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    toggleModal = (e) => {
        if (e) e.preventDefault();
        this.setState((prevState) => {
            return {
                modal: !prevState.modal
            }
        })
    }

    render(){

        let {
            items,
            banner,
            widgetCustom,
        } = this.props;

        let isItems = items && items.isOk && items.length;
        let typeImg = widgetCustom && widgetCustom.image && widgetCustom.image.extension;
        let contentImg = widgetCustom && widgetCustom.image && widgetCustom.image.file_content;

        return <div className="main-page__section main-page__section_banner">
            <div className={"main-banner main-banner_adapt-default"+(banner && banner.ExpirationDays ? " main-banner_standalone" : "")}>
                {/* {banner && banner.ExpirationDays ? <div className="main-banner__header">
                        <p className="main-banner__header-counter">
                            <span className="main-banner__header-counter-value">{banner.ExpirationDays}</span>
                            <span className="main-banner__header-counter-text">{getWord(
                                banner.ExpirationDays,
                                this.context.translate('День остался'),
                                this.context.translate('Дня осталось'),
                                this.context.translate('Дней осталось')
                            )}</span>
                        </p>
                        <p className="main-banner__header-title">
                            <nobr>{this.context.translate('Вам одобрено')}</nobr><br/>
                            <span className="u-bold"><nobr>{banner.Amount}</nobr></span>
                        </p>
                        <a className="main-banner__header-button u-btn u-btn_orange u-btn_narrow u-btn_adapt-type_wide" href="#" onClick={this.toggleModal}>{this.context.translate('Подробнее')}</a>
                    </div> :
                <div className="main-banner__header">
                    <p className="main-banner__header-title">{this.context.translate('Выгодные условия лизинга')}</p>
                    <p className="main-banner__header-subtitle">{this.context.translate('для вашего бизнеса')}</p>
                    <Link to="/applications/create" className="btn btn_orange">{this.context.translate('Отправить заявку')}</Link>
                </div>} */}
                {widgetCustom
                    && (
                        <div className="main-banner__header_custom">
                            <div className="main-banner__header_custom_text">
                                <p
                                    className="main-banner__header_custom-title"
                                    dangerouslySetInnerHTML={{ __html: this.context.translate(`${widgetCustom && widgetCustom.title}`) }}
                                />
                                <p
                                    className="main-banner__header_custom-subtitle"
                                    dangerouslySetInnerHTML={{ __html: this.context.translate(`${widgetCustom && widgetCustom.description}`) }}
                                />
                            </div>
                            <div className="main-banner__header_custom_wrap_img">
                                {typeImg === 'svg'
                                    && (
                                        <div
                                            className="main-banner__header_custom_img"
                                            dangerouslySetInnerHTML={{ __html: contentImg }}
                                        /> 
                                    )}
                                {(typeImg === 'png' || typeImg === 'jpg' || typeImg === 'jpeg')
                                    && (
                                        <div className="main-banner__header_custom_img">
                                            <img src={contentImg} alt="Изображение" />
                                        </div>
                                    )}
                            </div>
                        </div>
                    )}
                
                {!widgetCustom
                    && (
                        <div className="main-banner__header">
                            <p className="main-banner__header-title">{this.context.translate('Выгодные условия лизинга')}</p>
                            <p className="main-banner__header-subtitle">{this.context.translate('для вашего бизнеса')}</p>
                            <Link to="/applications/create" className="btn btn_orange">{this.context.translate('Отправить заявку')}</Link>
                        </div>
                    )}
                
                {isItems ? <p className="main-banner__title u-mobile-only">{this.context.translate('Заявки')}</p> : null}
                {isItems ? items.map((item, key) => <OrdersListItem item={item}
                                                                    IsHome={true}
                                                          key={key}
                                                          className="application_in_progress applications__item"
                />) : null}
            </div>
            {isItems ? <div className="main-page__show-all">
                <Link className="u-all-link" to="/applications">
                    {this.context.translate('Показать все')}<sup className="u-all-link__sup">({items.pagination && items.pagination.totalCount})</sup>
                </Link>
            </div> : null}
            {this.state.modal ? <Modal modalSize="m" classNameContent="modal modal_visible modal_fullscreen" onClickBackground={this.toggleModal}>
                <InvestPotential details={banner.Details} translate={this.context.translate}/>
            </Modal> : null}
        </div>
    }
}

function InvestPotential({details, translate}) {

    return  <div className="main-modal main-modal_adapt-default">
            <p className="main-modal__title">{translate('Одобренное предложение')}</p>
            <div className="main-modal__financing-list">
                {details.map((item, key) => <div className="main-modal__financing" key={key}>
                    <p className="main-modal__text" dangerouslySetInnerHTML={{
                        __html: translate("Для компании {companies} одобрено финансирование в размере", {companies: '<span className="u-bold">'+item.LesseesList+'</span>'})
                    }}/>
                    <div className="main-modal__list">
                        {Array.isArray(item.InvestmentPotentialList) ? item.InvestmentPotentialList.map((potential, potentialKey) => <div className="main-modal__item" key={potentialKey}>
                            <span className="main-modal__item-name">{potential.ProductName}</span>
                            <span className="main-modal__item-value">{potential.Amount}</span>
                        </div>) : <div className="main-modal__item">
                            <span className="main-modal__item-name">{item.InvestmentPotentialList.ProductName}</span>
                            <span className="main-modal__item-value">{item.InvestmentPotentialList.Amount}</span>
                        </div>}
                    </div>
                    <p className="main-modal__text">
                        {translate("на срок не менее {n} {unit} с графиком платежей и списком поручительств, аналогичными договору", {
                            n: item.MinContractTerm,
                            unit: getWord(item.MinContractTerm, translate('месяца'), translate('месяцев'), translate('месяцев'))
                        })}&nbsp;<nobr>{item.ContractNumber}</nobr>
                        &nbsp;<Link to={"/projects/dfa/"+item.ContractId}>«{item.ContractName}».</Link>&nbsp;
                    </p>
                    <p className="main-modal__text main-modal__text_validity">
                        {translate("Предложение действительно до")}&nbsp;
                        <span className="u-bold">{item.ExpirationDate}</span>
                    </p>
                </div>)}
            </div>
            <div className="main-modal__button-wrap">
                <Link className="main-modal__button u-btn u-btn_orange u-btn_adapt-type_wide" to={{pathname: "/applications/create", state: {from: "potential"}}}>{translate("Отправить заявку")}</Link>
            </div>
        </div>
}