import React, {Component} from "react";

import {Link, NavLink} from "react-router-dom";
import ProjectsSearch from "./ProjectsSearch";
import ProjectsList, {EmptyList} from "../../components/projects/ProjectsList";
import Preloader from "../common/Preloader";
import PropTypes from "prop-types";

export default class Projects extends Component {

    constructor(props){
        super(props);

        this.state = {
            filterOpened: null,
            activeTab: props.projects && props.projects.hasOwnProperty('active') ? (props.location.state && props.location.state.activeTab || 1) : 0
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps){
        if (nextProps.projects != this.props.projects) {
            this.setState({activeTab: nextProps.projects.hasOwnProperty('active') ? (this.state.activeTab ? this.state.activeTab : 1) : 0})
        }
    }

    toggleFilter = (e) => {
        e.preventDefault();
        setTimeout( () => {
            this.setState((prevState) => {
                return {
                    filterOpened: !prevState.filterOpened
                }
            })
        }, 300)
    }


    toTab = (num) => {
        return (e) => {
            e.preventDefault();
            this.setState({activeTab: num});
        }
    }

    renderTabs = () => {
        let {
            active,
            archive
        } = this.props.projects;

        var activeTab = this.state.activeTab;

        return activeTab ? <div className="tabs__menu">
            <Link to={"#"} className={"tabs__menu-link"+(activeTab === 1 ? " active" : "")} onClick={this.toTab(1)}>{this.context.translate('Активные')}<sup>({active.length})</sup></Link>
            <Link to={"#"} className={"tabs__menu-link"+(activeTab === 2 ? " active" : "")} onClick={this.toTab(2)}>{this.context.translate('Архив')}<sup>({archive.length})</sup></Link>
        </div> : null
    }

    render(){

        let {
            filterOpened,
            activeTab
        } = this.state;

        let {
            projects,
            totalCount,
            totalContractsCount,
            isOk,
            isSearch,
            isEmpty,
            catalog,
            user,
            autocomplete,
            search,
            downloadContractSchedule,
            children,
            getDocuments
        } = this.props;

        return <div className="page page_projects">
            <div className="page__main page__main_projects">
                <div className="page__content-container">
                    <div className="page__title-section">
                        <h1 className="page__title">{this.context.translate('Проекты')}</h1>
                        {isEmpty ? null : <div className="page__title-ui">
                            <span className={"u-more-link"+(filterOpened ? " u-more-link_open" : "")} onClick={this.toggleFilter}>{this.context.translate('Поиск')}</span>
                        </div>}
                    </div>
                    { isEmpty ? <EmptyList translate={this.context.translate}/> : <div>
                        <div className={"page__filter toggle-panel__content"+(filterOpened === true ? " down" : (filterOpened === false ? " up" : ""))}>
                            <div className="items-filter items-filter_projects">
                                {catalog.isOk ? <ProjectsSearch catalog={catalog}
                                                autocomplete={autocomplete}
                                                search={search}
                                                contractsCnt={totalContractsCount}
                                                projectsCnt={totalCount}
                                                filtered={activeTab === 0}
                                /> : null}
                            </div>
                        </div>
                        {isOk ? this.renderTabs() : null}
                        <div className="tabs">
                            {children}
                            <ProjectsList lesseeCount={(catalog.companies.filter(function (item) {
                                return item.IsSelected;
                            })).length} list={user && !!user.is_full_access ?
                                (activeTab ? (activeTab === 1 ? projects.active : projects.archive) : projects) :
                                {active: [], archive: []}
                            }
                                          downloadContractSchedule={downloadContractSchedule}
                                          activeTab={activeTab}
                                          getDocuments={getDocuments}
                            />
                            {isSearch || projects && !isOk ? <Preloader /> : null}
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    }
}