import React, {Component} from "react";
import Form from "../common/form/Form";
import FormField from "../common/form/FormField";
import PropTypes from "prop-types";

export default class DocumentsSearchForm extends Component{

    constructor(props){
        super(props);

        this.state = {
            DocumentName: {
                type: 'auto-complete',
                label: "Название документа",
                placeholder: "Например: счет-фактура, акт сверки",
                value: props.data && props.data.DocumentName || "",
                options: props.catalog && props.catalog.documentNames || [],
                optionsType: 'documentNames',
            },
            EntityNumber: {
                type: 'text',
                label: "Заявка или договор лизинга",
                placeholder: "Номер договора или название заявки",
                value: props.data && props.data.EntityNumber || "",
            },
            date: {
                type: 'date-range',
                label: "Дата отправки",
                value: "",
                showPeriods: true,
                yearSelection: true,
                canWrite: true,
            },
            LesseeId: {
                type: 'select',
                label: "Лизингополучатель",
                placeholder: "Укажите компанию",
                value: props.catalog && props.catalog.documentsLessees && props.catalog.documentsLessees.length == 1 ? props.catalog.documentsLessees[0].value : "",
                options: props.catalog && props.catalog.documentsLessees || [],
                optionsType: 'documentsLessees',
                allowEmpty: true,
                allowSearch: true,
                hidden: !props.catalog || !props.catalog.companies || props.catalog.companies.length == 1 || !props.catalog.documentsLessees || props.catalog.documentsLessees.length == 0,
                disabled: props.catalog && props.catalog.documentsLessees && props.catalog.documentsLessees.length == 1
            },
            Statuses: {
                type: 'select',
                label: "Статус документа",
                placeholder: "Укажите статус",
                value: props.catalog && props.catalog.documentStatus && props.catalog.documentStatus.length == 1 ? props.catalog.documentStatus[0].value : "",
                options: props.catalog && props.catalog.documentStatus || [],
                optionsType: 'documentStatus',
                allowEmpty: true,
                allowSearch: true,
            },
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    addCatalogOptions = (state, catalog) => {
        for (var key in state) {
            if (state[key].hasOwnProperty('optionsType') && catalog[state[key].optionsType] && state[key].options != catalog[state[key].optionsType]) {
                state[key].options = catalog[state[key].optionsType];

                if (key == 'LesseeId') {
                    if (!catalog.companies || catalog.companies.length == 1 || !catalog.documentsLessees || catalog.documentsLessees.length == 0) {
                        state.LesseeId.value = "";
                        state.LesseeId.disabled = false;
                        state.LesseeId.hidden = true;
                    } else if (catalog.documentsLessees.length == 1) {
                        state.LesseeId.value = catalog.documentsLessees[0].value;
                        state.LesseeId.disabled = true;
                        state.LesseeId.hidden = false;
                    } else {
                        state.LesseeId.value = "";
                        state.LesseeId.disabled = false;
                        state.LesseeId.hidden = false;
                    }
                }
            }

        }
        return state;
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.catalog != this.props.catalog) {
            var state = {...this.state};
            state = this.addCatalogOptions(state, nextProps.catalog);
            this.setState(state);
        }
        if (nextProps.data != this.props.data) {
            var state = {...this.state};
            for (var key in nextProps.data) {
                if (state.hasOwnProperty(key)) state[key].value = nextProps.data[key];
            }
        }
    }

    componentDidUpdate(prevProps) {
        // когда переключаем табы ВХОДЯЩИЕ, ИСХОДЯЩИЕ очищаем фильтр
        if (prevProps.direction !== this.props.direction) {
            this.onReset();
        }
    }

    onChangeField = (attribute, value) => {
        var state = {...this.state};
        if (state.hasOwnProperty(attribute)) {
            state[attribute].value = value;
            this.setState(state);
        }
    };

    renderField = (attribute) => {
        var fields = this.state;

        return fields.hasOwnProperty(attribute) && !fields[attribute].hidden ?
            <FormField fieldConfig={{
                ...fields[attribute],
                label: this.context.translate(fields[attribute].label),
                placeholder: this.context.translate(fields[attribute].placeholder)
            }}
                       attribute={attribute}
                       onChange={this.onChangeField}
                       className={fields[attribute].fieldClassName || ""}
            /> : null;
    };

    onReset = () => {
        var state = {...this.state};
        for (var attribute in state) {
            state[attribute].value = '';
        }
        this.setState(state);
        this.props.search();
    };

    onSubmit = () => {
        var state = {...this.state};

        if (state.date.value) {
            if (state.date.value.from) state.UploadDateStart = {value: (state.date.value.from).split('.').reverse().join('-')};
            if (state.date.value.to) state.UploadDateEnd = {value: (state.date.value.to).split('.').reverse().join('-')};
        }
        delete state['date'];
        this.props.search(state);
    }

    render(){

        var attributes = Object.keys(this.state);

        return <Form validate={true} onSubmit={this.onSubmit}>
            <div className="items-filter__body">
                <div className="items-filter__cols">
                    {attributes.map((attr) => <div className="items-filter__half-col" key={attr}>
                        {this.renderField(attr)}
                    </div>)}
                </div>
            </div>
            <div className="items-filter__ui-holder">
                <div className="items-filter__ui-item">
                    <button className="u-btn" type="submit">{this.context.translate('Найти')}</button>
                </div>
                <div className="items-filter__ui-item">
                    <button className="u-reset-link" type="reset" onClick={this.onReset}>
                        <span>{this.context.translate('Сбросить')}</span>
                    </button>
                </div>
            </div>
        </Form>
    }
}