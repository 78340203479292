import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import PropTypes from "prop-types";
import FormField from "../common/form/FormField";
import Form from "../common/form/Form";
import {getWord} from "../../tools/tools";
import {noSpace, numberMask} from "../../tools/mask";
import ready from "../../../img/notification_ready.svg";

export default class Recovery extends Component {
    constructor(props, context){
        super(props, context);

        this.state = {
            time: 0,
            token: location.search ? location.search.substr(7) : null,
            fieldsRecovery: {
                email: {
                    type: 'text',
                    label: "E-mail",
                    name: "email",
                    value: "",
                    required: true,
                    error: '',
                    mask: noSpace,
                }
            },
            fieldsCodevalidate: {
                code: {
                    type: 'text',
                    label: "Введите код подтверждения, отправленный Вам в SMS:",
                    name: "authCode",
                    value: "",
                    // required: true,
                    error: '',
                    mask: numberMask(4),
                    min: 4,
                    ref: (input) => { this.codeInput = input; }
                }
            }
        };

    }

    componentWillReceiveProps(nextProps){
        if (this.props.recoveryErrors != nextProps.recoveryErrors) {
            var state = {...this.state};
            for (var attr in state.fieldsRecovery) {
                state.fieldsRecovery[attr].error = nextProps.recoveryErrors && nextProps.recoveryErrors[attr] || ''
            }
            this.setState(state);
        }
        if (this.props.codevalidateErrors != nextProps.codevalidateErrors) {
            var state = {...this.state};
            for (var attr in state.fieldsCodevalidate) {
                state.fieldsCodevalidate[attr].error = nextProps.codevalidateErrors[attr] || ''
            }
            this.setState(state);
        }
        if (this.props.triesMore != nextProps.triesMore) {
            clearInterval(this.timer);
            this.setState({time: 30}, () => {
                this.timer = setInterval(this.setTime, 1000);
            });
        }
        if (this.props.fields.code != nextProps.fields.code) {
            this.setState({fieldsCodevalidate: {code: {...this.state.fieldsCodevalidate.code, value: nextProps.fields.code}}});
        }
    }

    static contextTypes = {
        screenSize: PropTypes.string,
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    funcState = (prevState) => {
        if(this.state.time < 1) {
            clearInterval(this.timer);
            return {time: 0};
        }
        var timer = --prevState.time;
        if (timer < 10) timer = '0'+(timer.toString());
        return {time: timer};
    };

    setTime = () => {
        this.setState(this.funcState);
    };


    onChangeField = (name) => {
        return (attribute, value, error) => {
            var prevState = {...this.state[name]};
            if (prevState.hasOwnProperty(attribute)) {
                prevState[attribute].value = value;
                prevState[attribute].error = error;
                this.setState({
                    ...this.state,
                    [name]: prevState
                })
            }
        }
    };

    onCodeRequest = () => {

        var {
            email
        } = this.state.fieldsRecovery;

        var body = new FormData();
        body.append('email', email.value);

        this.codeRequestBtn.focus();

        this.props.recovery('code-send', body);
    }

    onRepeatCodeRequest = (e) => {
        e.preventDefault();
        this.setState({time: 30}, () => {
            this.timer = setInterval(this.setTime, 1000);
            this.onCodeRequest();
        });
    }

    onCodeValidate = () => {
        var body = new FormData();
        body.append('email', this.props.fields.email);
        body.append('code', this.state.fieldsCodevalidate.code.value);

        this.props.recovery('code-validate', body);
    }

    onChangePassword = (password) => {
        var body = new FormData();
        body.append('password', password);
        body.append('recovery_token', this.state.token);

        this.props.recovery('change-password', body);
    }

    isValid = (name) => {
        var fields = {...this.state[name]};
        for (var attr in fields) {
            if (fields[attr].error || fields[attr].required && !fields[attr].value) return false;
        }
        return true;
    }

    renderFields = (fields, name) => {
        var result = [];

        for (var attribute in fields) {
            if (fields.hasOwnProperty(attribute)) {

                result.push(<div className="form__row" key={name+attribute}>
                    <FormField fieldConfig={{...fields[attribute], label: this.context.translate(fields[attribute].label)}}
                               attribute={attribute}
                               onChange={this.onChangeField(name)}
                    />
                </div>)
            }
        }

        return result;
    };

    render() {

        if (location.search) return <Redirect to="recovery" push={false}/>

        let {
            fieldsRecovery,
            fieldsCodevalidate,
            time,
            token
        } = this.state;

        let {
            step,
            triesMore,
            toCodeRequest,
            sendRequest
        } = this.props;

        return (<div className="auth__form">
            <h3 className="reg__title">Восстановление пароля</h3>
            <Form className={"auth__form-slide"+(step == 1 ? " active" : "")}
                  validate={true}
                  onSubmit={this.onCodeRequest}
            >
                {this.renderFields(fieldsRecovery, 'fieldsRecovery')}
                <p>
                    <Link to="/login">{this.context.translate("Вспомнили пароль?")}</Link>
                </p>
                <div className="form__buttons">
                    <button type="submit"
                            className={"btn btn_orange"+(this.isValid('fieldsRecovery') && !sendRequest ? "" : " disabled")}
                            ref={(button) => {this.codeRequestBtn = button;}}
                    >{this.context.translate("Восстановить")}</button>
                </div>
            </Form>
            <Form className={"auth__form-slide"+(step == 2 ? " active" : "")}
                  validate={true}
                  onSubmit={this.onCodeValidate}
            >
                {this.renderFields(fieldsCodevalidate, 'fieldsCodevalidate')}
                {triesMore ? triesMore === 0 ? <div className="form__message">
                        <div className="form__message-fix">
                            <p>{this.context.translate("Ваш аккаукнт заблокирован.")}</p>
                        </div>
                    </div> : <div className="form__message">
                    <div className="form__message-fix">
                        <p>{this.context.translate("У вас осталось {n} {unit} ввести код, после чего аккаунт будет заблокирован.", {
                            n: triesMore,
                            unit: getWord(triesMore, 'попытка', 'попытки', 'попыток')
                        })}</p>
                        {time ? <p>{this.context.translate("Вы сможете запросить код повторно через {n} сек.", {
                            n: "00:"+time
                        })}</p> : null}
                    </div>
                    <a href="#" className={time == 0 ? "" : "disabled"}
                       onClick={this.onRepeatCodeRequest}>{this.context.translate("Запросить повторно")}</a>
                </div> : null}
                <div className="form__buttons">
                    <button className="btn btn_clear_blue" type="button" onClick={toCodeRequest}>{this.context.translate("Назад")}</button>
                    <button className={"btn btn_orange"+(this.isValid('fieldsCodevalidate') && !sendRequest ? "" : " disabled")} type="submit">{this.context.translate("Подтвердить")}</button>
                </div>
            </Form>
            {step === 3 ? <div className="email-send">
                <p><img src={ready} /></p>
                {this.context.translate("На ваш e-mail, указанный в Договоре, отправлен временный пароль.")}
                <p>
                    <Link to="/login">{this.context.translate("Войти")}</Link>
                </p>
            </div> : null}
        </div>)
    }
}