import {parseErrors} from "../tools/api-helper";

const initState = {isOk: false, notFullUser: false};

export function profile(state = initState, action) {
    switch (action.type) {
        case 'PROFILE_INFO':
            return {isOk: false, notFullUser: false};
        case 'PROFILE_INFO_SUCCESS':
            return {isOk: true, ...action.data};
        case 'PROFILE_NOT_FULL_USER':
            return {isOk: true, notFullUser: true};
        case 'CHANGE_PASSWORD':
            return {...state, passwordErrors: {}};
        case 'CHANGE_PASSWORD_SUCCESS':
            var newState = {...state};
            delete newState['passwordErrors'];
            return newState;
        case 'CHANGE_PASSWORD_VALIDATION_ERROR':
            var errors = parseErrors(action.data);
            return {...state, passwordErrors: errors};
        case 'SAVE_NOTIFICATION_SETTINGS':
        case 'DATA_CHANGE_REQUEST':
            return {...state, savingStatus: 'load', message: ""};
        case 'SAVE_NOTIFICATION_SETTINGS_SUCCESS':
        case 'DATA_CHANGE_REQUEST_SUCCESS':
            return {...state, savingStatus: 'success', message: ""};
        case 'SAVE_NOTIFICATION_SETTINGS_ERROR':
        case 'DATA_CHANGE_REQUEST_ERROR':
        case 'SAVE_NOTIFICATION_SETTINGS_BAD_REQUEST':
        case 'DATA_CHANGE_REQUEST_BAD_REQUEST':
            return {...state, savingStatus: 'error', message: action.data.message};
        case "LOGOUT_SUCCESS":
            return {...initState};
    }
    return state;
}