import React, {Component} from "react"
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {companiesRequest} from "../../actions/companies";
import CompanyForm from "../../components/companies/CompanyForm";
import Modal from "../../components/common/Modal";
import PropTypes from "prop-types";

import ok from "../../../img/notification_ready.svg";

class CompanyChangeRequestContainer extends Component{

    constructor(props){
        super(props);

        this.state = {
            modal: false
        }

    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps){
        //success company request
        if (nextProps.detail != this.props.detail && !nextProps.detail) {
            this.setState({modal: true});
            this.closeTimeout = setTimeout(this.closeModal, 3000);
        }
    }

    componentWillUnmount(){
        clearTimeout(this.closeTimeout);
    }

    closeModal = () => {
        this.setState({modal: false});
        this.props.history.push('/companies');
    }

    sendCompaniesRequest = (body) => {
        this.props.companiesRequest(this.props.match.params.id, body)
    };

    render(){
        var {
            detail
        } = this.props;

        return <div className="page">
            <div className="page__main page__main_default">
                <div className="page__content-container">
                    <div className="page__back-link">
                        <Link className="u-back-link" to="/companies">
                            <span>{this.context.translate('Компании')}</span>
                        </Link>
                    </div>
                    <h1 className="page__title">{this.context.translate('Запрос на изменение данных компании')}</h1>
                    <div className="page__company-info page__company-info_adapt-pad">
                        <div className="company-info company-info_adapt_default">
                            <CompanyForm data={detail} onSubmit={this.sendCompaniesRequest}/>
                        </div>
                    </div>
                </div>
            </div>
            {this.state.modal ? <Modal modalSize="s" classNameContent="modal modal_visible modal-wrapper modal__xs text-center" onClickBackground={this.closeModal}>
                <p><img src={ok} /></p>
                <h2 className="form__title">{this.context.translate('Ваш запрос успешно отправлен')}</h2>
            </Modal> : null}
        </div>
    }

}

export default connect(
    state => ({
        detail: state.companiesRequestForm.detail
    }),
    (dispatch) => {
        return {
            companiesRequest: (id, body) => dispatch(companiesRequest(id, body))
        }
    }
)(CompanyChangeRequestContainer)
