import React, {Component} from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import ru from 'date-fns/locale/ru';
import { isValidDateFormat, parseDate } from "../../../tools/tools";

export default class DateRange extends Component{

    constructor(props){
        super(props);
        this.state = {
            from: props.value && props.value.from ? moment(props.value.from.split('.').reverse().join('-'), 'DD.MM.YYYY') : null,
            to: props.value && props.value.to ? moment(props.value.to.split('.').reverse().join('-'), 'DD.MM.YYYY') : null
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };
    
    componentWillReceiveProps(nextProps){
        if (nextProps.value != this.props.value && !nextProps.value) {
            this.setState({
                from: null,
                to: null
            })
        }
    }

    componentDidMount(){
        this.dateFrom.getElementsByTagName('input')[0].readOnly = !this.props.canWrite;
        this.dateTo.getElementsByTagName('input')[0].readOnly = !this.props.canWrite;
        moment.locale('ru');
    }

    onDateFrom = (date, event) => {
        let {
            attribute,
            value,
            onChange
        } = this.props;

        if(event.target.value) {
            if(isValidDateFormat(event.target.value)) {
                let val = event.target.value;
            this.setState({from: parseDate(val)}, () => {
                onChange && onChange(attribute, {
                    from: parseDate(val) ? moment(parseDate(val)).format('DD.MM.YYYY') : null,
                    to: value.to
                })
            })}
        } else {
            this.setState({from: date}, () => {
                onChange && onChange(attribute, {
                    from: date ? moment(date).format('DD.MM.YYYY') : null,
                    to: value.to
                })
            })
        } 
    };

    onDateTo = (date, event) => {
        let {
            attribute,
            value,
            onChange
        } = this.props;
       
        if(event.target.value) {
            if(isValidDateFormat(event.target.value)) {
                let val = event.target.value;
                this.setState({to: parseDate(val)}, () => {
                onChange && onChange(attribute, {
                    from: value.from,
                    to: parseDate(val) ? moment(parseDate(val)).format('DD.MM.YYYY') : null,
                })
            })}
        } else {
            this.setState({to: date}, () => {
                onChange && onChange(attribute, {
                    from: value.from,
                    to: date ? moment(date).format('DD.MM.YYYY') : null,
                })
            })
        } 
    };

    onDateRange = (range) => {
        return () => {
            let {
                attribute,
                onChange
            } = this.props;

            var newFrom = moment().subtract(1, range).toDate(),
                newTo = moment().toDate();
            this.setState({from: newFrom, to: newTo}, () => {
                onChange && onChange(attribute, {
                    from: moment(newFrom).format('DD.MM.YYYY'),
                    to: moment(newTo).format('DD.MM.YYYY')
                })
            })

        }
    };

    render(){

        return <div className="daterange">
            <div className="daterange__row daterange__row_flex">
                <div className={"daterange__input"+(this.state.from ? " filled" : "")}
                     ref={(datepicker) => this.dateFrom = datepicker}
                     key="date-from"
                >
                    <DatePicker
                        selected={this.state.from}
                        onChange={(date, event) => this.onDateFrom(date, event)}
                        maxDate={this.state.to || moment().toDate()}
                        // locale={this.context.currentLanguage.alias}
                        isClearable={true}
                        locale={ru}
                        dateFormat='P'
                        // выводит селекты год и месяц
                        peekNextMonth={this.props.yearSelection}
                        showMonthDropdown={this.props.yearSelection}
                        showYearDropdown={this.props.yearSelection}
                    />
                </div>
                <div className="daterange__separate"/>
                <div className={"daterange__input"+(this.state.to ? " filled" : "")}
                     ref={(datepicker) => this.dateTo = datepicker}
                     key="date-to"
                >
                    <DatePicker
                        selected={this.state.to}
                        onChange={(date, event) => this.onDateTo(date, event)}
                        minDate={this.state.from}
                        maxDate={moment().toDate()}
                        // locale={this.context.currentLanguage.alias}
                        isClearable={true}
                        locale={ru}
                        dateFormat='P'
                        // выводит селекты год и месяц
                        peekNextMonth={this.props.yearSelection}
                        showMonthDropdown={this.props.yearSelection}
                        showYearDropdown={this.props.yearSelection}
                        
                    />
                </div>
            </div>

            {this.props.showPeriods ? <div className="daterange__row">
                <span className="daterange__period" onClick={this.onDateRange('week')}>{this.context.translate("За неделю")}</span>
                <span className="daterange__period" onClick={this.onDateRange('months')}>{this.context.translate("За месяц")}</span>
                <span className="daterange__period" onClick={this.onDateRange('years')}>{this.context.translate("За год")}</span>
            </div> : null}
        </div>
    }
}
