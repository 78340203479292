const initState = {
    list: [],
    isOk: false,
    isDownLoadDocument: false,
    documentStatusCount: null,
    failedDownLoadDocument: false,
    stateSaveListFiles: [],
};
import {parseErrors} from "../tools/api-helper";


export function documents(state = initState, action) {
    switch (action.type){
        case "DOCUMENTS_LIST":
            return {...state, list: [], isOk: false, isSearch: false, isSearching: false};
        case "DOCUMENTS_LIST_SEARCH":
            return {...state, isSearching: true};
        case "DOCUMENTS_LIST_SUCCESS":
            return {...state, list: action.data, isOk: true, isSearch: false};
        case "DOCUMENTS_LIST_SEARCH_SUCCESS":
            return {...state, list: action.data, isOk: true, isSearch: true, isSearching: false};
        case "DOCUMENTS_REQUEST":
            var newState = {...state};
            delete newState['requestResult'];
            return newState;
        case "DOCUMENTS_REQUEST_CREATED":
            return {...state, requestResult: action.data};
        case "DOCUMENTS_REQUEST_ERROR":
            return {...state, requestResult: {Message: action.data.message}};
        case "DOWNLOAD_RECONCILIATION_STATEMENT":
        case "DOWNLOAD_HELPFUL_DOCUMENT":
            return {...state, loading: true, documentsFormError: {}};
        case "DOWNLOAD_RECONCILIATION_STATEMENT_VALIDATION_ERROR":
            var documentsFormError = parseErrors(action.data);
            return {...state, documentsFormError};
        case "DOWNLOAD_RECONCILIATION_STATEMENT_SUCCESS":
        case "DOWNLOAD_HELPFUL_DOCUMENT_SUCCESS":
            return {...state, loading: false};
        case "DOWNLOAD_DOCUMENT":
            return {...state, isDownLoadDocument: true};
        case "DOWNLOAD_DOCUMENT_SUCCESS":
            return {...state, isDownLoadDocument: false};
        case "DOWNLOAD_DOCUMENT_NOT_FOUND":
        case "DOWNLOAD_DOCUMENT_BAD_REQUEST":
        case "DOWNLOAD_DOCUMENT_FAILED_TO_FETCH":
        case "DOWNLOAD_DOCUMENT_ERROR":
            return {...state, isDownLoadDocument: false};
        case "FAILED_DOWNLOAD_DOCUMENT":
            return {...state, failedDownLoadDocument: action.data};
        case "DOCUMENT_STATUS_COUNT_SUCCESS":
            return {...state, documentStatusCount: +action.data};
        case "SAVE_LIST_FILES":
            return {...state, stateSaveListFiles: action.data};
        case "LOGOUT_SUCCESS":
            return {...initState};
    }
    return state;
}