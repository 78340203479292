import React, { Component } from "react";
import Form from "../common/form/Form";
import FormField from "../common/form/FormField";
import PropTypes from "prop-types";
import { convertTimeISOinDMY } from "../../tools/tools";

export default class DiadocApplicationSigning extends Component {

    constructor(props){
        super(props);

        this.state = {
            fields: {
                simpleSelect: {
                    type: 'select-diadoc',
                    label: "Выберите электронную подпись",
                    placeholder: "Выберите подпись",
                    value: "",
                    error: '',
                    options: props && props.userCertificate || [],
                    optionsType: 'userCertificate',
                    // success: false,
                    // allowEmpty: false,
                },
            },
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentDidMount(){
        this.props.disableBtnSignAction(false);
    }

    close = () => {
        this.props.close();
    }

    onChangeField = (attribute, value, error) => {
        var prevState = {...this.state.fields};
        if (prevState.hasOwnProperty(attribute)) {
            prevState[attribute].value = value;
            prevState[attribute].error = error;
            this.setState({fields: prevState})
        }
    };

    onSubmit = () => {
        let {
            simpleSelect,
        } = this.state.fields;

        // подписываем сертификат пользователя
        this.props.startCertificateUserSignatureAction(simpleSelect.value);
    }

    renderFields = (fields) => {
        var result = [];

        for (var attribute in fields) {
            if (fields.hasOwnProperty(attribute)) {

                result.push(<div className="profile-form__item" key={attribute}>
                    <FormField fieldConfig={{
                        ...fields[attribute],
                        label: this.context.translate(fields[attribute].label)
                    }}
                        attribute={attribute}
                        onChange={this.onChangeField}
                    />
                </div>)
            }
        }

        return result;
    };


    render() {

        const { simpleSelect } = this.state.fields;
        const { 
            type = 'first',
            back,
            disableBtnSign,
            detailApplication,
            companies,
            choosedOrderByList
        } = this.props;

        // берем данные заказа из localStorage на случай, если пришлось вначале войти в диадок. В этом случае данные хранилища стираются и нужно использовать сохраненные
        const savedDetailApplication = (detailApplication && detailApplication.fields) ? detailApplication : JSON.parse(localStorage.getItem('detailApplication'))
        const savedChoosedOrderByList = choosedOrderByList ? choosedOrderByList : JSON.parse(localStorage.getItem('choosedOrderByList'))

        const equipmentName = savedDetailApplication && savedDetailApplication.fields && savedDetailApplication.fields.EquipmentName;
        const equipmentBrandName = savedDetailApplication && savedDetailApplication.fields && savedDetailApplication.fields.EquipmentBrandName;
        const lesseeRegDate = savedDetailApplication && savedDetailApplication.fields && savedDetailApplication.fields.LesseeRegDate;
        const LesseeId = savedDetailApplication && savedDetailApplication.fields && savedDetailApplication.fields.LesseeId;
        const lesseeManualFullName = savedDetailApplication && savedDetailApplication.fields && savedDetailApplication.fields.LesseeManualFullName;
        const lesseeManualShortName = savedDetailApplication && savedDetailApplication.fields && savedDetailApplication.fields.LesseeManualShortName;

        const findChoiseCompany = companies && companies.find((i) => i.CompanyId === LesseeId);
        
        return (
            <div className="diadoc_application_signing">
                <Form validate={true} onSubmit={this.onSubmit}>
                    <h3 className="diadoc_application_signing__title">{this.context.translate('Подписание заявки')}</h3>
                    
                    <div className="diadoc_application_signing__wrap_info">
                        <div className="diadoc_application_signing__item_info">
                            {
                                (savedChoosedOrderByList) ?
                                <span>Название документа: {savedChoosedOrderByList.LinkTitle}</span>                                
                                : <span>Название документа: «{equipmentName} {equipmentBrandName}» от {convertTimeISOinDMY(new Date(), true)}</span>
                            }
                            
                        </div>
                        <div className="diadoc_application_signing__item_info">
                            Компания-лизингополучатель: {(findChoiseCompany && findChoiseCompany.CompanyName) || lesseeManualFullName || lesseeManualShortName}
                        </div>
                    </div>

                    <div className="diadoc_application_signing__wrap_elements_form">
                        {this.renderFields({...this.state.fields})}
                    </div>
                    
                    <div className="diadoc_application_signing__wrap_btns">
                        <button
                            className={`btn ${((simpleSelect.value && !disableBtnSign) ? "" : " disabled")}`}
                        >
                            {this.context.translate('Подписать')}
                        </button>

                        {type === 'second'
                            && (
                                <button
                                    onClick={() => back()}
                                    className={"btn btn_gray"}
                                >
                                    {this.context.translate('Назад')}
                                </button>
                            )}
                    </div>
                </Form>
            </div>
        )
    }
}
