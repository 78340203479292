/**
 * Prepare form data from fields
 * [
 *      attribute: {
 *          ...props,
 *          value: {attrVal}
 *      }
 * ]
 * @param fields
 * @returns {FormData}
 */
export function getFormData(fields) {
    var data = new FormData();

    for (var attribute in fields) {
        if (fields.hasOwnProperty(attribute)) {
            if (!fields[attribute].disabled && fields[attribute].value) {
                data.append(attribute, fields[attribute].value);
            }
        }
    }

    return data;
}
/**
 * Prepare form data from fields
 * [
 *      attribute: {
 *          ...props,
 *          value: {attrVal}
 *      }
 * ]
 * @param fields
 * @returns {String}
 */
export function getBodyData(fields) {
    var data = [];

    for (var attribute in fields) {
        if (fields.hasOwnProperty(attribute)) {
            if (!fields[attribute].disabled && fields[attribute].value) {
                data.push(attribute+"="+fields[attribute].value);
            }
        }
    }

    return data.join('&');
}

export function getBodyDataOnPagination(fields) {
    var data = [];

    for (var attribute in fields) {
        if (fields.hasOwnProperty(attribute)) {
            if (fields[attribute]) {
                data.push(attribute+"="+fields[attribute]);
            }
        }
    }

    return data.join('&');
}

export function getFormValues(fields) {
    var data = {};
    // не обязательные поля
    const notRequired = [
        'SupplierContactPersonWebsite',
        'LesseeEmail',
        'LesseePhone',
        'LesseeWebsite',
        'Comment',
        'EquipmentOperationRegionName',
        'EquipmentStorageAreaAddress',
        'LesseeSnils',
    ];

    for (var attribute in fields) {
        if (fields.hasOwnProperty(attribute)) {
            if (!fields[attribute].disabled) {
                const isNotRequired = notRequired.some((i) => i === attribute);

                if (fields[attribute].value || isNotRequired) {
                    var val = fields[attribute].value;
                    if (fields[attribute].type == 'number') val = val.replace(/\D/gim, '');
                    data[attribute] = val;
                }
            }
        }
    }

    return data;
}