import React, { Component } from "react";
import { connect } from 'react-redux';
import { deleteApplicationAction } from "../../actions/add-applications";
import PropTypes from "prop-types";

class ApplicationRemove extends Component {

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    onYes = () => {
        this.props.toggleRemoveModal();
        this.props.deleteApplicationAction(this.props.id); // удаляем заявку
    }

    onNo = () => {
        this.props.toggleRemoveModal();
    }

    render() {

        return (
            <div className="application_remove">
                <h3 className="application_remove__title">
                    Вы точно хотите удалить черновик заявки?
                </h3>
                
                <div className="application_remove__wrap_btns">
                    <button
                        onClick={this.onYes}
                        className={"btn"}
                    >{this.context.translate('Да')}
                    </button>

                    <button
                        onClick={this.onNo}
                        className={"btn btn_gray"}
                    >{this.context.translate('Нет')}
                    </button>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({}),
    (dispatch) => {
        return {
            deleteApplicationAction: (data) => dispatch(deleteApplicationAction(data)),
        }
    }
)(ApplicationRemove);