import {
    call,
    put,
    take
} from 'redux-saga/effects';

import {
    fetchClientApi,
    fetchFileApi,
    checkErrors, clientApiUrl
} from '../tools/api-helper';

import {checkToken} from "./auth";
import fileDownload from "js-file-download";

export function documentsStatusAction(params = '') {
    return {
        type: 'DOCUMENT_STATUS_COUNT',
        params,
    }
}

// запрашивает число документов со статусом требующем подписи
export function* documentsStatusSaga(action) {
    try {
        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi(`/documents/status-count${action.params || '' }`, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function documentsList(params = '', direction = 'Inbound', sort = 'DESC') {
    return {
        type: 'DOCUMENTS_LIST',
        params,
        direction,
        sort,
    }
}

// загрузка списка документов
export function* fetchDocumentsList(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        const requredCompanyKey = action.params && action.params.LesseeId && action.params.LesseeId.value;

        const direction = `?direction=${action.direction}`;
        const sorting = `&sorting=${action.sort}`;
        const page = `${(action.params && action.params.page) ? `&page=${action.params.page}` : `&page=${1}`}`;
        const count = `${(action.params && action.params.count) ? `&count=${action.params.count}` : `&count=${20}`}`;
        const title = `${(action.params && action.params.DocumentName && action.params.DocumentName.value) ? `&title=${action.params.DocumentName.value}` : ''}`;
        const referTo = `${(action.params && action.params.EntityNumber && action.params.EntityNumber.value) ? `&referTo=${action.params.EntityNumber.value}` : ''}`;
        const messageDateFrom = `${(action.params && action.params.UploadDateStart && action.params.UploadDateStart.value) ? `&messageDateFrom=${action.params.UploadDateStart.value}` : ''}`;
        const messageDateTo = `${(action.params && action.params.UploadDateEnd && action.params.UploadDateEnd.value) ? `&messageDateTo=${action.params.UploadDateEnd.value}` : ''}`;
        const companyKey = `${((requredCompanyKey) || action.params.companyKey) ? `&companyKey=${requredCompanyKey || action.params.companyKey}` : ''}`;
        const documentStatus = `${(action.params && action.params.Statuses && action.params.Statuses.value) ? `&documentStatus=${action.params.Statuses.value}` : ''}`;

        const { status, data, pagination, error } = yield call( () => {
            return fetchClientApi(`/documents${direction}${sorting}${page}${count}${title}${referTo}${messageDateFrom}${messageDateTo}${companyKey}${documentStatus}`, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (action.direction === 'Inbound') {
            // запрашивает число документов со статусом требующем подписи
            yield put(documentsStatusAction(`${direction}${title}${referTo}${messageDateFrom}${messageDateTo}${companyKey}${documentStatus}`));
        } else {
            yield put(documentsStatusAction());
        }

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (Object.keys(pagination).length) data.pagination = pagination;
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function documentsSearchInfo() {
    return {
        type: 'DOCUMENTS_SEARCH_INFO'
    }
}

export function* fetchDocumentsSearchInfo(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/documents/search-info", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function documentsWidgetInfo() {
    return {
        type: 'DOCUMENTS_WIDGET_INFO'
    }
}

export function* fetchDocumentsWidgetInfo(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/documents/widget-info", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function addDocumentsRequest(body) {
    return {
        type: 'DOCUMENTS_REQUEST',
        body
    }
}

export function* fetchAddDocumentsRequest(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/documents", {
                method: 'POST',
                body: action.body,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}


// загрузка документа
export function downloadDocument(id, title) {
    return {
        type: 'DOWNLOAD_DOCUMENT',
        id,
        title
    }
}

// загрузка документа
export function* fetchDownloadDocument(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, headers, error } = yield call( () => {
            return fetchFileApi(clientApiUrl+"/documents/"+action.id+"/download", localStorage.getItem('authToken'))
        });

        if (error) {
            yield put(checkErrors(action.type, {message: error}, 403));
        }
        else {
            if (status == 200) {
                var filename = decodeURI(headers.get("Content-Disposition").match(/filename\*=UTF-8\'\'(.*)/i) ? headers.get("Content-Disposition").match(/filename\*=UTF-8\'\'(.*)/i)[1] : headers.get("Content-Disposition").match(/filename=\"(.*)\"/i)[1]);
        
                fileDownload(data, filename);

                yield put(checkErrors(action.type, data, status))
            } else {
                yield put(checkErrors(action.type, data, status))
                yield put(downloadDocumentFailedModalAction(true))
            }
        }

    } catch(e) {
        alert(e);
    }
}

export function downloadDocumentFailedModalAction(data) {
    return {
        type: 'FAILED_DOWNLOAD_DOCUMENT',
        data,
    }
}

export function downloadHelpfulDocument(id) {
    return {
        type: 'DOWNLOAD_HELPFUL_DOCUMENT',
        id
    }
}

export function* fetchDownloadHelpfulDocument(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, headers, error } = yield call( () => {
            return fetchFileApi(clientApiUrl+"/helpful/"+action.id, localStorage.getItem('authToken'))
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status === 200) {
                var filename = headers.get("Content-Disposition").match(/filename="(.*)"/ig)[0];
                filename = filename.substr(10, filename.length - 11);

                fileDownload(data, filename);
            }
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

export function downloadReconciliationStatement(params) {
    return {
        type: 'DOWNLOAD_RECONCILIATION_STATEMENT',
        params
    }
}

export function* fetchDownloadReconciliationStatement(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, headers, error } = yield call( () => {
            return fetchFileApi(clientApiUrl+"/documents/reconciliation-statement?"+action.params, localStorage.getItem('authToken'))
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status == 200) {
                var filename = headers.get("Content-Disposition").match(/filename="(.*)"/ig)[0];
                filename = filename.substr(10, filename.length - 11);

                fileDownload(data, filename);
            }
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}

// для получения списка файлов
export function saveListFilesAction(data) {
    return {
        type: 'SAVE_LIST_FILES',
        data
    }
}