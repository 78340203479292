import React, { Component } from 'react';
import ReactDOM from 'react-dom';

let modalContainer = document.getElementById('modal');

export default class Modal extends Component {

	constructor(props){
        super(props);

		this.state = {
			activeScroll: false,
		},

		this.madalRef = React.createRef();

    }

	componentDidMount() {
		window.addEventListener('resize', this.onResize);
        this.onResize();
	}

	componentWillUnmount(){
        window.removeEventListener('resize', this.onResize);
    }

	onClick = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

	// если высота модального окна больше чем высота экрана, то включаем скролл
    onResize = () => {
		const activeScroll = window.innerHeight < this.madalRef.current.scrollHeight;

        if(activeScroll){
            this.setState({
                activeScroll: true
            })
        }else{
            this.setState({
                activeScroll: false
            })
        }
    };

	render() {
		let {
			classNameContent = "",
			onClickBackground,
			closeOnBackground = true,
            children,
			isTransparentBg = false,
			isBgImage = false,
			modalContainerClasses = '',
			modalSize,
		} = this.props;

		const {
			activeScroll,
		} = this.state;

		return ReactDOM.createPortal(
			<div
				className={`modal-container ${activeScroll ? 'scroll' : ''} ${isTransparentBg ? 'modal-transparent' : ''} ${modalContainerClasses}`}
				onClick={closeOnBackground ? onClickBackground : () => {}}
				ref={this.madalRef}
			>
				<div id="modal-wrapper" className={`modal-wrapper ${classNameContent} ${modalSize ? 'modal-size-' + modalSize : ''}`} onClick={this.onClick}>
					<button onClick={onClickBackground} className="fancybox-close-small" title="Закрыть">
						<svg viewBox="0 0 32 32">
							<path d="M10,10 L22,22 M22,10 L10,22"/>
						</svg>
					</button>
					{children}
				</div>
			</div>, modalContainer);
	}
}
