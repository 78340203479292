import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';

import AddOrder from "../../components/orders/AddOrder";
import {addFile, deleteFile, clearBuffer, updateFile, cancelFile} from "../../actions/file-buffer";
import {autoComplete} from "../../actions/catalog";
import {
    newApplication,
    saveField,
    saveFields,
    applicationFormInfo,
    viewApplication,
    sendApplication,
    findCompany,
    findCompanyProvider,
    clearApplicationForm,
    clearCompanyForm,
    setField,
    selectedDisabledBtnAction,
    deleteProviderCompanyForm,
    deleteCompanyForm,
} from "../../actions/add-applications";
import {
    startApplicationDiadocAction,
} from '../../actions/diadocAction';

import {
    downloadFile
} from "../../actions/applications";
import { selectedTypeVendorInformationAction, selectedTypeLeasingCompanyAction } from '../../actions/companies';
import Modal from "../../components/common/Modal";
import DiadocSomethingWentWrong from "../../components/companies/DiadocSomethingWentWrong";

class AddOrderContainer extends Component {

    constructor(){
        super();
        this.state = {
            preventSubmit: false,
            isFromAdmin: false,
            modalIsAdminSign: false,
        }
    }

    componentDidMount() {
        this.setState({isFromAdmin: window.location.href.indexOf('frontend') !== -1})
    }

    componentWillReceiveProps(nextProps) {
        //if application just created
        if (!this.props.match.params.id && nextProps.application.fields && nextProps.application.fields.ApplicationId) {
            if (this.props.location.state && this.props.location.state.from == 'recreate') {
                if (this.props.application && this.props.application.fields && this.props.application.fields.ApplicationId && nextProps.application && nextProps.application.fields && this.props.application.fields.ApplicationId != nextProps.application.fields.ApplicationId) {
                    nextProps.sendApplication(nextProps.application.fields.ApplicationId)
                }
            } else {
                // nextProps.history.push('/applications/update/' + nextProps.application.fields.ApplicationId);
                // this.props.viewApplication(nextProps.application.fields.ApplicationId);
            }
        }

        if (nextProps.applicationsDraft != this.props.applicationsDraft && nextProps.applicationsDraft.isOk && this.props.application.fields && this.props.application.fields.IsDraft) {
            var shouldGoBack = true;
            for (var i = 0; i < nextProps.applicationsDraft.length; i++) {
                if (nextProps.applicationsDraft[i].ApplicationId == nextProps.application.fields.ApplicationId) {
                    shouldGoBack = false;
                    break;
                }
            }
        }

        if (nextProps.applicationsInProgress != this.props.applicationsInProgress && nextProps.applicationsInProgress.isOk && (this.props.application.fields && !this.props.application.fields.IsDraft) && !(this.props.location.state && this.props.location.state.from == 'recreate')) {
            var shouldGoBack = true;
            for (var i = 0; i < nextProps.applicationsInProgress.length; i++) {
                if (nextProps.applicationsInProgress[i].ApplicationId == nextProps.application.fields.ApplicationId) {
                    shouldGoBack = false;
                    break;
                }
            }
            if (shouldGoBack) nextProps.history.push('/applications#progress');
        }

        // после того как была успешно создана новая заявка, мы переходим на нужный урл
        if (nextProps.application.status == 'send') {
            nextProps.history.push('/applications#progress');
        }

        if (JSON.stringify(nextProps.application.errors) != JSON.stringify(this.props.application.errors)) {
            this.setState({preventSubmit: false});
        }
    }

    sendApplication = (fields) => {
        if (this.state.preventSubmit) return false;

        this.setState({preventSubmit: true}, () => {
            this.props.sendApplication(this.props.application.fields.ApplicationId, fields);
        });
    };

    addCompany = (fields) => {
        let {
            application,
            saveFields
        } = this.props;

        saveFields(application.fields.ApplicationId, fields, true);
    };


    render() {

        let {
            catalog,
            getCatalog,

            application,
            saveField,
            saveFields,
            setField,
            applicationFormInfo,

            company,
            findCompany,
            findCompanyProvider,

            fileBuffer,
            fileParams,

            addApplicationDocument,
            removeApplicationDocument,
            updateApplicationDocument,
            cancelApplicationDocument,
            clearBuffer,

            location,

            downloadApplicationFile,
            clearCompanyForm,
            documentTypes,

            selectedDisabledBtnAction,
            fileSuccess,
            deleteProviderCompanyForm,
            deleteCompanyForm,
            startApplicationDiadocAction,
            diadocState,

            selectedTypeVendorInformation,
            selectedTypeVendorInformationAction,
            selectedTypeLeasingCompany,
            selectedTypeLeasingCompanyAction,
        } = this.props;

        return (
            <Fragment>
                <AddOrder catalog={catalog} //catalog autocomplete values
                        getCatalog={getCatalog} //autocomplete request

                        {...application}
                        IsRecreate={location.state && location.state.from == 'recreate'}
                        comeFrom={location.state}
                        application={application}

                        saveField={saveField}
                        saveFields={saveFields}
                        sendApplication={this.sendApplication}
                        setField={setField}

                        company={company}
                        findCompany={findCompany}
                        findCompanyProvider={findCompanyProvider}
                        addCompany={this.addCompany}
                        clearCompanyForm={clearCompanyForm}

                        fileBuffer={fileBuffer}
                        fileParams={fileParams}

                        addApplicationDocument={addApplicationDocument}
                        removeApplicationDocument={removeApplicationDocument}
                        updateApplicationDocument={updateApplicationDocument}
                        cancelApplicationDocument={cancelApplicationDocument}
                        clearBuffer={clearBuffer}

                        downloadApplicationFile={downloadApplicationFile}
                        documentTypes={documentTypes}

                        selectedDisabledBtnAction={selectedDisabledBtnAction}
                        fileSuccess={fileSuccess}
                        deleteProviderCompanyForm={deleteProviderCompanyForm}
                        deleteCompanyForm={deleteCompanyForm}
                        startApplicationDiadocAction={(typeApplication, id) => {
                            // если зашли от админки, то нельзя подписывать
                            if(this.state.isFromAdmin) this.setState({modalIsAdminSign: true})
                            else this.props.startApplicationDiadocAction(typeApplication, id);
                        }}
                        diadocState={diadocState}
                        applicationFormInfo={applicationFormInfo}

                        selectedTypeVendorInformation={selectedTypeVendorInformation}
                        selectedTypeVendorInformationAction={selectedTypeVendorInformationAction}
                        selectedTypeLeasingCompany={selectedTypeLeasingCompany}
                        selectedTypeLeasingCompanyAction={selectedTypeLeasingCompanyAction}
                />
                {
                    this.state.modalIsAdminSign &&
                  <Modal modalSize="m" classNameContent="modal modal_visible" onClickBackground={() => {this.setState({modalIsAdminSign: false})}}>
                      <DiadocSomethingWentWrong
                        close={() => {this.setState({modalIsAdminSign: false})}}
                        text='Подписание, отправка и отклонение документов по ЭДО недоступны при авторизации под пользователем'
                        typePDF=""
                      />
                  </Modal>
                }
            </Fragment>
        )
    }
}

export default connect(
    state => ({
        catalog: state.catalog,
        application: state.detailApplication,
        applicationsDraft: state.applications.draft,
        applicationsInProgress: state.applications.inProgress,
        applicationsArchive: state.applications.archive,
        documentTypes: state.applications.documentTypes,
        company: state.company,
        isReady: state.catalog.isOk,
        fileBuffer: state.fileBuffer,
        fileParams: state.catalog && state.catalog.fileParams,
        fileSuccess: state.help.fileSuccess,
        diadocState: state.diadocReducer,
        selectedTypeVendorInformation: state.company.selectedTypeVendorInformation,
        selectedTypeLeasingCompany: state.company.selectedTypeLeasingCompany,
    }),
    (dispatch) => {
        return {
            getCatalog: (type, query) => dispatch(autoComplete(type, query)),
            newApplication: (id) => dispatch(newApplication(id)),
            saveField: (id, name, value) => dispatch(saveField(id, name, value)),
            saveFields: (id, fields, isCompany) => dispatch(saveFields(id, fields, isCompany)),
            applicationFormInfo: (id) => dispatch(applicationFormInfo(id)),
            viewApplication: (id) => dispatch(viewApplication(id)),
            sendApplication: (id, fields) => dispatch(sendApplication(id, fields)),
            findCompany: (inn) => dispatch(findCompany(inn)),
            findCompanyProvider: (inn) => dispatch(findCompanyProvider(inn)),
            addApplicationDocument: (id, file) => dispatch(addFile(id, file)),
            removeApplicationDocument: (id, fileId) => dispatch(deleteFile(id, fileId)),
            updateApplicationDocument: (id, fileId, fileName) => dispatch(updateFile(id, fileId, fileName)),
            cancelApplicationDocument: (id) => dispatch(cancelFile(id)),
            clearBuffer: () => dispatch(clearBuffer()),
            clearApplicationForm: () => dispatch(clearApplicationForm()),
            downloadApplicationFile: (id, name) => dispatch(downloadFile(id, name)),
            clearCompanyForm: () => dispatch(clearCompanyForm()),
            setField: (attr, val) => dispatch(setField(attr, val)),
            selectedDisabledBtnAction: (data) => dispatch(selectedDisabledBtnAction(data)),
            saveListFilesAction: (data) => dispatch(saveListFilesAction(data)),
            deleteProviderCompanyForm: () => dispatch(deleteProviderCompanyForm()),
            deleteCompanyForm: () => dispatch(deleteCompanyForm()),
            startApplicationDiadocAction: (typeApplication, id) => dispatch(startApplicationDiadocAction(typeApplication, id)),
            selectedTypeVendorInformationAction: (data) => dispatch(selectedTypeVendorInformationAction(data)),
            selectedTypeLeasingCompanyAction: (data) => dispatch(selectedTypeLeasingCompanyAction(data)),
        }
    }
)(AddOrderContainer);