import React, {Component} from "react";
import {numberMask} from "../../../tools/mask";
import {number} from "prop-types";
import preloader from "../../../../img/elements/elem-preload.svg";

export default class AutoComplete extends Component{
    constructor(props){
        super(props);

        var options = props.options && props.options.map((opt) => {return opt.label});
        this.state = {
            options,
            propOptions: options,
            opened: false,
            blockOnBlur: false
        }
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.options != this.props.options && nextProps.options) {
            var options = nextProps.options.map((opt) => {return opt.label});
            this.setState({
                options: options.filter((opt) => {
                    return opt.toLowerCase().indexOf(nextProps.value.toLowerCase()) !== -1;
                }),
                propOptions: options
            })
            if(this.props.isOneItemDefaultChoose && nextProps.options.length === 1) {
                this.setState({
                    options: nextProps.options && nextProps.options.filter((opt) => {
                        return opt.label.toLowerCase().indexOf(nextProps.options[0].label.toLowerCase()) !== -1;
                    })
                });
                this.props.onChange && this.props.onChange(this.props.attribute, nextProps.options[0].id);
                this.props.saveField && this.props.saveField(this.props.attribute, nextProps.options[0].id);

                this.props.searchCallback && this.props.searchCallback(nextProps.options[0])
                this.windowClick();
            }
        }
        if (this.props.value != nextProps.value) {
            if (nextProps.getOptions) {
                if(!this.props.minChar || nextProps.value.length >= this.props.minChar) {
                    if (nextProps.value) {
                        if(!this.props.isPaused) {
                            nextProps.getOptions(nextProps.optionsType,nextProps.value);
                        } else {
                            if(this.timer) clearTimeout(this.timer);
                            this.timer = setTimeout(() => {
                                nextProps.getOptions(nextProps.optionsType,nextProps.value);
                            }, this.props.pauseCountSec * 1000)
                        }
                    }
                    else this.setState({options: []});
                }
            }
            else this.setState({options: this.state.propOptions ? this.state.propOptions.filter((opt) => {
                    return opt.toLowerCase().indexOf(nextProps.value.toLowerCase()) !== -1;
                }) : []})
        }
    }

    openList = (e) => {
        this.setState({opened: true});
    };

    componentWillMount(){
        let {
            value,
            optionsType,
            getOptions,
            minChar,
            searchCallback,
            notLoad
        } = this.props;

        if (value && getOptions && !notLoad) {
            getOptions(optionsType, value);
        }
    }

    componentDidMount(){
        document.addEventListener('click', this.windowClick);
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.windowClick);
    }

    windowClick = () => {
        this.setState({opened: false});
    };

    onSelect = (item, obj) => {
        return () => {
            this.setState({
                options: this.state.propOptions && this.state.propOptions.filter((opt) => {
                    return opt.toLowerCase().indexOf(item.toLowerCase()) !== -1;
                }),
                blockOnBlur: true
            });

            if(!this.props.saveField) {
                this.props.onChange && this.props.onChange(this.props.attribute, item);
            }
            this.props.saveField && this.props.saveField(this.props.attribute, item);

            this.props.searchCallback && obj && this.props.searchCallback(obj)
        }
    };

    onChange = (e) => {
        let {
            attribute,
            optionsType,
            onChange,
            getOptions,
            value,
            maxChar,
            searchCallback,
            blockedData
        } = this.props;

        var query = maxChar ? numberMask(maxChar)(e.target.value) : e.target.value;
        if(blockedData && blockedData.includes(query)) {
            onChange && onChange(attribute, query, query + ' не является корректным ИНН');
        }
        else {
            onChange && onChange(attribute, query);

            if (query) {
                if ((query.length < value.length) && getOptions) {
                    getOptions && getOptions(optionsType, query);
                } else {
                    this.setState({
                        options: this.state.propOptions && this.state.propOptions.filter((opt) => {
                            return opt.toLowerCase().indexOf(query.toLowerCase()) !== -1;
                        }),
                        query
                    })
                }
            } else this.setState({options: getOptions ? [] : this.state.propOptions, query})
        }
    };

    onBlur = () => {
        setTimeout(() => {
            if(this.props.canBlockBlur) {
                if(!this.state.blockOnBlur) {
                    console.log('blur')
                    var {
                        attribute,
                        value,
                        saveField,
                        onBlur
                    } = this.props;

                    saveField && saveField(attribute, value);
                    onBlur && onBlur(attribute, value)
                }
            } else {
                var {
                    attribute,
                    value,
                    saveField,
                    onBlur
                } = this.props;

                saveField && saveField(attribute, value);
                onBlur && onBlur(attribute, value)
            }
            this.setState({blockOnBlur: false})
        }, 500)

    }

    render(){
        let {
            placeholder = "Не выбрано",
            value,
            isOtherFieldSearch,
            searchsendrequest,
            withPreloader
        } = this.props;

        let {
            opened,
            options
        } = this.state;
        if(!isOtherFieldSearch) {
            return <div className="select-styler">
                <div className={"jq-selectbox__select"+(opened ? " opened" : "")+(options && options.length ? "" : " no-pointer")} onClick={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    var event = document.createEvent("Event");
                    event.initEvent("click", false, true);
                    document.dispatchEvent(event);
                    this.openList();
                }}>
                    <input type="text"
                           value={value}
                           placeholder={placeholder}
                           onChange={this.onChange}
                           onBlur={this.onBlur}
                           onFocus={this.openList}
                    />
                    {
                        withPreloader && searchsendrequest &&
                        <div className="preloader select-preloader">
                            <div className="preloader__inner select-preloader__inner">
                                <img className="splashscreen__indicator select-preloader__indicator" src={preloader} alt=""/>
                            </div>
                        </div>
                    }
                    {!searchsendrequest && options && options.length ? <div className={"jq-selectbox__trigger"}>
                        <div className="jq-selectbox__trigger-arrow"/>
                    </div> : null}
                </div>
                {opened && options && options.length ?
                    <div className="jq-selectbox__dropdown">
                        <ul>
                            {options.map(
                                (item, key) => <li key={key} onClick={this.onSelect(item)}
                                                   className={value == item ? "sel selected" : ""}>
                                    {item}
                                </li>)}
                        </ul>
                    </div> : null}
            </div>
        } else {
            return <div className="select-styler">
                <div className={"jq-selectbox__select"+(opened ? " opened" : "")+(this.props.options && this.props.options.length ? "" : " no-pointer")} onClick={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    var event = document.createEvent("Event");
                    event.initEvent("click", false, true);
                    document.dispatchEvent(event);
                    this.openList();
                }}>
                    <input type="text"
                           value={value}
                           placeholder={placeholder}
                           onChange={this.onChange}
                           onBlur={this.onBlur}
                           onFocus={this.openList}
                    />
                    {
                        withPreloader && searchsendrequest &&
                        <div className="preloader select-preloader">
                            <div className="preloader__inner select-preloader__inner">
                                <img className="splashscreen__indicator select-preloader__indicator" src={preloader} alt=""/>
                            </div>
                        </div>
                    }
                    {!searchsendrequest && this.props.options && this.props.options.length ? <div className={"jq-selectbox__trigger"}>
                        <div className="jq-selectbox__trigger-arrow"/>
                    </div> : null}
                </div>
                {opened && this.props.options && this.props.options.length ?
                    <div className="jq-selectbox__dropdown">
                        <ul>
                            {this.props.options.map(
                                (item, key) => <li key={key} onClick={this.onSelect(item.id, item)}
                                                   className={value == item.id ? "sel selected" : ""}>
                                    {item.label}
                                </li>)}
                        </ul>
                    </div> : null}
            </div>
        }

    }
}