import React, { Component } from "react";
import Form from "../common/form/Form";
import FormField from "../common/form/FormField";
import PropTypes from "prop-types";

export default class DiadocDocumentSigning extends Component {

    constructor(props){
        super(props);

        this.state = {
            fields: {
                simpleSelect: {
                    type: 'select-diadoc',
                    label: "Выберите электронную подпись",
                    placeholder: "Выберите подпись",
                    value: "",
                    error: '',
                    options: props && props.userCertificate || [],
                    optionsType: 'userCertificate',
                    // success: false,
                    // allowEmpty: false,
                },
            },
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentDidMount(){
        this.props.disableBtnSignAction(false);
    }

    close = () => {
        this.props.close();
    }

    onChangeField = (attribute, value, error) => {
        var prevState = {...this.state.fields};
        if (prevState.hasOwnProperty(attribute)) {
            prevState[attribute].value = value;
            prevState[attribute].error = error;
            this.setState({fields: prevState})
        }
    };

    onSubmit = () => {
        let {
            simpleSelect,
        } = this.state.fields;

        console.log('simpleSelect.value', simpleSelect.value);

        // запуск процесса подписи сертификата пользователя для ДОКУМЕНТОВ
        this.props.startCertificateUserSignatureDocumentsAction(simpleSelect.value);
    }

    renderFields = (fields) => {
        var result = [];

        for (var attribute in fields) {
            if (fields.hasOwnProperty(attribute)) {

                result.push(<div className="profile-form__item" key={attribute}>
                    <FormField fieldConfig={{
                        ...fields[attribute],
                        label: this.context.translate(fields[attribute].label)
                    }}
                        attribute={attribute}
                        onChange={this.onChangeField}
                    />
                </div>)
            }
        }

        return result;
    };


    render() {

        const { simpleSelect } = this.state.fields;
        const { type, disableBtnSign } = this.props;
        const typeClickedBtn = localStorage.getItem('selectedTypeBtn');

        return (
            <div className="diadoc_document_signing">
                <Form validate={true} onSubmit={this.onSubmit}>
                    <h3 className="diadoc_document_signing__title">{this.context.translate(`${typeClickedBtn === 'sign' ? 'Подписание документа': 'Отклонение документа'}` )}</h3>

                    <div className="diadoc_document_signing__wrap_elements_form">
                        {this.renderFields({...this.state.fields})}
                    </div>
                    
                    <div className="diadoc_document_signing__wrap_btns">
                        <button
                            className={`btn ${((simpleSelect.value && !disableBtnSign) ? "" : " disabled")}`}
                        >
                            {this.context.translate('Подписать')}
                        </button>

                        {type === 'second'
                            && (
                                <button
                                    // onClick={this.close}
                                    className={"btn btn_gray"}
                                >
                                    {this.context.translate('Назад')}
                                </button>
                            )}
                    </div>
                </Form>
            </div>
        )
    }
}
