const initState = {
    isOk: false,
    error: '',

    modalDiadoc: '',
    selectedBoxId: '',
    disableBtnSign: false,
    idApplication: '',
    checkAuthStatusDiadoc: null,
    boxesList: [],
    openIdSettings: {},
    sendOpenId: {},
    boxUserPermissions: [],
    signedApplication: false,
    signatureDocumentContent: {},
    userCertificate: [],
    contentPdfInBase64: '',
    signatureCertificate: {},

    // ДЛЯ ДОКУМЕНТООБОРОТА
    documentsUserPermissions: [],
    textReasonForRejection: '',
    signatureDeclineSigningRequest: {},
    signatureDeclineSigning: false,
    signatureSignDocument: {},
};

export function diadocReducer(state = initState, action) {
    switch (action.type) {
        case 'MODAL_DIADOC':
            return {
                ...state,
                modalDiadoc: action.data,
            };
        case 'SELECTED_BOX_ID':
            return {
                ...state,
                selectedBoxId: action.data,
            };
        case 'DISABLE_BTN_SIGN':
            return {
                ...state,
                disableBtnSign: action.data,
            };

        case 'GET_ID_APPLICATION':
            return {
                ...state,
                idApplication: action.data,
            };

        case 'CHECK_AUTH_STATUS_DIADOC_SUCCESS':
            console.log('CHECK_AUTH_STATUS_DIADOC_SUCCESS');
            return {
                ...state,
                checkAuthStatusDiadoc: action.data,
                error: '',
            };

        case 'BOXES_LIST_SUCCESS':
            const converArr = action.data && action.data.map((i) => ({
                    value: i.boxId,
                    label: `${i.companyName} <br> ${i.company && i.company.inn}`,
                }));

            return {
                ...state,
                boxesList: converArr,
                error: '',
            };

        case 'OPEN_ID_SETTINGS_SUCCESS':
            return {
                ...state,
                openIdSettings: action.data,
                error: '',
            };

        case 'SEND_OPEN_ID_SUCCESS':
            console.log('SEND_OPEN_ID_SUCCESS');
            return {
                ...state,
                sendOpenId: action.data,
                error: '',
            };

        case 'GET_BOX_USER_PERMISSIONS_SUCCESS':
            return {
                ...state,
                boxUserPermissions: action.data,
                error: '',
            };

        case 'SEND_SIGNED_APPLICATION_SUCCESS':
            return {
                ...state,
                signedApplication: action.data,
                error: '',
            };

        case 'GET_SIGNATURE_DOCUMENT_CONTENT_SUCCESS':
            return {
                ...state,
                signatureDocumentContent: action.data,
                error: '',
            };

        case 'GET_USER_CERTIFICATION_SUCCESS':
            return {
                ...state,
                userCertificate:  action.data.data,
                error: '', 
            };
            
        case 'CONTENT_PDF_IN_BASE64_SUCCESS':
            return {
                ...state,
                contentPdfInBase64:  action.data.data,
                error: '',
            };

        case 'SIGN_SERTIFICATE_SUCCESS':
            return {
                ...state,
                signatureCertificate:  action.data.data,
                error: '',
            };
       

        // ДЛЯ ДОКУМЕНТООБОРОТА
        case 'GET_DOCUMENTS_USER_PERMISSIONS_SUCCESS':
            return {
                ...state,
                documentsUserPermissions:  action.data,
                error: '',
            };

        case 'REASON_FOR_REJECTION':
            return {
                ...state,
                textReasonForRejection:  action.data,
            };

        case 'SEND_SIGNATURE_DECLINE_SIGNING_REQUEST_SUCCESS':
            return {
                ...state,
                signatureDeclineSigningRequest: action.data,
                error: '',
            };

        case 'SEND_SIGNATURE_DECLINE_SIGNING_SUCCESS':
            return {
                ...state,
                signatureDeclineSigning: action.data,
                error: '',
            };

        case 'SEND_SIGNATURE_SIGN_DOCUMENT_SUCCESS':
            return {
                ...state,
                signatureSignDocument: action.data,
                error: '',
            };
        
        case 'ERROR_TEXT':
            return {
                ...state,
                error: action.data
            };
        case "LOGOUT_SUCCESS":
            return {...initState};
        
    }
    return state;
}