import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {setCookie} from "../../tools/tools";

export default class CookieModal extends Component {

    setCookies = () => {
        setCookie("agree_cookies_dl", true)
        document.querySelector(".modal__cookies").style.display = "none"
    }

    render() {
        return (
            <div className="modal__cookies">
                <p>
                    Мы обрабатываем файлы Cookies согласно условиям в <a href="https://deltaleasing.ru/privacy-policy/" target="_blank">Политике конфиденциальности</a>.
                    Нажимая «ОК», Вы даете свое согласие на обработку Ваших Cookies. Отказаться от обработки Вы всегда можете в настройках своего браузера.
                </p>
                <button className="btn btn_blue modal__cookies_btn" type="button" onClick={this.setCookies}>OK</button>
            </div>
        )
    }
}
