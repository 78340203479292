import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";

class DocumentsAdded extends Component {

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    close = () => {
        this.props.close();
    }


    render() {
        const { stateSaveListFiles } = this.props;

        return (
            <div className="documents_added">
                <h3 className="documents_added__title">
                    Документы добавлены
                </h3>

                <div className="documents_added__wrap_files">
                    {stateSaveListFiles.map((item, index) => 
                        <div className="documents_added__file" key={index}>
                            {item.name}
                        </div>
                    )}
                </div>
                
                <div className="documents_added__wrap_btns">
                    <button
                        onClick={this.close}
                        className={"btn"}
                    >{this.context.translate('Готово')}
                    </button>
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        stateSaveListFiles: state.documents.stateSaveListFiles,
    }),
    (dispatch) => {
        return {}
    }
)(DocumentsAdded);