const initState = {isOk: false};

export function library(state = initState, action) {
    switch (action.type) {
        case 'LIBRARY_SUCCESS':
            var pagination = action.data.pagination;
            var data = action.data.map((item) => {
                var title = {};
                item.title.map((arr) => {
                    title[arr.lang] = arr.translation;
                });
                var description = {};
                item.description.map((arr) => {
                    description[arr.lang] = arr.translation;
                });
                return {
                    ...item,
                    title,
                    description
                }
            });
            data.pagination = pagination;
            return {data, isOk: true};
        case "LOGOUT_SUCCESS":
            return {...initState};
    }
    return state;
}