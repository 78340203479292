import React,{Component} from "react";
import minusBlue from "../../../../img/icons/minus-blue.svg";
import plusBlue from "../../../../img/icons/plus-blue.svg";
import Modal from "../Modal";
import KPSuccess from "../../documents/KPSuccess";
import KPRequest from "../../documents/KPRequest";
import KPCalculation from "../../documents/KPCalculation";

export default class Accordion extends Component{

    constructor(props){
        super(props);

        this.state = {
            modal: null,
            active: props.open || false,
        }
    }

    toClick = () => {
        this.setState({
            active: !this.state.active,
        })
    };

    closeModal = () => {
        this.setState({modal: null})
    };

    renderModal = () => {
        if (!this.state.modal) return null;

        let {
            id,
            applicationId,
            requestApplication,
            actionMessage,
            commercialOfferAcceptAction,
            isActionOk,
            requestKP,
            modalBtnIsDisabled
        } = this.props;

        switch (this.state.modal){
            case "calculation":
                return <Modal modalSize="s" classNameContent="modal modal_visible" onClickBackground={this.closeModal}>
                    <KPCalculation
                        commercialOfferAcceptAction={commercialOfferAcceptAction}
                        close={this.closeModal}
                        id={id}
                        applicationId={applicationId}
                    />
                </Modal>;
            case "request":
                return <Modal modalSize="l" classNameContent="modal modal_visible" onClickBackground={this.closeModal}>
                    <KPRequest
                        applicationId={applicationId}
                        id={id}
                        message={actionMessage}
                        requestKP={requestKP}
                        openSuccess={() => this.setState({ modal: "success" })}
                        isActionOk={isActionOk}
                        modalBtnIsDisabled={modalBtnIsDisabled}
                    />
                </Modal>;
            case "success":
                return <Modal modalSize="s" classNameContent="modal modal_visible" onClickBackground={this.closeModal}>
                        <KPSuccess />
                </Modal>;
            default:
                return null;
        }
    };

    render(){
        let active = this.state.active;

        let {
            children,
            name,
            isAccepted,
            isOneIsAccepted,
            IsConditionsChangePossible,
        } = this.props;

        return (
            <div className="accordion">
                <div className="accordion__header">
                    <div
                        className="accordion__header_left_part"
                        onClick={this.toClick}
                    >
                        <div className="accordion__header_wrap_icon">
                            <img className={`accordion__header_icon ${active ? 'active' : ''}`} src={minusBlue} alt="Минус"/>
                            <img className={`accordion__header_icon ${!active ? 'active' : ''}`} src={plusBlue} alt="Плюс"/>
                        </div>
                        <div className="accordion__header_name">
                            {name}
                        </div>
                    </div>

                    <div className="accordion__header_right_part">
                        {isOneIsAccepted
                            && (
                                <React.Fragment>
                                    {(+isAccepted === 1)
                                        && (
                                            <div className="accordion__dot_text">
                                                <div className="accordion__dot"></div>
                                                <div className="accordion__text">
                                                    Итоговое
                                                </div>
                                            </div>
                                        )}

                                    {(+isAccepted === 0)
                                        && (
                                            <div>
                                                {
                                                    +IsConditionsChangePossible === 1 &&
                                                      <div
                                                        className="btn"
                                                        onClick={() => this.setState({ modal: "request" })}
                                                      >
                                                          Запросить изменения
                                                      </div>
                                                }
                                            </div>
                                        )}
                                </React.Fragment>
                            )}
                        
                        {!isOneIsAccepted
                            && (
                                <div
                                    className="btn"
                                    onClick={() => this.setState({ modal: "calculation" })}
                                >
                                    Выбрать итоговый рассчет
                                </div>
                            )}
                        
                        
                    </div>
                </div>
                <div className={`accordion__body ${active ? 'active' : ''}`}>
                    {children}
                </div>
                {this.renderModal()}
            </div>
        );
    }
}