export default function recaptcha (state = {}, action) {

    switch (action.type) {
        case "RECAPTCHA":
            return {
                ...state,
            };
        case "RECAPTCHA_SUCCESS":
            return {
                ...state,
                siteKey: action.data.siteKey
            };
        default:
            return state;
    }
}