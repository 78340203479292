import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import {auth} from "./auth";
import {reg} from "./reg"
import {catalog} from "./catalog";
import {detailApplication} from "./detail-application";
import {applications} from "./applications";
import {singleApplication} from "./single-application"
import {company} from './company';
import {promotions} from "./promotions";
import {library} from "./library";
import {help} from "./help";
import {projects} from "./projects";
import {detailProject} from "./detail-project";
import {detailDocument} from "./detail-document";
import {files} from "./file-buffer";
import {deals} from "./deals";
import {contactsList, detailContact} from "./contacts";
import {profile} from "./profile";
import {companiesList, companiesRequestForm} from "./companies";
import {usersList, usersForm} from "./users";
import {documents} from "./documents";
import {notifications} from "./notifications";
import {communications} from "./communications";
import {multilingual} from "./multilingual";
import {diadocReducer} from "./diadocReducer";
import insurance from "./insurance";
import recaptcha from "./recaptcha";

let rootReducer = combineReducers({
  router: routerReducer,
  auth,
  reg,
  catalog,
  detailApplication,
  singleApplication,
  applications,
  company,
  promotions,
  library,
  help,
  projects,
  detailProject,
  detailDocument,
  fileBuffer: files,
  deals,
  contacts: contactsList,
  detailContact,
    profile,
    companiesList,
    companiesRequestForm,
    usersList,
    usersForm,
    documents,
    notifications,
    communications,
    multilingual,
  insurance,
  recaptcha,
  diadocReducer,
});

export default rootReducer;
