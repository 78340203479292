import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import {backToRequest, codeRequest, codeValidate, recovery} from "../../actions/auth";

import {nameMask, noSpace, numberMask, phoneMask} from "../../tools/mask";
import FormField from "../../components/common/form/FormField";
import "../../../styles/reg.css";
import {authPageSettings, autoComplete} from "../../actions/catalog";
import {
    regChangeData,
    regCheckPhone,
    regCheckSms,
    regFindCompanyProvider,
    regGetBusinessRegions,
    regSendData,
    regShowData
} from "../../actions/reg";
import RegStepPhone from "../../components/auth/RegStepPhone";
import RegStepCode from "../../components/auth/RegStepCode";
import RegStepUserData from "../../components/auth/RegStepUserData";
import RegStepUserDataList from "../../components/auth/RegStepUserDataList";
import Modal from "../../components/common/Modal";
import {getKey} from "../../actions/recaptcha";

class RegistrationContainer extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            time: 0,
            modal: false,
            onAgreeModal: false,
            step: 0,
            companyIsChoosen: false,
            regionId: props.regState.regionId || '',
            notChangeRegion: false,
            regIsSameUserError: false,
            showRememberBtn: false,
            fieldsRegStep1: {
                phone_number: {
                    type: 'phone_number',
                    label: "Номер телефона",
                    name: "regPhone",
                    value: props.regState.phone_number || "",
                    // required: true,
                    error: '',
                    mask: phoneMask,
                    isError: false,
                    ref: (input) => {
                        this.phoneInput = input;
                    }
                },
                    email: {
                    type: 'email',
                    label: "E-mail",
                    name: "regEmail",
                    value: props.regState.email || "",
                    // required: true,
                    error: '',
                    mask: noSpace,
                    isError: false,
                    ref: (input) => {
                        this.emailDataInput = input;
                    }
                }
            },
            fieldsRegStep2: {
                code: {
                    type: 'text',
                    label: "Введите код подтверждения, отправленный Вам в SMS:",
                    name: "smsCode",
                    value: "",
                    required: true,
                    error: '',
                    mask: numberMask(4),
                    min: 4,
                    ref: (input) => {
                        this.smsCodeInput = input;
                    }
                },
            },
            fieldsRegStep3: {
                last_name: {
                    type: 'text',
                    label: "Фамилия",
                    name: "lastName",
                    value: "",
                    required: true,
                    error: '',
                    mask: nameMask,
                    ref: (input) => {
                        this.lastNameInput = input;
                    }
                },
                first_name: {
                    type: 'text',
                    label: "Имя",
                    name: "firstName",
                    value: "",
                    required: true,
                    error: '',
                    mask: nameMask,
                    ref: (input) => {
                        this.firstNameInput = input;
                    }
                },
                second_name: {
                    type: 'text',
                    label: "Отчество",
                    name: "secondName",
                    value: "",
                    required: true,
                    error: '',
                    mask: nameMask,
                    ref: (input) => {
                        this.secondNameInput = input;
                    }
                },
                inn: {
                    type: "auto-complete",
                    label: this.context.translate("ИНН компании"),
                    placeholder: "Введите ИНН компании",
                    value: props.regState.inn || "",
                    required: true,
                    error: '',
                    options: this.props.regState.fieldsCompaniesProvider || [],
                    optionsType: 'companies',
                    getOptions: this.props.findCompanyProvider,
                    // allowEmpty: true,
                    allowSearch: true,
                    maxChar: 12,
                    minChar: 10,
                    mustLength: [10, 12],
                    success: !!props.regState.inn,
                    isOneItemDefaultChoose: true,
                    isOtherFieldSearch: true,
                    isPaused: true,
                    pauseCountSec: 0.3,
                    searchCallback: this.setCompany,
                    withPreloader: true,
                    notLoad: true,
                    blockedData: ['0000000000', '00000000000', '000000000000']
                },
                // kpp: {
                //     type: 'text',
                //     label: "КПП компании",
                //     name: "kppName",
                //     value: "",
                //     // required: true,
                //     error: '',
                //     mask: numberMask(9),
                //     min: 9,
                //     ref: (input) => {
                //         this.kppInput = input;
                //     }
                // },
                short_name: {
                    type: 'text',
                    label: "Краткое наименование компании",
                    name: "companyDescriptionName",
                    value: "",
                    // required: true,
                    error: '',
                    disabled: true,
                    // mask: onlyNumbers,
                    ref: (input) => {
                        this.companyDescriptionInput = input;
                    }
                },
                region_id: {
                    type: "auto-complete",
                    label: this.context.translate("Регион ведения бизнеса"),
                    placeholder: "Введите регион",
                    value: props.regState.regionName || "",
                    required: true,
                    error: '',
                    options: [],
                    optionsType: 'businessRegions',
                    getOptions: this.props.getBusinessRegions,
                    // allowEmpty: true,
                    allowSearch: true,
                    success: !!props.regState.regionName,
                    isOtherFieldSearch: true,
                    isPaused: true,
                    pauseCountSec: 0.5,
                    searchCallback: this.setRegion,
                    notLoad: true
                },
            },
            personal: {
                personal_data: {
                    type: "checkbox",
                    label: "Я даю {n} на обработку персональных данных {l} и ознакомлен {o} с {m}.",
                    labelOptions: {
                        n: '<a class="reg-agree-modal-btn">согласие</a>',
                        m: '<a class="reg-private-policy-btn" href="https://drive.google.com/file/d/1PI28rhOMfWeGdNu91D-R6yLs4pI7i-zp/view" target="_blank">Политикой конфиденциальности</a>',
                        l: '<span class="br-hide-2"></span>',
                        o: '<span class="br-hide-3"></span>'
                    },
                    value: false,
                    id: "personalData"
                }
            },
            autoFilled: []
        };

    }
    static contextTypes = {
        screenSize: PropTypes.string,
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    createReCaptcha = (siteKey) => {
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`
        script.async = true;
        script.defer = true;
        document.body.appendChild(script)
    }

    componentDidMount() {
        // Add reCaptcha
        this.props.getKey()

        if(this.phoneInput) {
            this.phoneInput.addEventListener("transitionend", () => {
                var autoFilled = [...this.state.autoFilled];
                autoFilled.push('phone_number');
                this.setState({autoFilled});
            }, false);
        }
        if(this.emailInput) {
            this.emailInput.addEventListener("transitionend", () => {
                var autoFilled = [...this.state.autoFilled];
                autoFilled.push('email');
                this.setState({autoFilled});
            }, false);
        }
        if(this.props.regState.step === 1) {
            this.setState({time: 120}, () => {
                this.timer = setInterval(this.setTime, 1000);
            });
        }

    }
    componentWillReceiveProps(nextProps) {
        if (this.props.recaptcha.siteKey != nextProps.recaptcha.siteKey) {
            this.createReCaptcha(nextProps.recaptcha.siteKey);
        }
        if((this.props.regState.step != nextProps.regState.step) && nextProps.regState.step === 1) {
            clearInterval(this.timer);
            this.setState({time: 120}, () => {
                this.timer = setInterval(this.setTime, 1000);
            });
        }
        if(this.props.regState.reg1Errors != nextProps.regState.reg1Errors) {
            let state = {...this.state};
            for (let attr in state.fieldsRegStep1) {
                state.fieldsRegStep1[attr].error = nextProps.regState.reg1Errors[attr] || ''
            }
            if(Object.keys(nextProps.regState.reg1Errors).includes('re_captcha')) state.fieldsRegStep1["email"].error = nextProps.regState.reg1Errors['re_captcha']
            if(Object.keys(nextProps.regState.reg1Errors).includes('user_exists')) {
                state.showRememberBtn = true
                state.regIsSameUserError = true
                state.fieldsRegStep1['email'].isError = true;
                state.fieldsRegStep1['phone_number'].isError = true;
            }
            this.setState(state);
        }
        if(this.props.regState.regDataErrors != nextProps.regState.regDataErrors) {
            let state = {...this.state};
            for (let attr in state.fieldsRegStep3) {
                state.fieldsRegStep3[attr].error = nextProps.regState.regDataErrors[attr] ? nextProps.regState.regDataErrors[attr].replace(/\[|\]/g, '') : ''
            }
            this.setState(state);
        }
        if (this.props.triesMore != nextProps.triesMore) {
            clearInterval(this.timer);
            this.setState({time: 120}, () => {
                this.timer = setInterval(this.setTime, 1000);
            });
        }
        if (this.props.regState.code != nextProps.regState.code) {
            this.setState({
                fieldsRegStep2: {
                    code: {
                        ...this.state.fieldsRegStep2.code,
                        value: nextProps.regState.code
                    }
                }
            });
        }
        if (this.props.regState.fieldsCompaniesProvider != nextProps.regState.fieldsCompaniesProvider) {
            this.setState({
                fieldsRegStep3: {
                    ...this.state.fieldsRegStep3,
                    inn: {
                        ...this.state.fieldsRegStep3.inn,
                        options: [...nextProps.regState.fieldsCompaniesProvider]
                    }
                },
                companyIsChoosen: this.props.regState.companyIsChoosen ? true : false
            });
        }
        if(this.props.regState.companyProviderErrors != nextProps.regState.companyProviderErrors) {
            this.setState({
                fieldsRegStep3: {
                    ...this.state.fieldsRegStep3,
                    inn: {
                        ...this.state.fieldsRegStep3.inn,
                        error: nextProps.regState.companyProviderErrors
                    },
                    short_name: {
                        ...this.state.fieldsRegStep3.short_name,
                        disabled: !nextProps.regState.handInn
                    }
                }
            });
        }
        if (this.props.regState.businessRegions != nextProps.regState.businessRegions) {
            this.setState({
                fieldsRegStep3: {
                    ...this.state.fieldsRegStep3,
                    region_id: {
                        ...this.state.fieldsRegStep3.region_id,
                        options: [...nextProps.regState.businessRegions]
                    }
                }
            });
        }
        if (this.props.regState.regModalSuccess != nextProps.regState.regModalSuccess && nextProps.regState.regModalSuccess) {
            this.setState({modal: true});
        }
    }

    funcState = (prevState) => {
        if (this.state.time < 1) {
            clearInterval(this.timer);
            return {time: 0};
        }
        var timer = --prevState.time;
        return {time: timer};
    };

    setTime = () => {
        this.setState(this.funcState);
    };
    onRepeatCodeRequest = (e) => {
        e.preventDefault();
        clearInterval(this.timer);

        this.setState({time: 120}, () => {
            this.timer = setInterval(this.setTime, 1000);
            this.checkPhone();
            this.setState({tries: this.state.tries - 1})
        });
    }


    onChangeField = (name) => {
        return (attribute, value, error, selectionStart, fromBlur) => {
            var prevState = {...this.state[name]};
            let regIsSameUserErrorCheck = false

            if (prevState.hasOwnProperty(attribute)) {
                prevState[attribute].value = value;
                prevState[attribute].error = error;
                if(fromBlur && (attribute === 'region_id') && !this.state.notChangeRegion && !this.state.regionId) {
                    prevState[attribute].error = 'Выберите регион из справочника';
                }
                if(name === 'fieldsRegStep1' && (attribute === 'email' || attribute === 'phone_number')) {
                    if(!fromBlur) {
                        prevState['email'].isError = false;
                        prevState['phone_number'].isError = false;
                        regIsSameUserErrorCheck = true;
                    }
                }
                this.setState({
                    ...this.state,
                    [name]: prevState,
                    regIsSameUserError: regIsSameUserErrorCheck ? false : this.state.regIsSameUserError,
                    autoFilled: [],
                    regionId: !(attribute === 'region_id') || fromBlur ? this.state.regionId : null,
                    notChangeRegion: attribute === 'region_id' ? false : this.state.notChangeRegion
                }, () => {
                    if (attribute == 'phone_number' && selectionStart) {
                        this.phoneInput.selectionStart = this.phoneInput.selectionEnd;
                    }
                })
            }
        }
    };

    renderFields = (fields, name) => {
        var result = [];

        for (var attribute in fields) {
            if (fields.hasOwnProperty(attribute)) {
                result.push(<div className="form__row" key={name + attribute}>
                    <FormField
                        fieldConfig={{...fields[attribute], label: this.context.translate(fields[attribute].label), searchsendrequest: this.props.regState.companiesProviderSendRequest}}
                        attribute={attribute}
                        onChange={this.onChangeField(name)}
                        changePassType={this.changePassType}
                    />
                </div>)
            }
        }

        return result;
    };

    isValid = (name) => {
        if (this.state.autoFilled.length == 2) return true;
        var fields = {...this.state[name]};
        for (var attr in fields) {
            if(attr === "inn") {
                if(
                    !(fields['inn'].value.length === 10 || fields['inn'].value.length === 12) ||
                    (!this.state.companyIsChoosen && !this.props.regState.handInn) ||
                    (
                        fields["inn"].required &&
                        !fields["inn"].value
                    )
                ) return false
            } else {
                if (
                    fields[attr].error ||
                    (fields[attr].type === 'auto-complete' && (fields[attr].value.length < fields[attr].minChar)) ||
                    (
                        fields[attr].required &&
                        !fields[attr].value
                    )
                ) return false;
            }
        }
        return true;
    }
    checkPhone = () => {
        window.grecaptcha
            .execute(this.props.recaptcha.siteKey)
            .then(token => {
                this.props.onRegCheckPhone(this.state.fieldsRegStep1.phone_number.value, this.state.fieldsRegStep1.email.value, token)
            })
    }
    checkSms = () => {
        this.props.onRegCheckSms(this.state.fieldsRegStep1.phone_number.value, this.state.fieldsRegStep2.code.value)
    }
    checkCompanyData = () => {
        if(this.isValid("fieldsRegStep3")) {
            const data = {
                first_name: this.state.fieldsRegStep3.first_name.value,
                last_name: this.state.fieldsRegStep3.last_name.value,
                second_name: this.state.fieldsRegStep3.second_name.value,
                email: this.state.fieldsRegStep3.email ? this.state.fieldsRegStep3.email.value : this.state.fieldsRegStep1.email.value,
                inn: this.state.fieldsRegStep3.inn.value,
                // kpp: this.state.fieldsRegStep3.kpp.value,
                short_name: this.state.fieldsRegStep3.short_name.value,
                regionName: this.state.fieldsRegStep3.region_id.value,
                regionId: this.state.regionId,
                phone_number: this.state.fieldsRegStep1.phone_number.value
            }
            this.props.onRegShowData(data)
        }
    }
    endReg = () => {
        this.props.onRegSendData({
            first_name: this.state.fieldsRegStep3.first_name.value,
            last_name: this.state.fieldsRegStep3.last_name.value,
            second_name: this.state.fieldsRegStep3.second_name.value,
            email: this.state.fieldsRegStep3.email ? this.state.fieldsRegStep3.email.value : this.state.fieldsRegStep1.email.value,
            is_changed: this.props.regState.isChanged ? 1 : 0,
            inn: this.state.fieldsRegStep3.inn.value,
            // kpp: this.state.fieldsRegStep3.kpp.value,
            short_name: this.state.fieldsRegStep3.short_name.value,
            region_id: this.state.regionId,
            phone_number: this.state.fieldsRegStep1.phone_number.value
        })
    }

    setCompany = (company) => {
        this.setState({
            fieldsRegStep3: {
                ...this.state.fieldsRegStep3,
                short_name: {
                    ...this.state.fieldsRegStep3.short_name,
                    value: company.ShortName || company.FullName
                },
                // kpp: {
                //     ...this.state.fieldsRegStep3.kpp,
                //     value: company.Kpp
                // }
            },
            companyIsChoosen: true
        })
    }
    setRegion = (region) => {
        this.setState({
            regionId: region.value,
            notChangeRegion: true,
            fieldsRegStep3: {
                ...this.state.fieldsRegStep3,
                region_id: {
                    ...this.state.fieldsRegStep3.region_id,
                    value: region.label,
                    error: ""
                }
            }
        })
    }

    onRegChangeData = () => {
        // if(!this.props.regState.isChanged) {
        //     this.setState({
        //         fieldsRegStep3: {
        //             last_name: {...this.state.fieldsRegStep3.last_name},
        //             first_name: {...this.state.fieldsRegStep3.first_name},
        //             second_name: {...this.state.fieldsRegStep3.second_name},
        //             email: {
        //                 type: 'email',
        //                 label: "E-mail",
        //                 name: "regDataEmail",
        //                 value: this.state.fieldsRegStep1.email.value || "",
        //                 required: true,
        //                 error: '',
        //                 mask: noSpace
        //             },
        //             inn: {...this.state.fieldsRegStep3.inn},
        //             short_name: {...this.state.fieldsRegStep3.short_name},
        //             region_id: {...this.state.fieldsRegStep3.region_id}
        //         }
        //     })
        // }
        this.props.onRegChangeData()
    }

    render() {
        let {
            fieldsRegStep1,
            fieldsRegStep2,
            fieldsRegStep3,
            personal,
            time,
            modal,
            agreeModal
        } = this.state;

        let {
            regState,
            onChangeStep
        } = this.props;

        return (
            <div className="reg__container">
                {
                    !modal &&
                    <div className="auth__form">
                        <h3 className="reg__title">Регистрация</h3>
                        {
                            regState.step === 0 &&
                            <RegStepPhone
                                context = {this.context}
                                checkPhone = {this.checkPhone}
                                fieldsRegStep1 = {this.state.fieldsRegStep1}
                                renderFields = {this.renderFields}
                                regState = {regState}
                                isValid = {this.isValid}
                                regIsSameUserError = {this.state.regIsSameUserError}
                                showRememberBtn = {this.state.showRememberBtn}
                                openAgreeModal={() => {this.setState({agreeModal: true})}}
                                privatePolicyLink={this.props.catalog.footerSettings.private_policy_link}
                            />
                        }
                        {
                            regState.step === 1 &&
                            <RegStepCode
                                checkSms = {this.checkSms}
                                renderFields = {this.renderFields}
                                fieldsRegStep2 = {fieldsRegStep2}
                                regState = {regState}
                                onRepeatCodeRequest = {this.onRepeatCodeRequest}
                                isValid = {this.isValid}
                                tries_more = {regState.tries_more}
                                time = {time}
                                context = {this.context}
                                back = {() => onChangeStep(0)}
                            />
                        }

                        {
                            regState.step === 2 &&
                            <RegStepUserData
                                context = {this.context}
                                regState = {regState}
                                checkCompanyData = {this.checkCompanyData}
                                endReg = {this.endReg}
                                fieldsRegStep1 = {fieldsRegStep1}
                                fieldsRegStep3 = {fieldsRegStep3}
                                regionId={this.state.regionId}
                                personal = {personal}
                                renderFields = {this.renderFields}
                                sendRequest = {this.props.sendRequest}
                                isValid = {this.isValid}
                                openAgreeModal={() => {this.setState({agreeModal: true})}}
                                privatePolicyLink={this.props.catalog.footerSettings.private_policy_link}

                            />
                        }

                        {
                            regState.step === 3 &&
                            <RegStepUserDataList
                                context = {this.context}
                                regState = {regState}
                                fieldsRegStep1 = {fieldsRegStep1}
                                fieldsRegStep3 = {fieldsRegStep3}
                                onRegChangeData = {this.onRegChangeData}
                                endReg = {this.endReg}
                            />
                        }
                    </div>
                }
                {
                    regState.step === 0 && !modal &&
                    <div className="reg__subtext">
                        <p>
                            Этот сайт защищен reCAPTCHA.
                        </p>
                    </div>
                }
                {modal ? <Modal modalSize="m"
                    classNameContent="modal modal-reg-success modal_visible"
                    onClickBackground={() => {window.location.href = "/login";}}
                    // isTransparentBg={true}
                    modalContainerClasses="modal-container-reg-agree"
                >
                    <div className="main-modal_adapt-default">
                        <p className="main-modal__title">{this.context.translate('Регистрация')}</p>
                        <div className="reg-modal-text">
                            {this.context.translate('Спасибо, письмо будет отправлено на почту после обработки вашей заявки.')} <br/><br/>
                            {this.context.translate('Это может занять некоторое время.')}
                        </div>
                    </div>
                    <button onClick={() => {window.location.href = "/login";}} className="btn">{this.context.translate("ОК")}</button>
                </Modal> : null}

                {agreeModal ? <Modal modalSize="xl"
                    classNameContent="modal modal-reg-agree modal_visible"
                    onClickBackground={() => {this.setState({agreeModal: false})}}
                    // isTransparentBg={true}
                    modalContainerClasses="modal-container-reg-agree"
                >
                    <div className="main-modal_adapt-default">
                        <p className="main-modal__title">{this.context.translate('Согласие')}</p>
                        <div className="reg-modal-text">
                            Я даю свое согласие Обществу с ограниченной ответственностью «ДельтаЛизинг»<br/>(ООО «ДельтаЛизинг»), адрес: 690091, г. Владивосток, пер. Ланинский, зд.2, на смешанную (автоматизированную и неавтоматизированную) обработку, а именно: сбор, запись, систематизацию, хранение, уточнение (обновление, изменение), использование, обезличивание, блокирование, удаление, уничтожение, следующих персональных данных: фамилия, имя, отчество; номер телефона; адрес электронной почты; дата и время осуществления доступа к сайту и установленным на нем сервисам.<br/><br/>
                            Согласие на обработку персональных данных предоставляется для целей: предоставления доступа к сервису Онлайн-Офис DeltaЛизинг и выполнения роли пользователя в нем согласно установленным правилам использования сервиса, а также установления и поддержания делового общения с Оператором, информирования об оказываемых Оператором услугах.<br/><br/>
                            Согласие действует до отмены доступа к сервису Онлайн-Офис DeltaЛизинг. Мне известно, что согласие может быть отозвано в любой момент путем направления обращения в письменной форме по указанному выше адресу Оператора либо по электронному адресу: <a href="mailto:data.privacy.ru@deltaleasing.ru">data.privacy.ru@deltaleasing.ru</a><br/><br/>
                            Мне понятно, что отзыв согласия или прекращение предусмотренной согласием обработки персональных по моему требованию могут привести к невозможности достижения предусмотренных согласием целей обработки персональных данных, в том числе к невозможности предоставления доступа к сервису Онлайн-Офис DeltaЛизинг.
                        </div>
                    </div>
                </Modal> : null}
            </div>
        )
    }
}

export default connect(
    state => ({
        recaptcha: state.recaptcha,
        catalog: state.catalog,
        splashImages: state.catalog && state.catalog.splashImages,
        authState: state.auth,
        regState: state.reg,
        pageSettings: state.catalog && state.catalog.authPageSettings,
        multilingual: state.multilingual
    }),
    (dispatch) => {
        return {
            getKey: () => dispatch(getKey()),
            onCodeRequest: (phone, password) => dispatch(codeRequest(phone, password)),
            toCodeRequest: () => dispatch(backToRequest()),
            onCodeValidate: (phone, code) => dispatch(codeValidate(phone, code)),
            authPageSettings: () => dispatch(authPageSettings()),
            recovery: (action, data) => dispatch(recovery(action, data)),
            onRegCheckPhone: (phone, email, token) => dispatch(regCheckPhone(phone, email, token)),
            onRegCheckSms: (phone, sms) => dispatch(regCheckSms(phone, sms)),
            onRegShowData: (data) => dispatch(regShowData(data)),
            onRegSendData: (data) => dispatch(regSendData(data)),
            onRegChangeData: () => dispatch(regChangeData()),
            getCatalog: (type, query) => dispatch(autoComplete(type, query)),
            findCompanyProvider: (type, query) => {
                if (!(query === '0000000000' || query === '00000000000' || query === '000000000000')) {
                    return dispatch(regFindCompanyProvider(type, query))
                }
            },
            getBusinessRegions: (type, query) => dispatch(regGetBusinessRegions(type, query)),
            onChangeStep: (step) => dispatch({type: 'REG_CHANGE_STEP', step}),
        }
    }
)(RegistrationContainer);