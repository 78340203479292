import React,{Component} from "react";
import OrdersListItem from "./OrdersListItem";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

export default class OrdersListDraft extends Component{


    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render(){

        let {
            list,
            sendApplication,

            fileBuffer,
            fileParams,

            addApplicationDocument,
            removeApplicationDocument,
            updateApplicationDocument,
            cancelApplicationDocument,
            clearBuffer,
            documentTypes,
            fileSuccess,
        } = this.props;

        // if (!list.length) return <Redirect to={"/applications"}/>;

        return (<div className="tabs__main">
                <div className="tabs__content">
                    <p>{this.context.translate('Неотправленные заявки хранятся 30 дней с момента последнего обновления')}</p>
                    <div className="applications">
                        {list.map((item, key) => <OrdersListItem item={item}
                                                                 key={key}
                                                                 sendApplication={sendApplication}

                                                                 fileBuffer={fileBuffer}
                                                                 fileParams={fileParams}

                                                                 addApplicationDocument={addApplicationDocument}
                                                                 removeApplicationDocument={removeApplicationDocument}
                                                                 updateApplicationDocument={updateApplicationDocument}
                                                                 cancelApplicationDocument={cancelApplicationDocument}
                                                                 clearBuffer={clearBuffer}
                                                                 documentTypes={documentTypes}
                                                                 typeApplication={'remove'}
                                                                 fileSuccess={fileSuccess}
                        />)}
                    </div>
                </div>
            </div>
        )
    }

}