import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Switch, Route, Redirect} from "react-router-dom";
import AuthLocked from "../../components/auth/AuthLocked";
import {backToRequest, codeRequest, codeValidate, recovery} from "../../actions/auth";
import {authPageSettings} from "../../actions/catalog";
import {getScreenSize} from "../../tools/tools";

import Auth from "../../components/auth/Auth";
import Recovery from "../../components/auth/Recovery";
import PropTypes from "prop-types";
import {phoneMask} from "../../tools/mask";
import RegistrationContainer from "./RegistrationContainer";
import ConfirmationContainer from "./ConfirmationContainer";

class AuthContainer extends Component {

    constructor(props){
        super(props);
        this.state = {};
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    }

    componentDidMount = () => {
        this.setState({screenSize: getScreenSize()});
        window.addEventListener('resize', this.onResize);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    };

    onResize = () => {
        this.setState({screenSize: getScreenSize()});
    };

    componentWillMount() {
        if (!this.props.pageSettings) {
            this.props.authPageSettings();
        }
    }

    componentWillReceiveProps(nextProps){

        if (this.props.splashImages != nextProps.splashImages) {
            if (nextProps.splashImages) {
                nextProps.splashImages.map((image) => {
                    var img = new Image();
                    img.src = image;
                })
            }
        }
    }

    render() {

        let isRegPage = false;
        if(window.location.pathname.includes('/registration')) isRegPage = true;

        let {
            authState,
            onCodeRequest,
            toCodeRequest,
            onCodeValidate,
            recovery,
            pageSettings
        } = this.props;

        if (authState.checkingLocked) {
            return <div className="auth page"/>;
        }

        if (authState.blocked) {
            return <AuthLocked pageSettings={pageSettings}/>;
        }

        return (
            <div className="auth page">
                {pageSettings ? <div className="auth__inner">
                    {
                        !isRegPage &&
                        <div className="auth__block" >
                            <h1 className = "auth__title">{pageSettings.intro_title[this.context.currentLanguage.alias]}</h1>
                            {this.state.screenSize == 'lg' ? <div className="auth__text inner-html" dangerouslySetInnerHTML={{__html: pageSettings.intro_body[this.context.currentLanguage.alias]}} /> : null}
                        </div>
                    }
                    <div className="auth__block">
                        <Switch>
                            <Route path="/login" render={
                                () => { return <Auth {...authState}
                                            onCodeRequest={onCodeRequest}
                                            toCodeRequest={toCodeRequest}
                                            onCodeValidate={onCodeValidate}
                                                     pageSettings={pageSettings}
                                />}
                            }/>
                            <Route path="/registration" render={
                                () => { return <RegistrationContainer />}
                            }/>
                            <Route path="/confirmation" render={
                                () => { return <ConfirmationContainer />}
                            }/>
                            <Route path="/recovery" render={
                                () => {return <Recovery {...authState}
                                            recovery={recovery}
                                            toCodeRequest={toCodeRequest}
                                />}
                            }/>
                            <Route path="*" render={() => {return <Redirect to={"/login"}/>}}/>
                        </Switch>
                        {
                            (
                              this.state.screenSize != 'lg' &&
                              !isRegPage
                            ) &&
                            <div
                                className="auth__text inner-html"
                                dangerouslySetInnerHTML={{__html: pageSettings.intro_body[this.context.currentLanguage.alias]}}
                            />
                        }
                        {/*<div className="auth__subtext">*/}
                        {/*    <p>{this.context.translate("Звоните ")}*/}
                        {/*        <a target="_blank" href={pageSettings.support_phone[this.context.currentLanguage.alias] ? "tel:"+(pageSettings.support_phone[this.context.currentLanguage.alias].replace(/\s/g, '')) : "#"}>{pageSettings.support_phone[this.context.currentLanguage.alias]}</a>*/}
                        {/*        {this.context.translate(" или вашему менеджеру")}*/}
                        {/*    </p>*/}
                        {/*</div>*/}
                    </div>
                </div> : null}
            </div>
        )
    }
}

export default connect(
    state => ({
        splashImages: state.catalog && state.catalog.splashImages,
        authState: state.auth,
        pageSettings: state.catalog && state.catalog.authPageSettings,
        multilingual: state.multilingual
    }),
    (dispatch) => {
        return {
            onCodeRequest: (phone, password) => dispatch(codeRequest(phone, password)),
            toCodeRequest: () => dispatch(backToRequest()),
            onCodeValidate: (phone, code) => dispatch(codeValidate(phone, code)),
            authPageSettings: () => dispatch(authPageSettings()),
            recovery: (action, data) => dispatch(recovery(action, data))
        }
    }
)(AuthContainer);