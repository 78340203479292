import React, {Component} from "react";
import {Link} from "react-router-dom";
import UsersListItem from "./UsersListItem";
import Modal from "../common/Modal";
import UserForm from "./UserForm";
import PropTypes from "prop-types";

export default class UsersList extends Component{

    constructor(props){
        super(props);
        this.state = {
            modal: null,
            user: null
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps){
        if (nextProps.usersList != this.props.usersList) {
            this.closeModal();
        }
    }

    openModal = (type, user = null) => {
        return (e) => {
            if (e) e.preventDefault();
            this.setState({modal: type, user: user});
        }
    }

    closeModal = () => {
        this.props.clearForm();
        this.setState({modal: null, user: null});
    }

    renderModal = () => {
        var {
            notificationSettings,
            usersForm,
            checkPhone,
            createUser,
            updateUser,
            deleteUser,
            viewUser
        } = this.props;

        switch (this.state.modal){
            case "create":
                return <Modal modalSize="l" classNameContent="modal modal_fullscreen" onClickBackground={this.closeModal} closeOnBackground={false}>
                    <div className="users-modal users-modal_add-user-exist">
                        <p className="users-modal__title">{this.context.translate('Новый пользователь')}</p>
                        <UserForm notificationSettings={notificationSettings}
                                  {...usersForm}
                                  checkPhone={checkPhone}
                                  createUser={createUser}
                                  updateUser={(body) => {updateUser(usersForm.userId, body)}}
                                  isCreation={true}
                                  key="user-from"
                        />
                    </div>
                </Modal>;
            case "update":

                if (!usersForm.notificationSettings) viewUser(this.state.user.LoginId);

                return <Modal modalSize="l" classNameContent="modal modal_fullscreen" onClickBackground={this.closeModal} closeOnBackground={false}>
                    <div className="users-modal users-modal_add-user-exist">
                        <p className="users-modal__title">{this.context.translate('Редактировать пользователя')}</p>
                        <UserForm notificationSettings={notificationSettings}
                                  {...usersForm}
                                  profile={this.state.user}
                                  checkPhone={checkPhone}
                                  createUser={createUser}
                                  updateUser={(body) => {updateUser(this.state.user.LoginId, body)}}
                                  key="user-from"
                        />
                    </div>
                </Modal>;
            case "delete":
                return <Modal modalSize="s" classNameContent="modal modal_visible modal-wrapper modal__xs text-center" onClickBackground={this.closeModal}>
                    <h2 className="form__title">{this.context.translate('Вы действительно хотите удалить пользователя?')}</h2>
                    <div className="form__buttons">
                        <button className="btn" onClick={() => {deleteUser(this.state.user.LoginId)}}>{this.context.translate('Удалить')}</button>
                        <button className="btn btn_clear_blue" onClick={this.closeModal}>{this.context.translate('Отмена')}</button>
                    </div>
                </Modal>;
            default:
                return null;
        }
    }

    render(){

        var {
            usersList
        } = this.props;

        return <div className="users users_adapt-default">
            <a href="#" onClick={this.openModal("create")} className="users__add u-btn u-btn_clear_blue u-btn_add u-btn_adapt-type_wide">{this.context.translate('Добавить пользователя')}</a>
            <div className="users__list">
                {usersList.map((item) => <UsersListItem item={item}
                                                        key={item.UserId}
                                                        onUpdate={this.openModal("update", item)}
                                                        onDelete={this.openModal("delete", item)}
                />)}
            </div>
            {this.renderModal()}
        </div>
    }
}