import React, {Component} from "react";
import { PaymentSchedule } from "../common/Schedule";
import PropTypes from "prop-types";


export default class CommercialOffer extends Component{

    constructor(props){
        super(props);

        this.state = {}
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render(){
        let {
            data,
            download,
        } = this.props;

        const equipmentInfo = [
            {
                label: "Тип предмета лизинга",
                value: data && data.EquipmentTypeName || <span>&mdash;</span>
            },
            {
                label: "Наименование предмета лизинга",
                value: data && data.EquipmentName || <span>&mdash;</span>
            },
            {
                label: "Стоимость предметов лизинга",
                value: data && data.TotalCost || <span>&mdash;</span>
            },
            {
                label: "Сумма всех платежей",
                value: data && data.TotalSum || <span>&mdash;</span>
            }
        ];
    
        const leaseInfo = [
            {
                label: "График платежей",
                value: data && data.ScheduleName || <span>&mdash;</span>
            },
            {
                label: "Срок",
                value: data && data.ContractTerm || <span>&mdash;</span>
            },
            {
                label: "Аванс",
                value: data && data.AdvancePercent || <span>&mdash;</span>
            },
            {
                label: "Удорожание в год",
                value: data && data.RiseInPrice || <span>&mdash;</span>
            }
        ];
    
        return  <div>
            <div className="application__offer-about">
                <div className="application__offer-info">
                    <div className="application__offer-params">
                        <div className="params-list params-list_application">
                            <div className="params-list__items">
                                {equipmentInfo.map((item, key) => <div className="params-list__list-item" key={key}>
                                    <span className="params-list__term">{this.context.translate(item.label)}:</span>&nbsp;
                                    <span className="params-list__defenition">{item.value}</span>
                                </div>)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="application__offer-finance">
                    <div className="application__offer-params">
                        <div className="params-list params-list_application">
                            <div className="params-list__items">
                                {leaseInfo.map((item, key) => <div className="params-list__list-item" key={key}>
                                    <span className="params-list__term">{this.context.translate(item.label)}:</span>&nbsp;
                                    <span className="params-list__defenition">{item.value}</span>
                                </div>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            {(data && data.Schedule && data.Schedule.payments && data.Schedule.payments.length) ? <div className="application__offer-graph">
                <p className="application__subtitle">{this.context.translate('График лизинговых платежей')}</p>
                <div className="application__offer-graph-info">
                    <div className="application__offer-graph-params">
                        <div className="application__offer-graph-param"><span
                            className="application__offer-graph-param-name">{this.context.translate('Дата расчета')}:&nbsp;</span><span
                            className="application__offer-graph-param-value">{data.CommercialOfferDate}</span>
                        </div>
                        <div className="application__offer-graph-param"><span
                            className="application__offer-graph-param-name">{this.context.translate('Курс конвертации')}:&nbsp;</span><span
                            className="application__offer-graph-param-value">{data.CommercialOfferRate}</span>
                        </div>
                        <div className="application__offer-graph-param u-mobile-only"><span
                            className="application__offer-graph-param-name">{this.context.translate('Размер платежа')}:&nbsp;</span><span
                            className="application__offer-graph-param-value">{data.Schedule.payments[0].PaymentAmountTooltipLabel}</span>
                        </div>
                        <div className="application__offer-graph-param u-mobile-only"><span
                            className="application__offer-graph-param-name">{this.context.translate('В т.ч. НДС')}:&nbsp;</span><span
                            className="application__offer-graph-param-value">{data.Schedule.payments[0].PaymentVatTooltipLabel}</span>
                        </div>
                    </div>
                    {data.HasOfferDoc ? <div className="application__offer-graph-downloads">
                        <span className="application__offer-graph-download-help">{this.context.translate('Скачать график')}:</span>
                        <a href="#" className="u-big-icon u-big-icon_pdf application__offer-graph-download" onClick={download(data.CommercialOfferId)}/>
                    </div> : null}
                </div>

                <PaymentSchedule {...data.Schedule} translate={this.context.translate}/>
                    {/* <p className="application__offer-graph-payments">
                        {this.context.translate('Итого сумма лизинговых платежей')}:&nbsp;
                        <span className="application__offer-graph-payments-value">{data.EquipmentFullCost}</span>,
                        {this.context.translate('в т.ч. НДС')}&nbsp;
                        <span  className="application__offer-graph-payments-value">{data.EquipmentCostNdsPart}</span>
                    </p> */}

                </div>
                : null}
        </div>
    }
}