import React, {Component} from 'react';
import Form from "../common/form/Form";

export default class RegStepUserData extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        if(document.querySelector('.reg-agree-modal-btn')) {
            document.querySelector('.reg-agree-modal-btn').addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation()
                this.props.openAgreeModal()
            })
        }
        if(document.querySelector('.reg-private-policy-btn')) document.querySelector('.reg-private-policy-btn').href = this.props.privatePolicyLink;
    }

    render() {
        const {
            context,
            regState,
            checkCompanyData,
            endReg,
            fieldsRegStep1,
            fieldsRegStep3,
            personal,
            renderFields,
            sendRequest,
            isValid,
            regionId
        } = this.props


        return (
            <Form validate={true} className="auth__form-slide active" onSubmit={checkCompanyData}>
                {
                    regState.isChanged &&
                    <div className="reg__ending_data_block">
                        <p>Данные регистрации:</p>
                        <p>Телефон: {fieldsRegStep1.phone_number.value}</p>
                        <p>E-mail: {fieldsRegStep1.email.value}</p>
                    </div>
                }
                {renderFields(fieldsRegStep3, 'fieldsRegStep3')}
                {
                    !regState.isChanged ?
                        renderFields(personal, 'personal') :
                        <div className="reg__ending_data_block">Для активации учетной записи мы отправим письмо на указанную почту</div>
                }
                <div className="form__buttons">
                    <button
                        type="submit"
                        className={`btn ${isValid('fieldsRegStep3') && !sendRequest && personal.personal_data.value && regionId ? "" : " disabled"}`}
                    >{context.translate("Продолжить")}</button>
                </div>
            </Form>
        )
    }
}