import React, {Component} from "react";
import {Link} from "react-router-dom";
import OrdersDocs from "./OrdersDocs";
import Accordion from "../common/Accordion/Accordion";
import {OrderActions, OrderStatus} from "./OrderHelpers";
import PropTypes from "prop-types";
import CommercialOffer from "./CommercialOffer";

export default class DetailOrder extends Component{

    constructor(props){
        super(props);

        this.state = {
        }
    }

    componentDidMount() {

    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    download = (offerId) => {
        return (e) => {
            e.preventDefault();
            this.props.download(this.props.ApplicationId, offerId);
        }
    }

    addDocument = (file) => {
        this.props.addApplicationDocument(this.props.ApplicationId, file);
    }

    removeDocument = (id) => {
        this.props.removeApplicationDocument(this.props.ApplicationId, id);
    }

    updateDocument = (id, name) => {
        this.props.updateApplicationDocument(this.props.ApplicationId, id, name)
    }

    closeModal = () => {
        this.setState({modal: null})
    };

    render(){
        let {
            ApplicationId,
            EquipmentName,
            Date,
            Status,
            FrozenTo,
            Documents,
            CommercialOffers,
            Actions,
            ProjectId,
            isActionOk,
            actionMessage,
            fileBuffer,
            fileParams,
            clearBuffer,
            cancelApplicationDocument,

            recallApplication,
            requestApplication,
            resumeApplication,
            location,


            downloadApplicationFile,
            documentTypes,
            startApplicationDiadocAction,
            commercialOfferAcceptAction,
            pdfApplicationFormAction,
            IsApplicationEditable,
            EquipmentBrandName,
            modalBtnIsDisabled,
            requestKP,
        } = this.props;

        // если хоть у одной КП isAccepted равен 1
        const isOneIsAccepted  = CommercialOffers.some((i) => +i.IsAccepted === 1);
        
        return (
            <div className="page__content-container">
                <div className="page__back-link">
                    <Link className="u-back-link" to={location.state && location.state.from || "/applications"}><span>{this.context.translate('Заявки')}</span></Link>
                </div>
                <h1 className="page__title">{this.context.translate('Заявка «{name}»', {name: `${EquipmentName}${EquipmentBrandName ? (' ' + EquipmentBrandName) : ''}`})}</h1>
                <p className="page__title-date">{this.context.translate('от {date} г.', {date: Date})}</p>
                <div className="page__application-view">
                    <div className="application application_adapt-default">
                        <OrderStatus className="application__status" status={Status} paused={FrozenTo}/>
                        <OrderActions actions={Actions}
                                        firstAsBtn={true}
                                        id={ApplicationId}
                                        projectId={ProjectId}

                                        isActionOk={isActionOk}
                                        modalBtnIsDisabled={modalBtnIsDisabled}
                                        actionMessage={actionMessage}

                                        recallApplication={recallApplication}
                                        requestApplication={requestApplication}
                                        resumeApplication={resumeApplication}
                                        pdfApplicationFormAction={pdfApplicationFormAction}
                                        startApplicationDiadocAction={startApplicationDiadocAction}
                        />
                        <OrdersDocs docs={Documents}
                                    download={downloadApplicationFile}
                                    fileBuffer={fileBuffer}
                                    fileParams={fileParams}
                                    clearBuffer={clearBuffer}
                                    addDocument={this.addDocument}
                                    updateDocument={this.updateDocument}
                                    removeDocument={this.removeDocument}
                                    cancelDocument={cancelApplicationDocument}
                                    documentTypes={documentTypes}

                        />
                        <div className="application__offer">
                            {CommercialOffers.length > 1 
                                && (
                                    <p className="application__title">{this.context.translate('Коммерческие предложения')}<sup>(<i className="js-files-table-num">{CommercialOffers ? CommercialOffers.length : 0}</i>)</sup></p>
                                )}
                                
                            {CommercialOffers.length <= 1
                                && (
                                    <p className="application__title">{this.context.translate('Коммерческое предложение')}</p>
                                )}

                            {CommercialOffers.length > 0 
                                && CommercialOffers.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <Accordion
                                            name={item.CommercialOfferTitle}
                                            open={index === 0}
                                            requestKP={requestKP}
                                            actionMessage={actionMessage}
                                            id={item.CommercialOfferId}
                                            isAccepted={item.IsAccepted}
                                            IsConditionsChangePossible={item.IsConditionsChangePossible}
                                            commercialOfferAcceptAction={commercialOfferAcceptAction}
                                            isOneIsAccepted={isOneIsAccepted}
                                            applicationId={ApplicationId}
                                            isActionOk={isActionOk}
                                            modalBtnIsDisabled={modalBtnIsDisabled}
                                        >
                                            <CommercialOffer data={item} download={this.download}/>
                                        </Accordion>
                                    </React.Fragment>
                                ))}

                            {CommercialOffers.length < 1
                                &&  (
                                    <CommercialOffer data={CommercialOffers[0]} download={this.download}/>
                                )}


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}