import React, {Component} from "react";
import Modal from "../common/Modal";
import Form from "../common/form/Form";
import PropTypes from "prop-types";

import ok from "../../../img/notification_ready.svg";

export default class Decline extends Component{
    
    constructor(props){
        super(props);
        this.state = {
            modal: false,
            comment: "",
            disabled: true,
            sent: false
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps = (nextProps) => {
        if (this.props.canComment != nextProps.canComment && nextProps.canComment) {
            this.setState({
                modal: false,
                comment: "",
                disabled: true
            });
        }
    }

    toggleModal = () => {
        this.setState((prevState) => {
            return {
                modal: !prevState.modal
            }
        })
    }

    onChange = (e) => {
        this.setState({
            comment: e.target.value,
            disabled: !e.target.value
        })
    }

    onSubmit = () => {
        var {
            comment
        } = this.state;

        if (comment) {
            this.props.decline(comment);
            this.setState({disabled: true});
        }
    }

    renderModal = () => {
        var {
            modal,
            comment,
            disabled
        } = this.state;

        return modal ? <Modal modalSize="l" classNameContent="modal modal_fullscreen request-decline" onClickBackground={this.toggleModal}>
            <div className="request-evaluate-modal request-evaluate-modal_adapt-default">
                <p className="request-evaluate-modal__title">{this.context.translate('Отправить обращение на доработку')}:</p>
                <Form className="request-evaluate-modal__form" validate={true} onSubmit={this.onSubmit}>
                    <div className="request-evaluate-modal__text">
                        <div className="textarea-input textarea-input_request-evaluate">
                            <label className="textarea-input__wrap">
                                <div className="textarea-input__input-wrap">
                                    <textarea className="textarea-input__input" type="text" placeholder={this.context.translate("Введите текст сообщения")} onChange={this.onChange} />
                                </div>
                            </label>
                        </div>
                    </div>
                    <button className={"u-btn request-evaluate-modal__form-submit"+(disabled ? " disabled" : "")}
                            type="submit">{this.context.translate('Отправить')}</button>
                </Form>
            </div>
        </Modal> : null;
    }

    render(){
        var {
            className = "message__decline"
        } = this.props;

        return <a href="#" className={className} onClick={(e) => {e.preventDefault()}}>
            <span  onClick={this.toggleModal}>{this.context.translate('отправить обращение на доработку')}</span>
            {this.renderModal()}
        </a>
    }
}
