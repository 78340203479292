import React, { Component } from 'react';
import { YMInitializer } from 'react-yandex-metrika';

import { NavLink, Link } from 'react-router-dom';
import logo from "../../../../img/logo.svg";
import SplashScreen from "./SplashScreen";
import PropTypes from "prop-types";
import Footer from "./Footer";
import CompaniesFilter from "./CompaniesFilter";
import ProfileMessages from "./ProfileMessages";
import ProfileNotifications from "./ProfileNotifications";
import {isSupportUser} from "../../../tools/tools";
import {phoneMask} from "../../../tools/mask";

/**
 * user - user menu label
 * title - page title
 * topMenuItems - array of objects with properties to and text
 * role - shipper - заказчик, carrier - перевозчик
 *
 */
export default class Layout extends Component {
    constructor(props, context){
        super(props, context);

        this.minSplashTime = 5000;

        this.state = {
            splash: props.allowSplash && props.isReady,
            splashStart: 0,
            menuOpened: false,
            pathname: props.history.location.pathname
        };

        this.pageMenu = [
            {label: 'Главная', url: '/'},
            {label: 'Мои дела', url: '/deals'},
            {label:  'Заявки', url: '/applications'},
            {label:  'Проекты', url:'/projects'},
            {label:  'Документооборот', 'url': '/documents'},
            {label:  'Страхование', url: '/insurance'},
            {label:  'Акции и специальные предложения', url: '/promotions'}
        ];

        this.profileMenu = [
            {label: 'Профиль', url: '/profile'},
            {label: 'Компании', url: '/companies'}
        ];
        if (props.user && props.user.is_company_admin && !!props.user.is_full_access && this.profileMenu.filter((item) => {return item.url == '/users'}).length == 0) {
            this.profileMenu.push({label: 'Пользователи', url: '/users'})
        }

        if (!props.isGuest) {
            props.getLayoutInfo();

            props.getCommunicationsFormInfo();
        }
        props.getLanguages();
        props.footerSettings();
        props.getDictionary(context.currentLanguage.alias);
    }

    componentDidMount(){
        if (this.props.allowSplash && this.props.isReady) {
            setTimeout(() => {this.setState({splash: false})}, this.minSplashTime);
        }
    }

    componentWillReceiveProps(nextProps){
        var now = Date.now();
        if (this.props.isGuest != nextProps.isGuest && nextProps.isGuest) {
            clearInterval(this.notificationsInterval);
            clearInterval(this.communicationsInterval);
        }
        if (this.props.isReady != nextProps.isReady && nextProps.isReady && nextProps.allowSplash) {
            this.setState({splash: true, splashStart: now});
            setTimeout(() => {this.setState({splash: false})}, this.minSplashTime);
        }
        if (this.props.splashImages != nextProps.splashImages) {
            if (nextProps.splashImages) {
                nextProps.splashImages.map((image) => {
                    var img = new Image();
                    img.src = image;
                })
            } else {
                nextProps.getSplashImages();
            }
        }
        if (nextProps.user != this.props.user) {
            if (nextProps.user && nextProps.user.is_company_admin && !!nextProps.user.is_full_access && this.profileMenu.filter((item) => {return item.url == '/users'}).length == 0) {
                this.profileMenu.push({label: 'Пользователи', url: '/users'})
            }
        }

        if (this.props.currentContact && !this.props.currentContact.isOk && this.props.info !== nextProps.info && !!nextProps.info) {
            this.props.detailContact(nextProps.info.OfficeId);
        }
    }


    shouldComponentUpdate(nextProps, nextState){
        if (nextProps.history.location.pathname != this.state.pathname) {
            if (nextProps.pageLoaded) {
                this.setState({pathname: nextProps.history.location.pathname});
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    static contextTypes = {
        screenSize: PropTypes.string,
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    logout = (e) =>  {
        e.preventDefault();
        this.props.logout();
    };

    toggleMenu = () => {
        this.setState((prevState) => {
            return {
                menuOpened: !prevState.menuOpened
            }
        })
    };

    render() {

        let {
            isGuest,
            isReady,
            user = {},
            info = {},
            companies = [],
            splashImages,
            footerLinks,
            children,

            saveCompanies,
            errorMessage,
            clearError,

            downloading,
            clearDownloading,

            notifications,
            markAsRead,
            downloadHelpfulDoc,

            communications,
            setRating,
            ratingList,
            decline,

            //splash
            message,
            getSplashMessage,
            allowSplash,

            multilingual,
            setLanguage
        } = this.props;

        let emailOffice = this.props.currentContact && this.props.currentContact.Email;
        let phoneOffice = this.props.currentContact && this.props.currentContact.Phone;
        let officeFound = info && info.OfficeId ? true : false;

        let isScreenLg = this.context.screenSize === 'lg';
        let isScreenSm = this.context.screenSize === 'sm';

        let {
            menuOpened
        } = this.state;

        return <div className={(menuOpened ? " menu__open" : "")}>
            {!isGuest && isReady ? <div className="container-wrap__bb2" key={"header"}>
                <YMInitializer
                  accounts={[96010180]}
                  options={{
                      clickmap:true,
                      trackLinks:true,
                      accurateTrackBounce:true,
                      webvisor:true
                  }}
                />
                <header className="header">
                    <div className="header__column">
                        <Link className="logo" to="/">
                            <img src={logo} />
                        </Link>
                    </div>
                    <div className="header__column">
                        <ManagerInfo name={info.SalesManagerName}
                                     phone={info.SalesManagerPhone}
                                     email={info.SalesManagerEmail}
                                     translate={this.context.translate}
                                     emailOffice={emailOffice}
                                     phoneOffice={phoneOffice}
                                     officeFound={officeFound}
                                     lessorPhone={info.LessorPhone}
                                     user={user}
                        />
                        <CompaniesFilter companies={companies}
                                         saveCompanies={saveCompanies}
                        />
                        <div className="profile">
                            <ProfileMessages key="profile-messages"/>
                            <ProfileNotifications key="profile-notifications" />
                            <ProfileMenu key="profile-menu"
                                         name={user.short_name}
                                         items={this.profileMenu}
                                         logout={this.logout}
                            />
                        </div>
                        <div className="menu-burger" onClick={this.toggleMenu}/>
                    </div>
                </header>
            </div> : <header className="header" key="header">
                <div className="header__column"><Link className="logo" to="/"><img src={logo}/></Link></div>
                {!isGuest ? <div className="header__column"><ProfileMenu name={user.short_name} items={[]} logout={this.logout}/></div> : null}
            </header>}
            {!isGuest && isReady && !isScreenLg ? <div className={"side-header"+(menuOpened ? " active" : "")} key={"side-header"} >
                <div className="side-header__head">
                    <div className="profile">
                        <ProfileMessages key="profile-messages"/>
                        <ProfileNotifications key="profile-notifications" />
                        <ProfileMenu key="profile-menu" name={user.short_name} items={this.profileMenu} logout={this.logout} onClick={this.toggleMenu}/>
                    </div>
                    <div className="menu-close" onClick={this.toggleMenu}/>
                </div>
                <div className="side-header__main">
                    <div className="side-header__block">
                        <ManagerInfo name={info.SalesManagerName}
                                     phone={info.SalesManagerPhone}
                                     email={info.SalesManagerEmail}
                                     translate={this.context.translate}
                                     emailOffice={emailOffice}
                                     phoneOffice={phoneOffice}
                                     officeFound={officeFound}
                                     lessorPhone={info.LessorPhone}
                        />
                        <CompaniesFilter companies={companies} saveCompanies={saveCompanies}/>
                    </div>
                    <div className="side-header__block">
                        <div className="page-menu">
                            {this.pageMenu.map((item, key) => <NavLink exact={item.url == '/'} to={item.url} onClick={this.toggleMenu} className={"page-menu__link"} activeClassName={"active"} key={key}>
                                <span>{this.context.translate(item.label)}</span>
                            </NavLink>)}
                        </div>
                        {/*<PageMenu items={this.pageMenu} />*/}
                    </div>
                    <div className="side-header__block">
                        <Link className="action action_sendBid" to="/applications/create"  onClick={this.toggleMenu}> <span>{this.context.translate('Отправить заявку')}</span></Link>
                    </div>
                </div>
            </div> : null}
            {!isGuest && isReady ? <div className="container-wrap__bb1" key={"page-header"}>
                <div className="page-head">
                    <div className="page-head__column">
                        <PageMenu items={this.pageMenu} />
                    </div>
                    <div className="page-head__column">
                        <Link className="action action_sendBid" to="/applications/create">
                            <span>{this.context.translate('Отправить заявку')}</span>
                        </Link>
                    </div>
                </div>
            </div> : null}

            {children}

            <Footer key={"footer"}
                    info={info}
                    footerLinks={footerLinks}
                    isGuest={isGuest || !isReady}
                    isScreenLg={isScreenLg}
                    multilingual={multilingual}
                    setLanguage={setLanguage}
            />

            {this.state.splash ? <SplashScreen user={user.user_name}
                                                             message={message}
                                                             getMessage={getSplashMessage}
                                                             images={splashImages}
                                                             key={"splash-screen"}
            /> : null}

            {downloading.progress || downloading.error ? <div className={"application-upload-mini application-upload-mini_adapt-default application-upload-mini_uploading"}>
                <div className="application-upload-mini__container">
                    <div className="application-upload-mini__inner">
                        <div className="application-upload-mini__content">
                            <p className="application-upload-mini__action">{this.context.translate(downloading.message)}</p>
                            <div className="application-upload-mini__controls">
                                <p className="application-upload-mini__control">
                                    <nobr>{this.context.translate('В процессе')}: <b>{downloading.progress}</b></nobr>
                                </p>
                                <p className="application-upload-mini__control">
                                    <nobr>{this.context.translate('Успешно')}: <b>{downloading.success}</b></nobr>
                                </p>
                                <p className="application-upload-mini__control">
                                    <nobr>{this.context.translate('Ошибки')}: <b>{downloading.error}</b></nobr>
                                </p>
                                {!downloading.progress ? <p className="application-upload-mini__control">
                                    <a className="application-upload-mini__link u-dotted-link" href="#" onClick={(e) => {e.preventDefault(); clearDownloading()}}><span>{this.context.translate('Закрыть')}</span></a>
                                </p> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div> : null}
        </div>;
    }
}

class ProfileMenu extends Component {

    constructor(props){
        super(props);
        this.state = {
            menuOpened: false
        }
    }

    static contextTypes = {
        translate: PropTypes.func
    }

    componentDidMount(){
        document.addEventListener('click', this.windowClick);
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.windowClick);
    }

    windowClick = () => {
        this.setState({menuOpened: false});
    };

    toggleMenu = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        var prevState = this.state.menuOpened;

        var event = document.createEvent("Event");
        event.initEvent("click", false, true);
        document.dispatchEvent(event);

        this.setState({menuOpened: !prevState});
    };

    supportLogout = (url) => {
        return (e) => {
            e.preventDefault();
            window.parent.location.href = url;
        }
    }

    render() {
        let {name, items, logout, onClick} = this.props;

        return <div className={"profile-menu"+(this.state.menuOpened ? " active" : "")}>
            <div className="profile-menu__head" onClick={this.toggleMenu}><span>{name}</span></div>
            <div className="profile-menu__dropdown">
                {items.map((item, key) => <NavLink exact to={item.url} className={"profile-menu__item"}
                                                   activeClassName={"active"} key={key} onClick={onClick}>
                    <span className="profile-menu__item-title">{this.context.translate(item.label)}</span>
                </NavLink>)}
                {isSupportUser ? <a href={"#"} onClick={this.supportLogout("/promotions/index")}
                                    className={"profile-menu__item"}><span className="profile-menu__item-title">{this.context.translate('Администрировать модули')}</span></a> : null}
                {isSupportUser ? <a href={"#"} onClick={this.supportLogout("/users/index")}
                                    className={"profile-menu__item"}><span className="profile-menu__item-title">{this.context.translate('Авторизоваться под пользователем')}</span></a> : null}
                <a href={"#"} onClick={isSupportUser ? this.supportLogout("/") : logout}
                   className={"profile-menu__item"}><span className="profile-menu__item-title">{this.context.translate('Выйти')}</span></a>
            </div>
        </div>;
    }
}

class PageMenu extends Component {


    static contextTypes = {
        translate: PropTypes.func
    }

    render(){

        let {
            items
        } = this.props;

        return <div className="page-menu">
            {items.map((item, key) => <NavLink exact={item.url == '/'} to={item.url} className={"page-menu__link"} activeClassName={"active"} key={key}>
                <span>{this.context.translate(item.label)}</span>
            </NavLink>)}
        </div>
    }
}

function ManagerInfo({name, phone, addPhone, email, translate, emailOffice, phoneOffice, officeFound, lessorPhone, user}) {
    {
        return user && !!user.is_full_access ? (<div className="contact contact_manager">
                {!!name ? <div className="contact__row">
                    <span className="contact__title">{translate('Ваш менеджер')}:</span> {name}
                </div> : null}
                {officeFound ? (!!name ?
                        <div className="contact__row"><a href={"mailto:"+email}>{email}</a></div>
                        :
                        <div className="contact__row"><a href={"mailto:"+emailOffice}>{emailOffice}</a></div>)
                    :
                    null
                }
                <div className="contact__row">
                    {officeFound ? (!!name ?
                            <span> <a  target="_blank" className="plaintext link_contacts-phone" href={phone ? "tel:"+phone.replace(/\s/g, '') : '#'}>{phoneMask(phone, 'def')}</a> {addPhone ? "("+addPhone+")" : ""}</span>
                            :
                            <span> <a target="_blank" className="plaintext link_contacts-phone" href={phoneOffice ? "tel:"+phoneOffice.replace(/\s/g, '') : '#'}>{phoneMask(phoneOffice, 'def')}</a> {addPhone ? "("+addPhone+")" : ""}</span>)
                        :
                        <span> <a target="_blank" className="plaintext link_contacts-phone" href={lessorPhone ? "tel:"+lessorPhone.replace(/\s/g, '') : '#'}>{phoneMask(lessorPhone, 'def')}</a> {addPhone ? "("+addPhone+")" : ""}</span>
                    }
                </div>
                <Link to='/contacts' className={"contact__row"} activeclassname={"active"}>
                    <span className="link_contacts">{translate('Ваша проектная команда')}</span>
                </Link>
            </div>
        ) : (
            <div className="contact">
                <p>Ваша компания еще на проверке</p>
                <p>Начните работу с отправки <Link to="/applications/create">заявки</Link></p>
            </div>
        )
    }
}