import React, { Component } from "react";
import DocumentsTable from "./DocumentsTable";
import TogglePanel from "../common/TogglePanel";
import DocumentsSearchForm from "./DocumentsSearchForm";
import Pagination from "../common/Pagination";
import Preloader from "../common/Preloader";
import { Link } from "react-router-dom";

import noFiles from "../../../img/no-files.svg";
import notFound from "../../../img/magnigier.svg";
import Modal from "../common/Modal";
import DocumentsOrderingWidget, { DocumentOrderingOk, DocumentLoadFailed } from "./DocumentsOrderingWidget";
import PropTypes from "prop-types";

export default class DocumentsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchFields: {},
            direction: 'Inbound',
            sort: 'DESC',
            modal: null,
            data: this.props.location.state ? this.props.location.state : {},
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.documentsRequestResult != this.props.documentsRequestResult && nextProps.documentsRequestResult) {
            this.openModal('success')();
        }
        if (nextProps.loading != this.props.loading && nextProps.loading === false) {
            this.closeModal();
        }

        // открывает модалку, если при загрузке файла произошла ошибка
        if (nextProps.failedDownLoadDocument != this.props.failedDownLoadDocument && nextProps.failedDownLoadDocument) {
            this.openModal('failed')();
        }
    }

    removeHash = () => {
        window.history.replaceState(null, null, window.location.pathname);
    };

    componentDidMount() {
        let hash = this.props.location && this.props.location.hash;
        
        // после того как подписали заявку и нажали на ссылку в модалке мы попадаем на страницу "Документооборот" и включаем вкладку "Исходящие"
        if (hash === "#Outbound") {
            this.setState({
                direction: 'Outbound',
            });
            this.removeHash();
        }
    }

    search = (fields = {}) => {
        this.setState({ searchFields: fields, data: {} });

        const checkCompanyKey = fields && fields.LesseeId && fields.LesseeId.value;
        const arrCompanyKey = this.props.documentsLessees.map((i) => i.value);
        // переключает способ поиска документов относительно выбранных компаний (выбираем в шапке или в фильтре)
        const toogleCompanyKeys = checkCompanyKey ? fields : { ...fields, companyKey: arrCompanyKey };

        this.props.getDocumentsList(toogleCompanyKeys, this.state.direction, this.state.sort);
    }

    toPage = (num) => {
        var fields = { ...this.state.searchFields };
        fields = { ...fields, page: num };

        this.search(fields);
    }

    // для табов ВХОДЯЩИЕ, ИСХОДЯЩИЕ
    toggleTabsHandler = (e, nameTab) => {
        if (e) {
            e.preventDefault();
        }
        let fields = { ...this.state.searchFields };
        fields = { ...fields, page: 1 };

        this.setState(() => {
            return {
                direction: nameTab
            }
        })
        this.search(fields);
    }

    // для сортировки таблицы
    sortDoc = (e) => {
        if (e) {
            e.preventDefault();
        }
        let fields = { ...this.state.searchFields };
        this.setState((prevState) => {
            return {
                sort: prevState.sort === 'DESC' ? 'ASC' : 'DESC'
            }
        }, () => {
            this.search(fields);
        })
    }

    openModal = (modal) => {
        return (e) => {
            if (e) e.preventDefault();
            this.setState({ modal })
        }
    }

    closeModal = (e) => {
        if (e) e.preventDefault();
        this.setState({ modal: null });

        if (this.state.modal === 'failed') {
            this.props.downloadDocumentFailedModalAction(false);
        }
    }

    showInvoice = () => {
        this.setState({ data: { DocumentName: "Счет-фактура" }, modal: false }, () => {
            this.props.getDocumentsList("DocumentName=Счет-фактура");
        });
    }

    renderModal = () => {
        var {
            catalog,
            getCatalog,
            downloadHelpfulDoc,
            addDocumentRequest,
            documentsRequestResult,
            downloadReconciliationStatement,
            documentsFormError,
        } = this.props;

        switch (this.state.modal) {
            case 'widget':
                return <Modal modalSize="l" onClickBackground={this.closeModal} classNameContent="modal_fullscreen page__widget-modal">
                    <DocumentsOrderingWidget catalog={catalog}
                        getCatalog={getCatalog}
                        downloadHelpfulDoc={downloadHelpfulDoc}
                        showInvoice={this.showInvoice}
                        addDocumentRequest={addDocumentRequest}
                        downloadReconciliationStatement={downloadReconciliationStatement}
                        documentsFormError={documentsFormError}
                    />
                </Modal>;
            case 'success':
                return <Modal modalSize='s' onClickBackground={this.closeModal}>
                    <DocumentOrderingOk {...documentsRequestResult}
                        CommunicationId={documentsRequestResult}
                        onClick={this.closeModal}
                        translate={this.context.translate}
                    />
                </Modal>;
            case 'failed':
                return <Modal modalSize='s' onClickBackground={this.closeModal}>
                    <DocumentLoadFailed {...documentsRequestResult}
                        onClick={this.closeModal}
                        translate={this.context.translate}
                    />
                </Modal>;
            default:
                return null;
        }
    }

    render() {
        const {
            activeTab,
            direction,
        } = this.state;

        let {
            documentsList,
            isOk,
            isSearch,
            isSearching,
            isDownLoadDocument,

            catalog,
            getCatalog,
            user,

            downloadDoc,
            documentStatusCount,
            startDocumentsDiadocAction,
        } = this.props;

        return <div className="page__content-container page__documents">
            <h1 className="page__title">{this.context.translate('Документооборот')}</h1>
            <div className="page__head-ui">
                <div className="page__head-ui-items">
                    {
                        user && !!user.is_full_access &&
                        <div className="page__head-ui-item">
                            <a className="u-btn u-btn_adapt-type_wide" href="#" onClick={this.openModal('widget')}>{this.context.translate('Заказать документ')}</a>
                        </div>
                    }
                    {/*<div className="page__head-ui-item">*/}
                    {/*<a className="u-btn u-btn_clear_blue u-btn_adapt-type_wide" href="#doc-widget">Сформировать выписку</a>*/}
                    {/*</div>*/}
                </div>
            </div>

            <div className="page__wrap_list_tabs">
                <div
                    onClick={(e) => this.toggleTabsHandler(e, 'Inbound')}
                    className={`page__list_tab ${direction === 'Inbound' ? 'active' : ''}`}
                >
                    Входящие
                    {!!documentStatusCount && <div className="page__list_tab_num">({documentStatusCount})</div>}
                </div>
                <div
                    onClick={(e) => this.toggleTabsHandler(e, 'Outbound')}
                    className={`page__list_tab ${direction === 'Outbound' ? 'active' : ''}`}
                >
                    Исходящие
                </div>
            </div>

            <TogglePanel className="search"
                labelClassName="page__title-section page__title-ui"
                contentClassName="page__filter-container"
                label={this.context.translate('Поиск')}
                slide={Object.keys(this.state.data).length ? "down" : ""}
            >
                <div className="page__filter">
                    <div className="items-filter items-filter_documents">
                        <DocumentsSearchForm
                            data={this.state.data}
                            catalog={catalog}
                            getCatalog={getCatalog}
                            search={this.search}
                            direction={direction}
                        />
                    </div>
                </div>
            </TogglePanel>

            {isOk || !user.is_full_access
                ?
                (user && !!user.is_full_access && documentsList.length
                    ? <DocumentsTable
                        key="documents-table"
                        list={documentsList}
                        direction={this.state.direction}
                        sort={this.state.sort}
                        sortDoc={this.sortDoc}
                        downloadDoc={downloadDoc}
                        startDocumentsDiadocAction={startDocumentsDiadocAction}
                    >
                        {isSearching ? <Preloader /> : null}
                    </DocumentsTable>
                    : renderEmpty(isSearch, this.context.translate))
                : <Preloader />
            }

            {isDownLoadDocument && <Preloader />}

            {documentsList.pagination
                && (
                    <Pagination
                        key="pagination" {...documentsList.pagination}
                        toPage={this.toPage}
                    />
                )}

            {this.renderModal()}
        </div>
    }
}

function renderEmpty(isSearch, translate) {
    return <div className="page__zero-results">
        {isSearch ? <div className="page__zero-results">
            <div className="info-widget">
                <div className="info-widget__head"><img className="info-widget__image" src={notFound} /></div>
                <div className="info-widget__title">{translate('Ничего не найдено')}</div>
                <div className="info-widget__message">{translate('Пожалуйста, повторите запрос с другими параметрами.')}</div>
            </div>
        </div> : <div className="info-widget">
            <div className="info-widget__head"><img className="info-widget__image" src={noFiles} /></div>
            <div className="info-widget__title">{translate('У вас пока нет документов')}</div>
        </div>}
    </div>
}