import React, {Component} from "react"
import {connect} from "react-redux";
import DocumentsList from "../../components/documents/DocumentsList";
import {
    documentsList,
    documentsSearchInfo,
    documentsWidgetInfo,
    downloadDocument,
    downloadHelpfulDocument,
    addDocumentsRequest,
    downloadReconciliationStatement,
    downloadDocumentFailedModalAction,
} from "../../actions/documents";
import {autoComplete} from "../../actions/catalog";
import Modal from "../../components/common/Modal";
import {
    disableBtnSignAction,
    toggleModalDiadocAction,
    reasonForRejectionAction,
    startDocumentsDiadocAction,
    continuationDocumentsDiadocAction,
    startCertificateUserSignatureDocumentsAction,
    continuationDocumentRejectionProcessAction,
 } from '../../actions/diadocAction';
import { getQueryParam, deleteQueryParam } from "../../tools/tools";
import { pdfApplicationFormAction } from "../../actions/applications";
import DiadocLogin from '../../components/companies/DiadocLogin';
import DiadocSomethingWentWrong from '../../components/companies/DiadocSomethingWentWrong';
import DiadocDocumentRejection from '../../components/companies/DiadocDocumentRejection';
import DiadocDocumentSigning from '../../components/companies/DiadocDocumentSigning';
import DiadocDocumentSigned from '../../components/companies/DiadocDocumentSigned';
import DiadocDocumentRejectionText from '../../components/companies/DiadocDocumentRejectionText';

class DocumentsContainer extends Component{

    constructor(props){
      super(props);
      this.state = {
        isFromAdmin: false,
      }
    }

    componentDidMount(){
        this.setState({isFromAdmin: window.location.href.indexOf('frontend') !== -1})
        // отображает документы соответствующие выбранным компаниям в верхней выпадашке (в шапке)
        const arrCompanyKey = this.props && this.props.documentsLessees && this.props.documentsLessees.map((i) => i.value);
        this.props.getDocumentsList({companyKey: arrCompanyKey || []});


        const code = getQueryParam('code');
        const url = window.location.href.includes('/documents?code');

        // после авторизации в КОНТУРЕ
        // находясь на странице "Документооборот" ищем в адресной строке квери параметр "code" после отправляем запрос
        if (url && code) {
            this.props.continuationDocumentsDiadocAction(code);

            setTimeout(() => deleteQueryParam('code'), 1000);
            setTimeout(() => deleteQueryParam('scope'), 1100);
            setTimeout(() => deleteQueryParam('state'), 1200);
            setTimeout(() => deleteQueryParam('session_state'), 1300);
        }
    }

    renderModal = () => {
        const {
            modalDiadoc,
            disableBtnSignAction,
            toggleModalDiadocAction,
            openIdSettings,
            diadocState,
            reasonForRejectionAction,
            pdfApplicationFormAction,
            startCertificateUserSignatureDocumentsAction,
            downloadDoc,
            continuationDocumentRejectionProcessAction,
        } = this.props;

        switch (modalDiadoc) {
            case 'diadoc_login_second':
                return (
                    <Modal modalSize="l" classNameContent="modal modal_visible" onClickBackground={() => toggleModalDiadocAction('')}>
                        <DiadocLogin
                            type="second"
                            close={() => toggleModalDiadocAction('')}
                            openIdSettings={openIdSettings}
                            pdfApplicationFormAction={pdfApplicationFormAction}
                        />
                    </Modal>
                )
            case 'diadoc_docoment_rejection':
                return (
                    <Modal modalSize="l" classNameContent="modal modal_visible" onClickBackground={() => toggleModalDiadocAction('')}>
                        <DiadocDocumentRejection
                            close={() => toggleModalDiadocAction('')}
                            openDiadocDocumentSigning={() => toggleModalDiadocAction('diadoc_docoment_signing')}
                            reasonForRejectionAction={reasonForRejectionAction}
                        />
                    </Modal>
                )
            case 'diadoc_something_went_wrong':
                return (
                    <Modal modalSize="m" classNameContent="modal modal_visible" onClickBackground={() => toggleModalDiadocAction('')}>
                        <DiadocSomethingWentWrong 
                            close={() => toggleModalDiadocAction('')}
                            text={`${diadocState && diadocState.error}`}
                            downloadDoc={downloadDoc}
                            typePDF="doc"
                        />
                    </Modal>
                )
            case 'diadoc_docoment_signing':
                return (
                    <Modal modalSize="l" classNameContent="modal modal_visible" onClickBackground={() => toggleModalDiadocAction('')}>
                        <DiadocDocumentSigning
                            textReject={diadocState && diadocState.textReasonForRejection}
                            userCertificate={diadocState && diadocState.userCertificate}
                            disableBtnSign={diadocState && diadocState.disableBtnSign}
                            close={() => toggleModalDiadocAction('')}
                            startCertificateUserSignatureDocumentsAction={startCertificateUserSignatureDocumentsAction}
                            disableBtnSignAction={disableBtnSignAction}
                        />
                    </Modal>
                )
            case 'diadoc_docoment_signed':
                return (
                    <Modal modalSize="m" classNameContent="modal modal_visible" onClickBackground={() => toggleModalDiadocAction('')}>
                        <DiadocDocumentSigned
                            close={() => toggleModalDiadocAction('')}
                        />
                    </Modal>
                )
            case 'diadoc_docoment_rejection_text':
                return (
                    <Modal modalSize="m" classNameContent="modal modal_visible" onClickBackground={() => toggleModalDiadocAction('')}>
                        <DiadocDocumentRejectionText
                            close={() => toggleModalDiadocAction('')}
                            back={() => toggleModalDiadocAction('diadoc_docoment_rejection')}
                            diadocState={diadocState}
                            continuationDocumentRejectionProcessAction={continuationDocumentRejectionProcessAction}
                            downloadDoc={downloadDoc}
                        />
                    </Modal>
                )
            case 'diadoc_docoment_reject':
                return (
                    <Modal modalSize="m" classNameContent="modal modal_visible" onClickBackground={() => toggleModalDiadocAction('')}>
                        <DiadocDocumentSigned 
                            close={() => toggleModalDiadocAction('')}
                            type="reject"
                        />
                    </Modal>
                )
            case 'diadoc_enter_from_admin_user':
                return (
                    <Modal modalSize="m" classNameContent="modal modal_visible" onClickBackground={() => toggleModalDiadocAction('')}>
                        <DiadocSomethingWentWrong
                            close={() => toggleModalDiadocAction('')}
                            text='Подписание, отправка и отклонение документов по ЭДО недоступны при авторизации под пользователем'
                            typePDF=""
                        />
                    </Modal>
              )
            
            default:
                return null;
        }
    }

    render(){
        
        return (
            <div className="page page_documents">
                <div className="page__main page__main_default">
                    <DocumentsList
                        {...this.props}
                        startDocumentsDiadocAction={
                            (typeBtn, item) => {
                              // если зашли от админки, то нельзя подписывать
                                if(this.state.isFromAdmin) this.props.toggleModalDiadocAction('diadoc_enter_from_admin_user')
                                else this.props.startDocumentsDiadocAction(typeBtn, item)
                            }
                        }
                    />
                </div>
                {this.renderModal()}
            </div>
        )
    }

}

export default connect(
    state => ({
        documentsList: state.documents.list,
        documentsRequestResult: state.documents.requestResult,
        loading: state.documents.loading,
        isOk: state.documents.isOk,
        isSearch: state.documents.isSearch,
        isSearching: state.documents.isSearching,
        catalog: state.catalog,
        user: state.catalog && state.catalog.user,
        documentsFormError: state.documents.documentsFormError,
        isDownLoadDocument: state.documents.isDownLoadDocument,
        documentStatusCount: state.documents.documentStatusCount,
        documentsLessees: state.catalog.documentsLessees,
        failedDownLoadDocument: state.documents.failedDownLoadDocument,

        modalDiadoc: state.diadocReducer.modalDiadoc,
        openIdSettings: state.diadocReducer.openIdSettings,
        diadocState: state.diadocReducer,
    }),
    (dispatch) => {
        return {
            getDocumentsList: (params, direction, sort) => dispatch(documentsList(params, direction, sort)),
            getSearchFormInfo: () => dispatch(documentsSearchInfo()),
            getWidgetFormInfo: () => dispatch(documentsWidgetInfo()),
            getCatalog: (type, query) => dispatch(autoComplete(type, query)),
            downloadDoc: (id, title) => dispatch(downloadDocument(id, title)),
            downloadHelpfulDoc: (id) => dispatch(downloadHelpfulDocument(id)),
            addDocumentRequest: (body) => dispatch(addDocumentsRequest(body)),
            downloadReconciliationStatement: (params) => dispatch(downloadReconciliationStatement(params)),
            downloadDocumentFailedModalAction: (data) => dispatch(downloadDocumentFailedModalAction(data)),
            pdfApplicationFormAction: (id) => dispatch(pdfApplicationFormAction(id)),

            disableBtnSignAction: (data) => dispatch(disableBtnSignAction(data)),
            toggleModalDiadocAction: (data) => dispatch(toggleModalDiadocAction(data)),
            reasonForRejectionAction: (data) => dispatch(reasonForRejectionAction(data)),
            startDocumentsDiadocAction: (typeBtn, item) => dispatch(startDocumentsDiadocAction(typeBtn, item)),
            continuationDocumentsDiadocAction: (data) => dispatch(continuationDocumentsDiadocAction(data)),
            startCertificateUserSignatureDocumentsAction: (data) => dispatch(startCertificateUserSignatureDocumentsAction(data)),
            continuationDocumentRejectionProcessAction: (data) => dispatch(continuationDocumentRejectionProcessAction(data)),
        }
    }
)(DocumentsContainer)
