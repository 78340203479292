import {
    call,
    put,
    select,
    take
} from 'redux-saga/effects';

import {applicationsList} from './applications';

import {
    fetchApi,
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {checkToken} from "./auth";
import {getCookie, getQueryParam, hasQueryParam} from "../tools/tools";

const company = state => state.company;

/**
 *
 * @returns {{type: string}}
 */
export function newApplication(id) {
    return {
        type: 'NEW_APPLICATION',
        id
    }
}

/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* fetchNewApplication(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type + '_TOKEN_OK');

        const {status, data, error} = yield call(() => {
            return fetchClientApi("/applications/" + action.id, {
                method: 'POST',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        alert(e);
    }
}

/**
 *
 * @param applicationId
 * @param fieldName
 * @param fieldValue
 * @returns {{type: string, applicationId: *, fieldName: *, fieldValue: *}}
 */
// после каждого заполнения элемента формы, отправляются его данные
export function saveField(applicationId, fieldName, fieldValue) {
    return {
        type: 'SAVE_FIELD',
        applicationId,
        fieldName,
        fieldValue
    }
}

/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
// после каждого заполнения элемента формы, отправляются его данные
export function* fetchSaveField(action) {
    try {
        yield put(checkToken(action.type));
        yield take(action.type + '_TOKEN_OK');

        let bodyMain = action.fieldName + '=' + action.fieldValue

        const {status, data, error} = yield call(() => {
            return fetchClientApi("/applications" + (action.applicationId ? "/" + action.applicationId : "") + "?action=edit", {
                method: 'PUT',
                body: bodyMain,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status === 200) {
                // для того что бы отдельть из строки только id и передать его в стор для LesseeId
                if (action.fieldName === 'LesseeId') {
                    let LesseeIdValues = action.fieldValue.split('&');
                    action.fieldValue = LesseeIdValues[0];
                }
                // для того что бы отдельть из строки только значение и передать его в стор для EquipmentQuantity
                if (action.fieldName === 'EquipmentQuantity') {
                    let EquipmentQuantity = action.fieldValue.toString().split('&');
                    action.fieldValue = parseInt(EquipmentQuantity[0]);
                }
                // для того что бы отдельть из строки только значение и передать его в стор для EquipmentCost
                if (action.fieldName === 'EquipmentCost') {
                    let EquipmentCost = action.fieldValue.toString().split('&');
                    action.fieldValue = EquipmentCost[0];
                }

                yield put(checkErrors(action.type, action, status));
                if (!action.applicationId && action.fieldName !== 'PromotionId' && action.fieldName !== 'InvestPotential') {
                    yield put({
                        type: 'SAVE_FIELD',
                        applicationId: data.ApplicationId,
                        fieldName: action.fieldName,
                        fieldValue: action.fieldValue
                    });
                }
            } else {
                yield put(checkErrors(action.type, data, status));
                if (!action.applicationId && action.fieldName !== 'PromotionId' && action.fieldName !== 'InvestPotential') {
                    yield put({
                        type: 'SAVE_FIELD',
                        applicationId: data.ApplicationId,
                        fieldName: action.fieldName,
                        fieldValue: action.fieldValue
                    });
                }
            }
        }

    } catch (e) {
        alert(e);
    }
}

// удаляем заявку
export function deleteApplicationAction(applicationId) {
    return {
        type: 'DELETE_APPLICATION',
        applicationId,
    }
}

// удаляем заявку
export function* deleteApplicationSaga(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type + '_TOKEN_OK');


        const {status, data, error} = yield call(() => {
            return fetchClientApi("/applications/" + action.applicationId + "?action=delete", {
                method: 'PUT',
                token: localStorage.getItem('authToken')
            })
        });

        // после того как удалили заявку, перезагружаем список заявок потому что deleteApplicationAction использует метод PUT
        if (status === 200) yield put(applicationsList('draft'));

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else yield put(checkErrors(action.type, data, status));

    } catch (e) {
        alert(e);
    }
}

/**
 *
 * @param applicationId
 * @param fields
 * @param isCompany
 * @returns {{type: string, applicationId: int, fields, isCompany}}
 */
export function saveFields(applicationId, fields, isCompany = false) {
    return {
        type: 'SAVE_FIELDS',
        applicationId,
        fields,
        isCompany
    }
}

/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* fetchSaveFields(action) {
    try {
        yield put(checkToken(action.type));
        yield take(action.type + '_TOKEN_OK');


        var body = [];

        for (var attr in action.fields) {
            body.push(attr + "=" + action.fields[attr]);
        }

        const {status, data, error} = yield call(() => {
            return fetchClientApi("/applications" + (action.applicationId ? "/" + action.applicationId : "") + "?action=edit", {
                method: 'PUT',
                body: body.join('&'),
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status === 200 || status == 201) {
                yield put(checkErrors(action.type, action, status));
                if (!action.applicationId) {
                    yield put({
                        type: 'SAVE_FIELDS',
                        applicationId: data.ApplicationId,
                        fields: action.fields,
                        isCompany: action.isCompany
                    });
                } else {
                    if (action.isCompany) {
                        yield put({type: "SAVE_COMPANY_SUCCESS"});
                    } else {
                        yield put({type: "UPDATE_APPLICATION_SUCCESS"});
                    }
                }
            } else yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        alert(e);
    }
}

/**
 *
 * @param applicationId
 * @returns {{type: string, applicationId: *}}
 */
export function sendApplication(applicationId, fields) {
    return {
        type: 'SEND_APPLICATION',
        applicationId,
        fields
    }
}

/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
// отправка формы на странице ОТПРАВИТЬ ЗАЯВКУ
export function* fetchSendApplication(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type + '_TOKEN_OK');

        var body = [];

        //отправка на сервер всех данных с формы новой заявки для проверки значений
        for (var attr in action.fields) {
            if(action.fields[attr] !== null) {
                if(attr === 'IsMailing' && typeof action.fields[attr] == 'boolean') body.push(attr + "=" + (action.fields[attr] ? 1 : 0))
                else body.push(attr + "=" + action.fields[attr]);
            }
        }
        if(getCookie('_ym_uid')) body.push(`WebAnaliticsClientId=${getCookie('_ym_uid')}`);

        const {status, data, error} = yield call(() => {
            return fetchClientApi("/applications/" + action.applicationId + "?action=send", {
                method: 'PUT',
                body: body.join('&'),
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else yield put(checkErrors(action.type, data, status));

    } catch (e) {
        alert(e);
    }
}

// получаем данные сохранненых полей и подставляем их, если переходим на документ через кнопук РЕДАКТИРОВАТЬ
export function viewApplication(id) {
    return {
        type: 'DETAIL_APPLICATION',
        id
    }
}


/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */

// получаем данные сохранненых полей и подставляем их, если переходим на документ через кнопук РЕДАКТИРОВАТЬ
export function* fetchViewApplication(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type + '_TOKEN_OK');


        const {status, data, error} = yield call(() => {
            return fetchClientApi("/applications/" + action.id + "?action=form-values", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        alert(e);
    }
}

export function applicationFormInfo(id) {
    return {
        type: 'FORM_INFO_APPLICATION',
        id
    }
}


/**
 *
 * @param action
 * @returns {IterableIterator<*>}
 */
export function* fetchApplicationFormInfo(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type + '_TOKEN_OK');


        const {status, data, error} = yield call(() => {
            return fetchClientApi("/applications" + (action.id ? "/" + action.id : "") + "?action=form-info", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        alert(e);
    }
}

export function selectedDisabledBtnAction(data) {
    return {
        type: 'FIND_COMPANY_ERROR_FETCH',
        data
    }
}

// для поиска компании СПАРК по клику на кнопки "Добавить по ИНН"
export function findCompany(inn) {
    return {
        type: 'FIND_COMPANY',
        inn
    }
}

export function* fetchFindCompany(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type + '_TOKEN_OK');


        const {status, data, error} = yield call(() => {
            return fetchClientApi("/applications/companies?inn=" + action.inn, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) {
            yield put(checkErrors(action.type, {message: error}, 403));
        } else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        alert(e);
    } finally {
        yield put({type: 'FIND_COMPANY_ERROR_FETCH', data: false});
    }
}

// ДЛЯ НОВОЙ КОМПАНИИ В РАЗДЕЛЕ "Информация о поставщике"
// для поиска компании СПАРК по клику на кнопки "Добавить по ИНН"
export function findCompanyProvider(inn) {
    return {
        type: 'FIND_COMPANY_PROVIDER',
        inn
    }
}

export function* fetchFindCompanyProvider(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type + '_TOKEN_OK');


        const {status, data, error} = yield call(() => {
            return fetchClientApi("/applications/companies?inn=" + action.inn, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) {
            yield put(checkErrors(action.type, {message: error}, 403));
        } else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch (e) {
        alert(e);
    } finally {
        yield put({type: 'FIND_COMPANY_PROVIDER_ERROR_FETCH', data: false});
    }
}

export function clearApplicationForm() {
    return {
        type: "CLEAR_APPLICATION_FORM"
    }
}

export function clearCompanyForm() {
    return {
        type: "CLEAR_COMPANY_FORM"
    }
}

// очищаем поля в модальном окне в разделе "Информация о поставщике", если СПАРК нашел компанию 
export function deleteProviderCompanyForm() {
    return {
        type: "DELETE_COMPANY_PROVIDER_FORM"
    }
}

// очищаем поля в модальном окне в разделе "Компания-лизингополучатель", если СПАРК нашел компанию 
export function deleteCompanyForm() {
    return {
        type: "DELETE_COMPANY_FORM"
    }
}

export function setField(attribute, value) {
    return {
        type: "SET_FIELD",
        attribute,
        value
    }
}