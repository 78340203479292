import React, {Component} from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import ru from 'date-fns/locale/ru';

export default class DateRange extends Component{

    constructor(props){
        super(props);
        this.state = {
            from: props.value && props.value.from ? moment(props.value.from.split('.').reverse().join('-')) : null,
            to: props.value && props.value.to ? moment(props.value.to.split('.').reverse().join('-')) : null
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };
    
    componentWillReceiveProps(nextProps){
        if (nextProps.value != this.props.value && !nextProps.value) {
            this.setState({
                from: null,
                to: null
            })
        }
    }

    componentDidMount(){
        this.dateFrom.getElementsByTagName('input')[0].readOnly = true;
        this.dateTo.getElementsByTagName('input')[0].readOnly = true;
        moment.locale('ru');
    }

    onDateFrom = (date) => {
        let {
            attribute,
            value,
            onChange
        } = this.props;

        this.setState({from: date ? moment(date).startOf('month').toDate() : null}, () => {
            onChange && onChange(attribute, {
                from: date ? moment(date).startOf('month').format('DD.MM.YYYY') : null,
                to: value.to
            })
        })

    };

    onDateTo = (date) => {
        let {
            attribute,
            value,
            onChange
        } = this.props;

        this.setState({to: date ? moment(date).endOf('month').toDate() : null}, () => {
            onChange && onChange(attribute, {
                from: value.from,
                to: date ? moment(date).endOf('month').format('DD.MM.YYYY') : null
            })
        })
    };

    onDateRange = (range) => {
        return () => {
            let {
                attribute,
                onChange
            } = this.props;

            let newFrom = moment().subtract(1, range).startOf(range).toDate(),
                newTo = moment().subtract(1, range).endOf(range).toDate();
            this.setState({from: newFrom, to: newTo}, () => {
                onChange && onChange(attribute, {
                    from: moment(newFrom).format('DD.MM.YYYY'),
                    to: moment(newTo).format('DD.MM.YYYY')
                })
            })

        }
    };

    render(){
        const renderMonthContent = (month, shortMonth, longMonth, day) => {
            return <span className="daterange__month_title">
                {longMonth[0].toUpperCase() + longMonth.substring(1)}
            </span>;
        };
        return <div className="daterange daterange__months">
            <div className='daterange_months__last_calcs'>
                <span
                  className="daterange_months__last_calcs__phase"
                  onClick={this.onDateRange('quarter')}
                >За прошлый квартал</span>
                <span
                  className="daterange_months__last_calcs__phase"
                  onClick={this.onDateRange('months')}
                >За прошлый месяц</span>
            </div>
            <div className="daterange__row daterange_months__row daterange__row_flex">
                <div className={"daterange_months__start daterange__input"+(this.state.from ? " filled" : "")}
                     ref={(datepicker) => this.dateFrom = datepicker}
                     key="date-from"
                >
                    <DatePicker
                        selected={this.state.from}
                        onChange={this.onDateFrom}
                        maxDate={this.state.to || moment(new Date()).subtract(1,'months').endOf('month').toDate()}
                        startDate={this.state.from}
                        endDate={this.state.to}
                        locale={ru}
                        selectsStart
                        isClearable={true}
                        dateFormat={window.innerWidth < 768 ? 'LLLL' : 'LLLL yyyy'}
                        renderMonthContent={renderMonthContent}
                        showMonthYearPicker
                    />
                    {this.state.from && <span className="daterange_months__curr_date">C {moment(this.state.from).format('DD MMMM yyyy')}</span>}
                </div>
                <div className="daterange__separate"/>
                <div className={"daterange_months__end daterange__input"+(this.state.to ? " filled" : "")}
                     ref={(datepicker) => this.dateTo = datepicker}
                     key="date-to"
                >
                    <DatePicker
                        selected={this.state.to}
                        onChange={this.onDateTo}
                        minDate={this.state.from}
                        maxDate={moment().subtract(1,'months').endOf('month').toDate()}
                        startDate={this.state.from}
                        endDate={this.state.to}
                        locale={ru}
                        selectsEnd
                        isClearable={true}
                        dateFormat={window.innerWidth < 768 ? 'LLLL' : 'LLLL yyyy'}
                        renderMonthContent={renderMonthContent}
                        showMonthYearPicker
                    />
                    {this.state.to && <span className="daterange_months__curr_date">По {moment(this.state.to).format('DD MMMM yyyy')}</span>}
                </div>
            </div>
        </div>
    }
}