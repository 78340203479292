const initState = {
    added: false,
    stateDisabled: false,
    fields: {},

    addedProvider: false,
    fieldsProvider: {},
    selectedTypeVendorInformation: 'main',
    selectedTypeLeasingCompany: 'main',
};

const textMeesage = 'Нижеуказанные данные взяты из СПАРК. В случае обнаружения несоответствий обратитесь, пожалуйста, к вашему менеджеру.';

export function company(state = initState, action) {

    switch (action.type) {
        case 'FIND_COMPANY':
            return { ...state, errors: {}, added: false };
        case 'FIND_COMPANY_VALIDATION_ERROR':
            var errors = { ...state.errors } || {};
            errors.Inn = action.data.message;
            return { ...state, errors, added: false };
        case 'FIND_COMPANY_ERROR_FETCH':
            return {
                ...state,
                stateDisabled: action.data
            };
        case 'FIND_COMPANY_NOT_FOUND':
            return { ...state, modalMessage: action.data.message, added: false };
        case 'FIND_COMPANY_SUCCESS':
            return { ...state, modalMessage: textMeesage, fields: action.data, added: false };
        case 'SAVE_FIELDS':
            return { ...state, modalMessage: '', added: false };
        case 'SAVE_COMPANY_SUCCESS':
            return { ...state, modalMessage: '', added: true };
        case 'FIND_COMPANY_BAD_REQUEST':
            return { ...state, modalMessage: action.data.message, added: false };
        case 'CLEAR_COMPANY_FORM':
            return { ...state, errors: {}, modalMessage: '', added: false };
        case 'DELETE_COMPANY_FORM':
            return { ...state, errors: {}, modalMessage: '', fields: {}, addedProvider: false };


        // ДЛЯ НОВОЙ КОМПАНИИ В РАЗДЕЛЕ "Информация о поставщике"
        case 'FIND_COMPANY_PROVIDER':
            return { ...state, errors: {}, addedProvider: false };
        case 'FIND_COMPANY_PROVIDER_VALIDATION_ERROR':
            var errors = { ...state.errors } || {};
            errors.Inn = action.data.message;
            return { ...state, errors, addedProvider: false };
        case 'FIND_COMPANY_PROVIDER_ERROR_FETCH':
            return {
                ...state,
                stateDisabled: action.data
            };
        case 'FIND_COMPANY_PROVIDER_NOT_FOUND':
            return { ...state, modalMessage: action.data.message, addedProvider: false };
        case 'FIND_COMPANY_PROVIDER_SUCCESS':
            return { ...state, modalMessage: textMeesage, fieldsProvider: action.data, added: false };
        case 'SAVE_FIELDS_PROVIDER':
            return { ...state, modalMessage: '', addedProvider: false };
        case 'SAVE_COMPANY_PROVIDER_SUCCESS':
            return { ...state, modalMessage: '', addedProvider: true };
        case 'FIND_COMPANY_PROVIDER_BAD_REQUEST':
            return { ...state, modalMessage: action.data.message, addedProvider: false };
        case 'CLEAR_COMPANY_PROVIDER_FORM':
            return { ...state, errors: {}, modalMessage: '', addedProvider: false };
        case 'DELETE_COMPANY_PROVIDER_FORM':
            return { ...state, errors: {}, modalMessage: '', fieldsProvider: {}, addedProvider: false };
        case 'SELECTED_TYPE_VENDOR_INFORMATION':
            return { 
                ...state,
                selectedTypeVendorInformation: action.data,
            };
        case 'SELECTED_TYPE_LEASING_COMPANY':
            return { 
                ...state,
                selectedTypeLeasingCompany: action.data,
            };
        case "LOGOUT_SUCCESS":
            return {...initState};
    }

    return state;
}