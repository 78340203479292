import React, {Component} from 'react';
import Form from "../common/form/Form";
import {getWord} from "../../tools/tools";

export default class RegStepCode extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            checkSms,
            renderFields,
            fieldsRegStep2,
            regState,
            onRepeatCodeRequest,
            isValid,
            tries_more,
            time,
            context,
            back
        } = this.props

        return (
            <Form validate={true} className="auth__form-slide active" onSubmit={checkSms}>
                {renderFields(fieldsRegStep2, 'fieldsRegStep2')}
                <div className="form__message">
                    {tries_more && <div className="text-error">Неверно введен код подтверждения. Повторите попытку</div>}
                    {
                        tries_more && tries_more < 10 ? (
                            <div>
                                {time ? <p>{context.translate("У вас осталось {m}, после чего аккаунт будет заблокирован. Вы сможете запросить код повторно через {n} сек.", {
                                    n: "0" + Math.floor(time / 60) + ":" + (time % 60 < 10 ? ("0" + (time % 60)) : (time % 60)),
                                    m: tries_more + " " + getWord(tries_more, "попытка", "попытки", "попыток")
                                })}</p> : null}
                            </div>
                        ) : (
                            <div>
                                {time ? <p>{context.translate("Вы сможете запросить код повторно через {n} сек.", {
                                    n: "0" + Math.floor(time / 60) + ":" + (time % 60 < 10 ? ("0" + (time % 60)) : (time % 60))
                                })}</p> : null}
                            </div>
                        )
                    }
                    <a className={`reg__linkBtn ${time == 0 ? "" : "disabled"}`}
                       onClick={onRepeatCodeRequest}>{context.translate("Запросить повторно")}</a>
                </div>
                <div className="form__buttons">
                    <button
                        type="button"
                        onClick={back}
                        className={"btn btn_clear_blue"}
                    >{context.translate("Назад")}</button>
                    <button
                        type="submit"
                        className={`btn ${isValid('fieldsRegStep2') && !regState.sendRequest ? "" : " disabled"}`}
                    >{context.translate("Продолжить")}</button>
                </div>
            </Form>
        )
    }
}