import React, {Component} from "react";
import {numberMask} from "../../tools/mask";
import Form from "../common/form/Form";
import FormField from "../common/form/FormField";
import CheckBox from "../common/form/CheckBox";
import PropTypes from "prop-types";

export default class CompanyForm extends Component{

    constructor(props){
        super(props);

        var companyMain = props.fieldsMainForm || {};
        var disabled = true;

        this.state = {
            Inn: {
                type: 'text',
                label: "ИНН",
                value: companyMain.LesseeManualTin || "",
                error: "",
                placeholder: "Введите ИНН",
                mask: numberMask()
            },
            Kpp: {
                type: 'text',
                label: "КПП",
                value: companyMain.LesseeManualKpp || "",
                error: "",
                placeholder: "Введите КПП",
            },
            FullName: {
                type: 'text',
                label: "Полное наименование",
                placeholder: 'Полное наименование',
                value: companyMain.LesseeManualFullName || "",
                error: "",
                disabled
            },


            LesseeManualShortName: {
                type: 'text',
                label: "Краткое наименование",
                value: companyMain.LesseeManualShortName || "",
                error: '',
                // success: !!company.LesseeManualShortName,
                success: !!companyMain.LesseeManualShortName,
                placeholder: 'Краткое наименование',
                disabled: true,
            },
            LesseeOwnershipId: {
                type: 'select',
                label: "ОПФ",
                placeholder: "Выберите из списка",
                value: companyMain.LesseeOwnershipId || "",
                error: '',
                options: props.catalog && props.catalog.lesseeOwnerships || [],
                // optionsType: 'equipmentBrands',
                success: false,
                allowEmpty: true,
                disabled: true,
            },
            LesseeManualOGRN: {
                type: 'text',
                label: "ОГРН",
                value: companyMain.LesseeManualOGRN || "",
                error: '',
                // success: !!company.EquipmentStorageAreaAddress,
                placeholder: 'Укажите',
                disabled: true,
            },

            // Юридический адрес
            LesseeManualIndexLegal: {
                type: 'text',
                label: "Индекс",
                value: companyMain.LesseeManualIndexLegal || "",
                error: "",
                mask: numberMask(),
                disabled
            },
            LesseeManualCityLegal: {
                type: 'text',
                label: "Город",
                value: companyMain.LesseeManualCityLegal || "",
                error: "",
                disabled
            },
            LesseeManualAddressLegal: {
                type: 'text',
                label: "Адрес",
                value: companyMain.LesseeManualAddressLegal || "",
                error: "",
                disabled
            },

            // Фактический адрес
            FactAddressEqualsLegalAddress: {
                type: 'checkbox',
                label: 'Совпадает с юридическим адресом',
                value: companyMain.FactAddressEqualsLegalAddress || false,
                disabled,
                id: 'FactAddressEqualsLegalAddress'
            },
            LesseeManualIndexActual: {
                type: 'text',
                label: "Индекс",
                value: companyMain.LesseeManualIndexActual || "",
                error: "",
                mask: numberMask(),
                disabled
            },
            LesseeManualCityActual: {
                type: 'text',
                label: "Город",
                value: companyMain.LesseeManualCityActual || "",
                error: "",
                disabled
            },
            LesseeManualAddressActual: {
                type: 'text',
                label: "Адрес",
                value: companyMain.LesseeManualAddressActual || "",
                error: "",
                disabled
            },

            // Адрес для корреспонденции
            IsLesseeManualAddressPostLegalMatched: {
                type: 'checkbox',
                label: 'Совпадает с юридическим адресом',
                value: companyMain.IsLesseeManualAddressPostLegalMatched || false,
                disabled,
                id: 'IsLesseeManualAddressPostLegalMatched'
            },
            IsLesseeManualAddressActualPostMatched: {
                type: 'checkbox',
                label: 'Совпадает с фактическим адресом',
                value: companyMain.IsLesseeManualAddressActualPostMatched || false,
                disabled,
                id: 'IsLesseeManualAddressActualPostMatched'
            },
            LesseeManualIndexPost: {
                type: 'text',
                label: "Индекс",
                value: companyMain.LesseeManualIndexPost || "",
                error: "",
                mask: numberMask(),
                disabled
            },
            LesseeManualCityPost: {
                type: 'text',
                label: "Город",
                value: companyMain.LesseeManualCityPost || "",
                error: "",
                disabled
            },
            LesseeManualAddressPost: {
                type: 'text',
                label: "Адрес",
                value: companyMain.LesseeManualAddressPost || "",
                error: "",
                disabled
            },
            
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentDidMount() {
        const {
            LesseeOwnershipId,
        } = this.props.fieldsMainForm;
        
        // при первой загрузке подставляет нужные значения в название полей
        if (this.props.fieldsMainForm && this.props.fieldsMainForm.LesseeOwnershipId) {
            this.contentForChangeLabelFeild(LesseeOwnershipId);
        }
    }
    
    // подставляем значения в поля после поиска ИНН
    addValues = (state, company) => {
        state.Kpp.value = company && company.Kpp || "";
        state.FullName.value = company && company.FullName || "";
        state.LesseeManualShortName.value = company && company.ShortName || "";
        state.LesseeOwnershipId.value = company && company.OwnershipId || "";
        state.LesseeManualOGRN.value = company && company.Ogrn || "";
        
        state.LesseeManualIndexLegal.value = company && company.LeggalAddress && company.LeggalAddress.PostCode || "";
        state.LesseeManualCityLegal.value = company && company.LeggalAddress && company.LeggalAddress.Location || "";
        state.LesseeManualAddressLegal.value = company && company.LeggalAddress && company.LeggalAddress.StreetAndBuilding || "";

        state.LesseeManualIndexActual.value = company && company.LeggalAddress && company.LeggalAddress.PostCode || "";
        state.LesseeManualCityActual.value = company && company.LeggalAddress && company.LeggalAddress.Location || "";
        state.LesseeManualAddressActual.value = company && company.LeggalAddress && company.LeggalAddress.StreetAndBuilding || "";
        
        for (var key in state) {
            state[key].success = !!state[key].value && !state[key].error;
        }
        state.FactAddressEqualsLegalAddress.disabled = false;
        return state;
    }

    componentWillReceiveProps(nextProps){
        var state = {...this.state};
        if (nextProps.errors != this.props.errors) {
            for (var key in state) {
                if (nextProps.errors && nextProps.errors[key]) {
                    state[key].error = nextProps.errors[key];
                    state[key].success = false;
                } else {
                    state[key].error = "";
                }
            }
        }
        if (nextProps.fields != this.props.fields) {
            state = this.addValues(state, nextProps.fields);
        }
        if (state != this.state) this.setState(state);

        // разблокируем поля формы если сервис временно не доступен
        if (this.props.modalMessage !== nextProps.modalMessage) {
            this.state.FullName.disabled = false;

            this.state.LesseeManualShortName.disabled = false;
            this.state.LesseeOwnershipId.disabled = false;
            this.state.LesseeManualOGRN.disabled = false;

            this.state.LesseeManualIndexLegal.disabled = false;
            this.state.LesseeManualCityLegal.disabled = false;
            this.state.LesseeManualAddressLegal.disabled = false;
            this.state.FactAddressEqualsLegalAddress.disabled = false;

            this.state.LesseeManualIndexActual.disabled = false;
            this.state.LesseeManualCityActual.disabled = false;
            this.state.LesseeManualAddressActual.disabled = false;
            this.state.LesseeManualIndexPost.disabled = false;
            this.state.LesseeManualCityPost.disabled = false;
            this.state.LesseeManualAddressPost.disabled = false;

            this.state.IsLesseeManualAddressPostLegalMatched.disabled = false;
            this.state.IsLesseeManualAddressActualPostMatched.disabled = false;

            const checkLesseeOwnershipId = this.props.fieldsMainForm && +this.props.fieldsMainForm.LesseeOwnershipId;
            // подставляет в селект значение которое было сохранено в сторе
            if (checkLesseeOwnershipId) {
                this.state.LesseeOwnershipId.value = this.props.fieldsMainForm && +this.props.fieldsMainForm.LesseeOwnershipId;
            }
            
        }
    }

    // для чекбокса СОВПАДАЕТ С ЮРИДИЧЕСКИМ АДРЕСОМ в секции "Фактический адрес"
    changeFactEqualsLegal = (attribute, value) => {
        var state = {...this.state};
        state.FactAddressEqualsLegalAddress.value = value;

        if (!value) {
            state.LesseeManualIndexActual.disabled = false;
            state.LesseeManualCityActual.disabled = false;
            state.LesseeManualAddressActual.disabled = false;

            state.LesseeManualIndexActual.value = '';
            state.LesseeManualCityActual.value = '';
            state.LesseeManualAddressActual.value = '';
        } else {
            state.LesseeManualIndexActual.value = state.LesseeManualIndexLegal.value;
            state.LesseeManualIndexActual.disabled = !this.props.company;
            state.LesseeManualCityActual.value = state.LesseeManualCityLegal.value;
            state.LesseeManualCityActual.disabled = !this.props.company;
            state.LesseeManualAddressActual.value = state.LesseeManualAddressLegal.value;
            state.LesseeManualAddressActual.disabled = !this.props.company;
        }
        this.setState(state);
    }

    // для чекбокса СОВПАДАЕТ С ЮРИДИЧЕСКИМ АДРЕСОМ
    changePostUrCheckbox = (attribute, value) => {
        var state = {...this.state};

        state.IsLesseeManualAddressPostLegalMatched.value = value;
        state.IsLesseeManualAddressActualPostMatched.value = false;

        // заполняем поля "Адрес для корреспонденции" исодердимым полей "Юридический адрес"
        if (value) {
            state.LesseeManualIndexPost.value = state.LesseeManualIndexLegal.value;
            state.LesseeManualCityPost.value = state.LesseeManualCityLegal.value;
            state.LesseeManualAddressPost.value = state.LesseeManualAddressLegal.value;

            // блокируем последние три поля
            state.LesseeManualIndexPost.disabled = true;
            state.LesseeManualCityPost.disabled = true;
            state.LesseeManualAddressPost.disabled = true;
        } else {
            // очищаем поля
            state.LesseeManualIndexPost.value = '';
            state.LesseeManualCityPost.value = '';
            state.LesseeManualAddressPost.value = '';

            // разблокируем последние три поля
            state.LesseeManualIndexPost.disabled = false;
            state.LesseeManualCityPost.disabled = false;
            state.LesseeManualAddressPost.disabled = false;
        }

        this.setState(state);
    }

    // для чекбокса СОВПАДАЕТ С ФАКТИЧЕСКИМ АДРЕСОМ
    changePostFactCheckbox = (attribute, value) => {
        var state = {...this.state};

        state.IsLesseeManualAddressActualPostMatched.value = value;
        state.IsLesseeManualAddressPostLegalMatched.value = false;

        if (value) {
            state.LesseeManualIndexPost.value = state.LesseeManualIndexActual.value;
            state.LesseeManualCityPost.value = state.LesseeManualCityActual.value;
            state.LesseeManualAddressPost.value = state.LesseeManualAddressActual.value;

            // блокируем последние три поля
            state.LesseeManualIndexPost.disabled = true;
            state.LesseeManualCityPost.disabled = true;
            state.LesseeManualAddressPost.disabled = true;
        } else {
            state.LesseeManualIndexPost.value = '';
            state.LesseeManualCityPost.value = '';
            state.LesseeManualAddressPost.value = '';

            // разблокируем последние три поля
            state.LesseeManualIndexPost.disabled = false;
            state.LesseeManualCityPost.disabled = false;
            state.LesseeManualAddressPost.disabled = false;
        }

        this.setState(state);
    }


    // контент для функуии которая изменяет название полей
    contentForChangeLabelFeild = (value) => {
        this.setState({
            ...this.state,
            LesseeManualOGRN: {
                ...this.state.LesseeManualOGRN,
                label: (+value === 4 || +value === 8 || +value === 9) ? 'ОГРНИП' : 'ОГРН',
            },
            FullName: {
                ...this.state.FullName,
                label: (+value === 4 || +value === 8 || +value === 9) ? 'ФИО индивидуального предпринимателя' : 'Полное наименование',
            },
            FactAddressEqualsLegalAddress: {
                ...this.state.FactAddressEqualsLegalAddress,
                label: (+value === 4 || +value === 8 || +value === 9) ? 'Совпадает с местом регистрации' : 'Совпадает с юридическим адресом',
            },
            IsLesseeManualAddressPostLegalMatched: {
                ...this.state.IsLesseeManualAddressPostLegalMatched,
                label: (+value === 4 || +value === 8 || +value === 9) ? 'Совпадает с местом регистрации' : 'Совпадает с юридическим адресом',
            },
            IsLesseeManualAddressActualPostMatched: {
                ...this.state.IsLesseeManualAddressActualPostMatched,
                label: (+value === 4 || +value === 8 || +value === 9) ? 'Совпадает с адресом местонахождения бизнеса' : 'Совпадает с фактическим адресом',
            },
        });
    }

    // изменяет название полей
    changeLabelFeild = (attribute, value) => {
        if (attribute === 'LesseeOwnershipId') {
            this.contentForChangeLabelFeild(value);
        }
    }

    onChangeField = (attribute, value) => {
        var state = {...this.state};
        if (state.hasOwnProperty(attribute)) {
            state[attribute].value = value;
            this.setState(state)
        }

        this.changeLabelFeild(attribute, value);
    };

    renderField = (attribute, options = {}) => {
        var fields = this.state;

        return fields.hasOwnProperty(attribute) ?
            <FormField {...options}
                        key={attribute}
                        fieldConfig={{
                            ...fields[attribute],
                            label: this.context.translate(fields[attribute].label),
                            placeholder: this.context.translate(fields[attribute].placeholder)
                        }}
                       attribute={attribute}
                       onChange={this.onChangeField}
            /> : null;
    };

    onSearch = (e) => {
        e.preventDefault();
        if (this.state.Inn.value) {
            this.props.findCompany && this.props.findCompany(this.state.Inn.value);
            this.props.selectedDisabledBtnAction(true);
        } else {
            this.setState({
                ...this.state,
                Inn: {
                    ...this.state.Inn,
                    error: this.context.translate('Это поле необходимо заполнить.')
                }
            })
        }
    }

    // Отправка формы
    onSubmit = () => {
        var companyFields = {...this.state};

        const { Leader } = this.props.fields;
        
        var fields = {
            LesseeManualFullName: companyFields.FullName.value.replace(/\"/gim, ''),
            LesseeManualTin: companyFields.Inn.value,
            LesseeManualKpp: companyFields.Kpp.value,
            LesseeManualShortName: companyFields.LesseeManualShortName.value,
            LesseeOwnershipId: companyFields.LesseeOwnershipId.value,
            LesseeManualOGRN: companyFields.LesseeManualOGRN.value,

            // юридический адрес
            LesseeManualIndexLegal: companyFields.LesseeManualIndexLegal.value,
            LesseeManualCityLegal: companyFields.LesseeManualCityLegal.value,
            LesseeManualAddressLegal: companyFields.LesseeManualAddressLegal.value,

            // фактический адрес
            LesseeManualIndexActual: companyFields.LesseeManualIndexActual.value,
            LesseeManualCityActual: companyFields.LesseeManualCityActual.value,
            LesseeManualAddressActual: companyFields.LesseeManualAddressActual.value,
            IsLesseeManualAddressActualLegalMatched: companyFields.FactAddressEqualsLegalAddress.value ? 1 : 0,

            // Адрес для корреспонденции
            IsLesseeManualAddressPostLegalMatched: companyFields.IsLesseeManualAddressPostLegalMatched.value ? 1 : 0,
            IsLesseeManualAddressActualPostMatched: companyFields.IsLesseeManualAddressActualPostMatched.value ? 1 : 0,
            LesseeManualIndexPost: companyFields.LesseeManualIndexPost.value,
            LesseeManualCityPost: companyFields.LesseeManualCityPost.value,
            LesseeManualAddressPost: companyFields.LesseeManualAddressPost.value,


            // для поля руководитель на главной форме
            LesseeLeader: Leader && Leader.FullName

        };
        
        this.props.addCompany && this.props.addCompany(fields);

        this.props.selectedTypeLeasingCompanyAction && this.props.selectedTypeLeasingCompanyAction('modal');
    };

    render(){

        const {
            FullName,
            LesseeManualShortName,
            LesseeManualOGRN,
            LesseeManualIndexLegal,
            LesseeManualCityLegal,
            LesseeManualAddressLegal,
        } = this.state;

        const {
            stateDisabled,
        } = this.props;

        // определят ИП 
        const replaceText = +this.state.LesseeOwnershipId.value === 4 || +this.state.LesseeOwnershipId.value === 8 || +this.state.LesseeOwnershipId.value === 9;
        // блокирует кнопку
        const disableBtn = FullName.value && (replaceText || LesseeManualShortName.value) && LesseeManualOGRN.value && LesseeManualIndexLegal.value && LesseeManualCityLegal.value && LesseeManualAddressLegal.value;
        
        return <Form className="form_modal"
                     validate={true}
                     onSubmit={this.onSubmit}
        >
            <h2 className="form__title">{this.context.translate('Новая компания')}</h2>
            <h3 className="form__subtitle">{this.context.translate('Информация о компании')}</h3>
            <div className="form__row">
                {this.renderField('Inn', {className: 'form__field_size_s form__field_size_tablet_inner'})}
                {this.renderField('Kpp', {className: 'form__field_size_s form__field_size_tablet_inner'})}
                <div className="form__field__wrap_btn_submit_and_spinner">
                    <div className="form__field__wrap_btn_submit">
                        <button
                            className={"btn"}
                            href="#"
                            onClick={this.onSearch}
                            disabled={stateDisabled}
                        >
                            {this.state.FullName.value ? this.context.translate('Повторить поиск') : this.context.translate('Найти Компанию')}
                        </button>
                    </div>
                    
                    {stateDisabled
                        && (
                            <div className="form__field__wraps_pinner">
                                <img src={`${require('../../../img/elements/elem-preload.svg')}`} alt="Спинер" />
                            </div>
                        )}
                </div>
            </div>
            <div className="form__text">
                <p>{this.props.modalMessage ? this.props.modalMessage : this.context.translate('Введите ИНН компании, чтобы получить информацию о ней в СПАРК.')}</p>
            </div>
            <div className="form__row">
                {this.renderField('FullName')}
            </div>
            <div className={`form__row ${replaceText ? 'hide_if_not_ip' : ''}`}>
                {this.renderField('LesseeManualShortName')}
            </div>
            <div className="form__row">
                {this.renderField('LesseeOwnershipId')}
                {this.renderField('LesseeManualOGRN')}
            </div>

            <h3 className="form__subtitle">{this.context.translate(`${replaceText ? 'Место регистрации' : 'Юридический адрес'}`)}</h3>
            <div className="form__row">
                {this.renderField('LesseeManualIndexLegal', {className: 'form__field_size_xs form__field_size_tablet_inner'})}
                {this.renderField('LesseeManualCityLegal', {className: 'form__field_size_m'})}
                {this.renderField('LesseeManualAddressLegal')}
            </div>
            
            <h3 className="form__subtitle">{this.context.translate(`${replaceText ? 'Адрес местонахождения бизнеса' : 'Фактический адрес'}`)}</h3>
            <div className="form__row">
                <div className={"form__field "+(this.state.FactAddressEqualsLegalAddress.disabled ? "disabled" : null)}>
                    <CheckBox {...this.state.FactAddressEqualsLegalAddress} onChange={this.changeFactEqualsLegal}/>
                </div>
            </div>
            <div className="form__row">
                {this.renderField('LesseeManualIndexActual', {className: 'form__field_size_xs form__field_size_tablet_inner'})}
                {this.renderField('LesseeManualCityActual', {className: 'form__field_size_m'})}
                {this.renderField('LesseeManualAddressActual')}
            </div>

            <h3 className="form__subtitle">{this.context.translate('Адрес для корреспонденции')}</h3>
            <div className="form__row">
                <div className={"form__field "+(this.state.IsLesseeManualAddressPostLegalMatched.disabled ? "disabled" : null)}>
                    <CheckBox {...this.state.IsLesseeManualAddressPostLegalMatched} onChange={this.changePostUrCheckbox}/>
                </div>
                <div className={"form__field "+(this.state.IsLesseeManualAddressActualPostMatched.disabled ? "disabled" : null)}>
                    <CheckBox {...this.state.IsLesseeManualAddressActualPostMatched} onChange={this.changePostFactCheckbox}/>
                </div>
            </div>
            <div className="form__row">
                {this.renderField('LesseeManualIndexPost', {className: 'form__field_size_xs form__field_size_tablet_inner'})}
                {this.renderField('LesseeManualCityPost', {className: 'form__field_size_m'})}
                {this.renderField('LesseeManualAddressPost')}
            </div>

            <div className="form__buttons">
                <button className={"btn"+(!!disableBtn ? "" : " disabled")} type="submit">{this.context.translate('Добавить')}</button>
            </div>
        </Form>
    }

}