import React, { Component } from "react";
import thanks from "../../../img/papers-thanks.svg";
import { history } from "../../containers/App";
import PropTypes from "prop-types";

export default class SuccessMessage extends Component {

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    close = () => {
        this.props.close();
    }

    toLink = () => {
        this.props.close();
        setTimeout(() => {
            history.push(`/communications/${this.props.communicationId}`); 
        }, 1000);
    }

    render() {
        return (
            <div className="success_message">
                <div className="success_message__img"><img src={thanks} /></div>
                <h3 className="success_message__title">
                    Спасибо, ваше обращение принято
                </h3>

                <div className="success_message__description">
                    Обычно мы отвечаем в течение 2 рабочих дней. <br></br>
                    Вы можете отслеживать обработку запроса по <span className="u-link" onClick={this.toLink}>ссылке</span>
                </div>
                
                <div className="success_message__wrap_btns">
                    <button
                        onClick={this.close}
                        className={"btn"}
                    >{this.context.translate('Ок')}
                    </button>
                </div>
            </div>
        )
    }
}