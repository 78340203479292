import React, {Component} from "react";
import parser from 'bbcode-to-react';
import Collapser from "../common/Collapser";
import {Link} from "react-router-dom";
import Modal from "../common/Modal";
import CopyToClipboard from "react-copy-to-clipboard";
import DealItemRound from "./DealItemRound";
import PropTypes from "prop-types";

export default class DealItem extends Component{

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render(){
        let {item, downloadInvoice, paymentNotification, typeDeals} = this.props;

        return <div className="notice-list__item">
            <DealItemRound item={item}  translate={this.context.translate}/>
            <DealItemBody item={item} translate={this.context.translate} typeDeals={typeDeals} />
            <DealItemDocs item={item} paymentNotification={paymentNotification} downloadInvoice={downloadInvoice} translate={this.context.translate}/>
        </div>
    }
}

function NoticeList({ObligationTypeId, OverduesList, ContractsList, translate}){

    if (ObligationTypeId == 5) {
        return ContractsList && ContractsList.length > 3 ? <Collapser label={translate("Посмотреть все")}
                                                     labelClassName="u-more-link"
                                                     labelActiveClassName="u-more-link_open"
        >
            <div className="u-collapser__container">
                <div className="notice-list__text-more">
                    <Contracts ContractsList={ContractsList} />
                </div>
            </div>
        </Collapser> : <Contracts ContractsList={ContractsList} />
    }

    if (OverduesList) return (
        <Collapser label={translate("Состав задолженности")}
                    labelClassName="u-more-link"
                    labelActiveClassName="u-more-link_open"
        >
            <div className="u-collapser__container">
                <div className="notice-list__text-more">
                    <Overdues OverduesList={OverduesList} />
                    {ContractsList ? <div className="notice-list__text-title">
                        {ContractsList && ContractsList.length == 1 ? translate('по договору лизинга') : translate('По договорам лизинга')+':'}&nbsp;
                        <Contracts ContractsList={ContractsList} /></div>
                    : null}
                </div>
            </div>
        </Collapser>);

    switch (ContractsList && ContractsList.length) {
        case 0:
            return "";
        case 1:
            return (
                <div className="notice-list__text-title">
                    {translate('по договору лизинга')}&nbsp;
                    <Contracts ContractsList={ContractsList} />
                </div>
            );
        case 2:
        case 3:
            return (
                    <div className="notice-list__text-title">
                        {translate('по договорам лизинга')}:
                        <Contracts ContractsList={ContractsList} />
                    </div>
            );
        default:
            return (
                <Collapser label={translate("По договорам лизинга")}
                           labelClassName="u-more-link"
                           labelActiveClassName="u-more-link_open"
                >
                    <div className="u-collapser__container">
                        <div className="notice-list__text-more">
                            <Contracts ContractsList={ContractsList} />
                        </div>
                    </div>
                </Collapser>);
    }
}

function DealItemBody({item, translate, typeDeals}) {
    return <div className="notice-list__body">
        <div className="notice-list__text-item">
            <div className="notice-list__title">
                {item.ObligationTypeName}
            </div>

            {(typeDeals === 'consideration')
                && (
                    <React.Fragment>
                        По вашей заявке « 
                        <Link to={`/applications/view/${item.ApplicationId}`}>
                            <span className="notice_list_notification__link">
                                {item.EquipmentName} {item.EquipmentBrandName}
                            </span>
                        </Link>
                        » добавлен новый расчет. Подробнее в разделе 
                        {' '}
                        <Link to="/applications#progress">
                            <span className="notice_list_notification__link">
                                Заявки
                            </span>
                        </Link>
                    </React.Fragment>
                )}

            {(item.ObligationTypeId == 7 && typeDeals !== 'consideration')
                && (
                    <div className="notice_list_notification__cell_content">
                        <div className="notice_list_notification__title">
                            <span className="notice_list_notification__normal_text">
                                Подробнее в разделе
                            </span>
                            {' '}
                            <Link to="/documents">
                                <span className="notice_list_notification__link">
                                    Документооборот
                                </span>
                            </Link>
                        </div>
                    </div>
                )}

            {(item.ObligationTypeId != 7 && typeDeals !== 'consideration')
                && (
                    <div className="notice-list__text">
                        {item.TotalAmount ?  <span className="collapser-before-text"> {translate('в размере')}&nbsp;
                                    <span className="u-summ u-summ_red">{item.TotalAmount}</span>
                            {item.ObligationTypeId == 2 ? " "+translate("за ранее просроченный, но впоследствии погашенный платеж")+" " : ""}
                                </span> : null}
                        <NoticeList ObligationTypeId={item.ObligationTypeId} OverduesList={item.OverduesList} ContractsList={item.ContractsList} translate={translate}/>
                    </div>
                )}
            
        </div>

        {item.ObligationNoteName ? <div className="notice-list__text-item u-mobile-hide">
            <div className="notice-list__text">
                <span className={"u-notice"+(item.ObligationNoteDetails ? " collapser-before-text" : "")+(item.ObligationNoteSeverityStyle === 'reminder' ? " u-notice_info" : "")}>{item.ObligationNoteName}.</span>
                {item.ObligationNoteDetails ? <Collapser label={translate("Подробнее")}
                           labelClassName="u-more-link"
                           labelActiveClassName="u-more-link_open"
                >
                    <div className="u-collapser__container">
                        <div className="notice-list__text-more">
                            {parser.toReact(item.ObligationNoteDetails)}
                        </div>
                    </div>
                </Collapser> : null}
            </div>
        </div> : null}
    </div>
}

class DealItemDocs extends Component {

    constructor(props){
        super(props);
        this.state = {
            modalOpened: false
        };
    }

    getModalContent = () => {
        return <Modal modalSize="m" classNameContent="modal modal_visible"  onClickBackground={this.toggleModal}>
            <Requisites Requisites={this.props.item.Requisites} translate={this.props.translate}/>
            <button onClick={this.toggleModal} className="btn">{this.props.translate("Скрыть")}</button>
        </Modal>
    }

    toggleModal = (e) => {
        if (e) e.preventDefault();
        this.setState((prevState) => {
            return {modalOpened: !prevState.modalOpened};
        })
    }

    downloadInvoice = (e) => {
        e.preventDefault();
        this.props.downloadInvoice(this.props.item.Data);
    }

    paymentNotification = (data) => {
        return (e) => {
            e.preventDefault();
            this.props.paymentNotification(data);
        }
    }

    render() {
        let {
            IsPayment,
            Data,
            RequisitesNotification
        } = this.props.item;

        return <div className="notice-list__docs">
            {Data ? <div className="notice-list__doc-item">
                <a className="u-doc-link" href="#" onClick={this.downloadInvoice}><span>{this.props.translate("Счет на оплату ШН")}</span></a>
            </div>: null}
            {IsPayment ? <div className="notice-list__doc-item">
                <a href="#" onClick={this.toggleModal}><span>{this.props.translate("Реквизиты для оплаты")}</span></a>
                {RequisitesNotification 
                    && (
                        <a href="#" onClick={this.paymentNotification(RequisitesNotification)}>
                            <span>{this.props.translate("Скачать уведомление о платеже")}</span>
                        </a>
                    )}
            </div> : null}
            {this.state.modalOpened ? this.getModalContent() : null}
        </div>
    }
}

class Requisites extends Component{

    constructor(props){
        super(props);
        this.state = {
            activePage: 1
        }
    }

    renderPager = (active,count) => {
        return count == 1 ? null : <div className="requisites__pager">
            <a className={active == 1 ? "disabled" : ""} href="#" onClick={(e) => {e.preventDefault();this.setState({activePage: active - 1})}}>&lt;</a>
            &nbsp;{active} / {count}&nbsp;
            <a className={active == count ? "disabled" : ""} href="#" onClick={(e) => {e.preventDefault();this.setState({activePage: active + 1})}}>&gt;</a>
        </div>
    };

    render() {

        let {
            Requisites,
            translate
        } = this.props;

        let Requisite = Requisites[this.state.activePage - 1];

        return <div className="requisites">
            <h2 className="form__title">{translate("Реквизиты для оплаты")}</h2>
            {this.renderPager(this.state.activePage, Requisites.length)}
            <div className="requisites__block">
                <div className="data__row">
                    <RequisiteItem label={translate("Наименование получателя")} value={Requisite.PayeeName} />
                </div>
                <div className="data__row">
                    <RequisiteItem label={translate("ИНН")} value={Requisite.PayeeTin} />
                    <RequisiteItem label={translate("КПП")} value={Requisite.PayeeKpp} />
                </div>
                <div className="data__row">
                    <RequisiteItem label={translate("Счет №")} value={Requisite.PayeePaymentAccount} />
                </div>
            </div>
            <div className="requisites__block">
                <div className="data__row">
                    <RequisiteItem label={translate("Банк получателя")} value={Requisite.PayeeBankName} />
                </div>
                <div className="data__row">
                    <RequisiteItem label={translate("БИК")} value={Requisite.PayeeBankBic} />
                    <RequisiteItem label={translate("Счет №")} value={Requisite.PayeeBankCorrespondentAccount} />
                </div>
            </div>
            <div className="requisites__block">
                <div className="data__row">
                    <RequisiteItem label={translate("Сумма")} value={Requisite.Payments.Amount} />
                </div>
                <div className="data__row">
                    <RequisiteItem label={translate("Назначение платежа")} value={Requisite.Payments.PaymentPurpose} />
                </div>
            </div>
        </div>
    }
}

function RequisiteItem({label, value}) {

    return <span>
        <b>{label}:</b>&nbsp;<span>{value}</span>
        <CopyToClipboard text={value}><button title={"Копировать строку в буфер обмена"} className="copy"/></CopyToClipboard>
    </span>
}

class Contracts extends Component{

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render(){
        let {
            ContractsList
        } = this.props;

        if (ContractsList && ContractsList.length > 1) {
            return <ul className="u-list-ul u-list-ul_theme_default">
                {ContractsList.map((listItem, key) => <li className="u-list-ul__item u-list-ul__item_color_blue" key={key}>
                    <Link to={"/projects/dfa/"+listItem.ContractId}>{listItem.ContractNumber+(listItem.ContractDate ? " "+this.context.translate("от")+" "+listItem.ContractDate : "")+" «"+listItem.ContractName+"»"}</Link>
                </li>)}
            </ul>
        } else {
            return (
                <React.Fragment>
                    {ContractsList
                        && (
                            <Link to={"/projects/dfa/" + ContractsList[0].ContractId}>{ContractsList[0].ContractNumber + (ContractsList[0].ContractDate ? " " + this.context.translate("от") + " " + ContractsList[0].ContractDate : "") + " «" + ContractsList[0].ContractName + "»"}</Link>
                        )
                    }
                </React.Fragment>
            )
        }
    }

}

function Overdues({OverduesList}) {
    return OverduesList.length > 1 ? <ul className="u-list-ul u-list-ul_theme_default">
        {OverduesList.map((listItem, key) => <li className="u-list-ul__item" key={key}>
            {listItem.Text}:&nbsp;
            <span className="u-summ u-summ_red">{listItem.Amount}</span>
        </li>)}
    </ul> : <span>{OverduesList[0].Text}:&nbsp;<span className="u-summ u-summ_red">{OverduesList[0].Amount}</span></span>
}