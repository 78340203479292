import React, {Component} from "react";
import FormField from "../common/form/FormField";
import Form from "../common/form/Form";
import PropTypes from "prop-types";
import {noSpace} from "../../tools/mask";

export default class SetPasswordForm extends Component{

    constructor(props){
        super(props);
        this.state = {
            new_password: {
                type: 'password',
                label: "Пароль",
                name: "authPassword",
                value: "",
                ispass: "true",
                // required: true,
                error: '',
                mask: noSpace
            },
            repeat_password: {
                type: 'password',
                label: "Повторите пароль",
                name: "authRepeatPassword",
                value: "",
                ispass: "true",
                // required: true,
                error: '',
                mask: noSpace
            }
        };

    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps){
        if (nextProps.activationErrors != this.props.activationErrors) {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    new_password: {
                        ...prevState.new_password,
                        error: nextProps.activationErrors.password
                    }
                }
            })
        }
    }

    getError = (name, value) => {
        switch (name) {
            case 'repeat_password':
                return this.state.new_password.value == value ? false : this.context.translate("Пароль введен неверно");
            case 'new_password':
                var re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z*#.=\]`^@\-'>?%<!&;{_~$:([,\/)}"\+|]{8,}$/;

                return re.test(value) ? false : this.context.translate("Пароль должен содержать латинские буквы в верхнем и нижнем регистрах и цифры и быть длинной не менее 8 символов.");
        }
    }

    onChangeField = (attribute, value, error) => {
        var prevState = {...this.state[attribute]};
        prevState.value = value;
        prevState.error = error || this.getError(attribute, value);
        this.setState({[attribute]: prevState})
    };

    onSubmit = () => {
        if (this.isValid()) {
            this.props.onSubmit(this.state.new_password.value);
        }
    }


    isValid = () => {
        var {
            new_password,
            repeat_password
        } = this.state;
        return !new_password.error && !repeat_password.error && new_password.value && repeat_password.value && new_password.value == repeat_password.value;
    }

    changePassType = (passType, attribute) => {
        this.setState({
            ...this.state,
            [attribute]: {
                ...this.state[attribute],
                type: passType
            }
        })
    }

    renderFields = (fields) => {
        var result = [];

        for (let attribute in fields) {
            if (fields.hasOwnProperty(attribute)) {

                result.push(<div className="form__row" key={attribute}>
                    <FormField fieldConfig={{...fields[attribute], label: this.context.translate(fields[attribute].label)}}
                               attribute={attribute}
                               onChange={this.onChangeField}
                               changePassType={(e) => this.changePassType(e, attribute)}
                    />
                </div>)
            }
        }

        return result;
    };

    render() {

        let fields = this.state;

        return (
            <Form className="auth__form-slide active set-password"
                  validate={true}
                  onSubmit={this.onSubmit}
            >
                {this.renderFields(fields)}
                {this.props.children}
                <div className="form__buttons">
                    <button type="submit"
                            className={"btn btn_orange" + (this.isValid() && !this.props.sendRequest ? "" : " disabled")}
                    >{this.context.translate('Установить пароль')}
                    </button>
                </div>
            </Form>
        );
    }
}