import React, {Fragment} from "react";
import {Link} from "react-router-dom";

const CompaniesSlideItem = ({item, length, showAll}) => {
    return <Fragment>
        <div className={'companies-slider__item'}>
            {item.file ? <img src={item.file} alt={item.title}/> : null}
            <a href={item.url} target={'_blank'}>{item.title}</a>
        </div>
        {showAll ? <a href={'#'} onClick={showAll} className='companies-slider__all-link visible-xs'>Показать все <sup>({length})</sup><span/></a> : null}
    </Fragment>
}

export default CompaniesSlideItem;