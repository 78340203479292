import React, { Component } from "react";
import Form from "../common/form/Form";
import FormField from "../common/form/FormField";
import PropTypes from "prop-types";

export default class DiadocCompanyChoice extends Component {

    constructor(props){
        super(props);

        this.state = {
            fields: {
                simpleSelect: {
                    type: 'select-diadoc',
                    label: "Выберите ящик компании в Диадок из которого будет произведена отправка документа",
                    placeholder: "Выберите компанию",
                    value: "",
                    error: '',
                    options: props && props.boxesList || [],
                    optionsType: 'boxesList',
                    // success: false,
                    // allowEmpty: false,
                },
            },
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    close = () => {
        this.props.close();
    }

    onChangeField = (attribute, value, error) => {
        var prevState = {...this.state.fields};
        if (prevState.hasOwnProperty(attribute)) {
            prevState[attribute].value = value;
            prevState[attribute].error = error;
            this.setState({fields: prevState})
        }
    };

    onSubmit = () => {
        let {
            simpleSelect,
        } = this.state.fields;

        console.log('выбранная опция', simpleSelect.value);
        // записываем в стор выбранный boxId
        this.props.selectedBoxIdAction(simpleSelect && simpleSelect.value);
        // продолжение цикла после выбора компании "новая заявка" - "applications"
        this.props.continuationAfterCompanySelectionApplicationDiadocAction(simpleSelect && simpleSelect.value);
    }

    renderFields = (fields) => {
        var result = [];

        for (var attribute in fields) {
            if (fields.hasOwnProperty(attribute)) {

                result.push(<div className="profile-form__item" key={attribute}>
                    <FormField fieldConfig={{
                        ...fields[attribute],
                        label: this.context.translate(fields[attribute].label)
                    }}
                        attribute={attribute}
                        onChange={this.onChangeField}
                    />
                </div>)
            }
        }

        return result;
    };


    render() {

        const { simpleSelect } = this.state.fields;

        return (
            <div className="diadoc_company_choice">
                <Form validate={true} onSubmit={this.onSubmit}>
                    <h3 className="diadoc_company_choice__title">{this.context.translate('Выбор ящика для отправки документа')}</h3>

                    <div className="diadoc_company_choice__wrap_elements_form">
                        {this.renderFields({...this.state.fields})}
                    </div>
                    
                    <div className="diadoc_company_choice__wrap_btns">
                        <button
                            // onClick={this.close}
                            className={`btn ${(simpleSelect.value ? "" : " disabled")}`}
                        >
                            {this.context.translate('Продолжить')}
                        </button>
                    </div>
                </Form>
            </div>
        )
    }
}
