import React, {Component} from "react";
import PropTypes from "prop-types";

import Preloader from "../common/Preloader";
import Tabs from "../common/Tabs";
import ProfileForm from "./ProfileForm";
import PasswordForm from "./PasswordForm";
import NotificationSettings from "./NotificationSettings";
import {getQueryParam} from "../../tools/tools";

export default class Profile extends Component{
    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render(){

        let {
            profile,
            notificationSettings,
            isOk,
            notFullUser,
            savingStatus,
            message,

            changePassword,
            passwordErrors,

            saveSettings,
            dataChangeRequest,

            user
        } = this.props;

        this.tabsMenu = [
            this.context.translate("Основная информация"),
            this.context.translate("Безопасность"),
        ];
        if(user && !!user.is_full_access) this.tabsMenu.push(this.context.translate("Настройки уведомлений"))

        return isOk ? <Tabs tabsMenu={this.tabsMenu}  activeTab={user && !!user.is_full_access && getQueryParam('blank') === 'notify_settings' ? 2 : undefined}>
            {
                <div className="page__profile-form">
                    <div className="profile-form profile-form_two-columns profile-form_adapt_default">
                        <ProfileForm {...profile} dataChangeRequest={dataChangeRequest} savingStatus={savingStatus} message={message}/>
                    </div>
                </div>
            }
            <div className="page__profile-form">
                <div className="profile-form profile-form_one-columns profile-form_adapt_default">
                    <PasswordForm onSubmit={changePassword} errors={passwordErrors}/>
                </div>
            </div>
            {
                !notFullUser &&
                <div className="page__profile-form">
                    <div className="profile-form profile-form_adapt_full">
                        <NotificationSettings savingStatus={savingStatus} message={message} notificationSettings={notificationSettings} saveSettings={saveSettings}/>
                    </div>
                </div>
            }
        </Tabs> : <Preloader/>
    }
}