import React, { Component } from "react";
import PropTypes from "prop-types";

export default class DiadocSomethingWentWrong extends Component {

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    close = () => {
        this.props.close();
    }


    render() {

        const {
            type = '',
            text = '',
            pdfApplicationFormAction = () => { },
            downloadDoc = () => { },
            typePDF = 'application',
        } = this.props;

        return (
            <div className="diadoc_something_went_wrong">
                <h3 className="diadoc_something_went_wrong__title">{this.context.translate('Что-то пошло не так...')}</h3>

                <div className="diadoc_something_went_wrong__description">
                    {this.context.translate(text)}
                </div>

                <div className="diadoc_something_went_wrong__wrap_btns">
                    {type !== 'second'
                        && (
                            <button
                                onClick={this.close}
                                className={"btn"}
                            >
                                {this.context.translate('Завершить')}
                            </button>
                        )}

                    {type === 'second'
                        && (
                            <React.Fragment>
                                <button
                                    onClick={() => this.props.openPopupDiadocCompanyChoice()}
                                    className={"btn"}
                                >
                                    {this.context.translate('Назад')}
                                </button>
                                <button
                                    onClick={this.close}
                                    className={"btn btn_gray"}
                                >
                                    {this.context.translate('Завершить')}
                                </button>
                            </React.Fragment>
                        )}

                    {(typePDF === 'application')
                        && (
                            <button
                                onClick={() => {
                                    if (typePDF === 'application') {
                                        pdfApplicationFormAction(localStorage.getItem('idApplication'))
                                    }
                                    // if (typePDF === 'doc') {
                                    //     const selectedDoc = JSON.parse(localStorage.getItem('selectedDoc'));
                                    //     downloadDoc(+selectedDoc.id, selectedDoc.title);
                                    //     this.close();
                                    // }
                                }}
                                className={"btn btn_white"}
                            >
                                {this.context.translate('Скачать заявку')}
                            </button>
                        )}
                </div>
            </div>
        )
    }
}
