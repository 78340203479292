const initState = {
    step: 0,
    sendRequest: false,
    authErrors: {},
    errorsRegions: {},
    regCodeErrors: {},
    regDataErrors: {},
    reg1Errors: {},
    regIsSameUserError: false,
    companyProviderValidationErrors: false,
    passError: {},
    phone_number: '',
    email: '',
    code: '',
    tries_more: null,
    first_name: '',
    last_name: '',
    second_name: '',
    inn: '',
    fieldsCompaniesProvider: [],
    companiesProviderSendRequest: false,
    companyIsChoosen: false,
    kpp: '',
    short_name: '',
    regionName: '',
    regionId: '',
    businessRegions: [],
    personalData: false,
    isChanged: false,
    confirmStep: 0,
    sms_code: '',
    regModalSuccess: false,
    confirmTempPassError: {},
    companyProviderErrors: "",
    handInn: false
};

import {
    parseErrors
} from "../tools/api-helper";

export function reg(state = initState, action) {
    switch (action.type) {
        case "REG_CHECK_PHONE_REQUEST":
            return {...state, reg1Errors: {}, sendRequest: true, regIsSameUserError: false,}
        case "REG_CHECK_PHONE_REQUEST_SUCCESS":
            return {...state, ...action.data, step: 1, sendRequest: false, regIsSameUserError: false};
        case "REG_CHECK_PHONE_REQUEST_VALIDATION_ERROR":
            return {...state, reg1Errors: parseErrors(action.data), sendRequest: false, regIsSameUserError: false};
        case 'REG_CONTINUE_DATA':
            return {...state, ...action.data, step: 2, sendRequest: false};
        case 'REG_SAME_USER':
            return {...state, sendRequest: false, regIsSameUserError: true};

        case "REG_CHECK_SMS_REQUEST":
            return {...state, regCodeErrors: {}, sendRequest: true}
        case "REG_CHECK_SMS_REQUEST_SUCCESS":
            return {...state, sendRequest: false, step: 2}
        case "REG_CHECK_SMS_REQUEST_VALIDATION_ERROR":
            return {...state, regCodeErrors: parseErrors(action.data.errors), tries_more: action.data.tries_more, sendRequest: false}
        case 'REG_CHECK_SMS_REQUEST_BAD_REQUEST':
            return {...state, regCodeErrors: action.data, sendRequest: false}

        case "REG_SHOW_INFO_DATA":
            return {...state, ...action.data, authErrors: {}, step: 3, companyIsChoosen: true}


        case "REG_SEND_DATA_REQUEST":
            return {...state, authErrors: {}, regDataErrors: {}, sendRequest: true, regModalSuccess: false}
        case "REG_SEND_DATA_REQUEST_SUCCESS":
            return {...state, sendRequest: false, regDataErrors: {}, regModalSuccess: true}
        case "REG_SEND_DATA_REQUEST_VALIDATION_ERROR":
            return {...state, regDataErrors: parseErrors(action.data), sendRequest: false, step: 2, regModalSuccess: false}

        case "REG_CHANGE_DATA":
            return {...state, isChanged: true, step: 2, regModalSuccess: false}

        case "REG_SEND_TEMP_PASSWORD_REQUEST":
            return {...state, confirmTempPassError: {}, sendRequest: true}
        case "REG_SEND_TEMP_PASSWORD_REQUEST_SUCCESS":
            return {...state, sendRequest: false, confirmStep: 1, ...action.data, sms_code: action.data.code, confirmTempPassError: {}}
        case "REG_SEND_TEMP_PASSWORD_REQUEST_VALIDATION_ERROR":
            return {...state, confirmTempPassError: parseErrors(action.data), sendRequest: false}


        case "REG_CHECK_CONFIRM_SMS_REQUEST":
            return {...state, regCodeErrors: {}, sendRequest: true}
        case "REG_CHECK_CONFIRM_SMS_REQUEST_SUCCESS":
            return {...state, sendRequest: false, confirmStep: 2}
        case "REG_CHECK_CONFIRM_SMS_REQUEST_VALIDATION_ERROR":
            return {...state, regCodeErrors: parseErrors(action.data.errors), tries_more: action.data.tries_more, sendRequest: false}
        case 'REG_CHECK_CONFIRM_SMS_REQUEST_BAD_REQUEST':
            return {...state, regCodeErrors: action.data, sendRequest: false}

        case "REG_SEND_PASSWORD_REQUEST":
            return {...state, passError: {}, sendRequest: true}
        case "REG_SEND_PASSWORD_REQUEST_SUCCESS":
            return {...state, sendRequest: false, ...action.data}
        case "REG_SEND_PASSWORD_REQUEST_VALIDATION_ERROR":
            return {...state, passError: parseErrors(action.data),  sendRequest: false}


        case 'REG_FIND_COMPANY_PROVIDER':
            return {
                ...state,
                errors: {},
                short_name: "",
                handInn: false,
                companyProviderValidationErrors: false,
                fieldsCompaniesProvider: [],
                companyProviderErrors: "Ищем компанию в СПАРК",
                companiesProviderSendRequest: true
            };
        case 'REG_FIND_COMPANY_PROVIDER_SUCCESS':
            return { ...state, fieldsCompaniesProvider: Array.isArray(action.data) ? [...action.data] : [action.data], companiesProviderSendRequest: false};
        case 'REG_FIND_COMPANY_PROVIDER_VALIDATION_ERROR':
            return {...state, companyProviderValidationErrors: true, companyIsChoosen: false, companyProviderErrors: action.data.message, companiesProviderSendRequest: false};
        case 'REG_FIND_COMPANY_PROVIDER_ERROR_FETCH':
            return {...state};
        case 'REG_FIND_COMPANY_PROVIDER_BAD_REQUEST':
            return {...state, companyProviderErrors: action.data.message, handInn: true, companiesProviderSendRequest: false};

        case 'REG_GET_BUSINESS_REGIONS':
            return { ...state, errorsRegions: {} };
        case 'REG_GET_BUSINESS_REGIONS_SUCCESS':
            return { ...state, businessRegions: [...action.data] };
        case 'REG_GET_BUSINESS_REGIONS_VALIDATION_ERROR':
            return { ...state, errorsRegions: state.errors };

        case 'REG_CHANGE_STEP':
            return {...state, step: action.step, tries_more: null, regIsSameUserError: false}

        case "LOGOUT_SUCCESS":
            return {...initState};
        default:
            return state
    }
    return state;
}