import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export default class DealINotification extends Component {

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render() {
        return (
            <div className="notice_list_notification">
                <div className="notice_list_notification__cell_icon">
                    <div className="notice_list_notification__wrap_icon">
                        <div className="circle-note circle-note_float-header circle-note_adapt-default">
                            <div className="circle-note__round circle-note__round_light-red circle-note_screamer">
                                <div className="circle-note__round-text circle-note__round-text_red">!</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="notice_list_notification__cell_content">
                    <div className="notice_list_notification__title">
                        У вас есть документ, требующий подписания
                        <br></br>
                        <span className="notice_list_notification__normal_text">
                            Подробнее в разделе
                        </span>
                        {' '}
                        <Link to="/documents">
                            <span className="notice_list_notification__link">
                                Документооборот
                            </span>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}
