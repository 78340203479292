import React, {Component} from 'react';
import Form from "../common/form/Form";

export default class RegStepUserDataList extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            context,
            regState,
            onRegChangeData,
            fieldsRegStep1,
            fieldsRegStep3,
            endReg
        } = this.props

        return (
            <div className="reg__ending_data">
                <div className="reg__ending_data_block">
                    <p>Данные регистрации:</p>
                </div>
                <div className="reg__ending_data_block">
                    <p>Телефон: {regState.phone_number}</p>
                    <p>E-mail: {fieldsRegStep3.email ? fieldsRegStep3.email.value : fieldsRegStep1.email.value}</p>
                    <p>ФИО: {regState.last_name} {regState.first_name} {regState.second_name}</p>
                    <p>ИНН компании {regState.inn}</p>
                    {/*<p>КПП компании {regState.kpp}</p>*/}
                    {fieldsRegStep3.short_name.value && <p>Краткое наименование компании: {fieldsRegStep3.short_name.value}</p>}
                    <p>Регион ведения бизнеса: {regState.regionName}</p>
                </div>
                <div className="reg__ending_data_block">
                    <a className="reg__linkBtn" onClick={onRegChangeData}>Изменить</a>
                </div>
                <p className="reg__ending_data_email">Для активации учетной записи мы отправим письмо на указанную почту</p>
                <div className="form__buttons">
                    <button
                        className={"btn"}
                        onClick={endReg}
                    >{context.translate("Завершить")}</button>
                </div>
            </div>
        )
    }
}