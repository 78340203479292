import React, {Component} from "react";
import {connect} from "react-redux";
import CommunicationsList from "../../components/communications/CommunicationsList";
import CommunicationsSearchForm from "../../components/communications/CommunicationsSearchForm";
import {Link} from "react-router-dom";
import {setRating, communicationsList, communicationsDecline} from "../../actions/communications";
import {getBodyData} from "../../tools/form-helper";
import PropTypes from "prop-types";

class CommunicationsContainer extends Component{

    constructor(props){
        super(props);
        this.state = {
            searchForm: {},
            menuOpened: false,
            sorts: [
                {value: '', label: 'По умолчанию'},
                {value: 'desc', label: 'Сначала новые'},
                {value: 'asc', label: 'Сначала старые'},
            ],
            activeSort: '',
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentDidMount(){
        document.addEventListener('click', this.windowClick);
    }

    componentWillUnmount(){
        document.removeEventListener('click', this.windowClick);
    }

    windowClick = () => {
        this.setState({menuOpened: false});
    };

    toPage = (num) => {
        this.props.getCommunicationsList(Object.keys(this.state.searchForm).length ? getBodyData(this.state.searchForm)+'&page='+num+'&date_sort='+this.state.activeSort : 'page='+num+'&date_sort='+this.state.activeSort);
    }

    search = (fields) => {
        this.setState({searchForm: fields});
        this.props.getCommunicationsList(getBodyData({...fields, date_sort: {value: this.state.activeSort}}));
    }
    toggleMenu = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        var prevState = this.state.menuOpened;

        var event = document.createEvent("Event");
        event.initEvent("click", false, true);
        document.dispatchEvent(event);

        this.setState({menuOpened: !prevState});
    };
    changeSorting = (value) => {
        this.setState({activeSort: value, menuOpened: false});
        this.props.getCommunicationsList(getBodyData({...this.state.searchForm, date_sort: {value}}));
    }

    render(){
        var {
            communications,
            catalog,
            ratingList,
            setRating,
            decline
        } = this.props;

        return <div className="page page_projects page_messages">
            <div className="page__main page__main_projects">
                <div className="page__content-container">
                    <div className="page__title-section">
                        <h1 className="page__title">{this.context.translate('Обращения')}</h1>
                        <div className="page__title-ui">
                            <Link className="page__title-button u-btn" to="/communications/create">{this.context.translate('Создать Обращение')}</Link>
                        </div>
                    </div>
                    {communications.list.length || communications.isSearch ? <div className="page__messages-filter">
                        <div className="messages-filter messages-filter_adapt-default">
                            <CommunicationsSearchForm catalog={catalog}
                                                      search={this.search}
                            />
                        </div>
                    </div> : null}
                    <div className="messages-sorting-container">
                        <div className={"profile-menu messages-sorting"+(this.state.menuOpened ? " active" : "")}>
                            <div>
                                <span>Сортировка: </span>
                                <span
                                  className="profile-menu__head"
                                  onClick={this.toggleMenu}
                                >
                                <span>{this.state.sorts.find(el => el.value === this.state.activeSort).label}</span>
                            </span>
                            </div>
                            <div className="profile-menu__dropdown">
                                {
                                    this.state.sorts.map((sort, index) => (
                                      <span
                                        key={index}
                                        className={`profile-menu__item ${sort.value === this.state.activeSort ? 'active-sort' : ''}`}
                                        onClick={() => {this.changeSorting(sort.value)}}
                                      >
                                      <span className="profile-menu__item-title">{this.context.translate(sort.label)}</span>
                                  </span>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <CommunicationsList list={communications.list}
                                        isSearch={communications.isSearch}
                                        ratingList={ratingList}
                                        setRating={setRating}
                                        decline={decline}
                                        toPage={this.toPage}
                                        translate={this.context.translate}
                    />
                </div>
            </div>
        </div>
    }
}

export default connect(
    state => ({
        communications: state.communications,
        catalog: {
            communicationStatuses: state.catalog.communicationStatuses || [],
            communicationTypes: state.catalog.communicationTypes || []
        },
        ratingList: state.catalog.communicationRatings || []
    }),
    (dispatch) => {
        return {
            setRating: (id, rating, comment) => dispatch(setRating(id, rating, comment)),
            decline: (id, comment) => dispatch(communicationsDecline(id, comment)),
            getCommunicationsList: (params) => dispatch(communicationsList(params))
        }
    }
)(CommunicationsContainer);