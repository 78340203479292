import React,{Component} from "react";

export default class TogglePanel extends Component{

    constructor(props){
        super(props);
        this.state = {
            slide: props.slide || ''
        }
    }

    toggle = (e) => {
        e.preventDefault();

        this.setState((prevState) => {
            return {
                slide: prevState.slide == 'down' ? 'up' : 'down'
            }
        });
        
    };

    componentWillReceiveProps(nextProps){
        if (nextProps.slide != this.props.slide) {
            this.setState({slide: nextProps.slide});
        }
    }

    render(){

        let {
            label,
            children,
            className = "",
            labelClassName = "",
            contentClassName = ""
        } = this.props;

        return <div className={"toggle-panel "+className}>
            <div className={"toggle-panel__label "+labelClassName} onClick={this.toggle}>
                <span className={"search__head-link"+(this.state.slide == 'down' ? " active" : "")}>{label}</span>
            </div>
            <div className={"toggle-panel__content "+contentClassName+" "+this.state.slide}>
                {children}
            </div>
        </div>
    }

}