import {
    call,
    put,
    take,
    select,
    fork,
} from 'redux-saga/effects';

import {
    fetchClientApi,
    fetchFileApi,
    clientApiUrl,
    checkErrors,
} from '../tools/api-helper';
import { 
    convertTimeISOinDMY,
    readAsText,
    base64ToArrayBuffer,
} from '../tools/tools';

import {checkToken} from "./auth";
import { getUserCertificates, Certificate, createDetachedSignature, createHash } from 'crypto-pro';

const stateDiadocReducer = state => state.diadocReducer;
const textErrorForRequest = 'Возникла неизвестная ошибка, пожалуйста, попробуйте позже';


// для модального окна диадока
export function toggleModalDiadocAction(data = '') {
    return {
        type: 'MODAL_DIADOC',
        data
    }
}


// для сообщения об ошибке
export function errorTextAction(data = '') {
    return {
        type: 'ERROR_TEXT',
        data
    }
}


// выбранный boxId
export function selectedBoxIdAction(data = '') {
    return {
        type: 'SELECTED_BOX_ID',
        data
    }
}


// блокирование кнопки ПОДПИСАТЬ
export function disableBtnSignAction(data) {
    return {
        type: 'DISABLE_BTN_SIGN',
        data
    }
}


// сохраняем текст причины отклонения документа
export function reasonForRejectionAction(data) {
    return {
        type: 'REASON_FOR_REJECTION',
        data
    }
}


// Получаем список сертификатов пользователя через "криптопро"
export function getUserCertificatesAction(typeForText = '') {
    return {
        type: 'GET_USER_CERTIFICATION',
        typeForText
    }
}
export function* getUserCertificatesSaga(action) {
    let certificates: Certificate[];

    try {
        certificates = yield call(() => getUserCertificates());
       
        // создаем массив объектов с нужными полями для селекта
        const converArrCertificate = [];

        for (let i = 0; i < certificates.length; i++) {
            const el = certificates[i];
            
            console.log('СПИСОК СЕРТИФИКАТОВ', el);

            // получаем название компании
            const getNameCompany = yield call(() => el.getOwnerInfo());
            console.log('Список поля сертификата', getNameCompany)
            const name = getNameCompany && getNameCompany.find((i) => i.title === 'Компания');
            const owner = getNameCompany && getNameCompany.find((i) => i.title === 'Владелец');
            const firstName = getNameCompany && getNameCompany.find((i) => i.title === 'Имя Отчество');
            const secondName = getNameCompany && getNameCompany.find((i) => i.title === 'Фамилия');

            // проверяем валидность сертификата
            const isValidCertificate = yield call(() => el.isValid());
            console.log('валидность сертификата', isValidCertificate);

            const fromDate = convertTimeISOinDMY(el.validFrom, true);
            const toDate = convertTimeISOinDMY(el.validTo, true);

            // если сертификат валидный, добавляем в массив
            if (isValidCertificate) {
                converArrCertificate.push({
                    value: el.thumbprint,
                    label: `${name ? (name.description + (secondName ? (' ' + secondName.description) : '') + (firstName ? (' ' + firstName.description) : '')) : (owner && owner.description)} <br> действителен с ${ fromDate } по ${ toDate }`,
                });
            }
            
        }

        yield put(checkErrors(action.type, { data: converArrCertificate }, 200));
    } catch(error) {
        let customTextError = '';

        if (action.typeForText === 'applications') {
            customTextError = 'Вы прервали подписание заявки. Начните еще раз, если вы сделали это по ошибке.';
        } else if (action.typeForText === 'document_signing') {
            customTextError = 'Вы прервали подписание документа. Начните еще раз, если вы сделали это по ошибке.';
        } else if (action.typeForText === 'document_rejection') {
            customTextError = 'Вы прервали отклонение документа. Начните еще раз, если вы сделали это по ошибке.';
        } else {
            customTextError = error.message;
        }

        yield put(sendErrorCriptoProInBdAction(customTextError));

        yield put(errorTextAction(customTextError));
        yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
    }
}


// подписываем сертификат пользователя через "криптопро"
export function signCertificateAction(thumbprint, contentPdf) {
    return {
        type: 'SIGN_SERTIFICATE',
        thumbprint,
        contentPdf,
    }
}
export function* signCertificateSaga(action) {

    try {
        // декодируем base64 в массив байтов
        const base64Buffer = base64ToArrayBuffer(action.contentPdf);
        // получаем хэш декодированного base64
        const contentPdfHash = yield call(() => createHash(base64Buffer));

        try {
            const signature = yield call(() => createDetachedSignature(action.thumbprint, contentPdfHash));

            yield put(checkErrors(action.type, { data: { contentPdfHash, signature } }, 200));
    
        } catch(error) {
            yield put(sendErrorCriptoProInBdAction(error.message));

            yield put(errorTextAction(error.message));
            yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
        }

    } catch(error) {
        yield put(sendErrorCriptoProInBdAction(error.message));

        yield put(errorTextAction(error.message));
        yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
    }
}


// получаем содердимое PDF в виде base64
export function getContentPdfInBase64Action() {
    return {
        type: 'CONTENT_PDF_IN_BASE64',
    }
}
export function* getContentPdfInBase64Saga(action) {
    try {
        const getId = localStorage.getItem('idApplication');

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        const { status, data, error } = yield call( () => {
            return fetchFileApi(clientApiUrl+"/applications/"+getId+"?action=pdf", localStorage.getItem('authToken'))
        });


        if (error) {
            yield put(errorTextAction(textErrorForRequest));
            yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
        }
        else {
            if (status === 200) {
                // конвертируем формат blob в base64 и получаем строку на выходу
                const resultBase64 = yield call(readAsText, data);
                // отрезаем у строки "data:application/pdf;base64,"
                const cuteResultBase64 = resultBase64.split(',').pop();
                yield put(checkErrors(action.type, { data: cuteResultBase64 }, status));
            }
            if (!!data.message){
                yield put(errorTextAction(data.message));
                yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
            } else {
                yield put(checkErrors(action.type, data, status));
            }
            
        }

    } catch(error) {
        yield put(errorTextAction(error.message));
        yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
    } 
}


// Проверка статуса авторизации
export function checkAuthStatusDiadocAction() {
    return {
        type: 'CHECK_AUTH_STATUS_DIADOC',
    }
}
export function* checkAuthStatusDiadocSaga(action) {
    try {
        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        // Проверка статуса авторизации
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/documents/get-open-id-authorization-status", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) {
            yield put(errorTextAction(textErrorForRequest));
            yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
        }
        else {
            if (!!data.message){
                yield put(errorTextAction(data.message));
                yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
            } else {
                yield put(checkErrors(action.type, data, status));
            }
            
        }

    } catch(error) {
        yield put(errorTextAction(error.message));
        yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
    }

}


// Возвращает настройки для OpenIdConnect
export function getOpenIdSettingsAction(section) {
    return {
        type: 'OPEN_ID_SETTINGS',
        section,
    }
}
export function* fetchGetOpenIdSettingsSaga(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi(`/documents/get-open-id-settings?section=${action.section}`, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) {
            yield put(errorTextAction(textErrorForRequest));
            yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
        }
        else {
            if (!!data.message){
                yield put(errorTextAction(data.message));
                yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
            } else {
                yield put(checkErrors(action.type, data, status));
            }
            
        }

    } catch(error) {
        yield put(errorTextAction(error.message));
        yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
    }
}


// Авторизует пользователя
export function sendOpenIdAction(data = '', section = '') {
    return {
        type: 'SEND_OPEN_ID',
        data,
        section,
    }
}
export function* fetchSendOpenIdSaga(action) {
    try {
        console.log('Начало авторизации пользователя в диадок')
        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');
        console.log('Токен авторизации в ДЛ получен')

        let body = new FormData();
        body.append('code', action.data);
        body.append('section', action.section);

        console.log('Отправка запроса на авторизацию в диадок /documents/send-open-id-connect-authorize')
        console.log('code: ' + action.data + ' | section: ' + action.section);
        const { status, data, error } = yield call( () => {
            return fetchClientApi(`/documents/send-open-id-connect-authorize`, {
                method: 'POST',
                body: body,
                token: localStorage.getItem('authToken')
            })
        });

        console.log('Ответ авторизации диадок')
        console.log('status: ' + status)
        console.log('data: ' + JSON.stringify(data))
        console.log('error: ' + error)

        if (error) {
            yield put(errorTextAction(textErrorForRequest));
            yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
        }
        else {
            if (!!data.message){
                yield put(errorTextAction(data.message));
                yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
            } else {
                console.log('Реагирование на ответ авторизации диадок')
                yield put(checkErrors(action.type, data, status));
            }
            
        }

    } catch(error) {
        yield put(errorTextAction(error.message));
        yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
    }
}


// Возвращает ящики пользователя
export function boxesListAction() {
    return {
        type: 'BOXES_LIST',
    }
}
export function* fetchBoxesListSaga(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi('/documents/get-boxes', {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) {
            yield put(errorTextAction(textErrorForRequest));
            yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
        }
        else {
            if (!!data.message){
                yield put(errorTextAction(data.message));
                yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
            } else {
                yield put(checkErrors(action.type, data, status));
            }
            
        }

    } catch(error) {
        yield put(errorTextAction(error.message));
        yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
    }
}


// Возвращает права пользователя внутри ящика
export function getBoxUserPermissionsAction(data) {
    return {
        type: 'GET_BOX_USER_PERMISSIONS',
        data,
    }
}
export function* fetchBoxUserPermissionsSaga(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi(`/documents/get-box-user-permissions?box_id=${action.data}`, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) {
            yield put(errorTextAction(textErrorForRequest));
            yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
        }
        else {
            if (!!data.message){
                yield put(errorTextAction(data.message));
                yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
            } else {
                yield put(checkErrors(action.type, data, status));
            }
            
        }

    } catch(error) {
        yield put(errorTextAction(error.message));
        yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
    }
}


// Отправляет подписанную заявку
export function sendSignedApplicationAction(data) {
    return {
        type: 'SEND_SIGNED_APPLICATION',
        data,
    }
}
export function* fetchSignedApplicationSaga(action) {
    try {
        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        let body = new FormData();
        body.append('selected_box_id', action.data.selected_box_id);
        body.append('base64_content', action.data.base64_content);
        body.append('base64_signature', action.data.base64_signature);
        body.append('correlation_id', action.data.correlation_id);



        const { status, data, error } = yield call( () => {
            return fetchClientApi('/documents/send-signed-application', {
                method: 'POST',
                body: body,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) {
            yield put(errorTextAction(textErrorForRequest));
            yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
        }
        else {
            if (!!data.message){
                yield put(errorTextAction(data.message));
                yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
            } else {
                yield put(checkErrors(action.type, data, status));
            }
            
        }

    } catch(error) {
        yield put(errorTextAction(error.message));
        yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
    }
}


// Возвращает права пользователя для документа
export function getDocumentsUserPermissionsAction(data) {
    return {
        type: 'GET_DOCUMENTS_USER_PERMISSIONS',
        data,
    }
}
export function* fetchDocumentsUserPermissionsSaga(action) {
    try {
        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        const { status, data, error } = yield call( () => {
            return fetchClientApi(`/documents/${action.data}/user-permissions`, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) {
            yield put(errorTextAction(textErrorForRequest));
            yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
        }
        else {
            if (!!data.message){
                yield put(errorTextAction(data.message));
                yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
            } else {
                yield put(checkErrors(action.type, data, status));
            }
            
        }

    } catch(error) {
        yield put(errorTextAction(error.message));
        yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
    }
}


// Генерирует файл отказа от подписи
export function sendSignatureDeclineSigningRequestAction(data) {
    return {
        type: 'SEND_SIGNATURE_DECLINE_SIGNING_REQUEST',
        data,
    }
}
export function* fetchSendSignatureDeclineSigningRequestSaga(action) {
    console.log('action', action);
    try {
        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        let body = new FormData();
        body.append('document_id', action.data.document_id);
        body.append('certificate_thumb_print', action.data.certificate_thumb_print);
        body.append('reason', action.data.reason);

        const { status, data, error } = yield call( () => {
            return fetchClientApi('/documents/send-signature-decline-signing-request', {
                method: 'POST',
                body: body,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) {
            yield put(errorTextAction(textErrorForRequest));
            yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
        }
        else {
            if (!!data.message){
                yield put(errorTextAction(data.message));
                yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
            } else {
                yield put(checkErrors(action.type, data, status));
            }
            
        }

    } catch(error) {
        yield put(errorTextAction(error.message));
        yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
    }
}


// Отклонить подписание документа
export function sendSignatureDeclineSigningAction(data) {
    return {
        type: 'SEND_SIGNATURE_DECLINE_SIGNING',
        data,
    }
}
export function* fetchSendSignatureDeclineSigningSaga(action) {
    try {
        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        let body = new FormData();
        body.append('document_id', action.data.document_id);
        body.append('base64_decline_content', action.data.base64_decline_content);
        body.append('decline_entity_filename', action.data.decline_entity_filename);
        body.append('base64_signature', action.data.base64_signature);

        const { status, data, error } = yield call( () => {
            return fetchClientApi('/documents/send-signature-decline-signing', {
                method: 'POST',
                body: body,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) {
            yield put(errorTextAction(textErrorForRequest));
            yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
        }
        else {
            if (!!data.message){
                yield put(errorTextAction(data.message));
                yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
            } else {
                yield put(checkErrors(action.type, data, status));
            }
            
        }

    } catch(error) {
        yield put(errorTextAction(error.message));
        yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
    }
}


// Возвращает содержимое документа
export function getSignatureDocumentContentAction(data) {
    return {
        type: 'GET_SIGNATURE_DOCUMENT_CONTENT',
        data,
    }
}
export function* fetchSignatureDocumentContentSaga(action) {
    try {
        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        const { status, data, error } = yield call( () => {
            return fetchClientApi(`/documents/get-signature-document-content?document_id=${action.data}`, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) {
            yield put(errorTextAction(textErrorForRequest));
            yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
        }
        else {
            if (!!data.message){
                yield put(errorTextAction(data.message));
                yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
            } else {
                yield put(checkErrors(action.type, data, status));
            }
            
        }

    } catch(error) {
        yield put(errorTextAction(error.message));
        yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
    }
}


// Подписывает существующий документ
export function sendSignatureSignDocumentAction(data) {
    return {
        type: 'SEND_SIGNATURE_SIGN_DOCUMENT',
        data,
    }
}
export function* fetchSendSignatureSignDocumentSaga(action) {
    try {
        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');
        
        let body = new FormData();
        body.append('document_id', action.data.document_id);
        body.append('base64_signature', action.data.base64_signature);

        const { status, data, error } = yield call( () => {
            return fetchClientApi('/documents/send-signature-sign-document', {
                method: 'POST',
                body: body,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) {
            yield put(errorTextAction(textErrorForRequest));
            yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
        }
        else {
            if (!!data.message){
                yield put(errorTextAction(data.message));
                yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
            } else {
                yield put(checkErrors(action.type, data, status));
            }
            
        }

    } catch(error) {
        yield put(errorTextAction(error.message));
        yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
    }
}


// Сохранить ошибку КриптоПро в БД  и лог
export function sendErrorCriptoProInBdAction(data) {
    return {
        type: 'ERROR_CRIPTO',
        data,
    }
}
export function* fetchSendErrorCriptoProInBdSaga(action) {
    try {
        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');
        
        let body = new FormData();
        body.append('message', action.data);

        const { status, data, error } = yield call( () => {
            return fetchClientApi('/documents/save-cryptopro-error', {
                method: 'POST',
                body: body,
                token: localStorage.getItem('authToken')
            })
        });

        console.log('data', data);

        if (error) {
            yield put(errorTextAction(textErrorForRequest));
            yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
        }
        else {
            if (!!data.message){
                yield put(errorTextAction(data.message));
                yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
            } else {
                yield put(checkErrors(action.type, data, status));
            }
            
        }

    } catch(error) {
        yield put(errorTextAction(error.message));
        yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
    }
}


///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
// НАЧАЛО ДЛЯ ЗАЯВКИ
///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////

// контент для успешного статуса - "applications"
function* contentForSuccessStatusAuthSaga() {
    // запускаем получение списка ящиков
    console.log('запускаем получение списка ящиков');
    yield put(boxesListAction());
    // дожидаемся когда результат списка ящиков будет получен
    console.log('дожидаемся когда результат списка ящиков будет получен');
    let actBoxesList = yield take('BOXES_LIST_SUCCESS');
    console.log('получение списка ящиков', actBoxesList.data);

    // если массив имеет более одного элемента
    if (actBoxesList.data.length > 1) {
        console.log('массив имеет более одного элемента');
        // открываем модальное окно "Выбор компании"
        yield put(toggleModalDiadocAction('diadoc_company_choice'));
        // после того как в модалке нажмем кнопку ПРОДОЛЖИТЬ сработает сага continuationAfterCompanySelectionApplicationDiadocAction

    } else if (actBoxesList.data.length === 1){
        console.log('массив имеет один элемент');
        // Возвращает права пользователя внутри единственного ящика
        const boxId = actBoxesList && actBoxesList.data && actBoxesList.data[0] && actBoxesList.data[0].boxId;
        yield put(getBoxUserPermissionsAction(boxId));
        console.log('Возвращает права пользователя внутри единственного ящика');

        // записываем в стор выбранного ящика
        yield put(selectedBoxIdAction(boxId));

        // дожидаемся когда получим права пользователя
        console.log('дожидаемся когда получим права пользователя');
        let actBoxUserPermissions = yield take('GET_BOX_USER_PERMISSIONS_SUCCESS');
        console.log('дожидаемся когда получим права пользователя', actBoxUserPermissions.data);

        // 'Есть возможность подписи у выбранного ящика?
        const isSign = actBoxUserPermissions.data.some((i) => (i === 'CanSignDocuments' || i === 'CanSignAndSendDocuments'));
        console.log('Есть возможность подписи у выбранного ящика?', isSign);

        if (isSign) {
            console.log('права есть');

            // Получаем список сертификатов пользователя через "криптопро"
            yield put(getUserCertificatesAction(''));
            // дожидаемся получения списка сертификатов
            let actGetSertificate = yield take('GET_USER_CERTIFICATION_SUCCESS');
            console.log('дожидаемся получения списка сертификатов', actGetSertificate.data.data);

            // список сертификатов получен?
            if (actGetSertificate.data.data && actGetSertificate.data.data.length > 0) {
                console.log('список сертификатов получен');
                // открываем модальное окно "Подписание заявки"
                yield put(toggleModalDiadocAction('diadoc_application_signing'));

                // после того как в модалке нажмем кнопку ПОДПИСАТЬ срабатывает сага startCertificateUserSignatureAction
                
            } else {
                console.log('список сертификатов НЕ получен');
                // открываем модальное окно "Что-то пошло не так..."
                yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
            }
        } else {
            // открываем модальное окно "Что-то пошло не так..."
            yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
        }
    } else {
        // открываем модальное окно "Что-то пошло не так..."
        yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
    }
}


// кейс когда пользователь АВТОРИЗОВАН в диадоке на странице "новая заявка" - "applications"
export function startApplicationDiadocAction(typeApplication = '', id = '') {
    return {
        type: 'START_APPLICATION_DIADOC',
        typeApplication,
        id,
    }
}
export function* startApplicationDiadocSaga(action) {
    localStorage.setItem('idApplication', action.id);

    const stateDiadoc = yield select(stateDiadocReducer);
    let actions = [];
    let finCnt = 0;
 
    try {
        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');

        // если используется НОВАЯ ЗАЯВКА, а не РЕДАКТИРОВАНИЕ
        console.log('2 Ожидание обработки получения заявок')
        if (action.typeApplication === 'newApplication') {
            // тайпы успешных запросов
            actions = [
                "APPLICATION_LIST_DRAFT_SUCCESS",
                "APPLICATION_LIST_IN-PROGRESS_SUCCESS",
                "APPLICATION_LIST_ARCHIVE_SUCCESS",
            ];
            // проходим циклом по тайпам и тем самым дожидаемся когда прелоадер на странице ЗАЯВКИ отработает (работа очень похожа на useEffect)
            while (actions.length > finCnt) {
                let act = yield take(actions);
                console.log('2Отработал экшн ' + act.type)
                let ind = actions.indexOf(act.type);
                actions.splice(ind,1);
            }
        }
        console.log('2Экшены успешных запросов заявок отработал.')

        // Проверка статуса авторизации
        console.log('2Проверка статуса автризации пользователя code: ' + action.data)
        yield put(checkAuthStatusDiadocAction());
        console.log('2 Ожидание проверки статуса автризации CHECK_AUTH_STATUS_DIADOC_SUCCESS')
        let isCheckAuthStatusDiadoc = yield take('CHECK_AUTH_STATUS_DIADOC_SUCCESS');
        console.log('Проверка статуса авторизации2', isCheckAuthStatusDiadoc.data);
  

        // если статус авторизации true
        if (isCheckAuthStatusDiadoc.data) {
            console.log('пользователь авторизован');

            // контент для успешного статуса
            yield contentForSuccessStatusAuthSaga();
        } 
        //  если статуса авторизации false
        else {
            console.log('пользователь НЕ авторизован');

            // запускаем получение url по которому нужно будет перейти
            yield put(getOpenIdSettingsAction('applications'));
            
            // открываем модальное окно для входа когда все заявки прогрузились
            if (actions.length === 0) {
                yield put(toggleModalDiadocAction('diadoc_login'));

                // после того как в модалке нажмем кнопку ВОЙТИ В ДИАДОК срабатывает сага continuationApplicationDiadocAction
            }
        }
    } catch(e) {
        alert(e);
    }

}

// кейс когда пользователь НЕАВТОРИЗОВАН в диадоке на странице "новая заявка" - "applications"
// ЗАПУСКАЕТСЯ когда в адресной строке есть квери параметр "code"
export function continuationApplicationDiadocAction(typeApplication, data) {
    return {
        type: 'CONTINUATION_APPLICATION_DIADOC',
        typeApplication,
        data,
    }
}
export function* continuationApplicationDiadocSaga(action) {
    let actions = [];
    let finCnt = 0;

    try {
        console.log('Начало метода авторизации пользователя в диадок')
        // console.log('Ожидание обработки получения заявок')
        // // если используется НОВАЯ ЗАЯВКА, а не РЕДАКТИРОВАНИЕ
        // if (action.typeApplication === 'newApplication') {
        //     // тайпы успешных запросов
        //     actions = [
        //         "APPLICATION_LIST_DRAFT_SUCCESS",
        //         "APPLICATION_LIST_IN-PROGRESS_SUCCESS",
        //         "APPLICATION_LIST_ARCHIVE_SUCCESS",
        //     ];
        //     // проходим циклом по тайпам и тем самым дожидаемся когда прелоадер на странице ЗАЯВКИ отработает (работа очень похожа на useEffect)
        //     while (actions.length > finCnt) {
        //         let act = yield take(actions);
        //         console.log('Отработал экшн ' + act.type)
        //         let ind = actions.indexOf(act.type);
        //         actions.splice(ind,1);
        //     }
        // }
        // console.log('Экшены успешных запросов заявок отработал.')

        // Авторизует пользователя
        console.log('Авторизует пользователя code: ' + action.data)
        yield put(sendOpenIdAction(action.data, 'applications'));
        // дожидаемся авторизации пользователя
        console.log('Начинаем дожидаться успешной автризации пользователя')
        let actSendOpenId = yield take('SEND_OPEN_ID_SUCCESS');
        console.log('Ответ от авторизации диадок: ' + actSendOpenId)
        console.log('дожидаемся авторизации пользователя', actSendOpenId.data);

        const isSuccess = actSendOpenId && actSendOpenId.data && actSendOpenId.data.isSuccessful;

        if (!!isSuccess) {
            console.log('пользователь авторизован');

            // контент для успешного статуса
            yield contentForSuccessStatusAuthSaga();
        } else {
            console.log('пользователь НЕ авторизован');
            // открываем модальное окно "Что-то пошло не так..."
            yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
        }


    } catch(e) {
        alert(e);
    }

}




// продолжение цикла после выбора компании "новая заявка" - "applications"
export function continuationAfterCompanySelectionApplicationDiadocAction(data) {
    return {
        type: 'CONTINUATION_AFTER_COMPANY_SELECTION_APPLICATION_DIADOC',
        data,
    }
}
export function* continuationAfterCompanySelectionApplicationDiadocSaga(action) {
    try {
        // Возвращает права выранного ящика
        yield put(getBoxUserPermissionsAction(action.data));

        // дожидаемся когда получим права пользователя
        let actBoxUserPermissions = yield take('GET_BOX_USER_PERMISSIONS_SUCCESS');
        console.log('дожидаемся когда получим права пользователя', actBoxUserPermissions.data);

        // 'Есть возможность подписи у выбрагнного ящика?
        const isSign = actBoxUserPermissions.data.some((i) => (i === 'CanSignDocuments' || i === 'CanSignAndSendDocuments'));
        // const isSign = actBoxUserPermissions.data.some((i) => (i === 'ммм'));
        console.log('Есть возможность подписи у выбрагнного ящика?', isSign);

        if (isSign) {
            console.log('права есть');

            // Получаем список сертификатов пользователя через "криптопро" (работа с заявкой)
            yield put(getUserCertificatesAction('applications'));
            // дожидаемся получения списка сертификатов
            let actGetSertificate = yield take('GET_USER_CERTIFICATION_SUCCESS');
            console.log('дожидаемся получения списка сертификатов', actGetSertificate.data.data);

            // список сертификатов получен?
            if (actGetSertificate.data.data && actGetSertificate.data.data.length > 0) {
            // if (0) {
                console.log('список сертификатов получен');
                // открываем модальное окно "Подписание заявки"
                yield put(toggleModalDiadocAction('diadoc_application_signing_second'));

                // после того как в модалке нажмем кнопку ПОДПИСАТЬ срабатывает сага startCertificateUserSignatureAction
                
            } else {
                console.log('список сертификатов НЕ получен');
                // открываем модальное окно "Что-то пошло не так..."
                yield put(toggleModalDiadocAction('diadoc_something_went_wrong_second'));
            }
        } else {
            console.log('прав нет');
            // открываем модальное окно "Что-то пошло не так..."
            yield put(errorTextAction('К сожалению, вам не доступна подпись документов по ЭДО. Обратитесь к администратору Диадок в вашей компании.'))
            yield put(toggleModalDiadocAction('diadoc_something_went_wrong_second'));
        }
    } catch(e) {
        alert(e);
    }

}


// запуск процесса подписи сертификата пользователя
export function startCertificateUserSignatureAction(data) {
    return {
        type: 'START_CERTIFICATE_USER_SIGNATURE',
        data
    }
}
export function* startCertificateUserSignatureSaga(action) {
    const stateDiadoc = yield select(stateDiadocReducer);
    const getId = localStorage.getItem('idApplication');

    try {
        // блокируем кнопку ПОДПИСАТЬ
        yield put(disableBtnSignAction(true));

        // получаем содердимое PDF в виде base64
        yield put(getContentPdfInBase64Action());
        // пожидаемся получения содердимого PDF в виде base64
        let actContentPDF = yield take('CONTENT_PDF_IN_BASE64_SUCCESS');
        console.log('пожидаемся получения содердимого PDF в виде base64', actContentPDF.data.data);

        // подписываем сертификат пользователя через "криптопро"
        yield put(signCertificateAction(action.data, actContentPDF.data.data));
        // дожидаемся подписи сертификата пользователя через "криптопро"
        let actSignature = yield take('SIGN_SERTIFICATE_SUCCESS');
        console.log('дожидаемся подписи сертификата пользователя через', actSignature.data.data);

        
        // Отправляет подписанную заявку
        yield put(sendSignedApplicationAction({
            selected_box_id : (stateDiadoc && stateDiadoc.selectedBoxId) || '',
            base64_content : actContentPDF.data.data || '',
            base64_signature : actSignature.data.data.signature || '',
            correlation_id : getId,
        }));
        // дожидаемся ответа от отправленной заявки
        let actSendSigned = yield take('SEND_SIGNED_APPLICATION_SUCCESS');
        console.log('дожидаемся ответа от отправленной заявки', actSendSigned.data);


        // если заявка была подписана, то
        if (actSendSigned.data) {
            // открываем модальное окно "Документ подписан"
            yield put(toggleModalDiadocAction('diadoc_docoment_signed'));
        } else {
            // открываем модальное окно "Что-то пошло не так..."
            yield put(toggleModalDiadocAction('diadoc_something_went_wrong_third'));
        }

    } catch(e) {
        alert(e);
    }
}

///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
// КОНЕЦ ДЛЯ ЗАЯВКИ
///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////





///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
// НАЧАЛО ДЛЯ ДОКУМЕНТАОБОРОТА
///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////

// контент для успешного статуса - "documents"
function* contentForSuccessStatusAuthDocumentsSaga(action) {
    const selectedDoc = JSON.parse(localStorage.getItem('selectedDoc'));
    const selectedTypeBtn = localStorage.getItem('selectedTypeBtn');

    // Возвращает права пользователя для документа
    yield put(getDocumentsUserPermissionsAction(+selectedDoc.id));
    // дожидаемся получения прав для документов
    let actDocumentsUserPermissions = yield take('GET_DOCUMENTS_USER_PERMISSIONS_SUCCESS');
    console.log('дожидаемся получения прав для документов', actDocumentsUserPermissions.data);
    
    // Есть возможность подписи?
    const isSign = actDocumentsUserPermissions.data.some((i) => (i === 'CanSignDocuments' || i === 'CanSignAndSendDocuments'));
    // Есть возможность отклонения?
    const isReject = actDocumentsUserPermissions.data.some((i) => (i === 'CanDeclineSigning'));


    let actGetSertificate = null;

    // если есть права на подпись или отклонения, то
    if (isSign || isReject) {
        // Получаем список сертификатов пользователя через "криптопро" (кнопка подписать/отклонить)
        yield put(getUserCertificatesAction(selectedTypeBtn === 'sign' ? 'document_signing' : 'document_rejection'));
        // дожидаемся получения списка сертификатов
        actGetSertificate = yield take('GET_USER_CERTIFICATION_SUCCESS');
        console.log('дожидаемся получения списка сертификатов', actGetSertificate.data.data);
    } else {
        console.log('прав нет');
        // открываем модальное окно "Что-то пошло не так..."
        yield put(errorTextAction('К сожалению, вам не доступна подпись документов по ЭДО. Обратитесь к администратору Диадок в вашей компании.'))
        yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
    }


    // Есть право подписать?
    if (isSign && (selectedTypeBtn === 'sign')) {
        console.log('есть права на подпись');

        // открываем модальное окно "Подписание документа"
        yield put(toggleModalDiadocAction('diadoc_docoment_signing'));
    }

    // Есть право отклонить документ?
    if (isReject && (selectedTypeBtn === 'reject')) {
        console.log('есть права на отклонение');
        // открываем модальное окно "отклонение документа"
        yield put(toggleModalDiadocAction('diadoc_docoment_rejection'));
    }
}

// кейс когда пользователь АВТОРИЗОВАН в диадоке на странице "Документооборот" - "documents"
export function startDocumentsDiadocAction(typeBtn = '', item = {}) {
    return {
        type: 'START_DOCUMENTS_DIADOC',
        typeBtn,
        item,
    }
}
export function* startDocumentsDiadocSaga(action) {
    // сохраняем выбранный домент
    localStorage.setItem('selectedDoc', JSON.stringify(action.item));
    // сохраняем тип выбранной кнопки подписать/отклонить
    localStorage.setItem('selectedTypeBtn', action.typeBtn);

    try {
        // Проверка статуса авторизации
        yield put(checkAuthStatusDiadocAction());
        let isCheckAuthStatusDiadoc = yield take('CHECK_AUTH_STATUS_DIADOC_SUCCESS');
        console.log('Проверка статуса авторизации', isCheckAuthStatusDiadoc.data);
  

        // если статус авторизации true
        if (isCheckAuthStatusDiadoc.data) {
            console.log('пользователь авторизован');

            // контент для успешного статуса
            yield contentForSuccessStatusAuthDocumentsSaga(action);
        } 
        //  если статуса авторизации false
        else {
            console.log('пользователь НЕ авторизован');

            // запускаем получение url по которому нужно будет перейти
            yield put(getOpenIdSettingsAction('documents'));
            
            // открываем модальное окно для входа когда все заявки прогрузились
            yield put(toggleModalDiadocAction('diadoc_login_second'));

            // после того как в модалке нажмем кнопку ВОЙТИ В ДИАДОК срабатывает сага continuationDocumentsDiadocAction
        }
    } catch(e) {
        alert(e);
    }
}

// кейс когда пользователь НЕАВТОРИЗОВАН в диадоке на странице "Документооборот" - "documents"
// ЗАПУСКАЕТСЯ когда в адресной строке есть квери параметр "code"
export function continuationDocumentsDiadocAction(data) {
    return {
        type: 'CONTINUATION_DOCUMENTS_DIADOC',
        data,
    }
}
export function* continuationDocumentsDiadocSaga(action) {
    try {
        // Авторизует пользователя
        yield put(sendOpenIdAction(action.data, 'documents'));
        // дожидаемся авторизации пользователя
        let actSendOpenId = yield take('SEND_OPEN_ID_SUCCESS');
        console.log('дожидаемся авторизации пользователя', actSendOpenId.data);

        const isSuccess = actSendOpenId && actSendOpenId.data && actSendOpenId.data.isSuccessful;

        if (!!isSuccess) {
            console.log('пользователь авторизован');

            // контент для успешного статуса
            yield contentForSuccessStatusAuthDocumentsSaga();
        } else {
            console.log('пользователь НЕ авторизован');
            // открываем модальное окно "Что-то пошло не так..."
            yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
        }
    } catch(e) {
        alert(e);
    }

}



// продолжение процесса отклонения документа
export function continuationDocumentRejectionProcessAction(data = {}) {
    return {
        type: 'CONTINUATION_DOCUMENT_REJECTION_PROCESS',
        data,
    }
}
export function* continuationDocumentRejectionProcessSaga(action) {
    try {
        // Отклонить подписание документа
        yield put(sendSignatureDeclineSigningAction(action.data));
        // дожидаемся отклонения документа
        let actSendSignDecline = yield take('SEND_SIGNATURE_DECLINE_SIGNING_SUCCESS');
        console.log('дожидаемся отклонения документа', actSendSignDecline.data);

        if (actSendSignDecline.data) {
            // открываем модальное окно "Документ отклонен"
            yield put(toggleModalDiadocAction('diadoc_docoment_reject'));
        } else {
            yield put(errorTextAction('Документ был Вами отклонен'));
            yield put(toggleModalDiadocAction('diadoc_something_went_wrong'));
        }
    } catch(e) {
        alert(e);
    }
}


// запуск процесса подписи сертификата пользователя для ДОКУМЕНТОВ
export function startCertificateUserSignatureDocumentsAction(data) {
    return {
        type: 'START_CERTIFICATE_USER_SIGNATURE_DOCUMENTS',
        data
    }
}
export function* startCertificateUserSignatureDocumentsSaga(action) {
    const stateDiadocFromStore = yield select(stateDiadocReducer);
    const selectedDoc = JSON.parse(localStorage.getItem('selectedDoc'));
    const selectedTypeBtn = localStorage.getItem('selectedTypeBtn');

    try {

        // блокируем кнопку ПОДПИСАТЬ
        yield put(disableBtnSignAction(true));

        // если нажали кнопку "Подписать"
        if (selectedTypeBtn ==='sign') {
            console.log('кейс с подписанием');

            // Возвращает содержимое документа
            yield put(getSignatureDocumentContentAction(+selectedDoc.id));
            // пожидаемся получения содержимого документа
            let actGetSignDocumentContentSuccess = yield take('GET_SIGNATURE_DOCUMENT_CONTENT_SUCCESS');
            console.log('пожидаемся получения содержимого документа', actGetSignDocumentContentSuccess.data);


            // подписываем сертификат пользователя через "криптопро"
            yield put(signCertificateAction(action.data, actGetSignDocumentContentSuccess.data.base64Content));
            // дожидаемся подписи сертификата пользователя через "криптопро"
            let actSignature = yield take('SIGN_SERTIFICATE_SUCCESS');
            console.log('дожидаемся подписи сертификата пользователя через', actSignature.data.data);

            // Подписывает существующий документ
            yield put(sendSignatureSignDocumentAction({
                document_id: actGetSignDocumentContentSuccess.data.documentId,
                base64_signature : actSignature.data.data.signature,
            }));
            // пожидаемся получения подписанного существующего документа
            let actSendDocumentSuccess = yield take('SEND_SIGNATURE_SIGN_DOCUMENT_SUCCESS');
            console.log('пожидаемся получения подписанного существующего документа', actSendDocumentSuccess.data);

            // открываем модальное окно "Документ подписан"
            yield put(toggleModalDiadocAction('diadoc_docoment_signed'));
        } 
        
        // если нажали кнопку "Отклонить"
        if (selectedTypeBtn ==='reject') {
            console.log('кейс с отклонением');

            // Генерирует файл отказа от подписи
            yield put(sendSignatureDeclineSigningRequestAction({
                document_id: +selectedDoc.id,
                certificate_thumb_print: action.data,
                reason: stateDiadocFromStore.textReasonForRejection,
            }));
            // пожидаемся получения сгенерированного файла отказа от подписи
            let actSendSign = yield take('SEND_SIGNATURE_DECLINE_SIGNING_REQUEST_SUCCESS');
            console.log('пожидаемся получения сгенерированного файла отказа от подписи', actSendSign.data);
            
            // подписываем сертификат пользователя через "криптопро"
            yield put(signCertificateAction(action.data, actSendSign.data.base64DeclineContent));
            // дожидаемся подписи сертификата пользователя через "криптопро"
            let actSignature = yield take('SIGN_SERTIFICATE_SUCCESS');
            console.log('дожидаемся подписи сертификата пользователя через', actSignature.data.data);

            // открываем модальное окно "Отклонение документа" с указанием причины
            yield put(toggleModalDiadocAction('diadoc_docoment_rejection_text'));
            
            // после того как в модалке нажмем кнопку ПРОДОЛЖИТЬ срабатывает сага continuationDocumentRejectionProcessAction
        }
    } catch(e) {
        alert(e);
    }
}






///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
// КОНЕЦ ДЛЯ ДОКУМЕНТАОБОРОТА
///////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////

