import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    detailApplication,
    downloadCommercialOffer,
    recallApplication,
    requestApplication,
    resumeApplication,
    downloadFile,
    commercialOfferAcceptAction,
    pdfApplicationFormAction,
    requestKP,
} from "../../actions/applications";
import {addFile, deleteFile, clearBuffer, updateFile, cancelFile} from "../../actions/file-buffer";
import DetailOrder from "../../components/orders/DetailOrder";
import Preloader from "../../components/common/Preloader";
import { startApplicationDiadocAction } from '../../actions/diadocAction';

class DetailOrderContainer extends Component {
    
    constructor(props){
        super(props);
        if (props.application) {
            if (!props.application.Status || (Array.isArray(props.application.Status) && props.application.Status[0].IsCurrent && props.application.Status[0].StatusId == 2)) {
                props.history.replace("/applications/update/"+props.application.ApplicationId);
            }
        }
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.application != this.props.application && nextProps.application) {
            if (!nextProps.application.Status || (Array.isArray(nextProps.application.Status) && nextProps.application.Status[0].IsCurrent && props.application.Status[0].StatusId == 2)) {
                nextProps.history.replace("/applications/update/"+nextProps.application.ApplicationId);
            }
        }
        if (Array.isArray(nextProps.application.Status) && nextProps.applicationsInProgress.isOk && nextProps.applicationsInProgress != this.props.applicationsInProgress) {
            var shouldGoBack = true;
            for (var i = 0; i < nextProps.applicationsInProgress.length; i++) {
                if (nextProps.applicationsInProgress[i].ApplicationId == nextProps.application.ApplicationId) {
                    shouldGoBack = false;
                    break;
                }
            }
            if (shouldGoBack) nextProps.history.push("/applications" + "#progress");
        }
        if (!Array.isArray(nextProps.application.Status) && nextProps.applicationsArchive.isOk && nextProps.applicationsArchive != this.props.applicationsArchive) {
            var shouldGoBack = true;
            for (var i = 0; i < nextProps.applicationsArchive.length; i++) {
                if (nextProps.applicationsArchive[i].ApplicationId == nextProps.application.ApplicationId) {
                    shouldGoBack = false;
                    break;
                }
            }
            if (shouldGoBack) nextProps.history.push("/applications" + "#archive");
        }
    }

    render() {

        let {
            application,
            fileBuffer,
            fileParams,
            isReady,

            downloadCommercialOffer,
            recallApplication,
            requestApplication,
            resumeApplication,
            addApplicationDocument,
            removeApplicationDocument,
            updateApplicationDocument,
            cancelApplicationDocument,
            clearBuffer,

            location,


            downloadApplicationFile,
            documentTypes,
            commercialOfferAcceptAction,
            pdfApplicationFormAction,
            startApplicationDiadocAction,
            requestKP,
        } = this.props;

        return (<div className="page page_projects page_application">
            <div className="page__main page__main_projects">
                {isReady ? <DetailOrder {...application}
                                        fileBuffer={fileBuffer}
                                        fileParams={fileParams}
                                        download={downloadCommercialOffer}
                                        recallApplication={recallApplication}
                                        requestApplication={requestApplication}
                                        requestKP={requestKP}
                                        resumeApplication={resumeApplication}
                                        addApplicationDocument={addApplicationDocument}
                                        removeApplicationDocument={removeApplicationDocument}
                                        updateApplicationDocument={updateApplicationDocument}
                                        cancelApplicationDocument={cancelApplicationDocument}
                                        clearBuffer={clearBuffer}
                                        location={location}
                                        downloadApplicationFile={downloadApplicationFile}
                                        documentTypes={documentTypes}
                                        startApplicationDiadocAction={startApplicationDiadocAction}
                                        commercialOfferAcceptAction={commercialOfferAcceptAction}
                                        pdfApplicationFormAction={pdfApplicationFormAction}
                />  : <Preloader />}
            </div>
        </div>)
    }
}

export default connect(
    state => ({
        application: state.singleApplication,
        applicationsInProgress: state.applications.inProgress,
        applicationsArchive: state.applications.archive,
        documentTypes: state.applications.documentTypes,
        fileBuffer: state.fileBuffer,
        fileParams: state.catalog && state.catalog.fileParams,
        isReady: state.singleApplication.isOk
    }),
    (dispatch) => {
        return {
            detailApplication: (id) => dispatch(detailApplication(id)),
            downloadCommercialOffer: (id, offerId) => dispatch(downloadCommercialOffer(id, offerId)),
            recallApplication: (id, reason) => dispatch(recallApplication(id, reason)),
            requestApplication: (id, description) => dispatch(requestApplication(id, description)),
            resumeApplication: (id, description) => dispatch(resumeApplication(id, description)),
            addApplicationDocument: (id, file) => dispatch(addFile(id, file)),
            removeApplicationDocument: (id, fileId) => dispatch(deleteFile(id, fileId)),
            updateApplicationDocument: (id, fileId, fileName) => dispatch(updateFile(id, fileId, fileName)),
            cancelApplicationDocument: (id) => dispatch(cancelFile(id)),
            clearBuffer: () => dispatch(clearBuffer()),
            downloadApplicationFile: (id, name) => dispatch(downloadFile(id, name)),
            startApplicationDiadocAction: (typeApplication, id) => dispatch(startApplicationDiadocAction(typeApplication, id)),
            commercialOfferAcceptAction: (idKP, idApplication) => dispatch(commercialOfferAcceptAction(idKP, idApplication)),
            pdfApplicationFormAction: (id) => dispatch(pdfApplicationFormAction(id)),
            requestKP: (id, description, offerId) => dispatch(requestKP(id, description, offerId)),
        }
    }
)(DetailOrderContainer);