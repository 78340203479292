import {
    call,
    put,
    select,
    take
} from 'redux-saga/effects';

import {
    fetchApi,
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';
import {isSupportUser} from "../tools/tools";

import {authPageSettings, splashImages, splashMessage} from "./catalog";
import {checkToken} from "./auth";

const reg = state => state.auth;

export function regCheckPhone(phone_number, email, token) {
    return {
        type: 'REG_CHECK_PHONE_REQUEST',
        phone_number,
        email,
        token
    }
}
export function regCheckSms(phone_number, code) {
    return {
        type: 'REG_CHECK_SMS_REQUEST',
        phone_number,
        code
    }
}
export function regShowData(data) {
    return {
        type: 'REG_SHOW_INFO_DATA',
        data
    }
}
export function regSendData(data) {
    return {
        type: 'REG_SEND_DATA_REQUEST',
        data
    }
}
export function regChangeData() {
    return {
        type: 'REG_CHANGE_DATA'
    }
}

export function regSendTempPassword(phone_number, password, token) {
    return {
        type: 'REG_SEND_TEMP_PASSWORD_REQUEST',
        phone_number,
        password,
        token
    }
}
export function regConfirmSendCode(phone_number, code) {
    return {
        type: 'REG_CHECK_CONFIRM_SMS_REQUEST',
        phone_number,
        code,
    }
}
export function regSendPassword(phone_number, password, confirm_password) {
    return {
        type: 'REG_SEND_PASSWORD_REQUEST',
        phone_number,
        password,
        confirm_password
    }
}
export function regFindCompanyProvider(type, query) {
    return {
        type: 'REG_FIND_COMPANY_PROVIDER',
        entity: type,
        query
    }
}
export function regGetBusinessRegions(type, query) {
    return {
        type: 'REG_GET_BUSINESS_REGIONS',
        entity: type,
        query
    }
}

export function* fetchRegCheckPhone(action) {
    try {
        var body = new FormData();
        if (action.phone_number) body.append('phone_number', '+'+action.phone_number.replace(/\D/gi, ''));
        body.append('email', action.email);
        body.append('re_captcha', action.token);

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/registration?action=check", {
                method: 'POST',
                body
            })
        });
        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status === 200) {
                // console.log("Your code: "+data.code);
                //add phone and password properties to data for code request repeating
                yield put(checkErrors(action.type, {...data, phone_number: action.phone_number, email: action.email}, status));
            } else if(status === 307) {
                yield put({type: 'REG_CONTINUE_DATA', data: {...data, phone_number: action.phone_number, email: action.email}});
            } else if(status === 303) {
                yield put({type: 'REG_SAME_USER'});
            } else if(status === 422) {
                yield put(checkErrors(action.type, data, status));
            } else {
                yield put(checkErrors(action.type, data, status));
            }
        }
    } catch(e) {
        alert(e);
    }
}

export function* fetchRegCheckSms(action) {
    try {
        var body = new FormData();
        if (action.phone_number) body.append('phone_number', '+'+action.phone_number.replace(/\D/gi, ''));
        body.append('code', action.code);

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/registration?action=code-validate", {
                method: 'POST',
                body
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status === 200) {
                yield put(checkErrors(action.type, {}, status));
            }
            if (status === 423) {
                localStorage.setItem('blockedPhone', action.phone);
            }
            yield put(checkErrors(action.type, data, status));
        }
    } catch(e) {
        alert(e);
    }
}
export function* showData(action) {
    return;
}
export function* fetchRegSendData(action) {
    try {
        var body = new FormData();
        body.append('first_name', action.data.first_name);
        body.append('last_name', action.data.last_name);
        body.append('second_name', action.data.second_name);
        body.append('email', action.data.email);
        body.append('is_changed', action.data.is_changed);
        body.append('inn', action.data.inn);
        // body.append('kpp', action.data.kpp);
        body.append('short_name', action.data.short_name);
        body.append('region_id', action.data.region_id);
        body.append('phone_number', '+'+action.data.phone_number.replace(/\D/gi, ''));

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/registration?action=profile", {
                method: 'POST',
                body
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status === 200) {
                yield put(checkErrors(action.type, {}, status));
            }
            if (status === 423) {
                localStorage.setItem('blockedPhone', action.phone);
            }
            yield put(checkErrors(action.type, data, status));
        }
    } catch(e) {
        alert(e);
    }
}
export function* fetchRegTempPassword(action) {
    try {
        var body = new FormData();
        body.append('phone_number', '+'+action.phone_number.replace(/\D/gi, ''));
        body.append('password', action.password);
        body.append('token', action.token);

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/registration?action=temporary-password", {
                method: 'POST',
                body
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status === 200) {
                yield put(checkErrors(action.type, {...data}, status));
                yield put(splashImages());
            }
            if (status === 423) {
                localStorage.setItem('blockedPhone', action.phone);
            }
            yield put(checkErrors(action.type, data, status));
        }
    } catch(e) {
        alert(e);
    }
}
export function* fetchRegConfirmCheckSms(action) {
    try {
        var body = new FormData();
        if (action.phone_number) body.append('phone_number', '+'+action.phone_number.replace(/\D/gi, ''));
        body.append('code', action.code);

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/registration?action=temporary-password-code-validate", {
                method: 'POST',
                body
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status === 200) {
                yield put(checkErrors(action.type, {}, status));
            }
            if (status === 423) {
                localStorage.setItem('blockedPhone', action.phone);
            }
            yield put(checkErrors(action.type, data, status));
        }
    } catch(e) {
        alert(e);
    }
}
export function* fetchRegPassword(action) {
    try {
        var body = new FormData();
        body.append('phone_number', '+'+action.phone_number.replace(/\D/gi, ''));
        body.append('password', action.password);
        body.append('confirm_password', action.confirm_password);

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/registration?action=permanent-password", {
                method: 'POST',
                body
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            if (status === 200) {
                localStorage.setItem('authToken', data.access_token);
                localStorage.setItem('authTokenExpire', data.access_token_expire);
                localStorage.setItem('refreshToken', data.refresh_token);
                yield put(splashMessage());
                yield put({type: "CODE_VALIDATE_SUCCESS", data});
            }
            if (status === 423) {
                localStorage.setItem('blockedPhone', action.phone);
            }
            yield put(checkErrors(action.type, data, status));
        }
    } catch(e) {
        alert(e);
    }
}

export function* fetchRegFindCompanyProvider(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/guest/" + action.entity + "?inn="+action.query, {})
        });

        if (error) {
            yield put(checkErrors(action.type, {message: error}, 403));
        }
        else {
            if(status === 400 || status === 422) yield put(checkErrors(action.type, data, status))
            else {
                const options = Array.isArray(data) ? data.map(el => {return {...el, id: el.Inn, label: el.ShortName || el.FullName}}) : [data].map(el => {return {...el, id: el.Inn, label: el.ShortName || el.FullName}})
                yield put(checkErrors(action.type, options, status));
            }
        }

    } catch(e) {
        alert(e);
    } finally {
        yield put({ type: 'REG_FIND_COMPANY_PROVIDER_ERROR_FETCH', data: false});
    }
}
export function* fetchRegGetBusinessRegions(action) {
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/guest/autocomplete/" + action.entity + "?query="+action.query, {})
        });

        const options = data.map(el => {return {...el, id: el.value, label: el.label}})
        if (error) {
            yield put(checkErrors(action.type, {message: error}, 403));
        }
        else {
            yield put(checkErrors(action.type, options, status));
        }

    } catch(e) {
        alert(e);
    }
}