import React, { Component } from "react";
import Form from "../common/form/Form";
import FormField from "../common/form/FormField";
import PropTypes from "prop-types";
import { noSpace } from "../../tools/mask";

export default class DiadocDocumentRejection extends Component {

    constructor(props){
        super(props);

        this.state = {
            fields: {
                comment: {
                    type: 'textarea',
                    label: "Укажите причину отклонения документа",
                    placeholder: "Введите текст",
                    value: "",
                    error: '',
                    // success: !!order.Comment
                },
            },
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    close = () => {
        this.props.close();
    }

    onChangeField = (attribute, value, error) => {
        var prevState = {...this.state.fields};
        if (prevState.hasOwnProperty(attribute)) {
            prevState[attribute].value = value;
            prevState[attribute].error = error;
            this.setState({fields: prevState})
        }
    };

    onSubmit = () => {
        let { comment } = this.state.fields;
        
        // созхраняем в стор введенный текст
        this.props.reasonForRejectionAction(comment.value);
        // открываем модальное окно 'Подписание документа'
        this.props.openDiadocDocumentSigning();
    }

    renderFields = (fields) => {
        var result = [];

        for (var attribute in fields) {
            if (fields.hasOwnProperty(attribute)) {

                result.push(<div className="profile-form__item" key={attribute}>
                    <FormField fieldConfig={{
                        ...fields[attribute],
                        label: this.context.translate(fields[attribute].label)
                    }}
                        attribute={attribute}
                        onChange={this.onChangeField}
                    />
                </div>)
            }
        }

        return result;
    };


    render() {

        const { comment } = this.state.fields;

        return (
            <div className="diadoc_document_rejection">
                <Form validate={true} onSubmit={this.onSubmit}>
                    <h3 className="diadoc_document_rejection__title">{this.context.translate('Отклонение документа')}</h3>

                    <div className="diadoc_document_rejection__wrap_elements_form">
                        {this.renderFields({...this.state.fields})}
                    </div>
                    
                    <div className="diadoc_document_rejection__wrap_btns">
                        <button
                            // блокируем кнопку если введены только пробелы или поле пустое
                            className={`btn ${(!!noSpace(comment.value) ? "" : " disabled")}`}
                        >
                            {this.context.translate('Продолжить')}
                        </button>
                    </div>
                </Form>
            </div>
        )
    }
}
