import React, {Component} from 'react';
import Form from "../common/form/Form";

export default class RegStepPhone extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            context,
            checkPhone,
            fieldsRegStep1,
            renderFields,
            regState,
            isValid,
            regIsSameUserError,
            showRememberBtn,
            openAgreeModal,
            privatePolicyLink
        } = this.props

        return (
            <Form validate={true} className="auth__form-slide active" onSubmit={checkPhone}>
                {renderFields(fieldsRegStep1, 'fieldsRegStep1')}
                {
                    (regState.regIsSameUserError || regIsSameUserError) &&
                    <p className="text-error">Пользователь с такими данными уже зарегистрирован.<br/>Для получения доступа обратитесь в офис ДельтаЛизинг</p>
                }
                <div className="form__buttons">
                    <p className="reg-agree-text">
                        Нажимая на «Продолжить», я даю <a onClick={openAgreeModal}>согласие</a> на обработку персональных данных.
                        Подробно с условиями обработки персональных данных можно ознакомиться<div className="br-hide-1" /> в <a href={privatePolicyLink} target="_blank">Политике конфиденциальности</a>.
                    </p>
                    <button
                        type="submit"
                        className={`btn ${isValid('fieldsRegStep1') && !regState.sendRequest && fieldsRegStep1.phone_number.value && fieldsRegStep1.email.value && !regIsSameUserError ? "" : " disabled"}`}
                    >{context.translate("Продолжить")}</button>
                    {
                        (showRememberBtn) &&
                        <a href="/recovery">{context.translate("Забыли пароль?")}</a>
                    }
                </div>
            </Form>
        )
    }
}