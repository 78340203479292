/**
 * @param count
 * @returns {function(*): *}
 */

export function nameMask(value) {
    if (!value) return "";
    if((/^[a-zа-я-]+$/i).test(value)) return value
}
export function numberMask(count = 0) {
    return (value) => {
        if (!value) return "";
        value = value.replace(/\D/gim, '');
        if (count && value.length > count) value.splice(0, count);
        return value;
    }
}

export function priceMask(value) {
    if (value.length < 3) return value;
    value = value.toString().replace(/\D/gim, '');
    var valArr = value.split('');
    valArr.reverse();
    var resArr = [];
    for (var i = 0; i < valArr.length; i++){
        if (i != 0 && i % 3 == 0) resArr.push(" ");
        resArr.push(valArr[i]);
    }
    return resArr.reverse().join('');
}

// маска для снилса
export function snilsMask(value) {
    // ограничиваем количество вводимых символов
    value = value.substr(0, 14);
    // разрешаем ввод только цифр
    value = value.toString().replace(/\D/g, '') || '';
    var valArr = value.split('');
    var resArr = [];
    // после каждой третьей цифры вставляем тире
    for (var i = 0; i < valArr.length; i++){
        if (i != 0 && i % 3 == 0) resArr.push("-");
        resArr.push(valArr[i]);
    }

    return resArr.join('');
}

// маска для кода подразделения
export function divisionCodeMask(value) {
    // ограничиваем количество вводимых символов
    value = value.substr(0, 7);
    // разрешаем ввод только цифр
    value = value.toString().replace(/\D/g, '') || '';
    var valArr = value.split('');
    var resArr = [];
    // после каждой третьей цифры вставляем тире
    for (var i = 0; i < valArr.length; i++){
        if (i == 3) resArr.push("-");
        resArr.push(valArr[i]);
    }

    return resArr.join('');
}

// маска для серии и номера паспорта
export function passportMask(value) {
    // ограничиваем количество вводимых символов
    value = value.substr(0, 12);
    // разрешаем ввод только цифр
    value = value.toString().replace(/\D/g, '') || '';
    var valArr = value.split('');
    var resArr = [];
    // после каждой третьей цифры вставляем тире
    for (var i = 0; i < valArr.length; i++){
        if (i == 2 || i == 4) resArr.push(" ");
        resArr.push(valArr[i]);
    }

    return resArr.join('');
}

export function onlyNumbers(value) {
    let result = value.toString().replace(/\D/gim, '');
    return result;
}

export function floatMask(value) {
    if (!value) return "";

    value = value.toString();
    var start = '';
    var tail = '';

    if (value.lastIndexOf('.') !== -1) {
        tail = value.substr(value.lastIndexOf('.') + 1);
        start = value.substr(0, value.lastIndexOf('.'));
    }
    else if (value.lastIndexOf(',') !== -1) {
        tail = value.substr(value.lastIndexOf(','));
        start = value.substr(0, value.lastIndexOf(',') + 1);
    }
    else {
        start = value;
    }

    return priceMask(start)+","+(tail ? (tail.length >= 2 ? tail : tail+'0') : '00');
}

export function serverDateFormatMask(date) {
    if (!date) return '';
    return date.split('.').reverse().join('-');
}

export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export function phoneMask(value, type = false) {
    if (!!value) {
        var next = value.replace(/[^+0-9]/gim, '');
        var arr = next.split('');

        if (arr[0] && arr[0] == 8) {
            arr.splice(0, 1, '+');
            arr.splice(1, 0, 7);
        }
        if (arr[0] && arr[0] != '+') {
            arr.splice(0, 0, '+');
        }
        if (arr[1] && arr[1] != 7) {
            arr.splice(1, 0, 7);
        }
        arr = arr.slice(0,12);
        var str = '';
        for (var i = 0; i < arr.length; i++) {
            switch (i) {
                case 3:
                case 4:
                case 6:
                case 7:
                case 9:
                case 11:
                    if (arr[i] !== '+') str += arr[i];
                    break;
                case 2:
                    if (arr[i] !== '+') str += ' ('+arr[i];
                    break;
                case 5:
                    if (arr[i] !== '+') str += ') '+arr[i];
                    break;
                case 8:
                case 10:
                    if (arr[i] !== '+') {
                        if (type === 'def') {
                            str += ' '+arr[i]
                        } else {
                            str += '-'+arr[i]
                        }
                    };
                    break;
                default:
                    str += arr[i];
            }
        }
        return str;
    } else return '';
}

export function dateFormatMask(next = '') {
    next = next.replace(/\D/gi, '');

    if (next.length > 8) next = next.substr(0, 8);
    var arr = next.split('');
    //day
    if (arr.length && arr[0] > 3) arr.splice(0, 0, 0);
    if (arr.length > 1 && arr[0] == 3 && arr[1] > 1) arr.splice(0, 0, 0);
    //month
    if (arr.length > 2 && arr[2] > 1) arr.splice(2, 0, 0);
    if (arr.length > 3 && arr[2] == 1 && arr[3] > 2) arr.splice(2, 0, 0);
    //year
    var now = new Date(Date.now());
    var currYear = (now.getFullYear() + '').split('');
    if (arr.length > 4 && arr[4] > currYear[0]) arr.splice(4, 1);
    if (arr.length > 5 && arr[4] == currYear[0] && arr[5] > currYear[1]) arr.splice(5, 1);
    if (arr.length > 6 && arr[4] == currYear[0] && arr[5] == currYear[1] && arr[6] > currYear[2]) arr.splice(6, 1);
    if (arr.length > 7 && arr[4] == currYear[0] && arr[5] == currYear[1] && arr[6] == currYear[2] && arr[7] > currYear[3]) arr.splice(7, 1);
    var res = '';

    for (var i = 0; i < Math.min(arr.length, 10); i++) {
        if (!isNumeric(arr[i])) continue;
        switch (i) {
            case 2:
            case 4:
                res += '.' + arr[i];
                break;
            default:
                res += arr[i];
        }
    }
    return res;
}

// запрет на ввод пробелов
export function noSpace(value) {
    return value.replace(/\s/g, "");
}