
export const defaultLanguage = window.navigator ? (window.navigator.language ||
window.navigator.systemLanguage ||
window.navigator.userLanguage) : null;

const initState = {
    currentLanguage: localStorage.getItem("currentLangAlias") ?
        {alias: localStorage.getItem("currentLangAlias"), label: localStorage.getItem("currentLangValue")} :
        {alias: defaultLanguage, label: defaultLanguage},
    dictionary: {},
    languages: []
};

export function multilingual(state = initState, action) {
    switch (action.type) {
        case 'LANGUAGES_SUCCESS':
            var currentLanguage = {};
            var langAlias = state.currentLanguage.alias || defaultLanguage;
            for (var i = 0; i < action.data.length; i++) {
                if (action.data[i].alias == langAlias) {
                    currentLanguage = action.data[i];
                    break;
                }
                if (action.data[i].is_default) {
                    currentLanguage = action.data[i];
                }
            }
            return {...state, languages: action.data, currentLanguage};
        case 'SET_CURRENT_LANGUAGE':
            return {...state, currentLanguage: action.lang};
        case 'DICTIONARY_SUCCESS':
            var dictionary = {};
            action.data.map((item) => {
                dictionary[item.alias] = item.translation;
            });
            return {...state, dictionary};
        case "LOGOUT_SUCCESS":
            return {...initState};
    }
    return state;
}
