import React, { Component } from "react";
import { Link } from "react-router-dom";
import OrdersForm from "./OrdersForm";
import Modal from "../common/Modal";
import CompanyForm from "./CompanyForm";
import SupplierInformationForm from "./SupplierInformationForm";
import PropTypes from "prop-types";
import CompaniesAgreement from "../companies/CompaniesAgreement";
import {getQueryParam, hasQueryParam} from '../../tools/tools';

export default class AddOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpened: false,
            modalOpenedAgreement: false,
            typeModalCompany: '',
            modalOpenedKp: false,
            modalAddedDocOpened: false,
            lesseeError: null
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    // для модального окна "Информация о компании" по клику на кнопку "Добавить по ИНН"
    toggleCompanyModal = (e, type) => {
        e.preventDefault();
        this.setState((prevState) => {
            if (prevState.modalOpened) {
                this.props.clearCompanyForm();
            }
            return {
                modalOpened: !prevState.modalOpened,
                typeModalCompany: type,
            }
        })

        this.setLesseeError(null)
    };

    setLesseeError = (val) => {
        this.setState({lesseeError: val})
    }

    // для модального окна "Соглашение" по клику на кнопку ПОДРОБНЕЕ
    toggleAgreementModal = (e) => {
        e.preventDefault();
        this.setState((prevState) => {
            return {
                modalOpenedAgreement: !prevState.modalOpenedAgreement,
            }
        })
    };

    // открывает модалку по клику на кнопку "Спецификация"
    openModalKp = () => {
        this.setState(() => {
            return {
                modalOpenedKp: true
            }
        })
    };

    // закрывает модалку по клику на кнопку "Спецификация"
    closeModalKp = () => {
        this.setState(() => {
            return {
                modalOpenedKp: false
            }
        })
    };

    // открывает содалку "Документы добавлены"
    openModalAddedDoc = () => {
        this.setState(() => {
            return {
                modalAddedDocOpened: true
            }
        })
    };

    // закрывает содалку "Документы добавлены"
    closeModalAddedDoc = () => {
        this.setState(() => {
            return {
                modalAddedDocOpened: false
            }
        })
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.company.added != this.props.company.added && nextProps.company.added) {
            this.setState({ modalOpened: false })
        }
    }


    openModal = (modal) => {
        return (e) => {
            if (e) e.preventDefault();
            this.setState({ modal })
        }
    }

    closeModal = (e) => {
        if (e) e.preventDefault();
        this.setState({ modal: null });
    }

    render() {
        let {
            fields,
            status,
            message,

            company,
            findCompany,
            findCompanyProvider,
            addCompany,
            selectedDisabledBtnAction,
            deleteProviderCompanyForm,
            deleteCompanyForm,
            application,
            startApplicationDiadocAction,
            selectedTypeVendorInformation,
            selectedTypeVendorInformationAction,
            selectedTypeLeasingCompany,
            selectedTypeLeasingCompanyAction,
            applicationFormInfo,
        } = this.props;

        return (
          <div className="page">
              <div className="page__main page__main_default">
                  <div className="page__application-edit-container">
                      <div className="page__application-edit-title-container">
                          <h1 className="page__title">{!fields || fields.IsDraft ? this.context.translate('Новая заявка') : this.context.translate('Редактирование заявки')}</h1>
                          {
                              (hasQueryParam('specialOffer') || (fields && fields.SpecialOfferName)) &&
                                <div className="page__application-promo">Акция: {getQueryParam('specialOffer') || (fields && fields.SpecialOfferName)}</div>
                          }
                      </div>
                      {fields && !fields.IsDraft ?
                        <h2 className="page__sub-title page__sub-title_mini">
                            {fields.EquipmentName || this.context.translate('Заявка') + ' № ' + fields.ApplicationId}
                        </h2> : null}
                      <div className="page__application-edit">
                          <div className="application-edit application-edit_adapt_default">
                              <OrdersForm
                                {...this.props}
                                addCompany={this.toggleCompanyModal}
                                toggleAgreementModal={this.toggleAgreementModal}
                                modalOpenedKp={this.state.modalOpenedKp}
                                openModalKp={this.openModalKp}
                                closeModalKp={this.closeModalKp}
                                typeModalCompany={this.state.typeModalCompany}
                                openModalAddedDoc={this.openModalAddedDoc}
                                closeModalAddedDoc={this.closeModalAddedDoc}
                                modalAddedDocOpened={this.state.modalAddedDocOpened}
                                deleteProviderCompanyForm={deleteProviderCompanyForm}
                                deleteCompanyForm={deleteCompanyForm}
                                applicationFormInfo={applicationFormInfo}
                                selectedTypeVendorInformation={selectedTypeVendorInformation}
                                selectedTypeVendorInformationAction={selectedTypeVendorInformationAction}
                                selectedTypeLeasingCompany={selectedTypeLeasingCompany}
                                selectedTypeLeasingCompanyAction={selectedTypeLeasingCompanyAction}
                                addCompanyModal={addCompany}
                                lesseeError={this.state.lesseeError}
                                setLesseeError={this.setLesseeError}
                                startApplicationDiadocAction={startApplicationDiadocAction}
                                application={application}
                              />
                          </div>


                          {this.state.modalOpened
                            && (
                              <Modal modalSize="l" classNameContent="modal modal_visible" onClickBackground={this.toggleCompanyModal}>
                                  {this.state.typeModalCompany === 'supplierInformation'
                                    && (
                                      <SupplierInformationForm {...company}
                                                               key="company-form"
                                                               findCompanyProvider={findCompanyProvider}
                                                               addCompany={addCompany}
                                                               selectedDisabledBtnAction={selectedDisabledBtnAction}
                                                               fieldsMainForm={fields}
                                                               selectedTypeVendorInformationAction={selectedTypeVendorInformationAction}
                                      />
                                    )}

                                  {this.state.typeModalCompany === 'lesseeCompany'
                                    && (
                                      <CompanyForm {...company}
                                                   key="company-form"
                                                   findCompany={findCompany}
                                                   addCompany={addCompany}
                                                   selectedDisabledBtnAction={selectedDisabledBtnAction}
                                                   catalog={this.props.catalog}
                                                   fieldsMainForm={fields}
                                                   selectedTypeLeasingCompanyAction={selectedTypeLeasingCompanyAction}
                                      />
                                    )}
                              </Modal>
                            )}

                          {this.state.modalOpenedAgreement
                            && (
                              <Modal modalSize="xl" classNameContent="modal modal_visible" onClickBackground={this.toggleAgreementModal}>
                                  <CompaniesAgreement
                                    fields={this.props.fields}
                                    catalog={this.props.catalog}
                                  />
                              </Modal>
                            )}

                          {status == 'error'
                            && (
                              <Modal modalSize="s" classNameContent="modal modal_visible modal_xs">
                                  <h2 className="form__title">{message}</h2>
                                  <Link to={"/applications" + "#drafts"}>{this.context.translate('Перейти к заявкам')}</Link>
                              </Modal>
                            )}
                      </div>
                  </div>
              </div>
          </div>
          )
    }
}