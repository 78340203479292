import React, {Component} from "react";
import pdf from "../../../img/icons/files/pdf.svg";
import xls from "../../../img/icons/files/xls.svg";
import other from "../../../img/icons/applications_print.svg";
import DocumentLoader from "../common/DocumentLoader";
import FileIcon from "../common/FileIcon";
import Modal from "../common/Modal";
import PropTypes from "prop-types";

export default class OrdersDocs extends Component{
    constructor(props){
        super(props);

        this.state = {
            modalOpened: null
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    openModal = (name, doc = null) => {
        return () => {
            this.setState({modalOpened: name, doc})
        }
    }

    closeModal = () => {
        if (this.state.modalOpened == 'add') {
            this.props.clearBuffer();
        }

        this.setState({modalOpened: null, id: null});
    }

    renderModalContent = () => {
        switch (this.state.modalOpened) {
            case 'add':
                var {
                    fileBuffer,
                    fileParams,
                    addDocument,
                    removeDocument,
                    updateDocument,
                    cancelDocument,
                    documentTypes,
                    typeName,
                    onlyOneTypeName = false,
                } = this.props;

                return <DocumentLoader files={fileBuffer}
                                       {...fileParams}
                                       addDocument={addDocument}
                                       removeDocument={removeDocument}
                                       updateDocument={updateDocument}
                                       cancelDocument={cancelDocument}
                                       close={this.closeModal}
                                       documentTypes={documentTypes}
                                       typeName={typeName}
                                       onlyOneTypeName={onlyOneTypeName}
                />;
            case 'remove':
                return <Modal modalSize="s" classNameContent="modal-wrapper modal modal__xs text-center modal_visible" onClickBackground={this.closeModal}>
                    <h2 className="form__title">{this.context.translate('Вы уверены, что хотите удалить документ')} "{this.state.doc.DocumentTypeName}"?</h2>
                    <div className="form__buttons">
                        <button className="btn" onClick={this.onRemove(this.state.doc.ID)}>{this.context.translate('Удалить')}</button>
                        <button className="btn btn_clear_blue" onClick={this.closeModal}>{this.context.translate('Отмена')}</button>
                    </div>
                </Modal>;
            default:
                return null;
        }
    }

    onRemove = (id) => {
        return () => {
            this.props.removeDocument(id);
            this.closeModal();
        }
    }

    onDownload = (doc) => {
        return (e) => {
            e.preventDefault();
            this.props.download(doc.FileId, (doc.Comment ? doc.Comment : doc.DocumentTypeName).trim() + '.' + doc.FileExtension)
        }
    }

    render(){

        var {docs, withoutBtn} = this.props;

        return <div className="form__files">
            <h2 className="form__title form__title_dop_mrb">{this.context.translate('Документы')}<sup>(<i className="js-files-table-num">{docs ? docs.length : 0}</i>)</sup></h2>
            
            {(docs && docs.length > 0) ? <table className="files-table">
                <thead>
                    <tr>
                        <th>{this.context.translate('Дата загрузки')}</th>
                        <th>{this.context.translate('Тип документа')}</th>
                        <th>{this.context.translate('Описание')}</th>
                        <th>{this.context.translate('Файл')}</th>
                        <th/>
                    </tr>
                </thead>
                <tbody>
                {docs.map((doc, key) => <tr key={doc.ID}>
                        <td data-th={this.context.translate("Дата загрузки")+" "}><span>{(new Date(doc.UploadStamp.replace(' ', 'T')+'Z')).toLocaleDateString('ru')}</span>
                        </td>
                        <td className="td_desctop">
                            <span>{doc.DocumentTypeName}</span>
                        </td>
                        <td>
                            <span>{doc.Comment}</span>
                        </td>
                        <td className="files-table__filename td_mob_flex">
                            <a className="td_mob_link" href="#" onClick={this.onDownload(doc)}>
                                <FileIcon ext={doc.FileExtension}/>
                            </a>
                            <span className="td_mob">{doc.DocumentTypeName}</span>
                        </td>
                        <td>
                            <div className="files-table__remove" onClick={this.openModal('remove', doc)}><span>{this.context.translate('Удалить')}</span></div>
                        </td>
                    </tr>)}
                </tbody>
            </table> : null}

            {
                !withoutBtn &&
                <div className="form__wrap_btn_add_doc">
                    <button type="button" className="btn btn_clear_blue btn_add form__files-button btn_width" onClick={this.openModal('add')}>
                        {this.context.translate('Добавить документ')}
                    </button>
                </div>
            }
            {this.renderModalContent()}
        </div>
    }
}