import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import {activation, backToRequest, codeRequest, codeValidate, recovery} from "../../actions/auth";
import Form from "../../components/common/form/Form";
import {getQueryParam, getWord} from "../../tools/tools";
import {noSpace, numberMask, onlyNumbers, phoneMask} from "../../tools/mask";
import FormField from "../../components/common/form/FormField";
import "../../../styles/reg.css";
import {authPageSettings} from "../../actions/catalog";
import {
    regConfirmSendCode,
    regSendPassword,
    regSendTempPassword
} from "../../actions/reg";

class ConfirmationContainer extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            time: 0,
            fieldsConfirmStep1: {
                phone_number: {
                    type: 'phone_number',
                    label: "Номер телефона",
                    name: "regPhone",
                    value: getQueryParam("phone_number") || "",
                    required: true,
                    error: '',
                    mask: phoneMask,
                    ref: (input) => {
                        this.phoneInput = input;
                    }
                },
                password: {
                    type: 'password',
                    label: "Временный пароль",
                    name: "tempPassword",
                    ispass: "true",
                    value: getQueryParam("password") || "",
                    required: true,
                    error: '',
                    mask: noSpace,
                    ref: (input) => {
                        this.tempPasswordInput = input;
                    }
                }
            },
            fieldsConfirmStep2: {
                code: {
                    type: 'text',
                    label: "Введите код подтверждения, отправленный Вам в SMS:",
                    name: "smsCode",
                    value: "",
                    required: true,
                    error: '',
                    mask: numberMask(4),
                    min: 4,
                    ref: (input) => {
                        this.smsCodeInput = input;
                    }
                },
            },
            fieldsConfirmStep3: {
                password: {
                    type: 'password',
                    label: "Постоянный пароль",
                    name: "password",
                    ispass: "true",
                    value: "",
                    required: true,
                    error: '',
                    mask: noSpace,
                    ref: (input) => {
                        this.passwordInput = input;
                    }
                },
                confirm_password: {
                    type: 'password',
                    label: "Повторить постоянный пароль",
                    name: "repeatPassword",
                    ispass: "true",
                    value: "",
                    required: true,
                    error: '',
                    mask: noSpace,
                    ref: (input) => {
                        this.repeatPasswordInput = input;
                    }
                },
            },
            autoFilled: []
        };

    }
    static contextTypes = {
        screenSize: PropTypes.string,
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentDidMount() {
        if(this.phoneInput) {
            this.phoneInput.addEventListener("transitionend", () => {
                var autoFilled = [...this.state.autoFilled];
                autoFilled.push('phone_number');
                this.setState({autoFilled});
            }, false);
        }
        if(this.props.regState.confirmStep === 1) {
            this.setState({time: 120}, () => {
                this.timer = setInterval(this.setTime, 1000);
            });
        }
    }
    componentWillMount() {
        if (!this.props.pageSettings) {
            this.props.authPageSettings();
        }
    }
    componentWillReceiveProps(nextProps) {
        if(this.props.regState.confirmTempPassError != nextProps.regState.confirmTempPassError) {
            let state = {...this.state};
            for (let attr in state.fieldsConfirmStep1) {
                state.fieldsConfirmStep1[attr].error = nextProps.regState.confirmTempPassError[attr] || ''
            }
            this.setState(state);
        }
        if(this.props.regState.passError != nextProps.regState.passError) {
            let state = {...this.state};
            for (let attr in state.fieldsConfirmStep3) {
                state.fieldsConfirmStep3[attr].error = nextProps.regState.passError[attr] || ''
            }
            this.setState(state);
        }
        if (this.props.splashImages != nextProps.splashImages) {
            if (nextProps.splashImages) {
                nextProps.splashImages.map((image) => {
                    var img = new Image();
                    img.src = image;
                })
            }
        }
        if((this.props.regState.confirmStep != nextProps.regState.confirmStep) && nextProps.regState.confirmStep === 1) {
            clearInterval(this.timer);
            this.setState({time: 120}, () => {
                this.timer = setInterval(this.setTime, 1000);
            });
        }
        if (this.props.triesMore != nextProps.triesMore) {
            clearInterval(this.timer);
            this.setState({time: 120}, () => {
                this.timer = setInterval(this.setTime, 1000);
            });
        }
        if (this.props.regState.sms_code != nextProps.regState.sms_code) {
            this.setState({
                fieldsConfirmStep2: {
                    code: {
                        ...this.state.fieldsConfirmStep2.code,
                        value: nextProps.regState.sms_code
                    }
                }
            });
        }
    }
    funcState = (prevState) => {
        if (this.state.time < 1) {
            clearInterval(this.timer);
            return {time: 0};
        }
        var timer = --prevState.time;
        return {time: timer};
    };

    setTime = () => {
        this.setState(this.funcState);
    };
    onRepeatCodeRequest = (e) => {
        e.preventDefault();
        clearInterval(this.timer);

        this.setState({time: 120}, () => {
            this.timer = setInterval(this.setTime, 1000);
            this.sendTempPassword();
        });
    }


    onChangeField = (name) => {
        return (attribute, value, error, selectionStart) => {
            var prevState = {...this.state[name]};
            if (prevState.hasOwnProperty(attribute)) {
                prevState[attribute].value = value;
                prevState[attribute].error = error;
                this.setState({
                    ...this.state,
                    [name]: prevState,
                    autoFilled: []
                }, () => {
                    if (attribute == 'phone_number' && selectionStart) {
                        this.phoneInput.selectionStart = this.phoneInput.selectionEnd;
                    }
                })
            }
        }
    };

    changePassType = (passType, name, attribute) => {
        this.setState({
            [name]: {
                ...this.state[name],
                [attribute]: {
                    ...this.state[name][attribute],
                    type: passType
                }
            }
        })
    }
    renderFields = (fields, name) => {
        let result = [];

        for (let attribute in fields) {
            if (fields.hasOwnProperty(attribute)) {
                result.push(<div className="form__row" key={name + attribute}>
                    <FormField
                        fieldConfig={{...fields[attribute], label: this.context.translate(fields[attribute].label)}}
                        attribute={attribute}
                        onChange={this.onChangeField(name)}
                        changePassType={(e) => this.changePassType(e, name, attribute)}
                    />
                </div>)
            }
        }

        return result;
    };

    isValid = (name) => {
        if (this.state.autoFilled.length == 2) return true;
        var fields = {...this.state[name]};
        for (var attr in fields) {
            if (fields[attr].error || fields[attr].required && !fields[attr].value) return false;
        }
        return true;
    }

    sendTempPassword = () => {
        this.props.onRegSendTempPassword(
            this.state.fieldsConfirmStep1.phone_number.value,
            this.state.fieldsConfirmStep1.password.value,
            getQueryParam("token")
        )
    }

    checkSmsCode = () => {
        this.props.onRegConfirmSendCode(this.state.fieldsConfirmStep1.phone_number.value, this.state.fieldsConfirmStep2.code.value)
    }

    setPassword = () => {
        this.props.onRegSendPassword(
            this.state.fieldsConfirmStep1.phone_number.value,
            this.state.fieldsConfirmStep3.password.value,
            this.state.fieldsConfirmStep3.confirm_password.value
        )
    }

    render() {
        let {
            fieldsConfirmStep1,
            fieldsConfirmStep2,
            fieldsConfirmStep3,
            time
        } = this.state;

        let {
            regState
        } = this.props

        return (
            <div className="reg__container">
                <div className="auth__form">
                    {
                        regState.confirmStep === 0 &&
                        <Form validate={true} className="auth__form-slide active" onSubmit={this.sendTempPassword}>
                            {this.renderFields(fieldsConfirmStep1, 'fieldsConfirmStep1')}
                            <div className="form__buttons">
                                <button
                                    type="submit"
                                    className={`btn btn_orange ${this.isValid('fieldsConfirmStep1') && !regState.sendRequest ? "" : " disabled"}`}
                                >{this.context.translate("Войти")}</button>
                            </div>
                        </Form>
                    }
                    {
                        regState.confirmStep === 1 &&
                        <Form validate={true} className="auth__form-slide active">
                            {this.renderFields(fieldsConfirmStep2, 'fieldsConfirmStep2')}
                            <div className="form__message">
                                {regState.tries_more && <div className="text-error">Неверно введен код подтверждения. Повторите попытку</div>}
                                {
                                    regState.tries_more && regState.tries_more < 10 ? (
                                        <div>
                                            {time ? <p>{this.context.translate("У вас осталось {m}, после чего аккаунт будет заблокирован. Вы сможете запросить код повторно через {n} сек.", {
                                                n: "0" + Math.floor(time / 60) + ":" + (time % 60 < 10 ? ("0" + (time % 60)) : (time % 60)),
                                                m: regState.tries_more + " " + getWord(regState.tries_more, "попытка", "попытки", "попыток")
                                            })}</p> : null}
                                        </div>
                                    ) : (
                                        <div>
                                            {time ? <p>{this.context.translate("Вы сможете запросить код повторно через {n} сек.", {
                                                n: "0" + Math.floor(time / 60) + ":" + (time % 60 < 10 ? ("0" + (time % 60)) : (time % 60))
                                            })}</p> : null}
                                        </div>
                                    )
                                }
                                <a className={`reg__linkBtn ${time == 0 ? "" : "disabled"}`}
                                   onClick={this.onRepeatCodeRequest}>{this.context.translate("Запросить повторно")}</a>
                            </div>
                            <div className="form__buttons">
                                <button
                                    className={`btn btn_orange  ${this.isValid('fieldsConfirmStep2') && !regState.sendRequest ? "" : " disabled"}`}
                                    onClick={this.checkSmsCode}
                                >{this.context.translate("Продолжить")}</button>
                            </div>
                        </Form>
                    }
                    {
                        regState.confirmStep === 2 &&
                        <Form validate={true} className="auth__form-slide active">
                            {this.renderFields(fieldsConfirmStep3, 'fieldsConfirmStep3')}
                            <div className="form__buttons">
                                <button
                                    className={`btn btn_orange  ${this.isValid('fieldsConfirmStep2') && !regState.sendRequest ? "" : " disabled"}`}
                                    onClick={this.setPassword}
                                >{this.context.translate("Войти")}</button>
                            </div>
                        </Form>
                    }
                </div>
            </div>
        )
    }
}

export default connect(
    state => ({
        splashImages: state.catalog && state.catalog.splashImages,
        regState: state.reg,
        pageSettings: state.catalog && state.catalog.authPageSettings,
        multilingual: state.multilingual
    }),
    (dispatch) => {
        return {
            onCodeRequest: (phone, password) => dispatch(codeRequest(phone, password)),
            toCodeRequest: () => dispatch(backToRequest()),
            onCodeValidate: (phone, code) => dispatch(codeValidate(phone, code)),
            authPageSettings: () => dispatch(authPageSettings()),
            recovery: (action, data) => dispatch(recovery(action, data)),
            onRegSendTempPassword: (phone_number, password, token) => dispatch(regSendTempPassword(phone_number, password, token)),
            onRegConfirmSendCode: (phone_number, code) => dispatch(regConfirmSendCode(phone_number, code)),
            onRegSendPassword: (phone_number, password, confirm_password) => dispatch(regSendPassword(phone_number, password, confirm_password)),
        }
    }
)(ConfirmationContainer);