import React, { Component } from "react";
import PropTypes from "prop-types";
import Form from "../common/form/Form";

export default class KPRequest extends Component {

    constructor(props){
        super(props);
        this.state = {
            Description: "По заявке <номер заявки> необходимо изменить акцептованный расчет на <номер расчета>.",
            preventSubmit: false
        }
    }
    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    // открывает модальное окно успеха, если успешно отправились данные в модальном окне KPRequest
    componentWillReceiveProps(nextProps){
        if ((this.props.isActionOk != nextProps.isActionOk) && nextProps.isActionOk) {
            this.props.openSuccess(); 
        }
    }

    onChange = (e) => {
        this.setState({Description: e.target.value});
    };

    onSubmit = () => {
        this.props.requestKP && this.props.requestKP(this.props.applicationId, this.state.Description, this.props.id);
    };

    render(){
        return <Form className="form_modal"
                     validate={false}
                     onSubmit={this.onSubmit}
        >
            <h2 className="form__title">{this.context.translate('Для изменения выбора итогового расчета потребуется создать обращение')}</h2>
            <div className="form__row">
                <div className="form__field">
                    <div className="form__textarea">
                        <textarea value={this.state.Description} onChange={this.onChange} placeholder={this.context.translate('По заявке <номер заявки> необходимо изменить акцептованный расчет на <номер расчета>.')}/>
                    </div>
                    {this.props.message ? <div className="form__text">
                        <span className="error">{this.props.message}</span>
                    </div> : null}
                </div>
            </div>

            <div className="form__buttons">
                <button
                    className={"btn"+(!this.state.Description || this.props.modalBtnIsDisabled ? " disabled" : "")}
                    type="submit"
                >{this.context.translate('Отправить')}</button>
            </div>
        </Form>
    }
}
