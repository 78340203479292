import {
    call,
    put,
    select,
    take
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {checkToken} from "./auth";

import {applicationDocuments} from "./applications";
import {getQueryParam, hasQueryParam} from "../tools/tools";

const files = state => state.fileBuffer;

// добавляем файл
export function addFile(id, file) {
    return {
        type: 'ADD_FILE',
        id,
        file
    }
}
// добавляем файл
export function* fetchAddFile(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        var body = new FormData();
        body.append('file', action.file);
        if (action.file.additionalData) {
            for (var attr in action.file.additionalData) {
                body.append(attr, action.file.additionalData[attr])
            }
        }

        const { status, data, error } = yield call( () => {
            return fetchClientApi("/applications"+(action.id ? "/"+action.id : "")+"/documents", {
                method: 'POST',
                body,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) {
            yield put(checkErrors(action.type, {massage: 'Ошибка загруки файла'}, 500));
        } else {
            if (status === 200 || status === 201) {
                // успешно отправлен файл
                yield put({ type: 'ISFILE_SUCCESS', data: true });

                var fileBuffer = yield select(files);
                if (!fileBuffer.filter((item) => {return item.ID === action.file.ID;}).length) {
                    yield put(deleteFile(action.id, data.FileId));
                } else {
                    yield put(applicationDocuments(action.id || data.ApplicationId));
                }
            }
            yield put(checkErrors(action.type, {
                id: action.id || data.ApplicationId,
                file: {
                    DocumentName: action.file.name,
                    FileExtension: action.file.name.substr(action.file.name.lastIndexOf('.') + 1),
                    ID: data.FileId,
                    UploadStamp: Date.now(),
                    LoadStamp: action.file.ID
                }
            }, status));
        }

    } catch(e) {
        alert(e);
    } finally{
        yield put({ type: 'ISFILE_SUCCESS', data: false });
    }

}

export function deleteFile(id, fileId) {
    return {
        type: 'DELETE_FILE',
        id,
        fileId
    }
}

export function* fetchDeleteFile(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');



        const { status, data, error } = yield call( () => {
            return fetchClientApi("/applications/documents/"+action.fileId, {
                method: 'DELETE',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, action, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function updateFile(id, fileId, fileName) {
    return {
        type: 'UPDATE_FILE',
        id,
        fileId,
        fileName
    }
}

export function* fetchUpdateFile(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');



        const { status, data, error } = yield call( () => {
            return fetchClientApi("/applications/documents/"+action.fileId, {
                method: 'PUT',
                body: "name="+action.fileName,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, {...action, data}, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function clearBuffer() {
    return {
        type: 'CLEAR_BUFFER'
    }
}

export function cancelFile(id) {
    return {
        type: 'CANCEL_FILE',
        id
    }
}