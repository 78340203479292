import React, { Component } from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";

export default class DiadocDocumentSigned extends Component {

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render() {

        const {
            type = 'resolve',
            typePDF = '',
        } = this.props;

        console.log('type', type);
        console.log('typePDF', typePDF);

        return (
            <div className="diadoc_document_signed">
                <h3 className="diadoc_document_signed__title">{this.context.translate(type === 'reject' ? 'Документ отклонен' : 'Документ подписан')}</h3>
                
                <div className="diadoc_document_signed__wrap_link">
                    {type === 'resolve'
                        && (
                            <div className="diadoc_document_signed__description">
                                Документ успешно подписан и отправлен по ЭДО.<br/>Изменение статуса документа можно отслеживать в разделе&nbsp;
                                <div className="diadoc_document_signed__link" onClick={() => this.props.close()}>
                                    <Link to={`/documents${typePDF === 'application' ? '#Outbound' : ''}`}>{this.context.translate("Документооборот")}</Link>
                                </div>
                                .
                            </div>
                        )}

                    {type === 'reject'
                        && (
                            <div className="diadoc_document_signed__description">
                                Документ успешно подписан и отправлен по ЭДО.<br/>Изменение статуса документа можно отслеживать в разделе&nbsp;
                                <div className="diadoc_document_signed__link" onClick={() => this.props.close()}>
                                    <Link to={`/documents${typePDF === 'application' ? '#Outbound' : ''}`}>{this.context.translate("Документооборот")}</Link>
                                </div>
                                .
                            </div>
                        )}
                </div>
            </div>
        )
    }
}
