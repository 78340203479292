import React, {Component} from "react";
import Form from "../common/form/Form";
import FormField from "../common/form/FormField";
import {priceMask, serverDateFormatMask} from "../../tools/mask";
import {getWord} from "../../tools/tools";
import PropTypes from "prop-types";

export default class ProjectsSearch extends Component{
    constructor(props){
        super(props);
        this.state = {
            ProjectName: {
                type: 'auto-complete',
                label: "Название проекта",
                value: "",
                error: '',
                options: props.catalog && props.catalog.projectNames || [],
                optionsType: 'projectNames',
                getOptions: props.autocomplete
            },
            ApproveDate: {
                type: 'date-range',
                label: "Дата одобрения проекта",
                value: "",
                error: '',
                yearSelection: true,
                canWrite: true,
            },
            EquipmentCost: {
                type: 'number-range',
                label: "Стоимость",
                value: "",
                error: '',
                mask: priceMask
            },
            SupplierId: {
                type: 'select',
                label: "Поставщик",
                placeholder: "Выберите поставщика",
                value: "",
                error: '',
                options: props.catalog && props.catalog.projectSuppliers || [],
                optionsType: 'projectSuppliers',
                hidden: props.catalog && props.catalog.projectSuppliers && props.catalog.projectSuppliers.length <= 1,
                allowEmpty: true
            },
            EquipmentCategoryId: {
                type: 'select',
                label: "Тип предмета лизинга",
                value: "",
                error: '',
                options: props.catalog && props.catalog.projectEquipmentCategories || [],
                optionsType: 'projectEquipmentCategories',
                hidden: props.catalog && props.catalog.projectEquipmentCategories && props.catalog.projectEquipmentCategories.length <= 1,
                allowEmpty: true
            },
            VehicleIdentity: {
                type: 'text',
                label: "Номер (VIN, кузов, двигатель)",
                value: "",
                error: '',
                hidden: props.catalog && !props.catalog.hasVehicles
            },
            ContractNumber: {
                type: 'auto-complete',
                label: "Номер договора лизинга",
                value: "",
                error: '',
                options: props.catalog && props.catalog.projectContracts || [],
                optionsType: 'projectContracts',
                getOptions: props.autocomplete
            },
            ContractStatusId: {
                type: 'select',
                label: "Статус договора лизинга",
                value: "",
                error: '',
                options: props.catalog && props.catalog.projectContractStatuses || [],
                optionsType: 'projectContractStatuses',
                hidden: props.catalog && props.catalog.projectContractStatuses && props.catalog.projectContractStatuses.length <= 1,
                allowEmpty: true
            },
            ContractSignDate: {
                type: 'date-range',
                label: "Дата подписания договора лизинга",
                value: "",
                error: '',
                yearSelection: true,
                canWrite: true,
            },
            ContractEndDate: {
                type: 'date-range',
                label: "Дата окончания договора лизинга",
                value: "",
                error: '',
                yearSelection: true,
                canWrite: true,
            },
            LesseeId: {
                type: 'select',
                label: "Лизингополучатель",
                placeholder: "Выберите лизингополучателя",
                value: props.catalog && props.catalog.projectLessees && props.catalog.projectLessees.length == 1 ? props.catalog.projectLessees[0].value : "",
                error: '',
                options: props.catalog && props.catalog.projectLessees || [],
                optionsType: 'projectLessees',
                hidden: !props.catalog || !props.catalog.companies || props.catalog.companies.length == 1,
                disabled: props.catalog && props.catalog.projectLessees && props.catalog.projectLessees.length == 1,
                allowEmpty: true
            },
            ContractCurrencyId: {
                type: 'select',
                label: "Валюта договора",
                value: "",
                error: '',
                options: props.catalog && props.catalog.projectCurrencies || [],
                optionsType: 'projectCurrencies',
                hidden: props.catalog && props.catalog.projectCurrencies && props.catalog.projectCurrencies.length <= 1,
                allowEmpty: true
            },
            HasPenalties: {
                type: 'select',
                label: "Наличие штрафной неустойки",
                value: "",
                error: '',
                placeholder: "Не важно (дефолт)",
                options: [
                    {
                        value: 1,
                        label: "Есть"
                    },
                    {
                        value: 0,
                        label: "Нет"
                    }
                ],
                allowEmpty: true,
                hidden: props.catalog && !props.catalog.hasPenalties
            },

        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    addCatalogOptions = (state, catalog) => {
        for (var key in state) {
            if (state[key].hasOwnProperty('optionsType') && catalog[state[key].optionsType] && state[key].options != catalog[state[key].optionsType]) {
                state[key].options = catalog[state[key].optionsType];
                if (key == 'LesseeId') {
                    if (!catalog.companies || catalog.companies.length == 1) {
                        state.LesseeId.value = "";
                        state.LesseeId.disabled = false;
                        state.LesseeId.hidden = true;
                    } else if (catalog.projectLessees.length == 1) {
                        state.LesseeId.value = catalog.projectLessees[0].value;
                        state.LesseeId.disabled = true;
                        state.LesseeId.hidden = false;
                    } else {
                        state.LesseeId.value = "";
                        state.LesseeId.disabled = false;
                        state.LesseeId.hidden = false;
                    }
                } else if (state[key].type == 'select') {
                    state[key].hidden = catalog[state[key].optionsType].length <= 1;
                }
            }
            if (key == 'HasPenalties') {
                state[key].hidden = !catalog.hasPenalties;
            }
            if (key == 'VehicleIdentity') {
                state[key].hidden = !catalog.hasVehicles;
            }
        }
        return state;
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.catalog != this.props.catalog) {
            var state = {...this.state};
            state = this.addCatalogOptions(state, nextProps.catalog);
            this.setState(state);
        }
    }

    onChangeField = (attribute, value) => {
        var state = {...this.state};
        if (state.hasOwnProperty(attribute)) {
            state[attribute].value = value;
            this.setState(state)
        }
    };

    renderFields = () => {
        var fields = [];
        for (var key in {...this.state}) {
            if (key !== 'HasPenalties' && key !== 'ContractCurrencyId') fields.push(this.renderField(key));
        }
        return fields;
    }

    renderField = (attribute, options = {}) => {
        var fields = this.state;

         return fields.hasOwnProperty(attribute) && !fields[attribute].hidden ?
             <div className="items-filter__half-col" key={attribute}>
                 <FormField {...options}
                       fieldConfig={{
                           ...fields[attribute],
                           label: this.context.translate(fields[attribute].label),
                           placeholder: this.context.translate(fields[attribute].placeholder)
                       }}
                       attribute={attribute}
                       onChange={this.onChangeField}
                 />
             </div> : null;
    };

    onSubmit = () => {
        var state = {...this.state};
        var params = [];
        for (var key in state) {
            var val = state[key].value;
            if (val && (val.from || val.to)) {
                if (val && val.from) params.push(key+'Min='+(key.indexOf('Date') === -1 ? val.from.replace(/\D/gim, '') : serverDateFormatMask(val.from)));
                if (val && val.to) params.push(key+'Max='+(key.indexOf('Date') === -1 ? val.to.replace(/\D/gim, '') : serverDateFormatMask(val.to)));
            } else params.push(key+"="+val);
        }
        this.props.search && this.props.search(params.join('&'));
    };

    onReset = () => {
        var state = {...this.state};
        for (var attribute in state) {
            state[attribute].value = '';
        }
        this.setState(state);
        this.props.search && this.props.search();
    };

    render(){

        var {
            contractsCnt,
            projectsCnt,
            filtered
        } = this.props;

        var {
            ContractCurrencyId,
            HasPenalties
        } = this.state;
        let searchLabel = getWord(projectsCnt, this.context.translate('Найден'), this.context.translate('Найдено'), this.context.translate('Найдено'));
        let projectsLabel = getWord(projectsCnt, this.context.translate('проект'), this.context.translate('проекта'), this.context.translate('проектов'));

        return <Form onSubmit={this.onSubmit} validate={false}>
                <div className="items-filter__body">
                    <div className="items-filter__cols">
                        {this.renderFields()}
                        {ContractCurrencyId.hidden ? null : <div className="items-filter__custom-col items-filter__custom-col_currency">
                            <FormField labelOptions={{className: "select-input__label-text"}}
                                       fieldConfig={{
                                           ...ContractCurrencyId,
                                           label: this.context.translate(ContractCurrencyId.label),
                                           placeholder: this.context.translate(ContractCurrencyId.placeholder)
                                       }}
                                       attribute={'ContractCurrencyId'}
                                       onChange={this.onChangeField}
                            />
                        </div>}
                        {!HasPenalties.hidden ? <div className="items-filter__custom-col items-filter__custom-col_finepay">
                            <FormField fieldConfig={{
                                ...HasPenalties,
                                label: this.context.translate(HasPenalties.label),
                                placeholder: this.context.translate(HasPenalties.placeholder)
                            }}
                                       attribute={'HasPenalties'}
                                       onChange={this.onChangeField}
                            />
                        </div> : null}
                    </div>
                </div>
                <div className="items-filter__ui-holder">
                    <div className="form__buttons">
                        <button className="btn" type="submit">{this.context.translate('Найти')}</button>
                        <button className="btn btn_underline" type="reset" onClick={this.onReset}>{this.context.translate('Сбросить')}</button>
                    </div>
                    {filtered ? <div className="items-filter__results">
                        <div className="items-filter__result-text" dangerouslySetInnerHTML={{__html: searchLabel + "&nbsp;<em>"+projectsCnt+"</em>&nbsp;" + projectsLabel}} />
                    </div> : null}
                </div>
        </Form>
    }
}