const initState = {
    isLayoutOk: false,
    downloading: {progress: 0, success: 0, error: 0},
    pageLoaded: false,
};

import {
    parseErrors
} from "../tools/api-helper";

export function catalog(state = initState, action) {
    switch (action.type) {
        case "AUTOCOMPLETE_SUCCESS":
            return {...state, [action.data.type]: action.data.data};

        case "FORM_INFO_APPLICATION":
            return {...state, ...action.data, isOk: false};
        case "FORM_INFO_APPLICATION_SUCCESS":
            return {...state, ...action.data, isOk: true};

        case "PROJECT_FILTER":
            return {...state, projectNames: [], projectContracts: [], isOk: false};
        case "ARCHIVE_SEARCH_INFO_APPLICATION":
            return {...state, archiveEquipmentNames: [], isOk: false};

        case "ARCHIVE_SEARCH_INFO_APPLICATION_SUCCESS":
            var data = action.data;
            data.archiveCurrencies = data.currencies;
            data.archiveEquipmentBrands = data.equipmentBrands;
            delete data['currencies'];
            delete data['equipmentBrands'];
            return {...state, ...data, isOk: true};
        
        case "DOCUMENTS_STATUS_SUCCESS":
            return {
                ...state, 
                documentStatus: action.data,
                isOk: true};

        case "DOCUMENTS_SEARCH_INFO_SUCCESS":
        case "DOCUMENTS_WIDGET_INFO_SUCCESS":
        case "COMMUNICATIONS_SEARCH_INFO_SUCCESS":
        case "COMMUNICATIONS_FORM_INFO_SUCCESS":
            return {...state, ...action.data, isOk: true};

        case "PROJECT_FILTER_SUCCESS":
            return {...state, ...action.data, isOk: true};

        case "LAYOUT_INFO":
            return {...state, isLayoutOk:false};
        case "LAYOUT_INFO_SUCCESS":
            return {...state, ...action.data, isLayoutOk: true};

        case "COMPANY_ACCEPT_SUCCESS":
            var companies = [...state.companies];
            companies = companies.map((item) => {
                if (item.CompanyId == action.data.id) {
                    item.IsConfirmed = 1;
                }
                return item;
            });
            return {...state, companies};

        case "COMPANY_REJECT_SUCCESS":
            var companies = [...state.companies];
            companies = companies.filter((item) => {
                return item.CompanyId != action.data.id;
            });
            return {...state, companies};

        case "SPLASH_IMAGES_SUCCESS":
            return {...state, splashImages: action.data};

        case "SPLASH_MESSAGE_SUCCESS":
            return {...state, splashMessage: action.data};

        case "SAVE_CLIENT_COMPANIES_SUCCESS":
            var companies =  [...state.companies];
            companies = companies.map((item) => {
                item.IsSelected = (action.data.indexOf(item.CompanyId) == -1 ? 0 : 1);
                return item;
            });
            return {...state, companies};

        case "AUTH_PAGE_SETTINGS_SUCCESS":
            var title = {};
            action.data.intro_title.map((item) => {
                title[item.lang] = item.translation;
            })
            var body = {};
            action.data.intro_body.map((item) => {
                body[item.lang] = item.translation;
            })
            var phone = {};
            action.data.support_phone.map((item) => {
                phone[item.lang] = item.translation;
            })
            return {...state, authPageSettings: {
                intro_title: title,
                intro_body: body,
                support_phone: phone
            }};

        case "FOOTER_SETTINGS_SUCCESS":
            return {...state, footerSettings: action.data};

        case "INVEST_POTENTIAL_SUCCESS":
            return {...state, investPotential: action.data};

        case "SUBSCRIPTIONS_SUCCESS":
            return {...state, notificationSettings: action.data};

        case "LOGOUT_SUCCESS":
        case "BLOCKED":
            return {...initState, footerSettings: state.footerSettings};

        case "CLEAR_ERROR":
            return {...state, errorMessage: ""};

        case 'DOWNLOAD_INVOICE':
        case 'DOWNLOAD_CONTRACT_SCHEDULE':
        case 'DOWNLOAD_COMMERCIAL_OFFER':
        case 'DOWNLOAD_RECONCILIATION_STATEMENT':
            var newState = {...state.downloading};
            newState.progress += 1;
            newState.message = "Идет генерация документов, пожалуйста подождите";
            return {...state, downloading: newState};
        case 'DOWNLOAD_INVOICE_SUCCESS':
        case 'DOWNLOAD_CONTRACT_SCHEDULE_SUCCESS':
        case 'DOWNLOAD_COMMERCIAL_OFFER_SUCCESS':
        case 'DOWNLOAD_RECONCILIATION_STATEMENT_SUCCESS':
            var newState = {...state.downloading};
            newState.progress -= 1;
            if (newState.progress == 0 && newState.error == 0) {
                return {...state, downloading: {progress: 0, success: 0, error: 0}};
            } else {
                newState.success += 1;
                if (newState.error != 0) {
                    newState.message = "Произошла ошибка, пожалуйста попробуйте позже";
                }
                return {...state, downloading: newState};
            }
        case 'DOWNLOAD_INVOICE_ERROR':
        case 'DOWNLOAD_CONTRACT_SCHEDULE_ERROR':
        case 'DOWNLOAD_COMMERCIAL_OFFER_ERROR':
        case 'DOWNLOAD_RECONCILIATION_STATEMENT_ERROR':
            var newState = {...state.downloading};
            newState.progress -= 1;
            newState.error += 1;
            if (newState.progress == 0) {
                newState.message = "Произошла ошибка, пожалуйста попробуйте позже";
            }
            return {...state, downloading: newState};
        case 'DOWNLOAD_RECONCILIATION_STATEMENT_BAD_REQUEST':
            var newState = {...state.downloading};
            newState.progress -= 1;
            newState.error += 1;
            newState.message = action.data.message;
            return {...state, downloading: newState};
        case 'DOWNLOAD_RECONCILIATION_STATEMENT_VALIDATION_ERROR':
        case 'CLEAR_DOWNLOADING':
            return {...state, downloading: {progress: 0, success: 0, error: 0}};
        case '@@router/LOCATION_CHANGE':
        case 'PAGE_LOADED':
            return {...state, pageLoaded: false};
        case 'PAGE_LOADED_SUCCESS':
            return {...state, pageLoaded: true};
        case "DOWNLOAD_APPLICATION_FILE_NOT_FOUND":
            return {...state, downloading: {message: "Файл не найден", progress: 0, success: 0, error: state.downloading.error + 1}};
        case "GET_INSURANCE_DOCUMENTS_ERROR":
            return state;
        case "LOGOUT_SUCCESS":
            return {...initState};
        default:
            if (action.type.indexOf("_ERROR") !== -1 && action.type.indexOf("_VALIDATION_ERROR") === -1) {
                return {...state, errorMessage: action.data.message, errorStatus: 500};
            }
            if (action.type.indexOf("_FAILED_TO_FETCH") !== -1) {
                return {...state, errorMessage: action.data.message, errorStatus: 403};
            }
    }
    return state;
}