import React, { Component } from "react";
import PropTypes from "prop-types";

export default class DiadocDocumentRejectionText extends Component {

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    close = () => {
        this.props.close();
    }

    render() {

        const {
            continuationDocumentRejectionProcessAction = () => {},
            downloadDoc = () => {},
            back = () => {},
            diadocState,
        } = this.props;

        // получаем данные выбранного документа
        const selectedDoc = JSON.parse(localStorage.getItem('selectedDoc'));

        return (
            <div className="diadoc_document_rejection_text">
                <h3 className="diadoc_document_rejection_text__title">{this.context.translate('Отклонение документа')}</h3>

                <div className="diadoc_document_rejection_text__wrap_links">
                    <div
                        className="diadoc_document_rejection_text__link"
                        onClick={() => {
                            downloadDoc(+selectedDoc.id, selectedDoc.title);
                        }}
                    >
                        {this.context.translate(selectedDoc && selectedDoc.title)}.pdf
                    </div>
                </div>

                <div className="diadoc_document_rejection_text__description">
                    {this.context.translate('Причина:')}
                    <br></br>
                    {this.context.translate(diadocState.textReasonForRejection)}
                </div>
                
                <div className="diadoc_document_rejection_text__wrap_btns">
                    <button
                        onClick={() => {
                            continuationDocumentRejectionProcessAction({
                                document_id: diadocState.signatureDeclineSigningRequest.documentId,
                                base64_decline_content: diadocState.signatureDeclineSigningRequest.base64DeclineContent,
                                decline_entity_filename: diadocState.signatureDeclineSigningRequest.declineEntityFilename,
                                base64_signature: diadocState.signatureCertificate.signature,
                            });
                        }}
                        className={"btn"}
                    >
                        {this.context.translate('Продолжить')}
                    </button>
                    
                    <button
                        onClick={back}
                        className={"btn btn_gray"}
                    >
                        {this.context.translate('назад')}
                    </button>
                </div>
            </div>
        )
    }
}
