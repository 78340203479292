import React, { Component } from "react";
import PropTypes from "prop-types";

export default class DiadocLogin extends Component {

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render() {

        const { 
            type = '',
            openIdSettings = {},
            pdfApplicationFormAction = () => {},
            detailApplication = {}
         } = this.props;

        return (
            <div className="diadoc_login">
                <h3 className="diadoc_login__title">{this.context.translate('Вход в Диадок')}</h3>

                {type === ''
                    && (
                        <div className="diadoc_login__description">
                            {this.context.translate('Мы уже получили вашу заявку и начали работу по ней. Чтобы ускорить рассмотрение, подпишите ее.')}
                            <br></br>
                            <br></br>
                            {this.context.translate('Вам понадобится квалифицированная электронная подпись и активная учетная запись в Диадок для подписания документов. ')}      
                            {this.context.translate('Для того, чтобы подписать документ, войдите в вашу учетную запись в Контур.Диадок.')}      
                            <br></br>
                            <br></br>
                            {this.context.translate('Скачайте печатную форму и подпишите ее в вашей системе ЭДО, если вы не используете Диадок.')}
                        </div>
                    )}
                
                {type === 'second'
                    && (
                        <div className="diadoc_login__description">
                            {this.context.translate('Вам понадобится квалифицированная электронная подпись и активная учетная запись в Диадок для подписания документов.')}
                            <br></br>
                            <br></br>    
                            {this.context.translate('Для того, чтобы подписать документ, войдите в вашу учетную запись в Контур.Диадок.')} 
                        </div>
                    )}
                
                <div className="diadoc_login__wrap_links">
                    <div className="diadoc_login__link">
                        <a href="https://help.kontur.ru/diadoc" target="_blank">
                            {this.context.translate('Проверить настройку рабочего места')}
                        </a>
                    </div>
                    {/* <div className="diadoc_login__link">
                        {this.context.translate('Скачать инструкцию')}
                    </div> */}
                </div>

                <div className="diadoc_login__wrap_btns">
                    <button
                        onClick={() => {
                            // сохраняем заказ на случай, если придется вначале войти в диадок
                            localStorage.setItem('detailApplication', JSON.stringify(detailApplication))

                            const url = openIdSettings && openIdSettings.url;
                            if (url) {
                                window.location.href = url
                            }
                        }}
                        className={"btn"}
                    >
                        {this.context.translate('Войти в диадок')}
                    </button>
                    
                    {/*{type === ''*/}
                    {/*    && (*/}
                    {/*        <button*/}
                    {/*            onClick={() => pdfApplicationFormAction(localStorage.getItem('idApplication'))}*/}
                    {/*            className={"btn btn_white"}*/}
                    {/*        >*/}
                    {/*            {this.context.translate('Скачать заявку')}*/}
                    {/*        </button>*/}
                    {/*    )}*/}
                </div>
            </div>
        )
    }
}
