import React, {Component} from "react";
import {numberMask} from "../../tools/mask";
import Form from "../common/form/Form";
import FormField from "../common/form/FormField";
import CheckBox from "../common/form/CheckBox";
import PropTypes from "prop-types";

export default class SupplierInformationForm extends Component{

    constructor(props){
        super(props);

        var companyMain = props.fieldsMainForm || {};
        var disabled = true;

        this.state = {
            Inn: {
                type: 'text',
                label: "ИНН",
                value: companyMain.SupplierTin || "",
                error: "",
                placeholder: "Введите ИНН",
                mask: numberMask()
            },
            Kpp: {
                type: 'text',
                label: "КПП",
                value: companyMain.SupplierKpp || "",
                error: "",
                placeholder: "Введите КПП",
            },

            FullName: {
                type: 'text',
                label: "Полное наименование",
                value: companyMain.SupplierName || "",
                error: "",
                disabled
            },
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    // подставляем значения в поля после поиска ИНН
    addValues = (state, company) => {
        state.FullName.value = company && company.FullName || "";
        state.Kpp.value = company && company.Kpp || "";

        for (var key in state) {
            state[key].success = !!state[key].value && !state[key].error;
        }
        return state;
    }

    componentWillReceiveProps(nextProps){
        var state = {...this.state};
        if (nextProps.errors != this.props.errors) {
            for (var key in state) {
                if (nextProps.errors && nextProps.errors[key]) {
                    state[key].error = nextProps.errors[key];
                    state[key].success = false;
                } else {
                    state[key].error = "";
                }
            }
        }
        if (nextProps.fieldsProvider != this.props.fieldsProvider) {
            state = this.addValues(state, nextProps.fieldsProvider);
        }
        if (state != this.state) this.setState(state);

        // разблокируем поля формы если сервис временно не доступен
        if (this.props.modalMessage !== nextProps.modalMessage) {
            this.state.FullName.disabled = false;
        }
    }

    onChangeField = (attribute, value) => {
        var state = {...this.state};
        if (state.hasOwnProperty(attribute)) {
            state[attribute].value = value;
            this.setState(state)
        }
    };

    renderField = (attribute, options = {}) => {
        var fieldsProvider = this.state;

        return fieldsProvider.hasOwnProperty(attribute) ?
            <FormField {...options}
                        key={attribute}
                        fieldConfig={{
                            ...fieldsProvider[attribute],
                            label: this.context.translate(fieldsProvider[attribute].label),
                            placeholder: this.context.translate(fieldsProvider[attribute].placeholder)
                        }}
                       attribute={attribute}
                       onChange={this.onChangeField}
            /> : null;
    };

    onSearch = (e) => {
        e.preventDefault();
        if (this.state.Inn.value) {
            this.props.findCompanyProvider && this.props.findCompanyProvider(this.state.Inn.value);
            this.props.selectedDisabledBtnAction(true);
        } else {
            this.setState({
                ...this.state,
                Inn: {
                    ...this.state.Inn,
                    error: this.context.translate('Это поле необходимо заполнить.')
                }
            })
        }
    }

    onSubmit = () => {
        var companyFields = {...this.state};

        var fieldsProvider = {
            SupplierTin: companyFields.Inn.value,
            SupplierKpp: companyFields.Kpp.value,
            SupplierName: companyFields.FullName.value.replace(/\"/gim, ''),
        };

        this.props.addCompany && this.props.addCompany(fieldsProvider);
        this.props.selectedTypeVendorInformationAction && this.props.selectedTypeVendorInformationAction('modal');
    };

    render(){

        const {
            stateDisabled,
        } = this.props;


        return <Form className="form_modal"
                     validate={true}
                     onSubmit={this.onSubmit}
        >
            <h2 className="form__title">{this.context.translate('Новая компания')}</h2>
            <h3 className="form__subtitle">{this.context.translate('Информация о компании')}</h3>
            <div className="form__row">
                {this.renderField('Inn', {className: 'form__field_size_s form__field_size_tablet_inner'})}
                {this.renderField('Kpp', {className: 'form__field_size_s form__field_size_tablet_inner'})}

                <div className="form__field__wrap_btn_submit_and_spinner">
                    <div className="form__field__wrap_btn_submit">
                        <button
                            className={"btn"}
                            href="#"
                            onClick={this.onSearch}
                            disabled={stateDisabled}
                        >
                            {this.state.FullName.value ? this.context.translate('Повторить поиск') : this.context.translate('Найти Компанию')}
                        </button>
                    </div>
                    
                    {stateDisabled
                        && (
                            <div className="form__field__wraps_pinner">
                                <img src={`${require('../../../img/elements/elem-preload.svg')}`} alt="Спинер" />
                            </div>
                        )}
                </div>
            </div>
            <div className="form__text">
                <p>{this.props.modalMessage ? this.props.modalMessage : this.context.translate('Введите ИНН компании, чтобы получить информацию о ней в СПАРК.')}</p>
            </div>
            <div className="form__row">
                {this.renderField('FullName')}
            </div>

            <div className="form__buttons">
                <button className={"btn"+(this.state.FullName.value ? "" : " disabled")} type="submit">{this.context.translate('Добавить')}</button>
            </div>
        </Form>
    }

}