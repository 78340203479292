import {
    call,
    put,
} from 'redux-saga/effects';

import {checkErrors, fetchClientApi} from "../tools/api-helper";

export function getKey(){
    return {
        type: "RECAPTCHA",
    }
}

export function* fetchRecaptcha(action){
    try {
        const { status, data, error } = yield call( () => {
            return fetchClientApi("/guest/recaptcha", {
                method: 'GET'
            })
        });
        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }
}