const initState = {list: [], isSearch: false};

export function communications(state = initState, action) {
    switch (action.type) {
        case "COMMUNICATIONS_LIST_SUCCESS":
            return {...state, list: action.data, isSearch: false};
        case "COMMUNICATIONS_LIST_SEARCH_SUCCESS":
            return {...state, list: action.data, isSearch: true};
        case "COMMUNICATIONS_SET_RATING_SUCCESS":
            var list = [...state.list];

            list = list.map((item) => {
                if (item.CommunicationId == action.data.id) {
                    item.RatingId = action.data.rating;
                    item.RatingValue = action.data.value;
                }
                return item;
            });
            list.pagination = {...state.list.pagination};

            var widget = {...state.widget};
            if (widget) {
                widget.list = widget.list.map((item) => {
                    if (item.CommunicationId == action.data.id) {
                        item.RatingId = action.data.rating;
                        item.RatingValue = action.data.value;
                    }
                    return item;
                });
            }

            var detail = {...state.detail};
            if (detail && detail.CommunicationId == action.data.id) {
                detail.RatingId = action.data.rating;
                detail.RatingValue = action.data.value;
                detail.CanRate = 0;
                detail.IsClosed = 1;
            }

            return {...state, list, widget, detail};
        case "COMMUNICATIONS_WIDGET_SUCCESS":
            return {...state, widget: action.data};

        case "COMMUNICATIONS_CREATE_DRAFT_CREATED":
            return {...state, draft: action.data};

        case 'COMMUNICATIONS_ADD_FILE_CREATED':
            var files = state.files ? [...state.files] : [];

            var newFile = action.data.file;
            newFile.status = 'success';
            newFile.ID = action.data.FileId;

            return {...state, files: [...files, newFile]};

        case 'COMMUNICATIONS_ADD_FILE_BAD_REQUEST':
            var files = state.files ? [...state.files] : [];
            var newFile = action.data.file;
            newFile.status = 'error';
            newFile.error = 'Ошибка загрузки файла ';
            return {...state, files: [...files]};

        case 'COMMUNICATIONS_ADD_FILE_ERROR':
            var files = state.files ? [...state.files] : [];
            var newFile = action.data.file;
            newFile.status = 'error';
            newFile.error = 'Ошибка загрузки файла ';
            return {...state, files: [...files]};

        case 'COMMUNICATIONS_DELETE_FILE_SUCCESS':
            var files = state.files ? [...state.files] : [];
            files = files.filter((item) => {
                return item.ID != action.data.fileId;
            });

            return {...state, files};

        case 'COMMUNICATIONS_UPDATE_FILE_SUCCESS':
            var files = state.files ? [...state.files] : [];
            files = files.map((item) => {
                if (item.ID == action.data.fileId) {
                    item.filename = action.data.fileName;
                }
                return item;
            });

            return {...state, files};

        case "COMMUNICATIONS_DETAIL_SUCCESS":
            var list = [...state.list];
            list = list.map((item) => {
                if (item.CommunicationId == action.data.CommunicationId) {
                    return {...item, ...action.data};
                } else {
                    return item;
                }
            });

            var widget = {...state.widget};
            if (widget && widget.list) {
                widget.list = widget.list.map((item) => {
                    if (item.CommunicationId == action.data.CommunicationId) {
                        return {...item, ...action.data};
                    } else {
                        return item;
                    }
                });
            }

            return {...state, detail: action.data, list, widget};

        case "NEW_MESSAGE":
            return {...state, detail: {...state.detail, Messages: [...state.detail.Messages, action.data]}};

        case "CLEAR_DRAFT":
            var newState = {...state};
            delete newState['draft'];
            delete newState['files'];
            return newState;
        case "LOGOUT_SUCCESS":
            return {...initState};
    }
    return state;
}
