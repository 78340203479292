import React, {Component} from "react";
import Form from "../common/form/Form";
import FormField from "../common/form/FormField";
import Modal from "../common/Modal";
import PropTypes from "prop-types";
import {noSpace} from "../../tools/mask";

import ok from "../../../img/notification_ready.svg";

export default class PasswordForm extends Component{

    constructor(props){
        super(props);

        this.state = {
            fields: {
                old_password: {
                    type: 'password',
                    label: "Старый пароль",
                    value: "",
                    error: '',
                    required: true,
                    mask: noSpace,
                },
                new_password: {
                    type: 'password',
                    label: "Новый пароль",
                    value: "",
                    error: '',
                    required: true,
                    mask: noSpace,
                },
                repeat_password: {
                    type: 'password',
                    label: "Повторить пароль",
                    value: "",
                    error: '',
                    required: true,
                    mask: noSpace,
                },
            },
            modal: false
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    getError = (name, value) => {
        switch (name) {
            case 'repeat_password':
                return this.state.fields.new_password.value == value ? false : this.context.translate("Пароль введен неверно");
            case 'new_password':
                var re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}$/;
                return re.test(value) ? false : this.context.translate("Пароль должен содержать латинские буквы в верхнем и нижнем регистрах, цифры, и быть длиной не менее 8 символов.");
            default:
                return "";
        }
    }

    componentWillReceiveProps(nextProps){

        if (nextProps.errors != this.props.errors) {
            if (nextProps.errors == undefined) {
                this.setState({modal: true});
                setTimeout(() => {this.setState({modal: false})}, 2000);
            } else {
                var state = {...this.state.fields};
                for (var key in state) {
                    state[key].error = nextProps.errors ? nextProps.errors[key] : ""
                }
                this.setState({fields: state});
            }
        }
    }

    onChangeField = (attribute, value, error) => {
        var prevState = {...this.state.fields};
        if (prevState.hasOwnProperty(attribute)) {
            prevState[attribute].value = value;
            prevState[attribute].error = error || this.getError(attribute, value);
            this.setState({fields: prevState})
        }
    };

    onSubmit = () => {
        let {
            old_password,
            new_password
        } = this.state.fields;

        this.props.onSubmit(old_password.value, new_password.value);
    }

    isValid = () => {
        let {
            old_password,
            repeat_password,
            new_password
        } = this.state.fields;

        return (!old_password.error && !repeat_password.error && !new_password.error &&
        old_password.value && new_password.value && new_password.value == repeat_password.value);

    }

    renderFields = (fields) => {
        var result = [];

        for (var attribute in fields) {
            if (fields.hasOwnProperty(attribute)) {

                result.push(<div className="profile-form__item" key={attribute}>
                    <FormField fieldConfig={{
                        ...fields[attribute],
                        label: this.context.translate(fields[attribute].label)
                    }}
                               attribute={attribute}
                               onChange={this.onChangeField}
                    />
                </div>)
            }
        }

        return result;
    };

    render(){

        return <Form validate={true} onSubmit={this.onSubmit}>
            <h2 className="profile-form__title u-mobile-hide">{this.context.translate('Сменить пароль')}</h2>
            <div className="profile-form__container">
                {this.renderFields({...this.state.fields})}
            </div>
            <div className="profile-form__item profile-form__item_btn">
                <button type="submit" className={"u-btn u-btn_adapt-type_wide"+(this.isValid() ? "" : " u-btn_disabled")}>{this.context.translate('Сохранить')}</button>
            </div>
            {this.state.modal ? <Modal modalSize="s" classNameContent="modal modal_visible modal-wrapper modal__xs text-center" onClickBackground={() => {this.setState({modal: false})}}>
                <p><img src={ok} /></p>
                <h2 className="form__title">{this.context.translate('Ваш пароль успешно изменен')}</h2>
            </Modal> : null}
        </Form>
    }
}