import React from "react";
import {Link} from "react-router-dom";

import DealItem from "../deals/DealItem";

export default function DealsWidget({
    className = "",
    title,items = [],
    count,
    link,
    downloadInvoice,
    paymentNotification,
    translate,
    typeDeals,
}) {
    return (
        <div className={"main-page__section"+(className ? " "+className : "")}>
            <p className="main-page__title">{title}</p>
            <div className="notice-list notice-list_last-events notice-list_adapt-events notice-list_main-page">
                <div className="notice-list__items">
                    {items.map((item, key) => 
                        <DealItem
                            item={item}
                            paymentNotification={paymentNotification}
                            key={key}
                            downloadInvoice={downloadInvoice}
                            typeDeals={typeDeals}
                        />
                    )}
                </div>
            </div>
            <div className="main-page__show-all">
                <Link to={link} className="u-all-link">
                    {translate("Показать все")}
                    <sup className="u-all-link__sup">({count})</sup>
                </Link>
            </div>
        </div>
    )
}