import React, { Component } from "react";
import PropTypes from "prop-types";

export default class KPSuccess extends Component {

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render() {
        return (
            <div className="kp_success">
                <h3 className="kp_success__title">{this.context.translate('Запрос отправлен вашему менеджеру')}</h3>
            </div>
        )
    }
}
