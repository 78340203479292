import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Link, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import Modal from '../../components/common/Modal';
import { getQueryParam, deleteQueryParam } from '../../tools/tools';

import OrdersListDraft from "../../components/orders/OrdersListDraft";
import OrdersListProgress from "../../components/orders/OrdersListProgress";
import OrdersListArchive from "../../components/orders/OrdersListArchive";
import Preloader from "../../components/common/Preloader";

import { addFile, deleteFile, clearBuffer, updateFile, cancelFile } from "../../actions/file-buffer";
import {
    applicationsList,
    archiveSearchInfo,
    recallApplication,
    requestApplication,
    resumeApplication,
    pdfApplicationFormAction,
} from "../../actions/applications";
import { sendApplication, viewApplication } from "../../actions/add-applications";
import { autoComplete } from "../../actions/catalog";
import noApps from "../../../img/no_apps.svg";

import {
    disableBtnSignAction,
    toggleModalDiadocAction,
    selectedBoxIdAction,
    startApplicationDiadocAction,
    continuationApplicationDiadocAction,
    continuationAfterCompanySelectionApplicationDiadocAction,
    startCertificateUserSignatureAction,
 } from '../../actions/diadocAction';
import DiadocLogin from '../../components/companies/DiadocLogin';
import DiadocCompanyChoice from '../../components/companies/DiadocCompanyChoice';
import DiadocSomethingWentWrong from '../../components/companies/DiadocSomethingWentWrong';
import DiadocApplicationSigning from '../../components/companies/DiadocApplicationSigning';
import DiadocDocumentSigned from '../../components/companies/DiadocDocumentSigned';




class OrdersContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            activeTab: 1,
            choosedOrderByList: null
        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillMount() {
        // this.props.applicationsList('draft');
        // this.props.applicationsList('in-progress');
        // this.props.applicationsList('archive');
        // this.props.archiveSearchInfo();
        if (this.props.applications.draft || this.props.applications.inProgress || this.props.applications.archive) {
            let tabsMenu = [];

            if (this.props.applications.draft.length) {
                tabsMenu.push({ key: 'drafts', tab: 0, content: <span>{this.context.translate('Неотправленные')}<sup>({this.props.applications.draft.length})</sup></span> });
            }
            if (this.props.applications.inProgress.length) {
                tabsMenu.push({ key: 'progress', tab: 1, content: <span>{this.context.translate('В работе')}<sup>({this.props.applications.inProgress.length})</sup></span> });
            }

            if (this.props.applications.archive.length || this.props.applications.archive.message) {
                tabsMenu.push({ key: 'archive', tab: 2, content: <span>{this.context.translate('Архив')}<sup>({this.props.applications.archive.pagination && this.props.applications.archive.pagination.totalCount})</sup></span> });
            }

            this.setState({ tabsMenu });
        }

        if (this.props.applications.draft.length > 0) {
            this.setState({ activeTab: 0 });
        }

    }
    componentDidMount() {
        if(this.props.applications.inProgress) {
            let hash = this.props.location && this.props.location.hash;
            if (hash === "#progress")
                this.setState({
                    activeTab: 1
                });
                this.removeHash();
        }
    }

    componentDidMount() {
        // const code = getQueryParam('code');
        // const url = window.location.href.includes('/applications?code');
        // console.log('Получаем code и проверяем url')
        // console.log('code: ' + code)
        // console.log('url includes /applications?code: ' + url)
        // // после авторизации в КОНТУРЕ
        // // находясь на странице "Заявки" ищем в адресной строке квери параметр "code" после отправляем запрос
        // if (url && code) {
        //     console.log('Успешная проверка code и url')
        //     this.props.continuationApplicationDiadocAction('newApplication', code);
        // }
    }

    componentDidUpdate(prevProps) {
        if (this.props.applications !== prevProps.applications) {
            if (this.props.applications.draft || this.props.applications.inProgress || this.props.applications.archive) {
                let hash = this.props.location && this.props.location.hash;
                let tabsMenu = [];

                if (this.props.applications.draft.length) {
                    tabsMenu.push({
                        key: 'drafts',
                        tab: 0,
                        content:
                            <span>{this.context.translate('Неотправленные')}<sup>({this.props.applications.draft.length})</sup></span>
                    });
                }
                if (this.props.applications.inProgress.length) {
                    tabsMenu.push({
                        key: 'progress',
                        tab: 1,
                        content:
                            <span>{this.context.translate('В работе')}<sup>({this.props.applications.inProgress.length})</sup></span>
                    });
                    if (!this.props.applications.draft.length) {
                        this.setState({ activeTab: 1 });
                    }
                }

                if (this.props.applications.archive.length || this.props.applications.archive.message) {
                    tabsMenu.push({
                        key: 'archive',
                        tab: 2,
                        content:
                            <span>{this.context.translate('Архив')}<sup>({this.props.applications.archive.pagination && this.props.applications.archive.pagination.totalCount})</sup></span>
                    });
                    if (!this.props.applications.draft.length && !this.props.applications.inProgress.length) {
                        this.setState({ activeTab: 2 });
                    }
                }

                this.setState({ tabsMenu });

                // если в адресной строке есть нужный hash, то переключается на нужный таб
                if (hash === "#drafts") {
                    if ((this.props.applications.draft.length > 0 && this.state.activeTab !== 0)) {
                        this.setState({
                            activeTab: 0
                        });
                    }
                    this.removeHash();
                }
                if (hash === "#progress") {
                    this.setState({
                        activeTab: 1
                    });
                    this.removeHash();
                }
                if (hash === "#archive") {
                    if (this.state.activeTab !== 2) {
                        this.setState({
                            activeTab: 2
                        });
                    }
                    this.removeHash();
                }
            }
        }
        if(
            (this.props.applications.draftIsOk !== prevProps.applications.draftIsOk) ||
            (this.props.applications.inProgressIsOk !== prevProps.applications.inProgressIsOk) ||
            (this.props.applications.archiveIsOk !== prevProps.applications.archiveIsOk)
        ) {
            if(this.props.applications.draftIsOk && this.props.applications.inProgressIsOk && this.props.applications.archiveIsOk) {
                console.log('Все типы заявок загружены')
                
                const code = getQueryParam('code');
                const url = window.location.href.includes('/applications?code');
                console.log('Получаем code и проверяем url')
                console.log('code: ' + code)
                console.log('url includes /applications?code: ' + url)
                // после авторизации в КОНТУРЕ
                // находясь на странице "Заявки" ищем в адресной строке квери параметр "code" после отправляем запрос
                if (url && code) {
                    console.log('Успешная проверка code и url')
                    this.props.continuationApplicationDiadocAction('newApplication', code);
                }
            }
        }
        if((this.props.diadocState.sendOpenId !== prevProps.diadocState.sendOpenId) && this.props.diadocState.sendOpenId.isSuccessful) {
            console.log('удаляем параметры')
            setTimeout(() => deleteQueryParam('code'), 1000);
            setTimeout(() => deleteQueryParam('scope'), 1100);
            setTimeout(() => deleteQueryParam('state'), 1200);
            setTimeout(() => deleteQueryParam('session_state'), 1300);
        }
    }

    removeHash = () => {
        window.history.replaceState(null, null, window.location.pathname);
    };

    archiveSearch = (params = null) => {
        this.props.applicationsList('archive', params);
    };

    searchFieldsSave = (fields) => {
        this.setState({ fields });
    };

    toTab = (num) => {
        return (e) => {
            e.preventDefault();
            this.setState({ activeTab: num });
        }
    };

    renderEmpty = () => {
        return <div className="page-message">
            <div className="page-message__main">
                <div className="page-message__img"><img src={noApps} /></div>
                <span className="page-message__title">{this.context.translate('У вас нет заявок')}</span>
                <div className="page-message__text">
                    <p>{this.context.translate('Пожалуйста, создайте новую заявку на лизинг и она автоматически появится на этой странице')}</p>
                </div>
                <div className="page-message__buttons">
                    <Link className="btn btn_orange hidden-tablet hidden-mobile" to="/applications/create">{this.context.translate('Отправить заявку')}</Link>
                    <Link className="btn btn_orange hidden-desktop" to="/applications/create">{this.context.translate('Создать заявку')}</Link>
                </div>
            </div>
        </div>
    };

    getOrderInfo = (order) => {
        // сохраняем заказ на случай, если придется вначале войти в диадок
        localStorage.setItem('choosedOrderByList', JSON.stringify(order))
        
        this.setState({choosedOrderByList: order})
        this.props.viewApplication(order.ApplicationId)
    }

    renderModal = () => {
        const {
            modalDiadoc,
            disableBtnSignAction,
            toggleModalDiadocAction,
            selectedBoxIdAction,
            openIdSettings,
            diadocState,
            continuationAfterCompanySelectionApplicationDiadocAction,
            startCertificateUserSignatureAction,
            pdfApplicationFormAction,
            companies,
        } = this.props;

        switch (modalDiadoc) {
            // ДЛЯ ПЕРВОГО КЕЙСА
            case 'diadoc_login':
                return (
                    <Modal modalSize="l" classNameContent="modal modal_visible" onClickBackground={() => toggleModalDiadocAction('')}>
                        <DiadocLogin
                            close={() => toggleModalDiadocAction('')}
                            openIdSettings={openIdSettings}
                            pdfApplicationFormAction={pdfApplicationFormAction}
                            detailApplication={this.props.detailApplication}
                        />
                    </Modal>
                )
            case 'diadoc_company_choice':
                return (
                    <Modal modalSize="l" classNameContent="modal modal_visible" onClickBackground={() => toggleModalDiadocAction('')}>
                        <DiadocCompanyChoice
                            boxesList={diadocState && diadocState.boxesList}
                            continuationAfterCompanySelectionApplicationDiadocAction={continuationAfterCompanySelectionApplicationDiadocAction}
                            close={() => toggleModalDiadocAction('')}
                            selectedBoxIdAction={selectedBoxIdAction}
                        />
                    </Modal>
                )
            case 'diadoc_something_went_wrong':
                return (
                    <Modal modalSize="m" classNameContent="modal modal_visible" onClickBackground={() => toggleModalDiadocAction('')}>
                        <DiadocSomethingWentWrong 
                            close={() => toggleModalDiadocAction('')}
                            text={`${diadocState && diadocState.error}`}
                            pdfApplicationFormAction={pdfApplicationFormAction}
                        />
                    </Modal>
                )
            case 'diadoc_enter_from_admin_user':
                return (
                  <Modal modalSize="m" classNameContent="modal modal_visible" onClickBackground={() => toggleModalDiadocAction('')}>
                      <DiadocSomethingWentWrong
                        close={() => toggleModalDiadocAction('')}
                        text='Подписание, отправка и отклонение документов по ЭДО недоступны при авторизации под пользователем'
                        typePDF=""
                      />
                  </Modal>
                )
            case 'diadoc_application_signing':
                return (
                    <Modal modalSize="l" classNameContent="modal modal_visible" onClickBackground={() => toggleModalDiadocAction('')}>
                        <DiadocApplicationSigning
                            type="first"
                            userCertificate={diadocState && diadocState.userCertificate}
                            disableBtnSign={diadocState && diadocState.disableBtnSign}
                            close={() => toggleModalDiadocAction('')}
                            startCertificateUserSignatureAction={startCertificateUserSignatureAction}
                            disableBtnSignAction={disableBtnSignAction}
                            detailApplication={this.props.detailApplication}
                            companies={companies}
                        />
                    </Modal>
                )
            case 'diadoc_something_went_wrong_second':
                return (
                    <Modal modalSize="m" classNameContent="modal modal_visible" onClickBackground={() => toggleModalDiadocAction('')}>
                        <DiadocSomethingWentWrong 
                            close={() => toggleModalDiadocAction('')}
                            openPopupDiadocCompanyChoice={() => toggleModalDiadocAction('diadoc_company_choice')}
                            type="second"
                            text={`${diadocState && diadocState.error}`}
                            pdfApplicationFormAction={pdfApplicationFormAction}
                        />
                    </Modal>
                )
            case 'diadoc_application_signing_second':
                return (
                    <Modal modalSize="l" classNameContent="modal modal_visible" onClickBackground={() => toggleModalDiadocAction('')}>
                        <DiadocApplicationSigning
                            userCertificate={diadocState && diadocState.userCertificate}
                            disableBtnSign={diadocState && diadocState.disableBtnSign}
                            close={() => toggleModalDiadocAction('')}
                            type="second"
                            back={() => toggleModalDiadocAction('diadoc_company_choice')}
                            startCertificateUserSignatureAction={startCertificateUserSignatureAction}
                            disableBtnSignAction={disableBtnSignAction}
                            detailApplication={this.props.detailApplication}
                            companies={companies}
                            choosedOrderByList={this.state.choosedOrderByList}
                        />
                    </Modal>
                )
            case 'diadoc_docoment_signed':

                // удаляем сохраненные данные с localStorage 
                localStorage.removeItem('choosedOrderByList')
                localStorage.removeItem('detailApplication')

                return (
                    <Modal modalSize="m" classNameContent="modal modal_visible" onClickBackground={() => toggleModalDiadocAction('')}>
                        <DiadocDocumentSigned
                            close={() => toggleModalDiadocAction('')}
                            typePDF="application"
                        />
                    </Modal>
                )
            case 'diadoc_something_went_wrong_third':
                return (
                    <Modal modalSize="m" classNameContent="modal modal_visible" onClickBackground={() => toggleModalDiadocAction('')}>
                        <DiadocSomethingWentWrong
                            close={() => toggleModalDiadocAction('')}
                            text={`${diadocState && diadocState.error}`}
                            pdfApplicationFormAction={pdfApplicationFormAction}
                        />
                    </Modal>
                )
            
            default:
                return null;
        }
    }



    render() {

        let {
            applications,
            isOk,

            sendApplication,
            recallApplication,
            requestApplication,
            resumeApplication,

            catalog,
            getCatalog,

            fileBuffer,
            fileParams,

            addApplicationDocument,
            removeApplicationDocument,
            updateApplicationDocument,
            cancelApplicationDocument,
            clearBuffer,
            pdfApplicationFormAction,
            fileSuccess,
            toggleModalDiadocAction,
            startApplicationDiadocAction,
            companies,
        } = this.props;

        return (
            <div className="page">
                <div className="page__main">
                    <h1 className="page__title">{this.context.translate('Заявки')}</h1>
                    {this.state.tabsMenu.length ? <Fragment>
                        <div className='tabs__menu'>
                            {this.state.tabsMenu.map((menuItem, key) =>
                                <Link key={key} to={"#"} className={"tabs__menu-link" + (this.state.activeTab === menuItem.tab ? " active" : "")} onClick={this.toTab(menuItem.tab)}>{menuItem.content}</Link>
                            )}
                        </div>
                        {this.state.activeTab === 0 ?
                            <OrdersListDraft list={applications.draft}
                                sendApplication={sendApplication}

                                fileBuffer={fileBuffer}
                                fileParams={fileParams}

                                addApplicationDocument={addApplicationDocument}
                                removeApplicationDocument={removeApplicationDocument}
                                updateApplicationDocument={updateApplicationDocument}
                                cancelApplicationDocument={cancelApplicationDocument}
                                clearBuffer={clearBuffer}
                                documentTypes={applications.documentTypes}
                                fileSuccess={fileSuccess}
                            /> : null
                        }

                        {this.state.activeTab === 1 ?
                            <OrdersListProgress list={applications.inProgress}
                                recallApplication={recallApplication}
                                requestApplication={requestApplication}
                                isActionOk={applications.isActionOk}
                                modalBtnIsDisabled={applications.modalBtnIsDisabled}
                                actionMessage={applications.actionMessage}

                                fileBuffer={fileBuffer}
                                fileParams={fileParams}

                                addApplicationDocument={addApplicationDocument}
                                removeApplicationDocument={removeApplicationDocument}
                                updateApplicationDocument={updateApplicationDocument}
                                cancelApplicationDocument={cancelApplicationDocument}
                                clearBuffer={clearBuffer}
                                documentTypes={applications.documentTypes}
                                pdfApplicationFormAction={pdfApplicationFormAction}
                                fileSuccess={fileSuccess}
                                startApplicationDiadocAction={startApplicationDiadocAction}
                                writeOrderFromList={this.getOrderInfo}
                                openModalDiadocFromAdminError={toggleModalDiadocAction}
                            /> : null
                        }

                        {this.state.activeTab === 2 ?
                            <OrdersListArchive list={applications.archive}
                                sendApplication={sendApplication}
                                resumeApplication={resumeApplication}
                                isActionOk={applications.isActionOk}
                                actionMessage={applications.actionMessage}

                                catalog={catalog}
                                getCatalog={getCatalog}

                                search={this.archiveSearch}
                                searchFields={this.state.fields}
                                searchFieldsSave={this.searchFieldsSave}

                                fileBuffer={fileBuffer}
                                fileParams={fileParams}

                                addApplicationDocument={addApplicationDocument}
                                removeApplicationDocument={removeApplicationDocument}
                                updateApplicationDocument={updateApplicationDocument}
                                cancelApplicationDocument={cancelApplicationDocument}
                                clearBuffer={clearBuffer}
                                documentTypes={applications.documentTypes}
                            /> : null
                        }
                    </Fragment> : this.renderEmpty()}
                </div>
                {isOk ? null : <Preloader />}

                {this.renderModal()}
            </div>
        )
    }
}

export default connect(
    state => ({
        applications: state.applications,
        isOk: state.applications.draft.isOk && state.applications.inProgress.isOk && state.applications.archive.isOk,

        catalog: state.catalog,

        fileBuffer: state.fileBuffer,
        fileParams: state.catalog && state.catalog.fileParams,
        fileSuccess: state.help.fileSuccess,
        modalDiadoc: state.diadocReducer.modalDiadoc,
        openIdSettings: state.diadocReducer.openIdSettings,
        diadocState: state.diadocReducer,
        detailApplication: state.detailApplication,
        companies: state.catalog.companies,
    }),
    (dispatch) => {
        return {
            applicationsList: (type, params) => dispatch(applicationsList(type, params)),
            sendApplication: (id) => dispatch(sendApplication(id)),
            recallApplication: (id, reason) => dispatch(recallApplication(id, reason)),
            requestApplication: (id, description) => dispatch(requestApplication(id, description)),
            resumeApplication: (id, description) => dispatch(resumeApplication(id, description)),

            archiveSearchInfo: () => dispatch(archiveSearchInfo()),
            getCatalog: (type, query) => dispatch(autoComplete(type, query)),

            addApplicationDocument: (id, file) => dispatch(addFile(id, file)),
            removeApplicationDocument: (id, fileId) => dispatch(deleteFile(id, fileId)),
            updateApplicationDocument: (id, fileId, fileName) => dispatch(updateFile(id, fileId, fileName)),
            cancelApplicationDocument: (id) => dispatch(cancelFile(id)),
            pdfApplicationFormAction: (id) => dispatch(pdfApplicationFormAction(id)),
            clearBuffer: () => dispatch(clearBuffer()),
            disableBtnSignAction: (data) => dispatch(disableBtnSignAction(data)),
            toggleModalDiadocAction: (data) => dispatch(toggleModalDiadocAction(data)),
            selectedBoxIdAction: (data) => dispatch(selectedBoxIdAction(data)),
            startApplicationDiadocAction: (typeApplication, id) => dispatch(startApplicationDiadocAction(typeApplication, id)),
            continuationApplicationDiadocAction: (typeApplication, data) => dispatch(continuationApplicationDiadocAction(typeApplication, data)),
            continuationAfterCompanySelectionApplicationDiadocAction: (data) => dispatch(continuationAfterCompanySelectionApplicationDiadocAction(data)),
            startCertificateUserSignatureAction: (data) => dispatch(startCertificateUserSignatureAction(data)),
            viewApplication: (id) => dispatch(viewApplication(id)),
        }
    }
)(OrdersContainer);