import React,{Component} from "react";
import {NavLink, Redirect, Link} from "react-router-dom";
import PropTypes from "prop-types";
import DealINotification from "../../components/deals/DealINotification";

import noApps from "../../../img/no_apps.svg";

export default class DealsList extends Component{

    constructor(props, context){
        super(props, context);

        var tabsMenu = [];
        

        if (props.overdue.length && props.overdue.pagination) {
            tabsMenu.push({
                key: 'overdue',
                content: <span>
                    {context.translate('Просроченные платежи и штрафы')}
                    <sup>({props.overdue.pagination.totalCount})</sup>
                </span>});
        }
        if (props.contracts.length && props.contracts.pagination) {
            tabsMenu.push({
                key: 'contracts',
                content: <span>
                    {context.translate('Одобренные договоры лизинга')}
                    <sup>({props.contracts.pagination.totalCount})</sup>
                </span>});
        }

        if (props.payments.length && props.payments.pagination) {
            tabsMenu.push({
                key: 'payments',
                content: <span>
                    {context.translate('Приближающиеся платежи')}
                    <sup>({props.payments.pagination.totalCount})</sup>
                </span>});
        }

        if (props.consideration.length && props.consideration.pagination) {
            tabsMenu.push({
                key: 'consideration',
                content: <span>
                    {context.translate('Заявки на рассмотрении')}
                    <sup>({props.consideration.pagination.totalCount})</sup>
                </span>});
        }
        
        this.state = {
            tabsMenu,
            activeTab: 0,
        }
    }


    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    componentWillReceiveProps(nextProps){
        if (nextProps.overdue != this.props.overdue || nextProps.contracts != this.props.contracts || nextProps.payments != this.props.payments || nextProps.consideration != this.props.consideration) {
            var tabsMenu = [];

            if (nextProps.overdue.length && nextProps.overdue.pagination) {
                tabsMenu.push({
                    key: 'overdue',
                    content: <span>
                        {this.context.translate('Просроченные платежи и штрафы')}
                        <sup>({nextProps.overdue.pagination.totalCount})</sup>
                    </span>});
            }
            if (nextProps.contracts.length && nextProps.contracts.pagination) {
                tabsMenu.push({
                    key: 'contracts',
                    content: <span>
                        {this.context.translate('Одобренные договоры лизинга')}
                        <sup>({nextProps.contracts.pagination.totalCount})</sup>
                    </span>});
            }

            if (nextProps.payments.length && nextProps.payments.pagination) {
                tabsMenu.push({
                    key: 'payments',
                    content: <span>
                        {this.context.translate('Приближающиеся платежи')}
                        <sup>({nextProps.payments.pagination.totalCount})</sup>
                    </span>});
            }

            if (nextProps.consideration.length && nextProps.consideration.pagination) {
                tabsMenu.push({
                    key: 'consideration',
                    content: <span>
                        {this.context.translate('Заявки на рассмотрении')}
                        <sup>({nextProps.consideration.pagination.totalCount})</sup>
                    </span>});
            }

            this.setState({tabsMenu});
        }
    }

    renderEmpty = () => {
        return <div className="page-message">
            <div className="page-message__main">
                <div className="page-message__img"><img src={noApps} /></div>
                <span className="page-message__title">{this.context.translate('У вас пока нет текущих дел')}</span>
                <div className="page-message__text">
                    <p>{this.context.translate('Они появятся, когда будет одобрен наш первый совместный проект')}</p>
                </div>
                <div className="page-message__buttons">
                    <Link className="btn btn_orange hidden-tablet hidden-mobile" to="/applications/create">{this.context.translate('Отправить заявку')}</Link>
                    <Link className="btn btn_orange hidden-desktop" to="/applications/create">{this.context.translate('Создать заявку')}</Link>
                </div>
            </div>
        </div>
    };

    toTab = (num) => {
        return () => {
            this.props.history.push("/deals/"+this.state.tabsMenu[num].key);
            this.setState({activeTab: num})
        }
    }

    render(){
        let {
            tabsMenu,
            activeTab
        } = this.state;

        if (tabsMenu.length && (location.pathname == '/deals' || location.pathname == '/users/frontend/deals')) {
            return <Redirect to={"/deals/"+tabsMenu[0].key}/>
        }

        return (tabsMenu.length ?
                <div className="page__tabs page__tabs_adapt_events">
                    <div className="tabs__menu">
                        {/* <div className="tabs__menu-prev" onClick={this.toTab(activeTab - 1 < 0 ? tabsMenu.length - 1 : activeTab - 1)}/> */}
                        {/* <div className="tabs__menu-next" onClick={this.toTab(activeTab + 1 < tabsMenu.length ? activeTab + 1 : 0)}/> */}
                        {tabsMenu.map((menuItem, key) =>
                            <NavLink
                                exact
                                key={menuItem.key}
                                to={"/deals/"+menuItem.key}
                                className={"tabs__menu-link"}
                                activeClassName={"active"}
                                onClick={() => {this.setState({activeTab: key})}}
                            >
                                {menuItem.content}
                            </NavLink>
                        )}
                    </div>
                    <div className="page__tabs-main">
                        {this.props.children}
                    </div>
                </div>
            : (this.props.isOk ? this.renderEmpty() : null)
        )
    }

}