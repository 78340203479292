import React, {Component} from "react";
import Form from "../common/form/Form";
import FormField from "../common/form/FormField";
import PropTypes from "prop-types";

export default class OrdersSearch extends Component{
    constructor(props){
        super(props);

        var fields = this.props.data.fields;

        this.state = {
            EquipmentName: {
                type: 'auto-complete',
                label: "Название предмета лизинга",
                placeholder: "Введите название или его часть",
                value: fields.EquipmentName || "",
                options: props.catalog && props.catalog.archiveEquipmentNames || [],
                optionsType: 'archiveEquipmentNames',
                getOptions: props.getCatalog
            },
            LesseeId: {
                type: 'select',
                label: "Компания-лизингополучатель",
                placeholder: "Укажите компанию",
                value: props.catalog && props.catalog.leaseCompanies && props.catalog.leaseCompanies.length == 1 ? props.catalog.leaseCompanies[0].value : (fields.LesseeId ? fields.LesseeId : ""),
                options: props.catalog && props.catalog.leaseCompanies || [],
                optionsType: 'leaseCompanies',
                allowEmpty: true,
                allowSearch: true,
                hidden: !props.catalog || !props.catalog.companies || props.catalog.companies.length == 1 || !props.catalog.leaseCompanies || props.catalog.leaseCompanies.length == 0,
                disabled: props.catalog && props.catalog.leaseCompanies && props.catalog.leaseCompanies.length == 1
            },
            EquipmentBrandName: {
                type: 'auto-complete',
                label: "Производитель (торговая марка)",
                placeholder: "Укажите производителя",
                value: fields.EquipmentBrandName || "",
                options: props.catalog && props.catalog.archiveEquipmentBrands || [],
                optionsType: 'archiveEquipmentBrands',
                hidden: !props.catalog || !props.catalog.archiveEquipmentBrands || props.catalog.archiveEquipmentBrands.length <= 1,
                fieldClassName: "datepicker__field"
            },
            date: {
                type: 'date-range',
                label: "Дата заявки",
                value: fields.date || "",
                showPeriods: true,
                fieldClassName: "datepicker__field",
                yearSelection: true,
                canWrite: true,
            },
            ApplicationStatusId: {
                type: 'select',
                label: "Статус заявки",
                value: fields.ApplicationStatusId || "",
                options: props.catalog && props.catalog.statuses || [],
                optionsType: 'statuses',
                allowEmpty: true,
                allowSearch: true,
                hidden: !props.catalog || !props.catalog.statuses || props.catalog.statuses.length <= 1,
                fieldClassName: "datepicker__field"
            },
            ContractCurrencyId: {
                type: 'select',
                label: "Валюта договора лизинга",
                value: fields.ContractCurrencyId || [],
                options: props.catalog && props.catalog.archiveCurrencies || [],
                optionsType: 'archiveCurrencies',
                allowEmpty: true,
                hidden: !props.catalog || !props.catalog.archiveCurrencies || props.catalog.archiveCurrencies.length <= 1,
                fieldClassName: "datepicker__field"
            },

        }
    }

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    addCatalogOptions = (state, catalog) => {
        for (var key in state) {
            if (state[key].hasOwnProperty('optionsType') && catalog[state[key].optionsType] && state[key].options != catalog[state[key].optionsType]) {
                state[key].options = catalog[state[key].optionsType];

                if (key == 'LesseeId') {
                    if (!catalog.companies || catalog.companies.length == 1 || !catalog.leaseCompanies || catalog.leaseCompanies.length == 0) {
                        state.LesseeId.value = this.props.data.fields.LesseeId || "";
                        state.LesseeId.disabled = false;
                        state.LesseeId.hidden = true;
                    } else if (catalog.leaseCompanies.length == 1) {
                        state.LesseeId.value = catalog.leaseCompanies[0].value;
                        state.LesseeId.disabled = true;
                        state.LesseeId.hidden = false;
                    } else {
                        state.LesseeId.value = this.props.data.fields.LesseeId || "";
                        state.LesseeId.disabled = false;
                        state.LesseeId.hidden = false;
                    }
                } else if (state[key].type == 'select') {
                    state[key].hidden = catalog[state[key].optionsType].length <= 1;
                }
            }

        }
        return state;
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.catalog != this.props.catalog) {
            var state = {...this.state};
            state = this.addCatalogOptions(state, nextProps.catalog);
            this.setState(state);
        }
    }

    onChangeField = (attribute, value) => {
        var state = {...this.state};
        if (state.hasOwnProperty(attribute)) {
            state[attribute].value = value;
            this.setState(state);
            this.props.onChange(attribute, value);
        }
    };

    renderField = (attribute) => {
        var fields = this.state;

         return fields.hasOwnProperty(attribute) && !fields[attribute].hidden ?
             <FormField fieldConfig={{
                 ...fields[attribute],
                 label: this.context.translate(fields[attribute].label),
                 placeholder: this.context.translate(fields[attribute].placeholder)
             }}
                   attribute={attribute}
                   onChange={this.onChangeField}
                   className={fields[attribute].fieldClassName || ""}
             /> : null;
    };

    onReset = () => {
        var state = {...this.state};
        for (var attribute in state) {
            state[attribute].value = '';
        }
        this.props.onReset();
        this.setState({state});
    };

    render(){

        return <Form onSubmit={this.props.onSubmit} validate={false}>
            <div className="form__row">
                {this.renderField('EquipmentName')}
            </div>
            <div className="form__row">
                {this.renderField('LesseeId')}
            </div>
            <div className="form__row">
                {this.renderField('EquipmentBrandName')}
                {this.renderField('date')}
            </div>
            <div className="form__row">
                {this.renderField('ApplicationStatusId')}
                {this.renderField('ContractCurrencyId')}
            </div>
            <div className="form__buttons">
                <button className="btn" type="submit">{this.context.translate('Найти')}</button>
                <button className="btn btn_underline" type="reset" onClick={this.onReset}>{this.context.translate('Сбросить')}</button>
            </div>
        </Form>
    }
}