import React, { Component } from "react";
import { Link } from "react-router-dom";
import FileIcon from "../common/FileIcon";
import { formatDate, optionsMonthYearNumeric } from "../../tools/tools";

export default class DocumentsTableItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showMore: false
        }
    }


    toggleShowMore = () => {
        this.setState((prevState) => {
            return {
                showMore: !prevState.showMore
            }
        })
    }

    render() {
        var item = this.props.item;
        let showMore = this.state.showMore;

        const {
            direction,
            startDocumentsDiadocAction,
            selectedDocumentAction,
        } = this.props;

        return (
            <div className="default-table__row">
                <div className="default-table__td">
                    <div className="default-table__inner-label">{this.props.labels[0]}</div>
                    {(item.source === 'Diadoc')
                        && (
                            <div className="default-table__wrap_value">
                                <a
                                    className="default-table__value important_color_link"
                                    style={{ color: `${item.color || ''}` }}
                                    href={item.uiUri}
                                    target="_blank"
                                >
                                    {item.title}
                                </a>
                                <div className="default-table__tooltip">
                                    Кликните на документ, чтобы перейти
                                    <br></br> в Диадок для дальнейшей работы
                                </div>
                            </div>
                        )}

                    {(item.source === 'Internal')
                        && (
                            <div className="default-table__wrap_value">
                                <div className="default-table__value">
                                    {item.title}
                                </div>
                            </div>
                        )}
                </div>

                <div className={`default-table__td${showMore ? "" : " default-table__td_more-item"}`}>
                    <div className="default-table__inner-label">{this.props.labels[1]}</div>
                    <div className="default-table__value">{item.lesseeName}</div>
                </div>
                <div className={`default-table__td${showMore ? "" : " default-table__td_more-item"}`}>
                    <div className="default-table__inner-label">{this.props.labels[2]}</div>
                    <div className="default-table__value important_color_link">
                        <a
                            className="default-table__value important_color_link"
                            href={`/projects/dfa/${item.referTo}`}
                            target="_blank"
                        >
                            {item.referToText}
                        </a>
                    </div>
                </div>
                <div className="default-table__td">
                    <div className="default-table__inner-label">{this.props.labels[3]}</div>
                    <div className="default-table__wrap_value">
                        {(direction === "Inbound" && (item && item.isHighlighted))
                            && (
                                <div className="default-table__circle_value"></div>
                            )}
                        <div className="default-table__value"><nobr>{item.statusText}</nobr></div>
                    </div>
                    
                    
                    {(((item && item.status) === 'SignatureRequired') && (item && item.isHighlighted))
                        && (
                            <div className="default-table__wrap_signature_required_btns">
                                <button
                                    onClick={() => startDocumentsDiadocAction('sign', item)}
                                    className={"btn mini_btn"}
                                >
                                    Подписать
                                </button>
                                
                                <button
                                    onClick={() => startDocumentsDiadocAction('reject', item)}
                                    className={"btn btn_white mini_btn"}
                                >
                                    Отклонить
                                </button>
                            </div>
                        )}
                </div>
                <div className="default-table__td default-table__td_align-right">
                    <div className="default-table__inner-label">{this.props.labels[4]}</div>
                    <div className="default-table__value">{formatDate(item.messageDate, optionsMonthYearNumeric)}</div>
                </div>
                <div className={`default-table__td default-table__td_align-center default-table__td_mobile-inline${showMore ? "" : " default-table__td_more-item"}`}>
                    <a href="#" onClick={(e) => { e.preventDefault(); this.props.downloadDoc(item.id, item.title) }}>
                        <div className="default-table__inner-label">Скачать файл</div>
                        <div className="default-table__value">
                            <FileIcon ext={"pdf"} />
                        </div>
                    </a>
                </div>
                <div className="default-table__more-toggle" onClick={this.toggleShowMore}>
                    <span className={`u-more-link ${showMore ? 'active' : ''}`}>
                        <span>{showMore ? 'Скрыть' : 'Подробнее'}</span>
                    </span>
                </div>
            </div>
        )
    }
}