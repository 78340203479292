import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export default class CompaniesAgreement extends Component {

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render() {
        let { catalog, fields } = this.props;

        // в блоке "Компания-лизингополучатель" ищем в списке компанию которую выбрали
        const findChosenCompany = catalog && catalog.leaseCompanies && catalog.leaseCompanies.find((i) => i.value === (fields && fields.LesseeId));
        const findOwnershipId = findChosenCompany && findChosenCompany.ownership_id;

        // определяет ИП это или ЮР лицо
        const isCheckFace = (+findOwnershipId === 4 || +findOwnershipId === 8 || +findOwnershipId === 9) || (fields && (+fields.LesseeOwnershipId === 4 || +fields.LesseeOwnershipId === 8 || +fields.LesseeOwnershipId === 9));

        return (
            <div className="companies_agreement">
                <h3 className="companies_agreement__title">
                    Соглашение
                </h3>

                <div className="companies_agreement__wrap_text">
                    {!isCheckFace
                        && (
                            // для ЮР лица
                            <div className="companies_agreement__text">
                                <span>
                                    <b className="companies_agreement__text_bold">Подпись лица в настоящей Заявке, действующего от имени Заявителя, подтверждает заверение о том, что:</b>
                                </span>

                                <span>
                                    1) вся представленная в Заявке (включая Приложения) информация является достоверной и полной, никакая информация, 
                                    не утаена и не искажена. Заявитель уведомлен и полностью согласен с тем, что обнаружение скрытой или ложной информации 
                                    является достаточным основанием для прекращения процесса рассмотрения Заявки на любом из его этапов;
                                </span>

                                <span>
                                    2) для Заявителя сделка (взаимосвязанные сделки), на заключение которой(ых) подается настоящая Заявка, в соответствии 
                                    с действующим законодательством не требует(ют) согласия на ее(их) совершение, поскольку <b className="companies_agreement__text_bold">не выходит(ят) 
                                    за пределы обычной хозяйственной деятельности и не относится(ятся) к крупным сделкам или сделкам</b>, на совершение которых Уставом требуется 
                                    согласие соответствующих органов управления общества. 
                                </span>

                                <span>
                                    3) Заявителем (являющимся оператором персональных данных в терминологии Федерального закона от 27.07.2006 №152-ФЗ «О защите персональных данных»):
                                </span>

                                <div className="companies_agreement__wrap_item">
                                    <div className="companies_agreement__item_line"></div>
                                    <div className="companies_agreement__item_text">
                                        получены согласия на обработку персональных данных (включая их передачу в нижеуказанных целях) физическими лицами, 
                                        указанным в настоящей Заявке, и являющимся участниками/акционерами, членами органов управления либо представителями 
                                        Заявителя (далее по тексту – Представители); 
                                    </div>
                                </div>

                                <div className="companies_agreement__wrap_item">
                                    <div className="companies_agreement__item_line"></div>
                                    <div className="companies_agreement__item_text">
                                        что Представители надлежащим образом уведомлены Заявителем об осуществлении Обществом с ограниченной ответственностью 
                                        «ДельтаЛизинг», ОГРН 1112536016801, г. Владивосток, пер. Ланинский, зд. 2 (далее по тексту – Компания) 
                                        обработки их персональных данных и им предоставлена информация, предусмотренная п.3 ст. 18 Федерального закона 
                                        от 27.07.2006 №152-ФЗ «О защите персональных данных», а также указанная в настоящей Заявке;
                                    </div>
                                </div>

                                <div className="companies_agreement__wrap_item">
                                    <div className="companies_agreement__item_line"></div>
                                    <div className="companies_agreement__item_text">
                                        что Представителям разъяснено, что полный или частичный отзыв их согласия, а также прекращение предусмотренной 
                                        согласием обработки персональных данных могут привести к невозможности достижения предусмотренной согласием цели 
                                        обработки персональных данных.
                                    </div>
                                </div>

                                <span>
                                    <b className="companies_agreement__text_bold">Целью</b> обработки персональных данных являются: определение возможности заключения с Заявителем сделок (договора(ов) лизинга, 
                                    купли-продажи и др.), их заключение, исполнение, изменение, прекращение, включая: осуществление Компанией прав 
                                    и обязанностей, связанных с исполнением требований законодательства и иных нормативных актов, в том числе, 
                                    корпоративных стандартов принятия мер должной осмотрительности и проверки полноты и достоверности 
                                    предоставленных сведений; установление и поддержание делового общения, а также защиту прав и законных интересов. 
                                </span>

                                <span>
                                    Согласие распространяется на следующие персональные данные: фамилия, имя, отчество, дата и место рождения, паспортные данные, 
                                    адреса регистрации и места жительства, контактные телефоны, адреса электронной почты, ИНН и иные данные, которые были 
                                    (будут) переданы Компании Заявителем или поступили (поступят) Компании иным способом. 
                                </span>

                                <span>
                                    Компания может проверить достоверность предоставленных персональных данных, в том числе с использованием услуг других 
                                    операторов, а также использовать полученную информацию при рассмотрении вопросов о заключении новых сделок.  
                                </span>

                                <span>
                                    Согласие предоставляется на осуществление любых действий, допустимых согласно законодательству и которые необходимы 
                                    или желаемы для достижения указанных выше целей, включая: сбор, систематизацию, хранение, уточнение (обновление, изменение), 
                                    использование, передачу (предоставление, доступ), обезличивание, блокирование, уничтожение; с использованием и без 
                                    использования средств автоматизации как способов обработки персональных данных. При этом Компания вправе передавать 
                                    персональные данные Представителей третьим лицам в целях выполнения требований законодательства, договорных обязательств 
                                    перед Заявителем, а также для достижения иных целей данного согласия, в необходимом объеме и с соблюдением требований 
                                    законодательства. В таких случаях согласие считается данным указанным выше третьим лицам, и такие третьи лица имеют 
                                    право на обработку персональных данных на основании настоящего согласия.   
                                </span>

                                <span>
                                    Согласие действует до истечения трех лет со дня прекращения отношений Заявителя с Компанией. Согласие может быть отозвано 
                                    в любой момент путем направления обращения в письменной форме по адресу Компании либо по электронному 
                                    адресу: <a href="mailto:data.privacy.ru@deltaleasing.ru">data.privacy.ru@deltaleasing.ru</a>. В случае отзыва настоящего согласия Компания будет вправе продолжить обработку 
                                    всех или части персональных данных в случаях, установленных законодательством .
                                </span>

                                <span>
                                    Заявитель осознает, что Компания при заключении сделки(ок) будет исходить из указанных выше заверений, данных согласно ст. 431.2 Гражданского кодекса РФ. 
                                </span>

                                <span>
                                    <b className="companies_agreement__text_bold">Подпись лица в настоящей Заявке, действующего от имени Заявителя, подтверждает предоставление Обществу с ограниченной 
                                    ответственностью «ДельтаЛизинг», ОГРН 1112536016801, г. Владивосток, пер. Ланинский, зд. 2:</b>
                                </span>

                                <span>
                                    1) <b className="companies_agreement__text_bold">согласия на получение информации о Заявителе (кредитных отчетов)</b> в бюро кредитных историй с целью проверки благонадежности, 
                                    а также на получение сведений о среднемесячных платежах и индивидуального рейтинга (скоринговой оценки) в порядке, 
                                    установленном Федеральным законом «О кредитных историях» от 30.12.2004 № 218-ФЗ; 
                                </span>

                                <span>
                                    2) <b className="companies_agreement__text_bold">согласия на оценку кредитоспособности</b> (скоринговой оценки) в АО «Объединенное Кредитное Бюро» в рамках оказываемой 
                                    им услуги «Бизнес скоринг СПАРК-ОКБ» и предоставление ее результатов в виде скорингового отчета;
                                </span>

                                {fields && fields.IsMailing == (true || '1')
                                    && (
                                        <span>
                                            3) <b className="companies_agreement__text_bold">согласия на получение автоматических рассылок</b> (автоматической передачи текстовых и иных сообщений) информационного 
                                            и (или) рекламного характера по сетям электрической и радиотелефонной (в том числе подвижной) связи по адресам электронной 
                                            почты и на номера телефонов (в том числе мобильных), распространителем (заказчиком) которых является Компания, 
                                        </span>
                                    )}
                            </div>
                        )}

                    {isCheckFace
                        && (
                            // для ИП
                            <div className="companies_agreement__text">
                                <span>
                                    <b className="companies_agreement__text_bold">Заявитель, подписывая настоящую Заявку лично (или действуя через представителя), подтверждает заверение о том, что:</b>
                                </span>

                                <span>
                                    1) вся представленная в Заявке (включая Приложения) информация является достоверной и полной, никакая информация, 
                                    не утаена и не искажена. Заявитель уведомлен и полностью согласен с тем, что обнаружение скрытой или ложной 
                                    информации является достаточным основанием для прекращения процесса рассмотрения Заявки на любом из его этапов;
                                </span>

                                <span>
                                    2) Заявителем (являющимся оператором персональных данных в терминологии Федерального закона от 27.07.2006 №152-ФЗ 
                                    «О защите персональных данных»):
                                </span>

                                <div className="companies_agreement__wrap_item">
                                    <div className="companies_agreement__item_line"></div>
                                    <div className="companies_agreement__item_text">
                                        получены согласия на обработку персональных данных (включая их передачу в нижеуказанных целях) физическими лицами, 
                                        указанным в настоящей Заявке(далее по тексту – Представители); 
                                    </div>
                                </div>

                                <div className="companies_agreement__wrap_item">
                                    <div className="companies_agreement__item_line"></div>
                                    <div className="companies_agreement__item_text">
                                        что Представители надлежащим образом уведомлены Заявителем об осуществлении Обществу с ограниченной ответственностью 
                                        «ДельтаЛизинг», ОГРН 1112536016801, г. Владивосток, пер. Ланинский, зд. 2 (далее по тексту – Компания) обработки 
                                        их персональных данных и им предоставлена информация, предусмотренная п.3 ст. 18 Федерального 
                                        закона от 27.07.2006 №152-ФЗ «О защите персональных данных», а также указанная в настоящей Заявке;
                                    </div>
                                </div>

                                <div className="companies_agreement__wrap_item">
                                    <div className="companies_agreement__item_line"></div>
                                    <div className="companies_agreement__item_text">
                                        что Представителям разъяснено, что полный или частичный отзыв их согласия, а также прекращение предусмотренной 
                                        согласием обработки персональных данных могут привести к невозможности достижения предусмотренной согласием цели 
                                        обработки персональных данных.
                                    </div>
                                </div>

                                <span>
                                    <b className="companies_agreement__text_bold">Целью</b> обработки персональных данных являются: определение возможности заключения с Заявителем сделок (договора(ов) лизинга, 
                                    купли-продажи и др.), их заключение, исполнение, изменение, прекращение, включая: осуществление Компанией прав 
                                    и обязанностей, связанных с исполнением требований законодательства и иных нормативных актов, в том числе, 
                                    корпоративных стандартов принятия мер должной осмотрительности и проверки полноты и достоверности предоставленных 
                                    сведений; установление и поддержание делового общения, а также защиту прав и законных интересов. 
                                </span>

                                <span>
                                    Согласие распространяется на следующие персональные данные: фамилия, имя, отчество, контактные телефоны, адреса электронной почты. 
                                </span>

                                <span>
                                    Согласие предоставляется на осуществление любых действий, допустимых согласно законодательству и которые необходимы или 
                                    желаемы для достижения указанных выше целей, включая: сбор, систематизацию, хранение, уточнение (обновление, изменение), 
                                    использование, передачу (предоставление, доступ), обезличивание, блокирование, уничтожение; с использованием и без 
                                    использования средств автоматизации как способов обработки персональных данных. При этом Компания вправе передавать 
                                    персональные данные 
                                </span>

                                <span>
                                    Представителей третьим лицам в целях выполнения требований законодательства, договорных обязательств перед Заявителем, 
                                    а также для достижения иных целей данного согласия, в необходимом объеме и с соблюдением требований законодательства. 
                                    В таких случаях согласие считается данным указанным выше третьим лицам, и такие третьи лица имеют право на обработку 
                                    персональных данных на основании настоящего согласия.   
                                </span>

                                <span>
                                    Согласие действует до истечения трех лет со дня прекращения отношений Заявителя с Компанией. Согласие может быть отозвано 
                                    в любой момент путем направления обращения в письменной форме по адресу Компании либо по электронному 
                                    адресу: <a href="mailto:data.privacy.ru@deltaleasing.ru">data.privacy.ru@deltaleasing.ru</a>. В случае отзыва настоящего согласия Компания будет вправе продолжить 
                                    обработку всех или части персональных данных в случаях, установленных законодательством .
                                </span>

                                <span>
                                    Заявитель осознает, что Компания при заключении сделки(ок) будет исходить из указанных выше заверений, данных согласно 
                                    ст. 431.2 Гражданского кодекса РФ. <b className="companies_agreement__text_bold">подтверждаю предоставление Обществу с ограниченной ответственностью 
                                    «ДельтаЛизинг», ОГРН 1112536016801, г. Владивосток, пер. Ланинский, зд. 2 (далее по тексту – Компания):</b>
                                </span>

                                <span>
                                    1) <b className="companies_agreement__text_bold">согласия на получение информации о Заявителе (кредитных отчетов)</b> в бюро кредитных историй с целью проверки благонадежности, 
                                    а также на получение сведений о среднемесячных платежах и индивидуального рейтинга (скоринговой оценки) в порядке, 
                                    установленном Федеральным законом «О кредитных историях» от 30.12.2004 № 218-ФЗ; 
                                </span>

                                <span>
                                    2) <b className="companies_agreement__text_bold">согласия на обработку моих (Заявителя) персональных данных</b> и подтверждаю, что, давая такое согласие, действую 
                                    своей волей и в своем интересе. 
                                </span>

                                <span>
                                    <b className="companies_agreement__text_bold">Целью</b> обработки персональных данных являются: определение возможности заключения с Заявителем сделок (договора(ов) 
                                    поручительства, залога и др.), их заключение, исполнение, изменение, прекращение, включая: осуществление Компанией 
                                    прав и обязанностей, связанных с исполнением требований законодательства и иных нормативных актов, в том числе, 
                                    корпоративных стандартов принятия мер должной осмотрительности и проверки полноты и достоверности предоставленных 
                                    сведений; установление и поддержание делового общения, а также защиту прав и законных интересов. 
                                </span>

                                <span>
                                    Согласие распространяется на следующие персональные данные: фамилия, имя, отчество, дата и место рождения, паспортные данные, 
                                    адреса регистрации и места жительства, контактные телефоны, адреса электронной почты, ИНН, ОГРНИП и иные данные, которые 
                                    были (будут) переданы Компании мною лично или поступили (поступят) Компании иным способом. 
                                </span>

                                <span>
                                    Я согласен(а) с тем, что Компания может проверить достоверность предоставленных мною персональных данных, в том числе 
                                    с использованием услуг других операторов, а также использовать полученную информацию при рассмотрении вопросов 
                                    о заключении новых сделок.  
                                </span>

                                <span>
                                    Согласие предоставляется на осуществление любых действий, допустимых согласно законодательству и которые необходимы или 
                                    желаемы для достижения указанных выше целей, включая: сбор, систематизацию, хранение, уточнение (обновление, изменение), 
                                    использование, передачу (предоставление, доступ), обезличивание, блокирование, уничтожение; с использованием и без 
                                    использования средств автоматизации как способов обработки персональных данных. При этом Компания вправе передавать 
                                    мои (Заявителя) персональные данные третьим лицам в целях выполнения требований законодательства, договорных 
                                    обязательств передо мной (Заявителем), а также для достижения иных целей данного согласия, в необходимом объеме и с 
                                    соблюдением требований законодательства. В таких случаях настоящее согласие считается данным указанным выше третьим 
                                    лицам, и такие третьи лица имеют право на обработку моих персональных данных на основании настоящего согласия.   
                                </span>

                                <span>
                                    Настоящее согласие действует до истечения трех лет со дня прекращения отношений с Компанией. Согласие может быть отозвано 
                                    в любой момент путем направления обращения в письменной форме по адресу Компании либо по электронному 
                                    адресу: <a href="mailto:data.privacy.ru@deltaleasing.ru">data.privacy.ru@deltaleasing.ru</a>. Мне понятно, что полный или частичный отзыв настоящего согласия, 
                                    а также прекращение предусмотренной согласием обработки персональных данных могут привести к невозможности 
                                    достижения предусмотренной согласием цели обработки персональных данных. В случае отзыва настоящего согласия 
                                    Компания будет вправе продолжить обработку всех или части персональных данных в случаях, установленных законодательством .
                                </span>
                                
                                {fields && fields.IsMailing == (true || '1')
                                    && (
                                        <span>
                                            3) <b className="companies_agreement__text_bold">согласия на получение автоматических рассылок</b> (автоматической передачи текстовых и иных сообщений) информационного 
                                            и (или) рекламного характера по сетям электрической и радиотелефонной (в том числе подвижной) связи по адресам 
                                            электронной почты и на номера телефонов (в том числе мобильных), распространителем (заказчиком) которых является Компания 
                                        </span>
                                    )}
                            </div>
                        )}
                </div>
            </div>
        )
    }
}