import React, { Component } from "react";
import PropTypes from "prop-types";

export default class KPCalculation extends Component {

    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render() {

        const {
            id,
            applicationId,
            close,
            commercialOfferAcceptAction,
        } = this.props;

        return (
            <div className="kp_calculation">
                <h3 className="kp_calculation__title">{this.context.translate('Вы уверены, что хотите выбрать итоговый расчет?')}</h3>
                <div className="form__buttons">
                    <button
                        className={"btn"}
                        onClick={() => {
                            close();
                            commercialOfferAcceptAction && commercialOfferAcceptAction(id, applicationId);
                        }}
                    >
                        {this.context.translate('Подтвердить')}
                    </button>
                </div>
            </div>
        )
    }
}
