import React, {Component} from "react";
import ProjectsListItem from "./ProjectsListItem";
import PropTypes from "prop-types";

import {Link} from "react-router-dom";

import noProjects from "../../../img/no-projects.svg";
import notFound from "../../../img/magnigier.svg";

export default class ProjectsList extends Component {


    static contextTypes = {
        currentLanguage: PropTypes.object,
        translate: PropTypes.func
    };

    render() {

        return <div className="page__projects">
            {this.props.list ? <div className="projects-list">
                {this.props.list.length ? <div className="projects-list__items">
                    {this.props.list.map((item) => <ProjectsListItem item={item}
                                                                          key={"project-"+item.ProjectId}
                                                                          lesseeCount={this.props.lesseeCount}
                                                                          downloadContractSchedule={this.props.downloadContractSchedule}
                                                                          activeTab={this.props.activeTab}
                                                                          getDocuments={this.props.getDocuments}
                    />)}
                </div> : <NotFound translate={this.context.translate}/>}
            </div> : null}
        </div>
    }
}

export function EmptyList({translate}){
    return <div className="page__no-projects">
        <div className="info-widget info-widget_centred-projects">
            <div className="info-widget__head">
                <img className="info-widget__image" src={noProjects} alt=""/>
            </div>
            <div className="info-widget__title">{translate('У вас пока нет одобренных проектов')}</div>
            <div className="info-widget__message">{translate('')}<br/>
            </div>
            <div className="info-widget__footer">
                <Link className="info-widget__footer-btn u-btn u-btn_orange" to="/applications/create">{translate('Создать заявку')}</Link>
            </div>
        </div>
    </div>
}

function NotFound({translate}) {
    return <div className="info-widget">
            <div className="info-widget__head">
                <img className="info-widget__image" src={notFound} alt=""/>
            </div>
            <div className="info-widget__title">{translate('Ничего не найдено')}</div>
            <div className="info-widget__message">{translate('Пожалуйста, повторите запрос с другими параметрами.')}</div>
        </div>
}