export const screenSizeLg = 1280;
export const screenSizeMd = 768;

export const isSupportUser = IS_SUPPORT_USER;
export const sentry_dsn = SENTRY_DSN;

/**
 * @returns {string}
 */
export function getScreenSize() {
    if (window.innerWidth >= screenSizeLg) return 'lg';
    if (window.innerWidth >= screenSizeMd) return 'md';
    return 'sm';
}

/**
 * @param cnt - items count
 * @param one - title for 1 item
 * @param some - title for 2 items
 * @param plural - title for plural items
 * @return string
 */
export function getWord(cnt, one, some, plural){
    if ([11,12,13,14].indexOf(cnt % 100) !== -1) return plural;
    switch (cnt % 10) {
        case 1:
            return (cnt % 100 === 11 ? plural : one);
        case 2:
        case 3:
        case 4:
            return some;
        default:
            return plural;
    }
}

export function getRandomInt(min, max){
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getFileSize(bytes) {
    var measure = [
        'б',
        'Кб',
        'Мб',
        'Гб'
    ];
    var n = 0;
    while (bytes/1024 > 1 && n < 3) {
        bytes = bytes/1024;
        n++;
    }
    return bytes.toFixed(n)+" "+measure[n];
}

export function detectmobile() {
    if( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    ){
        return true;
    }
    else {
        return false;
    }
}


export function getTime(diff, translate){
    if (diff >= 60) {
        diff = Math.round(diff/60);
        if (diff >= 60) {
            diff = Math.round(diff/60);
            if (diff >= 24) {
                diff = Math.round(diff/24);
                if (diff >= 7) {
                    if (diff >= 30) {
                        diff = Math.round(diff/30);
                        if (diff >= 12) {
                            diff = Math.round(diff/12);
                            return diff+" "+getWord(diff, translate("год"), translate("года"), translate("лет"));
                        } else {
                            return diff+" "+getWord(diff, translate("месяц"), translate("месяца"), translate("месяцев"));
                        }
                    } else {
                        diff = Math.round(diff/7);
                        return diff+" "+getWord(diff, translate("неделю"), translate("недели"), translate("недель"));
                    }
                } else {
                    return diff+" "+getWord(diff, translate("день"), translate("дня"), translate("дней"));
                }
            } else {
                return diff+" "+getWord(diff, translate("час"), translate("часа"), translate("часов"));
            }
        } else {
            return diff+" "+getWord(diff, translate("минуту"), translate("минуты"), translate("минут"));
        }
    } else {
        diff = Math.round(diff);
        return diff+" "+getWord(diff, translate("секунду"), translate("секунды"), translate("секунд"));
    }
}

// форматирует дату
export const optionsMonthYearNumeric = { day: 'numeric', month: 'numeric', year: 'numeric' };
export const formatDate = (date, options) => new Intl.DateTimeFormat('ru-RU', options).format(new Date(date));

export const newDate = new Date();
export function newDateConstructor(data) {
    return new Date(data);
}
export function parseDate(dateStr) {
    const [day, month, year] = dateStr.split('.').map(Number);
    return new Date(year, month - 1, day);
  }
export function isValidDateFormat(value) {
    // Регулярное выражение для проверки формата dd/mm/yyyy
    const regex = /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d\d$/;
    return regex.test(value);
}   
// устанавливаем квери параметр в адресную строку
export function setQueryParam(name, data) {
    const url = new URL(document.location);
    url.searchParams.set(name, data);
    window.history.pushState({}, '', url.toString());
}

// получаем имя квери параметра из адресной строки
export function hasQueryParam(name) {
    const url = new URL(document.location);
    return url.searchParams.has(name);
}

// получаем значение квери параметра из адресной строки
export function getQueryParam(name) {
    const url = new URL(document.location);
    return url.searchParams.get(name);
}

// удаляем квери параметр из адресной строки
export function deleteQueryParam(name) {
    const url = new URL(document.location);
    url.searchParams.delete(name);
    window.history.pushState({}, '', url.toString());
}

// преобразуем дату из ISO формата в "день.месяц.год"
export function convertTimeISOinDMY(data, withZero) {
    const day = new Date(data).getDate();
    const month = (((new Date(data).getMonth() + 1) < 10) && withZero ? '0' : '') + (new Date(data).getMonth() + 1);
    const fullYear = new Date(data).getFullYear();

    return `${day}.${month}.${fullYear}`;
}

// ЭТО ВАРИАНТ для redux sagas, конвертируем формат blob в base64
export function readAsText(blob) {
    return new Promise((resolve, reject) => {
      const reader  = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    })
}

// декодируем base64 в массив байтов
export function base64ToArrayBuffer(base64) {
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

// генерация рандомного id
export function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}
// устанавливаем кукис
export function setCookie(name,value,days) {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

// получаем кукис
export function getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

// удаляем кукис
export function eraseCookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
