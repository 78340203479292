import {
    call,
    put,
    take
} from 'redux-saga/effects';

import {
    fetchClientApi,
    checkErrors
} from '../tools/api-helper';

import {checkToken} from "./auth";

export function companiesList() {
    return {
        type: 'COMPANIES_LIST'
    }
}

// определяем где была выбрана компания в блоке "Информация о поставщике" или в маленьком модальном окне
export function selectedTypeVendorInformationAction(data) {
    return {
        type: 'SELECTED_TYPE_VENDOR_INFORMATION',
        data
    }
}

// определяем где была выбрана компания в блоке "Компания-лизингополучатель" или в большом модальном окне
export function selectedTypeLeasingCompanyAction(data) {
    return {
        type: 'SELECTED_TYPE_LEASING_COMPANY',
        data
    }
}

export function* fetchCompaniesList(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/companies", {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function companiesDetail(id) {
    return {
        type: 'COMPANIES_DETAIL',
        id
    }
}

export function* fetchCompaniesDetail(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/companies/"+action.id, {
                method: 'GET',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function companiesRequest(id, body) {
    return {
        type: 'COMPANIES_REQUEST',
        id,
        body
    }
}

export function* fetchCompaniesRequest(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/companies/"+action.id, {
                method: 'POST',
                body: action.body,
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, data, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function companyAccept(id) {
    return {
        type: 'COMPANY_ACCEPT',
        id
    }
}

export function* fetchCompanyAccept(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/companies/"+action.id+'?action=accept', {
                method: 'PUT',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, action, status));
        }

    } catch(e) {
        alert(e);
    }

}

export function companyReject(id) {
    return {
        type: 'COMPANY_REJECT',
        id
    }
}

export function* fetchCompanyReject(action) {
    try {

        yield put(checkToken(action.type));
        yield take(action.type+'_TOKEN_OK');


        const { status, data, error } = yield call( () => {
            return fetchClientApi("/companies/"+action.id+'?action=reject', {
                method: 'PUT',
                token: localStorage.getItem('authToken')
            })
        });

        if (error) yield put(checkErrors(action.type, {message: error}, 403));
        else {
            yield put(checkErrors(action.type, action, status));
        }

    } catch(e) {
        alert(e);
    }

}
