import React, {Component} from "react";

import {connect} from "react-redux";

import Projects from "../../components/projects/Projects";

import {projectsFilter, projectsList, downloadContractSchedule, projectDocuments} from "../../actions/projects";
import {autoComplete} from "../../actions/catalog";

class ProjectsContainer extends Component{

    search = (params = null) => {
        this.props.getList(params)
    }

    render(){

        let {
            projects,
            isEmpty,
            catalog,
            user,
            autocomplete,
            downloadContractSchedule,
            location,
            getDocuments
        } = this.props;

        return <Projects catalog={catalog}
                         autocomplete={autocomplete}
                         search={this.search}
                         {...projects}
                         user={user}
                         isEmpty={isEmpty}
                         downloadContractSchedule={downloadContractSchedule}
                         location={location}
                         getDocuments={getDocuments}
        />
    }

}

export default connect(
    state => ({
        projects: state.projects,
        isEmpty: state.projects.isOk && !state.projects.count,
        catalog: state.catalog,
        user: state.catalog && state.catalog.user,
    }),
    (dispatch) => {
        return {
            getList: (params) => dispatch(projectsList(params)),
            getFilterInfo: () => dispatch(projectsFilter()),
            autocomplete: (type, query) => dispatch(autoComplete(type, query)),
            downloadContractSchedule: (id) => dispatch(downloadContractSchedule(id)),
            getDocuments: (id) => dispatch(projectDocuments(id, 1))
        }
    }
)(ProjectsContainer)
