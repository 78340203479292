import React, { Component } from "react";
import DocumentsTableItem from "./DocumentsTableItem";

export default class DocumentsTable extends Component {

    constructor(props) {
        super(props);
        this.labels = ['Название документа', 'Лизингополучатель', 'К чему относится', 'Статус документа', 'Дата отправки', 'Файл'];
    }

    render() {
        const {
            list,
            sort,
            sortDoc,
        } = this.props;

        return <div className="page__docs-table">
            <div className="default-table default-table_documents default-table_pp-documents js-demo">
                <div className="default-table__head">
                    <div className="default-table__row">
                        {this.labels.map((item, key) => 
                            <div className="default-table__th" key={key}>
                                {key === 4 ? 
                                    <a 
                                        href="#"
                                        className="default-table__wrap_arrow"
                                        onClick={sortDoc}
                                    >
                                    {item}
                                    <span className={'arrow '+(sort === 'DESC' ? 'down' : 'up')}>&#10141;</span>
                                </a>
                                    : item}
                            </div>
                        )}
                    </div>
                </div>
                <div className="default-table__body">
                    {list.map((item, key) => (
                        <React.Fragment key={key}>
                            <DocumentsTableItem
                                labels={this.labels}
                                item={item}
                                {...this.props}
                            />
                        </React.Fragment>
                    ))}
                </div>
            </div>
            {this.props.children}
        </div>
    }
}