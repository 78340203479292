import React, {Component, Fragment} from 'react';
import {createStore, applyMiddleware} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import createHistory from 'history/createBrowserHistory';
import {routerMiddleware} from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';

import {rootSaga} from '../actions/actions';
import rootReducer from '../reducers/rootReducer';
import {getScreenSize, isSupportUser, sentry_dsn} from "../tools/tools";
import * as Sentry from "@sentry/react";

export const history = isSupportUser ? createHistory({basename: "/users/frontend"}) : createHistory();
const middleware = routerMiddleware(history);

let initState = {};

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, initState, applyMiddleware(middleware, sagaMiddleware));

sagaMiddleware.run(rootSaga);

import Loadable from 'react-loadable';

const LoadableLoginComponent = Loadable({
    loader: () => import('./Login'),
    loading: function () {
        return <div/>;
    }
});
const LoadableCabinetComponent = Loadable({
    loader: () => import('./Router'),
    loading: function () {
        return <div/>;
    }
});

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            testAddress: ''
        };
    }

    componentDidMount = () => {
        var target = document.getElementById('modal');
        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.type == 'childList') {
                    let scrollWidth = window.innerWidth - document.body.clientWidth;
                    if (document.getElementById("modal").innerHTML) {
                        if (!document.body.classList.contains("unscrollable")) {
                            document.body.classList.add("unscrollable");
                            if (scrollWidth) {
                                document.body.setAttribute('style', 'padding-right:' + scrollWidth + 'px');
                                document.getElementById("modal").setAttribute('style', 'padding-right:' + scrollWidth + 'px');
                            }
                        }
                    } else {
                        document.body.classList.remove("unscrollable");
                        document.body.removeAttribute('style');
                    }
                }
            });
        });
        var config = {attributes: true, childList: true, characterData: true};
        observer.observe(target, config);

        if (isSupportUser) {
            var target2 = document.getElementById('root');
            var observer2 = new MutationObserver(function (mutations) {
                mutations.forEach(function (mutation) {
                    if (mutation.type == 'childList') {
                        if (document.getElementsByClassName('page-blank').length) {
                            if (!document.body.classList.contains("unscrollable")) {
                                document.body.classList.add("unscrollable");
                            }
                        } else {
                            document.body.classList.remove("unscrollable");
                        }
                    }
                });
            });
            observer2.observe(target2, config);
        }

        // Показываем верхнюю плашку, если площадка тестовая и не локальная
        const hostname = window.location.hostname;
        const isAdminka = window.location.href.indexOf('frontend') !== -1;
        if(
            hostname !== 'localhost' &&
            hostname !== 'online.deltaleasing.ru' &&
            hostname !== 'backend.deltaleasing.ru'
        ) {

            const arrHostName = hostname.split('.');
            const stageItem = arrHostName.find(el => el.indexOf('stage') !== -1)

            if(stageItem) {
                let testAddress = '';
                testAddress += 'Тестовый';
                testAddress += " " + (stageItem.split('stage')[1] ? stageItem.split('stage')[1] : '1')
                testAddress += `. ${isAdminka ? 'Админка' : 'Фронт'}`

                this.setState({testAddress})
            }
        }
    };

    componentDidCatch(error, errorInfo) {
        if(sentry_dsn) {
            Sentry.withScope(scope => {
                Object.keys(errorInfo).forEach(key => {
                    scope.setExtra(key, errorInfo[key]);
                });
                Sentry.captureException(error);
            });
        }
    }

    render() {
        let {
            isGuest
        } = this.props;

        return (
            <Fragment>
                {this.state.testAddress && <div className="test-info-block">{this.state.testAddress}</div>}
                <div className={`${this.state.testAddress != '' ? 'big-container' : ''}`}>
                    {
                        isGuest ? <LoadableLoginComponent/> : <LoadableCabinetComponent/>
                    }
                </div>
            </Fragment>
        );
    }
}

export default withRouter(connect(
    state => ({
        isGuest: state.auth && state.auth.isGuest
    })
)(App));

export {store};