const initState = [];

export function files(state = initState, action) {
    switch (action.type) {
        case 'ADD_FILE':
            return [...state, action.file];

        case 'ADD_FILE_SUCCESS':
        case 'ADD_FILE_CREATED':

            var newState = [...state];
            newState.map((item) => {
                if (item.ID == action.data.file.LoadStamp) {
                    item.status = 'success';
                    item.ID = action.data.file.ID;
                }
                return item;
            });
            return newState;

        case 'ADD_FILE_BAD_REQUEST':
        case 'COMMUNICATIONS_ADD_FILE_BAD_REQUEST':
            var newState = [...state];
            newState.map((item) => {
                if (item.ID == action.data.file.LoadStamp) {
                    item.status = 'error';
                    item.error = 'Ошибка загрузки файла '+(action.data.message?action.data.message:'');
                }
                return item;
            });
            return newState;

        case 'ADD_FILE_ERROR':
        case 'COMMUNICATIONS_ADD_FILE_ERROR':

            var newState = [...state];
            newState.map((item) => {
                if (item.ID == action.data.file.LoadStamp) {
                    item.status = 'error';
                    item.error = 'Ошибка загрузки файла '+(action.data.name?action.data.name:'');
                }
                return item;
            });
            return newState;

        case 'UPDATE_FILE':
        case 'COMMUNICATIONS_UPDATE_FILE':

            var newState = [...state];
            newState.map((item) => {
                if (item.ID == action.fileId) {
                    item.filenameError = '';
                }
                return item;
            });
            return newState;

        case 'UPDATE_FILE_BAD_REQUEST':
        case 'COMMUNICATIONS_UPDATE_FILE_BAD_REQUEST':

            var newState = [...state];
            newState.map((item) => {
                if (item.ID == action.data.fileId) {
                    item.filenameError = action.data.data.message;
                }
                return item;
            });
            return newState;

        case 'UPDATE_FILE_SUCCESS':
        case 'COMMUNICATIONS_UPDATE_FILE_SUCCESS':

            var newState = [...state];
            newState.map((item) => {
                if (item.ID == action.data.fileId) {
                    item.filename = action.data.fileName;
                }
                return item;
            });
            return newState;

        case 'DELETE_FILE_SUCCESS':
        case 'COMMUNICATIONS_DELETE_FILE_SUCCESS':
            var newState = [...state];
            return newState.filter((item) => {
                return item.ID != action.data.fileId;
            });

        case 'CANCEL_FILE':
            var newState = [...state];
            return newState.filter((item) => {
                return item.ID != action.id;
            });

        case 'CLEAR_BUFFER':
            return [];


        case 'COMMUNICATIONS_ADD_FILE':
            return [...state, action.file];
        case 'COMMUNICATIONS_ADD_FILE_CREATED':
            var newState = [...state];

            newState.map((item) => {
                if (item.ID == action.data.file.ID) {
                    item.status = 'success';
                    item.ID = action.data.FileId;
                }
                return item;
            });
            return newState;
        case "LOGOUT_SUCCESS":
            return {...initState};
    }
    return state;
}